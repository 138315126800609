<div
  class="bew-box"
  [class.bew-box-error]="erroneous"
  [class.bew-box-ok]="!erroneous"
  [class.bew-box-disabled]="disabled">
  <span *ngIf="hasLabel" class="bew-box-title">{{ label }}</span>
  <ng-content></ng-content>
  <span *ngIf="hasIcons" class="bew-box-icon-container">
    <span *ngIf="showErrorIcon" class="bew-box-icon"></span>
  </span>
</div>
