import { Component, Input, OnInit } from '@angular/core';
import {
  FormComponentWithDisabled,
  FormComponentWithLabel,
} from '../form-component';

@Component({
  selector: 'app-bew-box',
  templateUrl: './bew-box.component.html',
  styleUrls: ['./bew-box.component.scss'],
})
export class BewBoxComponent
  implements OnInit, FormComponentWithLabel, FormComponentWithDisabled
{
  constructor() {}

  @Input()
  erroneous: boolean = false;
  @Input()
  label: string = '';
  @Input()
  showErrorIcon: boolean = false;
  @Input()
  disabled: boolean = false;

  ngOnInit(): void {}

  get hasLabel(): boolean {
    return this.label.length > 0;
  }

  get hasIcons(): boolean {
    return this.showErrorIcon;
  }
}
