import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormComponentWithLabel } from '../form-component';

export enum Type {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Undefined = '',
}

@Component({
  selector: 'app-bew-notification',
  templateUrl: './bew-notification.component.html',
  styleUrls: ['./bew-notification.component.scss'],
})
export class BewNotificationComponent implements FormComponentWithLabel {
  @Input()
  canBeDismissed: boolean = true;
  @Input()
  label: string = 'MISSING_LABEL';
  @Input()
  text: string = 'MISSING_TEXT';
  @Input()
  technicalInfo: string = '';
  @Input()
  showTechnicalInfoText: string = 'SHOW_TECHNICAL_INFO';
  @Input()
  dismissButtonLabel: string = 'DISMISS_LABEL';
  @Output()
  readonly visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  showTechnicalInfo: boolean = false;

  constructor() {}

  private _type: Type = Type.Undefined;

  /**
   * The type of the notification component. This is required to be set (if not set, styling won't work correctly).
   */
  @Input()
  set type(type: 'error' | 'warning' | 'info') {
    switch (type) {
      case Type.Error:
        this._type = Type.Error;
        break;
      case Type.Warning:
        this._type = Type.Warning;
        break;
      case Type.Info:
        this._type = Type.Info;
        break;
      default:
        throw `Unknown notification type $type`;
    }
  }

  private _visible: boolean = false;

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
  }

  /**
   * This is an alternative to `technicalInfo` if you want the component to convert the exception into technical info.
   */
  @Input()
  set exception(exception: any | undefined) {
    this.technicalInfo =
      BewNotificationComponent.convertExceptionToString(exception);
  }

  get hasTechnicalInfo(): boolean {
    return this.technicalInfo.length > 0;
  }

  get isTypeInfo(): boolean {
    return this._type === Type.Info;
  }

  get isTypeWarning(): boolean {
    return this._type === Type.Warning;
  }

  get isTypeError(): boolean {
    return this._type === Type.Error;
  }

  private static convertExceptionToString(exception?: any): string {
    if (exception === undefined || exception === null) {
      return '';
    } else {
      if (Array.isArray(exception)) {
        let string = '';
        for (const item of exception) {
          string +=
            BewNotificationComponent.convertSingleExceptionToString(item);
          string += '\n';
        }
        return string;
      } else {
        return BewNotificationComponent.convertSingleExceptionToString(
          exception,
        );
      }
    }
  }

  private static convertSingleExceptionToString(exception?: any): string {
    if (exception === undefined || exception === null) {
      return '';
    } else {
      const toString = BewNotificationComponent.exceptionToString(exception);
      const stringify = BewNotificationComponent.exceptionStringify(exception);
      return `${toString}\n\n${stringify}`;
    }
  }

  private static exceptionToString(exception: any): string {
    try {
      // yes, this can throw (the toString method can be overwritten).
      return exception.toString();
    } catch (error) {
      console.warn('Error converting exception to string.', error);
      return '';
    }
  }

  private static exceptionStringify(exception: any): string {
    try {
      // yes, this can throw: If the exception is self-referencing.
      return JSON.stringify(exception);
    } catch (error) {
      console.warn('Cannot stringify exception.', error);
      return '';
    }
  }

  onInvokeDismiss() {
    this._visible = false;
    this.visibleChange.emit(false);
  }
}
