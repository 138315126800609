import {
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  BetriebApiService,
  BetriebsleiterChangeAntragApiService,
  BetriebsleiterChangeAntragDTO,
} from '../../../../backend';
import { SortConfiguration } from '../../../table-column-sort/table-column-sort.component';
import {
  EfoTableComponent,
  SearchConfiguration,
} from '../../../efo-table/efo-table.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../../../error-modal/error-modal.component';

@Component({
  selector: 'app-antrag-betriebsleiter-change-current',
  templateUrl: './antrag-betriebsleiter-change-current.component.html',
  styleUrl: './antrag-betriebsleiter-change-current.component.scss',
})
export class AntragBetriebsleiterChangeCurrentComponent {
  @ViewChildren(EfoTableComponent) // @ts-ignore
  efoBenutzerTableComponent!: QueryList<EfoTableComponent>;
  @Output()
  public reload = new EventEmitter<void>();

  antraege?: Array<BetriebsleiterChangeAntragDTO> = [];

  totalElementsAntraege: number = 0;
  totalPagesAntraege: number = 0;
  numberOfElementsAntraege: number = 0;

  id: string | undefined = undefined;

  pageNumber: number = 0;
  pageSize: number = 25;

  totalItems: number = 0;
  numberOfItems: number = 0;
  totalPages: number = 0;

  searchTerm: string = '';

  constructor(
    private readonly betriebApiService: BetriebApiService,
    private readonly betriebsleiterChangeAntragApiService: BetriebsleiterChangeAntragApiService,
    private modalService: NgbModal,
  ) {}

  ngAfterViewInit() {
    this.searchAll();
  }

  searchAll() {
    this.efoBenutzerTableComponent.forEach((table) => table.search());
  }

  public searchBerechtigungAntraege(searchConfiguration: SearchConfiguration) {
    this.betriebsleiterChangeAntragApiService
      .findAllByCurrentBenutzer(
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.antraege = page.content;
        if (page.totalElements) {
          this.totalElementsAntraege = page.totalElements;
        } else {
          this.totalElementsAntraege = 0;
        }
        if (page.totalPages) {
          this.totalPagesAntraege = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsAntraege = page.numberOfElements;
        }
        this.hasberechtigungsAntraege();
      });
  }

  sortConfiguration: SortConfiguration = {
    sortBy: 'createDate',
    direction: 'DESC',
  };

  withdraw(antragDTO: BetriebsleiterChangeAntragDTO) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = [
      'modal.confirm.delete.betriebsleiter_change_antrag',
    ];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';

    modalRef.result.then((result) => {
      if (result) {
        if (antragDTO && antragDTO.id) {
          this.betriebsleiterChangeAntragApiService
            .withdraw(antragDTO.id)
            .subscribe((value) => this.reload.emit());
        }
      }
    });
  }

  hasberechtigungsAntraege() {
    return this.antraege != undefined && this.antraege.length > 0;
  }
}
