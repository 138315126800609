import { Component, EventEmitter, Output } from '@angular/core';
import {
  BerechtigungAntragDTO,
  BetriebApiService,
  BetriebDTO,
} from '../../../backend';
import { SearchConfiguration } from '../../efo-table/efo-table.component';
import { BerechtigungService } from '../../../services/berechtigung.service';

@Component({
  selector: 'app-betrieb-select',
  templateUrl: './betrieb-select.component.html',
  styleUrl: './betrieb-select.component.scss',
})
export class BetriebSelectComponent {
  @Output()
  public reload = new EventEmitter<void>();

  totalElements: number = 0;
  totalPages: number = 0;
  numberOfElements: number = 0;

  berechtigungAntraege?: Array<BerechtigungAntragDTO> = [];

  betriebe?: Array<BetriebDTO> = [];

  id: string | undefined = undefined;

  constructor(
    public readonly berechtigungService: BerechtigungService,
    private readonly betriebApiService: BetriebApiService,
  ) {}

  findAll(searchConfiguration: SearchConfiguration) {
    this.betriebApiService
      .findAll(
        searchConfiguration.searchTerm,
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.betriebe = page.content;
        if (page.totalElements) {
          this.totalElements = page.totalElements;
        } else {
          this.totalElements = 0;
        }
        if (page.totalPages) {
          this.totalPages = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElements = page.numberOfElements;
        }
      });
  }

  select(betriebDTO: BetriebDTO) {
    this.berechtigungService.setBetrieb(betriebDTO);
  }

  protected readonly Object = Object;
}
