import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';

export interface FormComponentWithError {
  /**
   * If this value is non-empty, the form component is erroneous. The given error string is displayed to the user.
   */
  error: string;
  errors: ValidationErrors | null;
}

export interface RadioFormComponent {
  checked: boolean;
  readonly checkedChange: EventEmitter<boolean>;
}

export interface InvokedEventComponent {
  /**
   * Element has been invoked (usually clicked).
   */
  readonly invoke: EventEmitter<void>;
}

export interface FormComponentWithLabel {
  /**
   * Describes the form component.
   */
  label: string;
}

export interface FormComponentWithDisabled {
  /**
   * Disables the form component. Default value is 'false' (unless stated otherwise).
   */
  disabled: boolean;
}

export interface FormComponentWithInline {
  /**
   * Whether to display the component inline. Default value is 'false'.
   */
  inline: boolean;
}

@Component({
  template: '',
})
export abstract class BewFormComponent<T>
  implements ControlValueAccessor, FormComponentWithDisabled
{
  /**
   * Bind this to the input control.
   */
  @Input()
  abstract disabled: boolean;
  @Output()
  readonly valueChange: EventEmitter<T> = new EventEmitter<T>();
  @Output()
  readonly touched: EventEmitter<T> = new EventEmitter<T>();

  isTouched: boolean = false;

  /**
   * Call this after a value change.
   */
  onValueChanged() {
    const currentValue = this.readValueFromControl();
    this.onChangeFn(currentValue);
    this.valueChange.emit(currentValue);
  }

  /**
   * Call this - usually on blur.
   */
  onTouched() {
    this.onTouchedFn();
    this.isTouched = true;
    this.touched.emit();
  }

  private onTouchedFn = () => {};
  private onChangeFn = (_value: T) => {};

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  writeValue(obj: any): void {
    this.writeValueToControl(obj);
    this.onValueChanged();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Reads the current value (either directly from the control or from a field).
   */
  protected abstract readValueFromControl(): T;

  /**
   * Writes the value to the control.
   */
  protected abstract writeValueToControl(value: T): void;

  static setError(from: ValidationErrors | null, to: FormComponentWithError) {
    if (from === null) {
      to.error = '';
    } else {
      let currentString = '';
      for (const [, value] of Object.entries(from)) {
        if (currentString.length > 0) {
          currentString += '; ';
        }
        currentString += value.toString();
      }
      to.error = currentString;
    }
  }
}

@Component({
  template: '',
})
export abstract class BewFormComponentWithValue<T> extends BewFormComponent<T> {
  @Input()
  @Output()
  value: T = this.initialValue();

  protected abstract initialValue(): T;

  protected readValueFromControl(): T {
    return this.value;
  }

  protected writeValueToControl(value: T) {
    this.value = value;
  }
}
