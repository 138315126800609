<!-- the header. We use a header with only service navigation and no navigation menu -->
<div class="container">
  <app-bew-simple-header
    (languageSelected)="onLanguageSelected($event)"
    [breadcrumbData]="convertNullToUndefined(breadcrumbData | async)"
    [serviceNavigationData]="
      convertNullToUndefined(serviceNavigationData | async)
    "
    [signetLabel]="'Eigene Formel'"
    [signetLinkAction]="signetLinkAction"
    [mainMenuData]="convertNullToUndefined(mainMenuData | async)">
  </app-bew-simple-header>
</div>
<div class="container">
  <router-outlet></router-outlet>
</div>

<!-- The footer: It occupies the entire screen width, so it's places outside the bootstrap container; the actual footer then is wrapped inside the bootstrap container -->
<app-bew-footer-container>
  <div class="container">
    <app-bew-footer
      [signetLabel]="'footer.signetlabel' | translate"
      [aboutText]="'footer.about' | translate"
      [copyrightText]="
        'footer.copyright' | translate: { year: getCurrentYear() }
      "
      [footerNavigationItems]="
        convertNullToUndefined(footerNavigationItems | async)
      ">
    </app-bew-footer>
  </div>
</app-bew-footer-container>
