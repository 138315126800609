import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BewGalleryBodyComponent } from './gallery/bew-gallery/bew-gallery-body/bew-gallery-body.component';
import { BewButtonComponent } from './components/bew-button/bew-button.component';
import { BewTextareaComponent } from './components/bew-textarea/bew-textarea.component';
import { BewInputComponent } from './components/bew-input/bew-input.component';
import { BewFileUploadComponent } from './components/bew-file-upload/bew-file-upload.component';
import { BewCheckboxComponent } from './components/bew-checkbox/bew-checkbox.component';
import { BewRadioComponent } from './components/bew-radio/bew-radio.component';
import { BewGalleryReactiveFormComponent } from './gallery/bew-gallery/bew-gallery-reactive-form/bew-gallery-reactive-form.component';
import { BewNotificationComponent } from './components/bew-notification/bew-notification.component';
import { BewServiceNavigationComponent } from './components/bew-service-navigation/bew-service-navigation.component';
import { BewMainMenuComponent } from './components/bew-main-menu/bew-main-menu.component';
import { BewLinkActionDirective } from './components/link-action/bew-link-action.directive';
import { BewBreadcrumbComponent } from './components/bew-breadcrumb/bew-breadcrumb.component';
import { BewSimpleHeaderComponent } from './components/bew-simple-header/bew-simple-header.component';
import { BewFooterComponent } from './components/bew-footer/bew-footer.component';
import { BewGalleryFooterComponent } from './gallery/bew-gallery/bew-gallery-footer/bew-gallery-footer.component';
import { BewFooterNavigationComponent } from './components/bew-footer-navigation/bew-footer-navigation.component';
import { BewFooterContainerComponent } from './components/bew-footer-container/bew-footer-container.component';
import { BewHeroImageComponent } from './components/bew-hero-image/bew-hero-image.component';
import { BewDropTargetComponent } from './components/bew-drop-target/bew-drop-target.component';
import { BewSingleUploadComponent } from './components/bew-single-upload/bew-single-upload.component';
import { BewPendingComponent } from './components/bew-pending/bew-pending.component';
import { BewGalleryHeaderComponent } from './gallery/bew-gallery/bew-gallery-header/bew-gallery-header.component';
import { BewSignetComponent } from './components/bew-signet/bew-signet.component';
import { BewGalleryMultiUploadComponent } from './gallery/bew-gallery/bew-gallery-multi-upload/bew-gallery-multi-upload.component';
import { BewMultiUploadComponent } from './components/bew-multi-upload/bew-multi-upload.component';
import { BewDateInputComponent } from './components/bew-date-input/bew-date-input.component';
import { BewRadioGroupComponent } from './components/bew-radio-group/bew-radio-group.component';
import { BewGalleryComponent } from './gallery/bew-gallery/bew-gallery/bew-gallery.component';
import { BewSandButtonComponent } from './components/bew-sand-button/bew-sand-button.component';
import { BewBoxComponent } from './components/bew-box/bew-box.component';
import { BewGallerySectionComponent } from './gallery/bew-gallery/bew-gallery-section/bew-gallery-section.component';
import { BewSelectComponent } from './components/bew-select/bew-select.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    BewGalleryBodyComponent,
    BewButtonComponent,
    BewGallerySectionComponent,
    BewTextareaComponent,
    BewInputComponent,
    BewFileUploadComponent,
    BewBoxComponent,
    BewCheckboxComponent,
    BewRadioComponent,
    BewSandButtonComponent,
    BewGalleryComponent,
    BewRadioGroupComponent,
    BewDateInputComponent,
    BewGalleryReactiveFormComponent,
    BewMultiUploadComponent,
    BewGalleryMultiUploadComponent,
    BewNotificationComponent,
    BewSignetComponent,
    BewGalleryHeaderComponent,
    BewServiceNavigationComponent,
    BewMainMenuComponent,
    BewLinkActionDirective,
    BewBreadcrumbComponent,
    BewSimpleHeaderComponent,
    BewFooterComponent,
    BewGalleryFooterComponent,
    BewFooterNavigationComponent,
    BewFooterContainerComponent,
    BewHeroImageComponent,
    BewDropTargetComponent,
    BewSingleUploadComponent,
    BewPendingComponent,
    BewSelectComponent,
  ],
  exports: [
    BewInputComponent,
    BewFileUploadComponent,
    BewButtonComponent,
    BewBoxComponent,
    BewTextareaComponent,
    BewCheckboxComponent,
    BewSandButtonComponent,
    BewRadioComponent,
    BewRadioGroupComponent,
    BewDateInputComponent,
    BewNotificationComponent,
    BewMultiUploadComponent,
    BewLinkActionDirective,
    BewFooterComponent,
    BewFooterContainerComponent,
    BewSimpleHeaderComponent,
    BewHeroImageComponent,
    BewSingleUploadComponent,
    BewPendingComponent,
    BewSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forRoot(),
    PdfJsViewerModule,
    NgbTooltip,
  ],
})
export class BewModule {}
