/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PruefungsNotesDTO } from './pruefungsNotesDTO';

export interface FormelHistoryEntryDTO { 
    username?: string;
    firstName?: string;
    lastName?: string;
    state?: FormelHistoryEntryDTO.StateEnum;
    createDate?: Date;
    pruefungsNotes?: PruefungsNotesDTO;
}
export namespace FormelHistoryEntryDTO {
    export type StateEnum = 'IN_ARBEIT' | 'IN_PRUEFUNG' | 'MELDEBESTAETIGUNG_MIT_KOSTENFOLGE' | 'MELDEBESTAETIGUNG_OHNE_KOSTENFOLGE' | 'MELDEBESTAETIGUNG_NEUE_VERSION_VORHANDEN' | 'ZUR_NACHBEARBEITUNG_ZURUECKGEWIESEN' | 'VERZICHTET';
    export const StateEnum = {
        INARBEIT: 'IN_ARBEIT' as StateEnum,
        INPRUEFUNG: 'IN_PRUEFUNG' as StateEnum,
        MELDEBESTAETIGUNGMITKOSTENFOLGE: 'MELDEBESTAETIGUNG_MIT_KOSTENFOLGE' as StateEnum,
        MELDEBESTAETIGUNGOHNEKOSTENFOLGE: 'MELDEBESTAETIGUNG_OHNE_KOSTENFOLGE' as StateEnum,
        MELDEBESTAETIGUNGNEUEVERSIONVORHANDEN: 'MELDEBESTAETIGUNG_NEUE_VERSION_VORHANDEN' as StateEnum,
        ZURNACHBEARBEITUNGZURUECKGEWIESEN: 'ZUR_NACHBEARBEITUNG_ZURUECKGEWIESEN' as StateEnum,
        VERZICHTET: 'VERZICHTET' as StateEnum
    };
}