import { Set } from 'immutable';

/**
 * The list of file types Dabbawala/Axioma accept is constant and is the same for the entire application. This class keeps a list
 * of accepted files and also a list of rejected files.
 *
 * https://confluence.bedag.ch/display/EFSGKJA/Schnittstellen
 */
export class AcceptedFiles {
  constructor(
    public readonly acceptedMimeTypes: Set<string>,
    public readonly rejectedMimeTypes: Set<string>,
  ) {}

  private static _singleton?: AcceptedFiles;

  static get singleton(): AcceptedFiles {
    if (AcceptedFiles._singleton === undefined) {
      AcceptedFiles._singleton = new AcceptedFiles(
        AcceptedFiles.setOfAcceptedFiles(),
        AcceptedFiles.setOfRejectedFiles(),
      );
    }
    return AcceptedFiles._singleton;
  }

  private static setOfAcceptedFiles(): Set<string> {
    // According to the specification:
    //
    //   - PDF
    //   - Microsoft Office Formats (Word, Excel)
    //   - Bitmaps (JPG, GIF, TIFF)
    //
    // Note: I extend the "Microsoft Office" a bit (this would legally not work if the state would prefer formats of one company) - also
    // include other common office formats.
    return Set([
      // pdf
      'application/pdf',
      // images (bitmaps and svg)
      'image/bmp',
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/tiff',
      'image/webp',
      'image/svg+xml',
      // office documents
      'application/x-abiword',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/rtf',
      'text/plain',
      'application/vnd.visio',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]);
  }

  private static setOfRejectedFiles(): Set<string> {
    // things we know that are executable.
    return Set(['application/octet-stream', 'application/x-msdownload']);
  }
}
