import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { PersistedValue } from '../data/persisted-value';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private readonly persistedCurrentLocale: PersistedValue<string | null> =
    new PersistedValue<string | null>('current_locale', () => {
      return null;
    });

  constructor(
    private readonly translateService: TranslateService,
    private readonly logger: NGXLogger,
  ) {
    this.setupTranslateService();
    this.continuouslyTransferCurrentLocaleToTranslateService();
  }

  /**
   * All locales supported by the system (example: `["de-CH", "fr-CH"]`).
   */
  static get supportedLocales(): Array<string> {
    const supportedLocales = environment.supportedLocales;
    if (!supportedLocales) {
      throw "Supported locales is missing in the environment. Set them! see 'environment.supportedLocales'.";
    }
    return supportedLocales;
  }

  /**
   * Sets a new locale. Does nothing if the given locale is already the current locale.
   */
  setLocale(locale: string) {
    // if it's the one from the browser, just clear the storage.
    if (locale === this.validLocaleFromBrowser()) {
      this.persistedCurrentLocale.value = null;
    } else {
      const currentLocale = this.localeNow;
      if (currentLocale !== locale) {
        this.persistedCurrentLocale.value = locale;
      }
    }
  }

  /**
   * Returns the locale as stream.
   */
  get locale(): Observable<string> {
    return this.persistedCurrentLocale.observable.pipe(
      map((_ignored) => {
        return this.localeNow;
      }),
    );
  }

  /**
   * Returns "true" if the current locale has not been overwritten, and we're using the browser locale.
   */
  get isCurrentlyUsingTheBrowserLocale(): boolean {
    const locale = this.localeNow;
    return locale === this.validLocaleFromBrowser();
  }

  static languageFromLocale(locale: string): string {
    const splits = locale.split('-');
    if (splits.length > 0) {
      return splits[0];
    } else {
      return '';
    }
  }

  /**
   * Returns the current locale.
   */
  get localeNow(): string {
    // first we have to see whether the browser-local has been overwritten.
    const overwritten = this.persistedCurrentLocale.value;
    if (overwritten !== null) {
      if (LocaleService.localeIsSupported(overwritten)) {
        return overwritten;
      } else {
        this.logger
          .warn(`Got something strange in the local storage: '${overwritten}'. A locale \
                that's not supported by the system. Will be removed.`);
        this.persistedCurrentLocale.value = null;
      }
    }

    // ok, ask the browser (this is the normal case for most users).
    const validLocaleFromBrowser = this.validLocaleFromBrowser();
    if (validLocaleFromBrowser !== null) {
      return validLocaleFromBrowser;
    }

    // still no locale. Take the fallback locale.
    this.logger.info(
      "The browser's locale is not supported. Will use the fallback locale.",
    );
    return LocaleService.defaultLocale;
  }

  private static get defaultLocale(): string {
    return environment.defaultLocale;
  }

  private validLocaleFromBrowser(): string | null {
    let localeFromBrowser = this.translateService.getBrowserCultureLang();
    if (localeFromBrowser === undefined || localeFromBrowser === null) {
      return null;
    }
    // we have to check whether the language is supported
    const isSupported = LocaleService.localeIsSupported(localeFromBrowser);
    if (!isSupported) {
      this.logger
        .warn(`The browser locale ${localeFromBrowser} is not supported. The language from the \
      locale is not in the list of supported languages.`);
      return null;
    } else {
      return localeFromBrowser;
    }
  }

  private static localeIsSupported(locale: string): boolean {
    const languageFromLocale = LocaleService.languageFromLocale(locale);
    for (const supportedLocale of LocaleService.supportedLocales) {
      const languageFromSupportedLocale =
        LocaleService.languageFromLocale(supportedLocale);
      if (
        languageFromLocale.trim().toLowerCase() ===
        languageFromSupportedLocale.trim().toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  }

  private continuouslyTransferCurrentLocaleToTranslateService() {
    // transfer initially
    this.transferCurrentLocaleToTranslateService();
    this.locale.subscribe((locale) => {
      this.logger.info(`Locale has changed to '${locale}'`);
      this.transferCurrentLocaleToTranslateService();
    });
  }

  private transferCurrentLocaleToTranslateService() {
    const locale = this.localeNow;
    const language = LocaleService.languageFromLocale(locale);
    this.translateService.use(language);
    this.logger.info(
      `New language for angular translations is '${language}' (locale '${locale}').`,
    );
  }

  private setupTranslateService(): void {
    const languagesToAdd = [];
    for (const locale of LocaleService.supportedLocales) {
      languagesToAdd.push(locale);
      languagesToAdd.push(LocaleService.languageFromLocale(locale));
    }
    this.translateService.addLangs(languagesToAdd);
    const defaultLanguage = LocaleService.languageFromLocale(
      LocaleService.defaultLocale,
    );
    this.translateService.setDefaultLang(defaultLanguage);
  }
}
