import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortConfiguration } from '../table-column-sort/table-column-sort.component';

@Component({
  selector: 'app-efo-table',
  templateUrl: './efo-table.component.html',
  styleUrl: './efo-table.component.scss',
})
export class EfoTableComponent {
  @Output()
  searchContent = new EventEmitter<SearchConfiguration>();

  @Input() totalElements: number = 0;
  @Input() totalPages: number = 0;
  @Input() numberOfElements: number = 0;

  @Input() pageSize: number = 25;
  @Input() searchable: boolean = true;

  id: string | undefined = undefined;

  searchTerm: string = '';

  pageConfiguration: PageConfiguration = {
    pageNumber: 0,
    pageSize: 25,
  };

  sortConfiguration: SortConfiguration = {
    sortBy: 'createDate',
    direction: 'DESC',
  };

  searchConfiguration: SearchConfiguration = {
    searchTerm: '',
    pageConfiguration: this.pageConfiguration,
    sortConfiguration: this.sortConfiguration,
  };

  ngOnInit() {
    this.pageConfiguration.pageSize = this.pageSize;
    this.search();
  }

  search() {
    if (this.searchTerm.length < 3) {
      this.searchConfiguration.searchTerm = undefined;
    } else {
      this.searchConfiguration.searchTerm = this.searchTerm;
    }
    this.searchContent.emit(this.searchConfiguration);
  }

  changeSorting(sortConfiguration: SortConfiguration) {
    this.sortConfiguration = sortConfiguration;
    this.searchConfiguration.sortConfiguration = sortConfiguration;
    this.search();
  }

  public previousPageDisabled() {
    return this.pageConfiguration.pageNumber == 0;
  }

  public previousPage() {
    if (this.pageConfiguration.pageNumber > 0) {
      this.pageConfiguration.pageNumber--;
      this.search();
    }
  }

  public nextPage() {
    if (this.pageConfiguration.pageNumber < this.totalPages) {
      this.pageConfiguration.pageNumber++;
      this.search();
    }
  }

  public nextPageDisabled() {
    return this.pageConfiguration.pageNumber + 1 >= this.totalPages;
  }

  public pageItemsFrom(): number {
    if (this.numberOfElements == 0) {
      return 0;
    }
    return (
      this.pageConfiguration.pageSize * this.pageConfiguration.pageNumber + 1
    );
  }
  public pageItemsTo() {
    if (this.pageConfiguration.pageNumber + 1 >= this.totalPages) {
      return this.numberOfElements;
    }
    return (
      this.pageConfiguration.pageSize * (this.pageConfiguration.pageNumber + 1)
    );
  }

  searchFromStart() {
    this.pageConfiguration.pageNumber = 0;
    this.search();
  }
}

export interface PageConfiguration {
  pageNumber: number;
  pageSize: number;
}

export interface SearchConfiguration {
  searchTerm: string | undefined;
  pageConfiguration: PageConfiguration;
  sortConfiguration: SortConfiguration;
}
