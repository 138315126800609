import { Component, Input, OnInit, Output } from '@angular/core';
import {
  BewFormComponentWithValue,
  FormComponentWithDisabled,
  FormComponentWithInline,
  FormComponentWithLabel,
} from '../form-component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-bew-checkbox',
  templateUrl: './bew-checkbox.component.html',
  styleUrls: ['./bew-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BewCheckboxComponent,
    },
  ],
})
export class BewCheckboxComponent
  extends BewFormComponentWithValue<boolean>
  implements
    OnInit,
    FormComponentWithInline,
    FormComponentWithLabel,
    FormComponentWithDisabled
{
  @Input()
  inline: boolean = false;
  @Input()
  label: string = 'NO_LABEL';
  @Input()
  @Output()
  disabled: boolean = false;

  ngOnInit(): void {}

  protected initialValue(): boolean {
    return false;
  }
}
