import {
  FileFilter,
  FilerResultAccepted,
  FilerResultRejectedFile,
  FilerResultUndesiredFile,
  FilterResult,
} from './file-filter';

export abstract class ListMimeTypeFilter implements FileFilter {
  /**
   * Files that are not allowed.
   */
  readonly filesNotAllowed: ListMimeTypeFilterEntry =
    new ListMimeTypeFilterEntry();
  /**
   * Files that are allowed but not desired, file will be accepted but the user will be presented a warning.
   */
  readonly filesAllowedButNotDesired: ListMimeTypeFilterEntry =
    new ListMimeTypeFilterEntry();

  apply(file: File): FilterResult {
    const mimeType = file.type;
    return this.checkByMimeType(mimeType);
  }

  abstract textForNotAllowed(mimeType: string): string;

  abstract textForAllowedButNotDesired(mimeType: string): string;

  private checkByMimeType(mimeType: string): FilterResult {
    if (!this.filesNotAllowed.isValidMimeType(mimeType)) {
      const text = this.textForNotAllowed(mimeType);
      return new FilerResultRejectedFile(text);
    } else if (!this.filesAllowedButNotDesired.isValidMimeType(mimeType)) {
      const text = this.textForAllowedButNotDesired(mimeType);
      return new FilerResultUndesiredFile(text);
    }
    return new FilerResultAccepted();
  }
}

export class ListMimeTypeFilterEntry {
  whiteList?: Array<string>;
  blackList?: Array<string>;

  private static isMimeTypeFoundIn(
    mimeType: string,
    array: Array<string>,
  ): boolean {
    const normalizedMimeType = mimeType.trim().toLowerCase();
    for (const mimeFromArray of array) {
      if (mimeFromArray.trim().toLowerCase() === normalizedMimeType) {
        return true;
      }
    }
    return false;
  }

  isValidMimeType(mimeType: string): boolean {
    if (this.blackList !== undefined) {
      if (ListMimeTypeFilterEntry.isMimeTypeFoundIn(mimeType, this.blackList)) {
        return false;
      }
    }
    if (this.whiteList !== undefined) {
      return ListMimeTypeFilterEntry.isMimeTypeFoundIn(
        mimeType,
        this.whiteList,
      );
    } else {
      return true;
    }
  }
}
