<div class="bew-breadcrumb" *ngIf="data !== undefined">
  <a [appBewLinkAction]="data.home" class="bew-breadcrumb-home"></a>
  <ng-container *ngFor="let item of data.items">
    <div class="bew-breadcrumb-arrow"></div>
    <a
      [appBewLinkAction]="item.action"
      [class.bew-breadcrumb-link]="!item.active"
      [class.bew-breadcrumb-link-active]="item.active"
      >{{ item.i18nKeyForLabel | translate }}</a
    >
  </ng-container>
</div>
