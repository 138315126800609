/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Wirkstoffrisiko = '{\"code\": 1600, \"name\": \"HOHES\", \"riskFactor\": 5.0}' | '{\"code\": 1601, \"name\": \"MITTLERES\", \"riskFactor\": 3.0}' | '{\"code\": 1602, \"name\": \"GERINGES\", \"riskFactor\": 1.0}';

export const Wirkstoffrisiko = {
    _1600NameHOHESRiskFactor50: '{\"code\": 1600, \"name\": \"HOHES\", \"riskFactor\": 5.0}' as Wirkstoffrisiko,
    _1601NameMITTLERESRiskFactor30: '{\"code\": 1601, \"name\": \"MITTLERES\", \"riskFactor\": 3.0}' as Wirkstoffrisiko,
    _1602NameGERINGESRiskFactor10: '{\"code\": 1602, \"name\": \"GERINGES\", \"riskFactor\": 1.0}' as Wirkstoffrisiko
};