<div class="textarea" [class.invalid]="invalid">
  <textarea
    #textArea
    placeholder="{{ label }}"
    [class.invalid]="invalid"
    [disabled]="disabled"
    (ngModelChange)="onValueChanged()"
    (blur)="onTouched()"
    [(ngModel)]="value"
    [maxLength]="maxlength || 2147483647"
    [minLength]="minlength || 0">
  </textarea>
  <label class="floating-label" [class.invalid]="invalid">{{ label }}</label>
  <span *ngIf="error.length > 0" class="error">{{ error }}</span>
</div>
