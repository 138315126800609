import { Component, Input, OnInit } from '@angular/core';
import { LinkAction } from '../link-action/link-action';

@Component({
  selector: 'app-bew-main-menu',
  templateUrl: './bew-main-menu.component.html',
  styleUrls: ['./bew-main-menu.component.scss'],
})
export class BewMainMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input()
  data?: MainMenuData;
}

export interface MainMenuData {
  /**
   * Small or big menu items. See "Header (Theme)" (small = true) vs. "Header (OE)" (small = false) in the style guide.
   */
  small: boolean;
  items: MenuItems;
  search?: SearchItem;
}

export type MenuItems = Array<MenuItem>;

export interface MenuItem {
  label: string;
  selected: boolean;
  action: LinkAction;
}

export interface SearchItem {
  action: LinkAction;
}
