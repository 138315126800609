import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormComponentWithDisabled,
  FormComponentWithLabel,
} from '../form-component';
import { List } from 'immutable';
import { DomSanitizer } from '@angular/platform-browser';
import { BewFileUploadComponent } from '../bew-file-upload/bew-file-upload.component';
import { ErrorModalComponent } from '../../../app/components/error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bew-multi-upload',
  templateUrl: './bew-multi-upload.component.html',
  styleUrls: ['./bew-multi-upload.component.scss'],
})
export class BewMultiUploadComponent
  implements
    FormComponentWithLabel,
    FormComponentWithDisabled,
    BewMultiUploadInterface
{
  private static DRAG_LEAVE_TIMER_TIMEOUT_MS = 250;

  @Input()
  erroneous: boolean = false;
  @Input()
  showErrorIcon: boolean = false;

  @Input()
  label: string = 'NO_LABEL';
  /**
   * A text that's shown when there's at least one file.
   */
  @Input()
  withFilesText: string = 'TEXT_WITH_FILES';
  /**
   * A text that's show when there's no file.
   */
  @Input()
  noFilesText: string = 'TEXT_NO_FILES';

  @Input()
  disabled: boolean = false;

  @Input()
  uploadFileButtonText: string = 'TEXT_UPLOAD_FILES';

  @Input()
  numberOfFilesTextProviderFunction: NumberOfFilesTextProviderFunction =
    BewMultiUploadComponent.defaultNumberOfFilesTextProviderFunction;

  /**
   * Show an icon.
   */
  @Input()
  showIcon = true;

  @Output()
  readonly filesChange: EventEmitter<List<BewFile>> = new EventEmitter<
    List<BewFile>
  >();

  private _files: List<BewFile> = List.of();

  get files(): List<BewFile> {
    return this._files;
  }

  @Input()
  set files(files: List<BewFile>) {
    this._files = files;
    this.filesChange.emit(files);
  }

  /**
   * Set this text to some text that's displayed when the system is uploading files.
   */
  @Input()
  uploadingText: string = 'MISSING_TEXT_UPLOADING';

  /**
   * Text that's displayed when the user drags files over the multi file upload.
   */
  @Input()
  dropHereText: string = 'MISSING_TEXT_DROP_FILES_HERE';

  /**
   * An additional note text that is showed if not empty. This text usually contains something like "only upload
   * PDF files" or something like this.
   */
  @Input()
  noticeText: string = '';

  /**
   * The upload function (upload 1-n files).
   */
  @Input()
  uploadFunction: UploadFunction =
    BewMultiUploadComponent.defaultUploadFunction;

  /**
   * Change this if you want to customize file removal. The default implementation just removes the file from the list.
   */
  @Input()
  removeFileAtIndexFunction: RemoveFileAtIndexFunction =
    BewMultiUploadComponent.defaultRemoveFileAtIndexFunction;

  @Input()
  downloadFileAtIndexFunction: DownloadFileAtIndexFunction =
    BewMultiUploadComponent.defaultDownloadFileAtIndexFunction;

  @Input()
  previewFileAtIndexFunction: PreviewFileAtIndexFunction =
    BewMultiUploadComponent.defaultPreviewFileAtIndexFunction;

  private overlayTextDropHere = '';
  private leaveTimer?: number;
  private uploading: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) {}

  get disabledInternal(): boolean {
    // disabled if really disabled or if overlay is active.
    return this.disabled || this.overlayActive;
  }

  get overlayActive(): boolean {
    return this.resultingOverlayText.length > 0;
  }

  get resultingOverlayText(): string {
    if (this.uploading) {
      return this.uploadingText;
    } else if (this.overlayTextDropHere.length > 0) {
      return this.overlayTextDropHere;
    } else {
      return '';
    }
  }
  get filenamesOfUploadedFiles(): Iterable<string> {
    // TODO find out why i have typscript problems
    // @ts-ignore
    return this.files.map((file) => {
      return file?.name;
    });
  }

  get numberOfFilesUploaded(): number {
    return this.files.size;
  }

  get showWithFilesText(): boolean {
    return this.hasFiles && this.withFilesText.length > 0;
  }

  get showNoFilesText(): boolean {
    return !this.hasFiles && this.noFilesText.length > 0;
  }

  get hasFiles(): boolean {
    return this.files.size > 0;
  }

  get hasNoticeText(): boolean {
    return this.noticeText.length > 0;
  }

  get numberOfFilesUploadedText() {
    return this.numberOfFilesTextProviderFunction(this.numberOfFilesUploaded);
  }

  private static defaultUploadFunction(
    _self: BewMultiUploadInterface,
    _fileList: FileList,
  ): Promise<void> {
    throw 'Implement the upload function';
  }

  private static async defaultRemoveFileAtIndexFunction(
    self: BewMultiUploadInterface,
    index: number,
    modalService: NgbModal,
  ): Promise<void> {
    const modalRef = modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = ['modal.confirm.delete.file'];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';
    modalRef.result.then((result) => {
      if (result) {
        self.files = self.files.remove(index);
      }
    });
  }

  private static defaultDownloadFileAtIndexFunction(
    self: BewMultiUploadInterface,
    index: number,
  ): Promise<void> {
    throw 'Implement the download function';
  }

  private static defaultPreviewFileAtIndexFunction(
    self: BewMultiUploadInterface,
    index: number,
  ): Promise<Blob> {
    throw 'Implement the preview function';
  }

  private static defaultNumberOfFilesTextProviderFunction(
    count: number,
  ): string {
    return `Implement me: ${count} files.`;
  }

  async onRemoveFileAtIndex(index: number): Promise<void> {
    await this.removeFileAtIndexFunction(this, index, this.modalService);
  }

  async onDownloadFileAtIndex(index: number): Promise<void> {
    await this.downloadFileAtIndexFunction(this, index);
  }

  async onPreviewFileAtIndex(
    index: number,
    fileUploadComponent: BewFileUploadComponent,
  ): Promise<void> {
    let content = await this.previewFileAtIndexFunction(this, index);
    if (fileUploadComponent.filename) {
      if (fileUploadComponent.filename.endsWith('.pdf')) {
        fileUploadComponent.togglePdfPreview(
          content,
          fileUploadComponent.filename,
        );
      } else {
        let fileURL = URL.createObjectURL(content);
        const safeUrl = this.sanitizer.bypassSecurityTrustUrl(fileURL);
        fileUploadComponent.toggleImgPreview(safeUrl);
      }
    }
  }

  onDragOver(event: DragEvent) {
    if (this.disabled) {
      return true;
    }

    event.stopPropagation();
    event.preventDefault();
    this.stopDragLeaveTimer();
    this.overlayTextDropHere = this.dropHereText;
    return false;
  }

  async onDrop(event: DragEvent): Promise<boolean> {
    if (this.disabled) {
      return true;
    }

    this.endDragOverlayTextImmediately();
    event.stopPropagation();
    event.preventDefault();
    const dataTransfer = event.dataTransfer;
    if (dataTransfer !== null) {
      await this.uploadFiles(dataTransfer.files);
    }
    return false;
  }

  onDragLeave(event: DragEvent) {
    if (this.disabled) {
      return true;
    }

    event.stopPropagation();
    event.preventDefault();
    this.stopDragLeaveTimer();
    this.leaveTimer = window.setTimeout(() => {
      this.endDragOverlayTextImmediately();
    }, BewMultiUploadComponent.DRAG_LEAVE_TIMER_TIMEOUT_MS);
    return false;
  }

  onDragEnter(event: DragEvent) {
    if (this.disabled) {
      return true;
    }

    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  /**
   * Files selected using the upload button.
   */
  async onFileSelected(event: Event): Promise<void> {
    const target: HTMLInputElement = event.target as any;
    const fileList = target.files;
    if (fileList !== null) {
      await this.uploadFiles(fileList);
    }
  }

  private endDragOverlayTextImmediately() {
    this.overlayTextDropHere = '';
    this.stopDragLeaveTimer();
  }

  private stopDragLeaveTimer() {
    const leaveTimer = this.leaveTimer;
    this.leaveTimer = undefined;
    if (leaveTimer !== undefined) {
      window.clearTimeout(leaveTimer);
    }
  }

  private async uploadFiles(files: FileList): Promise<void> {
    this.uploading = true;
    try {
      await this.uploadFunction(this, files);
    } catch (error) {
      console.warn(
        'Do not throw in the upload function, since the multi upload component cannot handle ' +
          "exceptions (it can't display exceptions).",
        error,
      );
    } finally {
      this.uploading = false;
    }
  }

  protected readonly name = name;
}

/**
 * Public interface for the bew multi upload.
 */
export interface BewMultiUploadInterface {
  files: List<BewFile>;
  noticeText: string;
  erroneous: boolean;
  showErrorIcon: boolean;
}

export type NumberOfFilesTextProviderFunction = (count: number) => string;

export type RemoveFileAtIndexFunction = (
  self: BewMultiUploadInterface,
  index: number,
  modalService: NgbModal,
) => Promise<void>;

export type UploadFunction = (
  self: BewMultiUploadInterface,
  fileList: FileList,
) => Promise<void>;

export type DownloadFileAtIndexFunction = (
  self: BewMultiUploadInterface,
  index: number,
) => Promise<void>;

export type PreviewFileAtIndexFunction = (
  self: BewMultiUploadInterface,
  index: number,
) => Promise<Blob>;

export interface BewFile {
  name: string;
  payload?: any;
}
