/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileDTO } from './fileDTO';

export interface LagerungDraftDTO { 
    lagerungshinweise?: Array<LagerungDraftDTO.LagerungshinweiseEnum>;
    haltbarkeitsdauer?: string;
    bemerkung?: string;
    files?: Array<FileDTO>;
    notes?: string;
}
export namespace LagerungDraftDTO {
    export type LagerungshinweiseEnum = 'NICHT_UEBER_25_GRAD' | 'BEI_RAUMTEMPARATUR' | 'BEI_15_BIS_30_GRAD' | 'IM_KUEHLSCHRANK' | 'IN_DER_ORIGINALVERPACKUNG' | 'VOR_LICHT_SCHUETZEN' | 'TIEFGEKUEHLT' | 'NICHT_IM_KUEHLSCHRANK' | 'NICHT_EINFRIEREN' | 'BEHAELTER_FEST_VERSCHLOSSEN' | 'KINDERWARNUNG';
    export const LagerungshinweiseEnum = {
        NICHTUEBER25GRAD: 'NICHT_UEBER_25_GRAD' as LagerungshinweiseEnum,
        BEIRAUMTEMPARATUR: 'BEI_RAUMTEMPARATUR' as LagerungshinweiseEnum,
        BEI15BIS30GRAD: 'BEI_15_BIS_30_GRAD' as LagerungshinweiseEnum,
        IMKUEHLSCHRANK: 'IM_KUEHLSCHRANK' as LagerungshinweiseEnum,
        INDERORIGINALVERPACKUNG: 'IN_DER_ORIGINALVERPACKUNG' as LagerungshinweiseEnum,
        VORLICHTSCHUETZEN: 'VOR_LICHT_SCHUETZEN' as LagerungshinweiseEnum,
        TIEFGEKUEHLT: 'TIEFGEKUEHLT' as LagerungshinweiseEnum,
        NICHTIMKUEHLSCHRANK: 'NICHT_IM_KUEHLSCHRANK' as LagerungshinweiseEnum,
        NICHTEINFRIEREN: 'NICHT_EINFRIEREN' as LagerungshinweiseEnum,
        BEHAELTERFESTVERSCHLOSSEN: 'BEHAELTER_FEST_VERSCHLOSSEN' as LagerungshinweiseEnum,
        KINDERWARNUNG: 'KINDERWARNUNG' as LagerungshinweiseEnum
    };
}