import {
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  BerechtigungAntragApiService,
  BerechtigungAntragDTO,
  BetriebApiService,
} from '../../../../backend';
import { SortConfiguration } from '../../../table-column-sort/table-column-sort.component';
import {
  EfoTableComponent,
  SearchConfiguration,
} from '../../../efo-table/efo-table.component';
import { ErrorModalComponent } from '../../../error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-betrieb-berechtigung-beantragt-overview',
  templateUrl: './antrag-current.html',
  styleUrl: './antrag-current.scss',
})
export class AntragCurrent {
  @ViewChildren(EfoTableComponent) // @ts-ignore
  efoBenutzerTableComponent!: QueryList<EfoTableComponent>;
  @Output()
  public reload = new EventEmitter<void>();

  berechtigungAntraege?: Array<BerechtigungAntragDTO> = [];

  totalElementsAntraege: number = 0;
  totalPagesAntraege: number = 0;
  numberOfElementsAntraege: number = 0;

  id: string | undefined = undefined;

  pageNumber: number = 0;
  pageSize: number = 25;

  totalItems: number = 0;
  numberOfItems: number = 0;
  totalPages: number = 0;

  searchTerm: string = '';

  constructor(
    private readonly betriebApiService: BetriebApiService,
    private readonly berechtigungAntragApiService: BerechtigungAntragApiService,
    private modalService: NgbModal,
  ) {}

  ngAfterViewInit() {
    this.searchAll();
  }

  searchAll() {
    this.efoBenutzerTableComponent.forEach((table) => table.search());
  }

  public searchBerechtigungAntraege(searchConfiguration: SearchConfiguration) {
    this.berechtigungAntragApiService
      .findAllByCurrentBenutzer(
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.berechtigungAntraege = page.content;
        if (page.totalElements) {
          this.totalElementsAntraege = page.totalElements;
        } else {
          this.totalElementsAntraege = 0;
        }
        if (page.totalPages) {
          this.totalPagesAntraege = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsAntraege = page.numberOfElements;
        }
        this.hasberechtigungsAntraege();
      });
  }

  sortConfiguration: SortConfiguration = {
    sortBy: 'createDate',
    direction: 'DESC',
  };

  withdraw(berechtigungAntragDTO: BerechtigungAntragDTO) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = [
      'modal.confirm.delete.berechtigung_antrag',
    ];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';

    modalRef.result.then((result) => {
      if (result) {
        if (berechtigungAntragDTO && berechtigungAntragDTO.id) {
          this.berechtigungAntragApiService
            .withdraw(berechtigungAntragDTO.id)
            .subscribe((value) => this.reload.emit());
        }
      }
    });
  }

  hasberechtigungsAntraege() {
    return (
      this.berechtigungAntraege != undefined &&
      this.berechtigungAntraege.length > 0
    );
  }
}
