<!-- file is there -->
<ng-container *ngIf="hasFile">
  <div
    [class.file-upload-error]="invalid"
    [class.file-upload-success]="!invalid"
    class="floating-label">
    <!-- valid -->
    <ng-container *ngIf="!invalid">
      <input
        [disabled]="disabled"
        class="file-upload-success"
        readonly
        value="{{ filename }}" />
      <label class="floating-label">{{ label }}</label>
      <span
        (click)="onDownload()"
        class="file-download-img"
        [ngbTooltip]="tooltipFileDownload"
        triggers="hover"
        [openDelay]="500"
        [closeDelay]="300"></span>
      <span
        *ngIf="!disabled"
        (click)="onRemove()"
        class="file-upload-img-success"
        [ngbTooltip]="tooltipFileRemove"
        triggers="hover"
        [openDelay]="500"
        [closeDelay]="300">
      </span>
      <span
        *ngIf="isPreviewable()"
        (click)="onPreview()"
        class="file-preview-img"
        [ngbTooltip]="tooltipFilePreview"
        triggers="hover"
        [openDelay]="500"
        [closeDelay]="300">
      </span>
    </ng-container>

    <!-- invalid -->
    <ng-container *ngIf="invalid">
      <input
        (change)="onFileSelected($event)"
        [disabled]="disabled"
        class="file-upload"
        type="file"
        value="" />
      <span class="bew-placeholder">{{ filename }}</span>
      <span class="file-upload-img"></span>
      <span class="error">{{ error }}</span>
      <span class="file-upload-invalid"></span>
    </ng-container>
  </div>
  <div>
    <div [hidden]="!showImgPreview" class="img-preview">
      {{ 'file.preview' | translate }} {{ filename }}
      <img
        id="showEtikette"
        class="container show_etikette"
        [src]="imgPreview" />
    </div>
    <div [hidden]="!showPdfPreview" class="pdf-preview">
      {{ 'file.preview' | translate }} {{ filename }}
      <ng2-pdfjs-viewer
        #pdfViewer
        [openFile]="false"
        [viewBookmark]="false"
        [download]="false">
      </ng2-pdfjs-viewer>
    </div>
  </div>
</ng-container>

<!-- no file -->
<ng-container *ngIf="!hasFile">
  <div
    [class.file-upload-error]="invalid"
    [class.file-upload]="!invalid"
    class="floating-label">
    <!-- valid -->
    <ng-container *ngIf="!invalid">
      <input
        (change)="onFileSelected($event)"
        [disabled]="disabled"
        class="file-upload"
        type="file"
        value="" />
      <span class="bew-placeholder">{{ label }}</span>
      <span class="file-upload-img"></span>
    </ng-container>
    <!-- invalid -->
    <ng-container *ngIf="invalid">
      <input
        (change)="onFileSelected($event)"
        [disabled]="disabled"
        class="file-upload"
        type="file"
        value="" />
      <span class="bew-placeholder">{{ label }}</span>
      <span class="file-upload-img"></span>
      <span class="error">{{ error }}</span>
      <span class="file-upload-invalid"></span>
    </ng-container>
  </div>
</ng-container>

<ng-template #tooltipFilePreview>
  <div [innerHTML]="'tooltip.file_preview' | translate"></div>
</ng-template>
<ng-template #tooltipFileDownload>
  <div [innerHTML]="'tooltip.file_download' | translate"></div>
</ng-template>
<ng-template #tooltipFileRemove>
  <div [innerHTML]="'tooltip.file_remove' | translate"></div>
</ng-template>
