import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BerechtigungAntragCreateDTO,
  BerechtigungAntragDTO,
  BetriebDTO,
} from '../../backend';
import RolleEnum = BerechtigungAntragDTO.RolleEnum;

@Component({
  selector: 'app-betrieb-berechtigung-beantragen',
  templateUrl: './betrieb-berechtigung-beantragen.component.html',
  styleUrl: './betrieb-berechtigung-beantragen.component.scss',
})
export class BetriebBerechtigungBeantragenComponent {
  betriebRollen: Array<RolleEnum> = [
    RolleEnum.EFOBETRIEBSLEITER,
    RolleEnum.EFOBETRIEBSLEITERSTV,
    RolleEnum.EFOBETRIEBSMITARBEITER,
  ];
  betriebRolle: RolleEnum = RolleEnum.EFOBETRIEBSMITARBEITER;

  @Output()
  public antrag = new EventEmitter<BerechtigungAntragCreateDTO>();

  @Input()
  betriebId: string | undefined;

  berechtigungBeantragen() {
    this.antrag.emit({ betriebId: this.betriebId, rolle: this.betriebRolle });
  }
}
