<app-bew-box
  *ngIf="
    currentBerechtigung &&
    (berechtigungService.isAdminOrHasAnyRolleAndBetrieb([
      'EFO_BETRIEBSMITARBEITER',
      'EFO_BETRIEBSLEITER_STV',
      'EFO_BETRIEBSLEITER'
    ]) | async)
  "
  [label]="getCurrentBetriebName()">
  <app-bew-box label="{{ 'betrieb.stammdaten.title' | translate }}">
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.name' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.name }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.name2' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.name }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.beproid' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.beproId }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.bur' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.bur }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.uid' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.uid }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.name' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.gln }}</div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.stammdaten.betriebsart' | translate }}
      </div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.betriebsArt }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.telefon' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.telefon }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.mail' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.mail }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.postfach' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.postfach }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.strasse' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.strasse }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.plz' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.plz }}</div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.stammdaten.ort' | translate }}</div>
      <div class="col-10">{{ currentBerechtigung.betrieb?.ort }}</div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.stammdaten.korrespondenzsprache' | translate }}
      </div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.korrespondenzSprache }}
      </div>
    </div>
  </app-bew-box>

  <app-bew-box label="{{ 'betrieb.rechnungsaddresse.title' | translate }}">
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.rechnungsaddresse.name' | translate }}
      </div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.name1 }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.rechnungsaddresse.name2' | translate }}
      </div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.name2 }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.rechnungsaddresse.zusatz' | translate }}
      </div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.zusatz1 }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.rechnungsaddresse.zusatz2' | translate }}
      </div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.zusatz2 }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.rechnungsaddresse.strasse' | translate }}
      </div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.strasse }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.rechnungsaddresse.postfach' | translate }}
      </div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.postfach }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.rechnungsaddresse.plz' | translate }}</div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.plz }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">{{ 'betrieb.rechnungsaddresse.ort' | translate }}</div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.ort }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ 'betrieb.rechnungsaddresse.country' | translate }}
      </div>
      <div class="col-10">
        {{ currentBerechtigung.betrieb?.rechnungsAddresse?.country }}
      </div>
    </div>
  </app-bew-box>

  <app-bew-box label="{{ 'betrieb.korrespondenz.title' | translate }}">
    <div class="row">
      <div class="col-6">
        <app-bew-input
          [disabled]="!korrespondenzMailMutable"
          [label]="'betrieb.korrespondenz.title' | translate"
          [(value)]="korrespondenzMail"
          [maxlength]="255">
        </app-bew-input>
      </div>
      <div class="col-6" *ngIf="!korrespondenzMailMutable">
        <button
          class="efo-button blue input-row"
          (click)="korrespondenzMailMutable = true"
          [ngbTooltip]="tooltipKorrespondenzMailEdit"
          triggers="hover"
          [openDelay]="500"
          [closeDelay]="300">
          <i class="fa fa-edit"></i>
        </button>
      </div>
      <div class="col-6" *ngIf="korrespondenzMailMutable">
        <button
          class="efo-button blue input-row"
          (click)="updateKorresponenzMail()"
          [ngbTooltip]="tooltipSave"
          triggers="hover"
          [openDelay]="500"
          [closeDelay]="300">
          <i class="fa fa-save"></i>
        </button>
      </div>
    </div>
  </app-bew-box>

  <app-bew-box label="{{ 'betrieb.mailconfiguration.title' | translate }}">
    <div class="bew-table-container">
      <app-efo-table
        [searchable]="false"
        [pageSize]="1000"
        [totalElements]="totalElementsBenutzer"
        [totalPages]="totalPagesBenutzer"
        [numberOfElements]="numberOfElementsBenutzer"
        (searchContent)="findAllBerechtigungenOfCurrentBetrieb($event)">
        <tr table-header>
          <th></th>
          <th>
            {{ 'betrieb.mailconfiguration.pruefung' | translate }}
          </th>
          <th>
            {{ 'betrieb.mailconfiguration.accept' | translate }}
          </th>
          <th>
            {{ 'betrieb.mailconfiguration.reject' | translate }}
          </th>
          <th></th>
          <th></th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungenForBetrieb?.length == 0">
            <td colspan="7">
              {{ 'betrieb.benutzerverwaltung.none' | translate }}
            </td>
          </tr>
          <tr>
            <td>
              {{ 'betrieb.mailconfiguration.korrespondenzmail' | translate }}
            </td>
            <td>
              <input
                #korrespondenzMailFormelInPruefung
                type="checkbox"
                [ngModel]="
                  currentBerechtigung.betrieb?.korrespondenzMailConfiguration
                    ?.mailFormelInPruefung
                " />
            </td>
            <td>
              <input
                #korrespondenzMailFormelAccepted
                type="checkbox"
                [ngModel]="
                  currentBerechtigung.betrieb?.korrespondenzMailConfiguration
                    ?.mailFormelAccepted
                " />
            </td>
            <td>
              <input
                #korrespondenzMailFormelRejected
                type="checkbox"
                [ngModel]="
                  currentBerechtigung.betrieb?.korrespondenzMailConfiguration
                    ?.mailFormelRejected
                " />
            </td>

            <td>
              <button
                class="efo-button blue"
                (click)="
                  updateKorresponenzMailConfiguration(
                    korrespondenzMailFormelInPruefung.checked,
                    korrespondenzMailFormelAccepted.checked,
                    korrespondenzMailFormelRejected.checked
                  )
                "
                [ngbTooltip]="tooltipSave"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-save"></i>
              </button>
            </td>
          </tr>

          <tr>
            <td>{{ 'betrieb.mailconfiguration.submitter' | translate }}</td>
            <td>
              <input
                #submitterMailFormelInPruefung
                type="checkbox"
                [ngModel]="
                  currentBerechtigung.betrieb?.submitterMailConfiguration
                    ?.mailFormelInPruefung
                " />
            </td>
            <td>
              <input
                #submitterMailFormelAccepted
                type="checkbox"
                [ngModel]="
                  currentBerechtigung.betrieb?.submitterMailConfiguration
                    ?.mailFormelAccepted
                " />
            </td>
            <td>
              <input
                #submitterMailFormelRejected
                type="checkbox"
                [ngModel]="
                  currentBerechtigung.betrieb?.submitterMailConfiguration
                    ?.mailFormelRejected
                " />
            </td>

            <td>
              <button
                class="efo-button blue"
                (click)="
                  updateMailSubmitterConfiguration(
                    submitterMailFormelInPruefung.checked,
                    submitterMailFormelAccepted.checked,
                    submitterMailFormelRejected.checked
                  )
                "
                [ngbTooltip]="tooltipSave"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-save"></i>
              </button>
            </td>
          </tr>

          <ng-container *ngFor="let b of berechtigungenForBetrieb; index as i">
            <tr>
              <td>{{ b.benutzer?.firstName }} {{ b.benutzer?.lastName }}</td>
              <td>
                <input
                  #mailFormelInPruefung
                  type="checkbox"
                  [ngModel]="b.mailConfiguration?.mailFormelInPruefung" />
              </td>
              <td>
                <input
                  #mailFormelAccepted
                  type="checkbox"
                  [ngModel]="b.mailConfiguration?.mailFormelAccepted" />
              </td>
              <td>
                <input
                  #mailFormelRejected
                  type="checkbox"
                  [ngModel]="b.mailConfiguration?.mailFormelRejected" />
              </td>
              <td>
                <button
                  class="efo-button blue"
                  (click)="
                    updateMailConfigurationForBetrieb(
                      b.id,
                      mailFormelInPruefung.checked,
                      mailFormelAccepted.checked,
                      mailFormelRejected.checked
                    )
                  "
                  [ngbTooltip]="tooltipSave"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-save"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>

  <app-bew-box label="{{ 'betrieb.benutzerverwaltung.title' | translate }}">
    <div class="bew-table-container">
      <app-efo-table
        #benutzerTable
        [searchable]="false"
        [pageSize]="1000"
        [totalElements]="totalElementsBenutzer"
        [totalPages]="totalPagesBenutzer"
        [numberOfElements]="numberOfElementsBenutzer"
        (searchContent)="findAllBerechtigungenOfCurrentBetrieb($event)">
        <tr table-header>
          <th>
            {{ 'betrieb.benutzerverwaltung.username' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.firstname' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.lastname' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.mail' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.rolle' | translate }}
          </th>
          <th></th>
          <th></th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungenForBetrieb?.length == 0">
            <td colspan="7">
              {{ 'betrieb.benutzerverwaltung.none' | translate }}
            </td>
          </tr>
          <ng-container *ngFor="let b of berechtigungenForBetrieb; index as i">
            <tr>
              <td>{{ b.benutzer?.username }}</td>
              <td>{{ b.benutzer?.firstName }}</td>
              <td>{{ b.benutzer?.lastName }}</td>
              <td>{{ b.benutzer?.mail }}</td>
              <td>{{ 'enum.rolle.' + b.rolle | translate }}</td>

              <td>
                <button
                  *ngIf="betriebBenutzerDeletable(b)"
                  class="efo-button red"
                  (click)="deleteFromBetrieb(b)"
                  [ngbTooltip]="tooltipDelete"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td>
                <app-update-rolle
                  *ngIf="betriebBenutzerMutable(b)"
                  [berechtigungId]="b.id"
                  [isAdmin]="berechtigungService.isAdmin()"
                  (update)="updateRolle($event)">
                </app-update-rolle>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>

  <app-bew-box
    label="{{ 'betrieb.antraege.title' | translate }}"
    *ngIf="
      currentBerechtigung?.rolle == 'EFO_BETRIEBSLEITER' ||
      currentBerechtigung?.rolle == 'EFO_BETRIEBSLEITER_STV' ||
      currentBerechtigung?.rolle == 'ADMINISTRATOR'
    ">
    <div class="bew-table-container">
      <app-efo-table
        #antraegeTable
        [searchable]="false"
        [pageSize]="1000"
        [totalElements]="totalElementsAntraege"
        [totalPages]="totalPagesAntraege"
        [numberOfElements]="numberOfElementsAntraege"
        (searchContent)="findAllAntraegeOfCurrentBetrieb($event)">
        <tr table-header>
          <th>
            {{ 'betrieb.benutzerverwaltung.username' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.firstname' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.lastname' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.mail' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.rolle' | translate }}
          </th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungAntraegeForBetrieb?.length == 0">
            <td colspan="5">
              {{ 'betrieb.antraege.none' | translate }}
            </td>
          </tr>
          <ng-container
            *ngFor="let b of berechtigungAntraegeForBetrieb; index as i">
            <tr>
              <td>{{ b.benutzer?.username }}</td>
              <td>{{ b.benutzer?.firstName }}</td>
              <td>{{ b.benutzer?.lastName }}</td>
              <td>{{ b.benutzer?.mail }}</td>
              <td>{{ 'enum.rolle.' + b.rolle | translate }}</td>
            </tr>
            <tr>
              <td colspan="4">
                <app-bew-input
                  #remark
                  [label]="'betrieb.antraege.remark' | translate">
                </app-bew-input>
              </td>
              <td>
                <button
                  *ngIf="betriebBenutzerAntragAcceptableRejectable(b)"
                  class="efo-button blue input-row"
                  (click)="accept(b)"
                  [ngbTooltip]="tooltipAccept"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-check"></i>
                </button>
                <button
                  *ngIf="betriebBenutzerAntragAcceptableRejectable(b)"
                  [disabled]="remark.value == ''"
                  class="efo-button red input-row"
                  (click)="reject(b, remark.value)"
                  [ngbTooltip]="tooltipReject"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-x"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>

  <app-bew-box
    *ngIf="currentBerechtigung?.rolle == 'EFO_BETRIEBSLEITER'"
    label="{{ 'pad.detail.antraege.betriebsleiter.change.title' | translate }}">
    <div class="bew-table-container">
      <app-efo-table
        #benutzerTable
        [searchable]="false"
        [pageSize]="1000"
        [totalElements]="totalElementsBenutzer"
        [totalPages]="totalPagesBenutzer"
        [numberOfElements]="numberOfElementsBenutzer"
        (searchContent)="findAllBerechtigungenOfCurrentBetrieb($event)">
        <tr table-header>
          <th>
            {{ 'betrieb.benutzerverwaltung.username' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.mail' | translate }}
          </th>
          <th>
            {{ 'betrieb.benutzerverwaltung.rolle' | translate }}
          </th>
          <th></th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungenForBetrieb?.length == 0">
            <td colspan="7">
              {{ 'betrieb.benutzerverwaltung.none' | translate }}
            </td>
          </tr>
          <ng-container *ngFor="let b of berechtigungenForBetrieb; index as i">
            <tr>
              <td>{{ b.benutzer?.username }}</td>
              <td>{{ b.benutzer?.mail }}</td>
              <td>{{ 'enum.rolle.' + b.rolle | translate }}</td>
              <td>
                <button
                  *ngIf="b.rolle != 'EFO_BETRIEBSLEITER'"
                  class="efo-button blue"
                  (click)="changeBetriebsleier(b)"
                  [ngbTooltip]="tooltipBetriebsleiterChange"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-arrow-left-rotate"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>
</app-bew-box>

<ng-template #tooltipKorrespondenzMailEdit>
  <div [innerHTML]="'tooltip.korrespondenz_mail_edit' | translate"></div>
</ng-template>

<ng-template #tooltipSave>
  <div [innerHTML]="'tooltip.save' | translate"></div>
</ng-template>

<ng-template #tooltipDelete>
  <div [innerHTML]="'tooltip.delete' | translate"></div>
</ng-template>

<ng-template #tooltipAccept>
  <div [innerHTML]="'tooltip.accept' | translate"></div>
</ng-template>

<ng-template #tooltipReject>
  <div [innerHTML]="'tooltip.reject' | translate"></div>
</ng-template>

<ng-template #tooltipBetriebsleiterChange>
  <div [innerHTML]="'tooltip.betriebsleiter_change' | translate"></div>
</ng-template>
