<div class="modal-header">
  <h4 *ngIf="type == 'ERROR'" class="modal-title">
    {{ 'modal.error.title' | translate }}
  </h4>
  <h4 *ngIf="type == 'INFO'" class="modal-title">
    {{ 'modal.info.title' | translate }}
  </h4>
  <h4 *ngIf="type == 'CONFIRM_DELETE'" class="modal-title">
    {{ 'modal.confirm.delete.title' | translate }}
  </h4>
  <h4 *ngIf="type == 'CONFIRM_REMOVE'" class="modal-title">
    {{ 'modal.confirm.remove.title' | translate }}
  </h4>
</div>
<div class="modal-body">
  <div *ngFor="let m of message">
    <p>{{ m | translate }}</p>
  </div>
</div>
<div
  *ngIf="type == 'CONFIRM_DELETE' || type == 'CONFIRM_REMOVE'"
  class="modal-footer">
  <button
    *ngIf="type == 'CONFIRM_DELETE'"
    type="button"
    class="efo-button blue"
    (click)="confirm()">
    {{ 'modal.confirm.delete.accept' | translate }}
  </button>
  <button
    *ngIf="type == 'CONFIRM_REMOVE'"
    type="button"
    class="efo-button blue"
    (click)="confirm()">
    {{ 'modal.confirm.remove.accept' | translate }}
  </button>
  <button type="button" class="efo-button red" (click)="cancel()">
    {{ 'modal.confirm.delete.cancel' | translate }}
  </button>
</div>
<div *ngIf="type == 'INFO' || type == 'ERROR'" class="modal-footer">
  <button type="button" class="efo-button red" (click)="close()">
    {{ 'modal.close' | translate }}
  </button>
</div>
