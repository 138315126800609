import { Component, Input, OnInit } from '@angular/core';
import { FooterNavigationItems } from '../bew-footer-navigation/bew-footer-navigation.component';

@Component({
  selector: 'app-bew-footer',
  templateUrl: './bew-footer.component.html',
  styleUrls: ['./bew-footer.component.scss'],
})
export class BewFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input()
  signetLabel?: string;
  @Input()
  copyrightText?: string;
  @Input()
  aboutText?: string;
  @Input()
  footerNavigationItems?: FooterNavigationItems;
}
