<div class="row">
  <div class="col-8">
    <select name="role" class="form-control" [(ngModel)]="rolle">
      <option *ngFor="let rolle of rollen" value="{{ rolle }}">
        {{ 'enum.rolle.' + rolle | translate }}
      </option>
    </select>
  </div>
  <div class="col-1">
    <button
      class="efo-button blue"
      (click)="berechtigungBeantragen()"
      [ngbTooltip]="tooltipUpdateRolle"
      triggers="hover"
      [openDelay]="500"
      [closeDelay]="300">
      <i class="fa fa-user-edit"></i>
    </button>
  </div>
</div>

<ng-template #tooltipUpdateRolle>
  <div [innerHTML]="'tooltip.update_rolle' | translate"></div>
</ng-template>
