<div class="bew-simple-header">
  <div class="bew-simple-header-signet" *ngIf="signetLabel !== undefined">
    <app-bew-signet [label]="signetLabel" [linkAction]="signetLinkAction">
    </app-bew-signet>
  </div>
  <div class="bew-simple-header-service-navigation">
    <app-bew-service-navigation
      [data]="serviceNavigationData"
      (languageSelected)="
        onLanguageSelected($event)
      "></app-bew-service-navigation>
  </div>
  <div class="bew-simple-header-main-menu">
    <app-bew-main-menu [data]="mainMenuData"></app-bew-main-menu>
  </div>
  <div class="bew-simple-header-hr">
    <hr />
  </div>
  <div class="bew-simple-header-breadcrumb">
    <app-bew-breadcrumb [data]="breadcrumbData"></app-bew-breadcrumb>
  </div>
  <div class="bew-simple-header-empty"></div>
</div>
