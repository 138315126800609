import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService implements HttpInterceptor {
  constructor(
    private readonly errorService: ErrorService,
    private readonly translateService: TranslateService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        let errorTexts: string[] = [];
        if (error.error.errorCodes) {
          for (let e of error.error.errorCodes) {
            errorTexts.push(this.translateService.instant(e));
          }
        }
        this.errorService.throwError(
          errorTexts || [errorMessage] || ['An unexpected error occurred.'],
        );
        return throwError(() => new Error(errorMessage));
      }),
    );
  }
}
