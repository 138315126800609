import { Injectable } from '@angular/core';
import {
  Allgemein,
  BehaelterGroesse,
  Formel,
  FormelRisiko,
  FormelRisikoParameter,
  Komponente,
  Lagerung,
  Lohnhersteller,
  PruefungsNotes,
  Verpackung,
  Zusammensetzung,
} from '../../components/formel/formel-detail/formel-detail.component';
import {
  AllgemeinDTO,
  BehaelterGroesseDTO,
  CreateFormelDTO,
  FileDTO,
  FormelDTO,
  FormelRisikoDTO,
  FormelRisikoParameterDTO,
  KompenenteDTO,
  LagerungDTO,
  LohnherstellerDTO,
  PruefungsNotesDTO,
  UpdateFormelContentDTO,
  UpdatePruefungsNotesDTO,
  VerpackungDTO,
  ZusammensetzungDTO,
} from '../../backend';
import { List } from 'immutable';
import { UpdateBetriebNotesDTO } from '../../backend/model/updateBetriebNotesDTO';
import LagerungshinweiseEnum = LagerungDTO.LagerungshinweiseEnum;

@Injectable({
  providedIn: 'root',
})
export class FormelMapperService {
  constructor() {}

  public mapFormelToCreateFormelDTO(
    formel: Formel,
    betriebId: string,
  ): CreateFormelDTO {
    if (formel.formelRisiko) {
      return {
        formelDraftId: formel.id,
        allgemein: this.mapAllgemeinToDto(formel.allgemein),
        zusammensetzung: this.mapZusammensetzungtoDto(formel.zusammensetzung),
        lagerung: this.mapLagerungToDto(formel.lagerung),
        verpackung: this.mapVerpackungToDto(formel.verpackung),
        formelRisiko: this.mapDtoToFormelRisiko(formel.formelRisiko),
        betriebId: betriebId,
      };
    }
    return {
      formelDraftId: formel.id,
      allgemein: this.mapAllgemeinToDto(formel.allgemein),
      zusammensetzung: this.mapZusammensetzungtoDto(formel.zusammensetzung),
      lagerung: this.mapLagerungToDto(formel.lagerung),
      verpackung: this.mapVerpackungToDto(formel.verpackung),
      betriebId: betriebId,
    };
  }

  public mapFormelToUpdateFormelContentDTO(
    formel: Formel,
  ): UpdateFormelContentDTO {
    let formelDto: UpdateFormelContentDTO = {
      formelId: formel.id,
      allgemein: this.mapAllgemeinToDto(formel.allgemein),
      zusammensetzung: this.mapZusammensetzungtoDto(formel.zusammensetzung),
      lagerung: this.mapLagerungToDto(formel.lagerung),
      verpackung: this.mapVerpackungToDto(formel.verpackung),
    };

    if (formel.formelRisiko) {
      formelDto.formelRisikoDTO = this.mapFormelRisikoToDto(
        formel.formelRisiko,
      );
    }

    return formelDto;
  }

  public mapFormelToUpdatePruefungsNotesDTO(
    formel: Formel,
  ): UpdatePruefungsNotesDTO {
    return {
      formelId: formel.id,
      pruefungsNotes: this.mapFormelToPruefungsNotesDTO(formel.pruefungsNotes),
    };
  }

  public mapFormelToUpdateBetriebNotesDTO(
    formel: Formel,
  ): UpdateBetriebNotesDTO {
    return {
      formelId: formel.id,
      betriebNotes: formel.betriebNotes,
    };
  }

  public mapFormelToPruefungsNotesDTO(
    pruefungsNotes: PruefungsNotes,
  ): PruefungsNotesDTO {
    return {
      notesAllgemein: pruefungsNotes.notesAllgemein,
      notesZusammensetzung: pruefungsNotes.notesZusammensetzung,
      notesLagerung: pruefungsNotes.notesLagerung,
      notesVerpackung: pruefungsNotes.notesVerpackung,
    };
  }

  private mapAllgemeinToDto(allgemein: Allgemein): AllgemeinDTO {
    if (allgemein.lohnherstellung && allgemein.lohnhersteller) {
      return {
        praeparatName: allgemein.praeparatName,
        praeparatArt: this.undefinedIfEmpty(allgemein.praeparatArt),
        indikation: allgemein.indikation,
        dosierungsVorschrift: allgemein.dosierungsVorschrift,
        darreichungsform: allgemein.darreichungsform,
        lohnherstellung: allgemein.lohnherstellung,
        lohnhersteller: this.mapLohnherstellerToDto(allgemein.lohnhersteller),
        files: allgemein.files.toArray(),
        notes: allgemein.notes,
      };
    } else {
      return {
        praeparatName: allgemein.praeparatName,
        praeparatArt: this.undefinedIfEmpty(allgemein.praeparatArt),
        indikation: allgemein.indikation,
        dosierungsVorschrift: allgemein.dosierungsVorschrift,
        darreichungsform: allgemein.darreichungsform,
        lohnherstellung: allgemein.lohnherstellung,
        files: allgemein.files.toArray(),
        notes: allgemein.notes,
      };
    }
  }

  private mapLohnherstellerToDto(
    lohnhersteller: Lohnhersteller,
  ): LohnherstellerDTO {
    return {
      name: lohnhersteller.name,
      betriebsArt: this.undefinedIfEmpty(lohnhersteller.betriebsArt),
      strasse: lohnhersteller.strasse,
      plz: lohnhersteller.plz,
      ort: lohnhersteller.ort,
    };
  }

  private mapZusammensetzungtoDto(
    zusammensetzung: Zusammensetzung,
  ): ZusammensetzungDTO {
    console.log(zusammensetzung);
    console.log(Number(zusammensetzung.chargengroesse));
    return {
      id: zusammensetzung.id,
      chargengroesse: this.toNumberOrUndefined(zusammensetzung.chargengroesse),
      chargengroesseEinheit: this.undefinedIfEmpty(
        zusammensetzung.chargengroesseEinheit,
      ),
      komponenten: this.mapKomponentenToDtos(
        zusammensetzung.komponenten.toArray(),
      ),
      files: zusammensetzung.files.toArray(),
      notes: zusammensetzung.notes,
    };
  }

  private mapKomponentenToDtos(komponenten: Komponente[]): KompenenteDTO[] {
    return List<KompenenteDTO>(
      komponenten.map((komponente) => {
        return {
          komponentOrder: Number(komponente?.komponentOrder),
          bezeichnung: komponente?.bezeichnung,
          qualitaet: this.undefinedIfEmpty(komponente?.qualitaet),
          art: this.undefinedIfEmpty(komponente?.art),
          einheitsMenge: Number(komponente?.einheitsMenge),
          einheit: this.undefinedIfEmpty(komponente?.einheit),
          bemerkung: komponente?.bemerkung,
        };
      }),
    ).toArray();
  }

  private mapLagerungToDto(lagerung: Lagerung): LagerungDTO {
    return {
      lagerungshinweise: List(lagerung.lagerungshinweise).toArray(),
      bemerkung: lagerung.bemerkung,
      haltbarkeitsdauer: lagerung.haltbarkeitsDauer,
      files: lagerung.files.toArray(),
      notes: lagerung.notes,
    };
  }

  private mapVerpackungToDto(verpackung: Verpackung): VerpackungDTO {
    return {
      id: verpackung.id,
      behaelterGroessen: this.mapBehaelterGroessenToDtos(
        verpackung.behaelterGroessen.toArray(),
      ),
      files: verpackung.files.toArray(),
      notes: verpackung.notes,
      etiketten: verpackung.etiketten.toArray(),
    };
  }

  private mapBehaelterGroessenToDtos(
    behaelterGroessen: BehaelterGroesse[],
  ): BehaelterGroesseDTO[] {
    return List<BehaelterGroesseDTO>(
      behaelterGroessen.map((behaelterGroesse) => {
        return {
          packungsinhalt: behaelterGroesse?.packungsinhalt,
          einheit: behaelterGroesse?.einheit,
          artMaterialBehaelter: behaelterGroesse?.artMaterialBehaelter,
          bemerkung: behaelterGroesse?.bemerkung,
        };
      }),
    ).toArray();
  }

  //-------------------------
  public mapDtoToFormel(formelDTO: FormelDTO): Formel {
    let formel: Formel = {
      id: formelDTO.id,
      state: formelDTO.state,
      allgemein: this.mapDtoToAllgemein(formelDTO.allgemein),
      zusammensetzung: this.mapDtoToZusammensetzung(formelDTO.zusammensetzung),
      lagerung: this.mapDtoToLagerung(formelDTO.lagerung),
      verpackung: this.mapDtoToVerpackung(formelDTO.verpackung),
      pruefungsNotes: this.mapDtoToPruefungsNotes(formelDTO.pruefungsNotes),
      betriebNotes: this.valueOrEmpty(formelDTO.betriebNotes),
    };

    if (formelDTO.formelRisiko) {
      formel.formelRisiko = this.mapDtoToFormelRisiko(formelDTO.formelRisiko);
    }

    if (formelDTO.parent) {
      formel.parent = this.mapDtoToFormel(formelDTO.parent);
    }
    return formel;
  }

  private mapDtoToAllgemein(allgemeinDTO: AllgemeinDTO | undefined): Allgemein {
    if (allgemeinDTO != undefined) {
      return {
        praeparatName: this.valueOrEmpty(allgemeinDTO.praeparatName),
        praeparatArt: allgemeinDTO.praeparatArt,
        indikation: this.valueOrEmpty(allgemeinDTO.indikation),
        dosierungsVorschrift: this.valueOrEmpty(
          allgemeinDTO.dosierungsVorschrift,
        ),
        darreichungsform: allgemeinDTO.darreichungsform,
        lohnherstellung: this.valueOrFalse(allgemeinDTO.lohnherstellung),
        lohnhersteller: this.mapDtoToLohnhersteller(
          allgemeinDTO.lohnhersteller,
        ),
        files: this.toList(allgemeinDTO.files),
        notes: this.valueOrEmpty(allgemeinDTO.notes),
      };
    }
    return {
      praeparatName: '',
      praeparatArt: undefined,
      indikation: '',
      dosierungsVorschrift: '',
      darreichungsform: undefined,
      lohnherstellung: false,
      lohnhersteller: {
        name: '',
        betriebsArt: undefined,
        postfach: '',
        strasse: '',
        plz: '',
        ort: '',
      },
      files: List<FileDTO>(),
      notes: '',
    };
  }

  private mapDtoToLohnhersteller(
    lohnherstellerDTO: LohnherstellerDTO | undefined,
  ): Lohnhersteller {
    if (lohnherstellerDTO != undefined) {
      return {
        name: this.valueOrEmpty(lohnherstellerDTO.name),
        betriebsArt: lohnherstellerDTO.betriebsArt,
        postfach: this.valueOrEmpty(lohnherstellerDTO.postfach),
        strasse: this.valueOrEmpty(lohnherstellerDTO.strasse),
        plz: this.valueOrEmpty(lohnherstellerDTO.plz),
        ort: this.valueOrEmpty(lohnherstellerDTO.ort),
      };
    }
    return {
      name: '',
      betriebsArt: undefined,
      postfach: '',
      strasse: '',
      plz: '',
      ort: '',
    };
  }

  private mapDtoToZusammensetzung(
    zusammensetzungDTO: ZusammensetzungDTO | undefined,
  ): Zusammensetzung {
    if (zusammensetzungDTO != undefined) {
      return {
        id: zusammensetzungDTO.id,
        chargengroesse: this.toStringOrEmpty(zusammensetzungDTO.chargengroesse),
        chargengroesseEinheit: zusammensetzungDTO.chargengroesseEinheit,
        komponent: {
          komponentOrder: '',
          bezeichnung: '',
          qualitaet: undefined,
          art: undefined,
          einheitsMenge: '',
          einheit: undefined,
          bemerkung: '',
        },
        komponenten: this.mapDtosTokomponenten(
          zusammensetzungDTO.komponenten?.sort(
            // @ts-ignore
            (a, b) => a.komponentOrder - b.komponentOrder,
          ),
        ),
        files: this.toList(zusammensetzungDTO.files),
        notes: this.valueOrEmpty(zusammensetzungDTO.notes),
      };
    }
    return {
      id: undefined,
      chargengroesse: '',
      chargengroesseEinheit: undefined,
      komponent: {
        komponentOrder: '',
        bezeichnung: '',
        qualitaet: undefined,
        art: undefined,
        einheitsMenge: '',
        einheit: undefined,
        bemerkung: '',
      },
      komponenten: List.of(),
      files: List<FileDTO>(),
      notes: '',
    };
  }

  private mapDtoToLagerung(lagerungDTO: LagerungDTO | undefined): Lagerung {
    if (lagerungDTO != undefined) {
      return {
        lagerungshinweise: this.mapDtosToLagerungshinweise(
          lagerungDTO.lagerungshinweise,
        ),
        haltbarkeitsDauer: this.valueOrEmpty(lagerungDTO.haltbarkeitsdauer),
        bemerkung: this.valueOrEmpty(lagerungDTO.bemerkung),
        files: this.toList(lagerungDTO.files),
        notes: this.valueOrEmpty(lagerungDTO.notes),
      };
    }
    return {
      lagerungshinweise: List.of(),
      haltbarkeitsDauer: '',
      bemerkung: '',
      files: List<FileDTO>(),
      notes: '',
    };
  }

  private mapDtoToVerpackung(
    verpackungDTO: VerpackungDTO | undefined,
  ): Verpackung {
    if (verpackungDTO != undefined) {
      return {
        id: verpackungDTO.id,
        behealterGroesse: {
          packungsinhalt: '',
          einheit: undefined,
          artMaterialBehaelter: '',
          bemerkung: '',
        },
        behaelterGroessen: this.mapDtosToBehaelterGroessen(
          verpackungDTO.behaelterGroessen,
        ),
        files: this.toList(verpackungDTO.files),
        notes: this.valueOrEmpty(verpackungDTO.notes),
        etiketten: this.toList(verpackungDTO.etiketten),
      };
    }
    return {
      id: undefined,
      behealterGroesse: {
        packungsinhalt: '',
        einheit: undefined,
        artMaterialBehaelter: '',
        bemerkung: '',
      },
      behaelterGroessen: List.of(),
      files: List<FileDTO>(),
      notes: '',
      etiketten: List<FileDTO>(),
    };
  }

  private mapDtoToPruefungsNotes(
    pruefungsNotesDTO: PruefungsNotesDTO | undefined,
  ): PruefungsNotes {
    if (pruefungsNotesDTO != undefined) {
      return {
        notesAllgemein: this.valueOrEmpty(pruefungsNotesDTO.notesAllgemein),
        notesZusammensetzung: this.valueOrEmpty(
          pruefungsNotesDTO.notesZusammensetzung,
        ),
        notesLagerung: this.valueOrEmpty(pruefungsNotesDTO.notesLagerung),
        notesVerpackung: this.valueOrEmpty(pruefungsNotesDTO.notesVerpackung),
      };
    }
    return {
      notesAllgemein: '',
      notesZusammensetzung: '',
      notesLagerung: '',
      notesVerpackung: '',
    };
  }

  private mapDtosToBehaelterGroessen(
    behaelterGroesseDTOS: BehaelterGroesseDTO[] | undefined,
  ): List<BehaelterGroesse> {
    if (behaelterGroesseDTOS != undefined) {
      return List<BehaelterGroesse>(
        behaelterGroesseDTOS.map((b) => {
          return {
            packungsinhalt: this.valueOrEmpty(b.packungsinhalt),
            einheit: b.einheit,
            artMaterialBehaelter: this.valueOrEmpty(b.artMaterialBehaelter),
            bemerkung: this.valueOrEmpty(b.bemerkung),
          };
        }),
      );
    }
    return List.of();
  }

  private toList(files: FileDTO[] | undefined): List<FileDTO> {
    if (files != undefined) {
      return List<FileDTO>(files);
    }
    return List.of();
  }

  private mapDtosTokomponenten(
    kompenenteDTOs: KompenenteDTO[] | undefined,
  ): List<Komponente> {
    if (kompenenteDTOs != undefined) {
      return List<Komponente>(
        kompenenteDTOs.map((k) => {
          return {
            komponentOrder: this.toStringOrEmpty(k.komponentOrder),
            bezeichnung: this.valueOrEmpty(k.bezeichnung),
            qualitaet: k.qualitaet,
            art: k.art,
            einheitsMenge: this.toStringOrEmpty(k.einheitsMenge),
            einheit: k.einheit,
            bemerkung: this.valueOrEmpty(k.bemerkung),
          };
        }),
      );
    }
    return List.of();
  }

  private mapDtosToLagerungshinweise(
    lagerungshinweise: LagerungshinweiseEnum[] | undefined,
  ): List<LagerungshinweiseEnum> {
    if (lagerungshinweise != undefined) {
      return List(
        lagerungshinweise.map((l) => {
          return l;
        }),
      );
    }
    return List.of();
  }

  private toNumberOrUndefined(value: string) {
    if (value != undefined && value == '') {
      return undefined;
    }
    return Number(value);
  }

  private undefinedIfEmpty(value: any) {
    if (value != undefined && value == '') {
      return undefined;
    }
    return value;
  }

  public valueOrEmpty(value: string | undefined) {
    if (value == undefined) {
      return '';
    }
    return value;
  }

  private valueOrFalse(value: boolean | undefined) {
    if (value == undefined) {
      return false;
    }
    return value;
  }

  public toStringOrEmpty(value: Number | undefined) {
    if (value == undefined) {
      return '';
    }
    return value.toString();
  }

  private mapDtoToFormelRisiko(formelRisikoDTO: FormelRisikoDTO): FormelRisiko {
    return {
      formelRisikoParameter: formelRisikoDTO.formelRisikoParameter,
      risikoFaktor: formelRisikoDTO.risikoFaktor,
    };
  }

  private mapFormelRisikoToDto(formelRisiko: FormelRisiko): FormelRisikoDTO {
    return {
      formelRisikoParameter: formelRisiko.formelRisikoParameter,
      risikoFaktor: formelRisiko.risikoFaktor,
    };
  }

  public mapFormelRisikoParameterToDto(
    formelRisikoParameter: FormelRisikoParameter,
  ): FormelRisikoParameterDTO {
    return {
      anwendungsart: formelRisikoParameter.anwendungsart,
      mengenverhaeltnis: formelRisikoParameter.mengenverhaeltnis,
      wirkstoffrisiko: formelRisikoParameter.wirkstoffrisiko,
      herstellungsprozess: formelRisikoParameter.herstellungsprozess,
      arzneiform: formelRisikoParameter.arzneiform,
      produktionsmenge: formelRisikoParameter.produktionsmenge,
    };
  }

  public mapDtoToFormelRisikoParameter(
    formelRisikoParameterDto: FormelRisikoParameterDTO,
  ): FormelRisikoParameter {
    return {
      anwendungsart: formelRisikoParameterDto.anwendungsart,
      mengenverhaeltnis: formelRisikoParameterDto.mengenverhaeltnis,
      wirkstoffrisiko: formelRisikoParameterDto.wirkstoffrisiko,
      herstellungsprozess: formelRisikoParameterDto.herstellungsprozess,
      arzneiform: formelRisikoParameterDto.arzneiform,
      produktionsmenge: formelRisikoParameterDto.produktionsmenge,
    };
  }
}
