import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import {
  BenutzerApiService,
  BenutzerDTO,
  BerechtigungAntragApiService,
  BetriebApiService,
} from '../../../backend';
import { BerechtigungApiService } from '../../../backend/api/berechtigungApi.service';
import { BerechtigungDTO } from '../../../backend/model/berechtigungDTO';
import { BerechtigungService } from '../../../services/berechtigung.service';
import {
  EfoTableComponent,
  SearchConfiguration,
} from '../../efo-table/efo-table.component';

@Component({
  selector: 'app-berechtigung-current-overview',
  templateUrl: './berechtigung-current-overview.component.html',
  styleUrl: './berechtigung-current-overview.component.scss',
})
export class BerechtigungCurrentOverviewComponent {
  @ViewChild(EfoTableComponent)
  efoTableComponent!: EfoTableComponent;

  @Output()
  public reload = new EventEmitter<void>();

  totalElements: number = 0;
  totalPages: number = 0;
  numberOfElements: number = 0;

  berechtigungen?: Array<BerechtigungDTO> = [];
  currentBerechtigung?: BerechtigungDTO;
  benutzer?: Array<BenutzerDTO> = [];

  id: string | undefined = undefined;

  constructor(
    private readonly betriebApiService: BetriebApiService,
    private readonly berechtigungApiService: BerechtigungApiService,
    private readonly berechtigungService: BerechtigungService,
    private readonly benutzerApiService: BenutzerApiService,
    private readonly berechtigungAntragApiService: BerechtigungAntragApiService,
  ) {}

  selectedIndex: number | null = null;

  selectRow(berechtigung: BerechtigungDTO, index: number) {
    this.select(berechtigung);
    this.selectedIndex = index;
  }

  ngOnInit() {
    this.initView();
  }

  initView() {
    this.berechtigungService.currentBerechtigung.subscribe((b) => {
      if (b != null) {
        this.currentBerechtigung = b;
      } else {
        this.currentBerechtigung = undefined;
      }
    });
  }

  setSelectedBerechtigung() {
    if (this.berechtigungen) {
      this.berechtigungen.forEach((b, index) => {
        if (b.id && this.berechtigungService.isCurrentBerechtigung(b.id)) {
          this.selectedIndex = index;
        }
      });
    }
  }

  findAllBerechtigungen(searchConfiguration: SearchConfiguration) {
    this.berechtigungApiService
      .findAllByCurrentBenutzer(
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.berechtigungen = page.content;
        this.setSelectedBerechtigung();
        if (page.totalElements) {
          this.totalElements = page.totalElements;
        } else {
          this.totalElements = 0;
        }
        if (page.totalPages) {
          this.totalPages = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElements = page.numberOfElements;
        }
      });
  }

  select(berechtigung: BerechtigungDTO) {
    this.berechtigungService.setBerechtigung(berechtigung);
    this.currentBerechtigung = berechtigung;
  }
}
