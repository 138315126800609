/**
 * An observable value that's persisted in the local storage.
 *
 *  - T must be something that can be serialized to json / pure json values. DOES NOT work with classes, such as Set.
 *  - Detects if there's a change or not (using lodash).
 */
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '../services/storage.service';
import { map } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash-es';

export class PersistedValue<T> {
  private readonly storage: Storage;

  constructor(
    private readonly key: string,
    private readonly initialValueProvider: () => T,
    storage?: Storage,
  ) {
    if (storage !== undefined) {
      this.storage = storage;
    } else {
      this.storage = window.localStorage;
    }
  }

  get value(): T {
    const value = this.valueNotCloned;
    // values are cloned. If not, something from outside could change that value.
    return PersistedValue.cloneDeep<T>(value);
  }

  set value(value: T) {
    const currentValue = this.valueNotCloned;
    if (!isEqual(currentValue, value)) {
      // We deeply clone the value to be able to detect changes (if not, something from outside could make changes we can't detect easily).
      const clonedValue = PersistedValue.cloneDeep<T>(value);
      this.behaviorSubject.next(clonedValue);
      this.persistValue(clonedValue);
    }
  }

  get observable(): Observable<T> {
    return this.behaviorSubject.pipe(
      map((value) => {
        // values are cloned. If not, something from outside could change that value.
        return PersistedValue.cloneDeep<T>(value);
      }),
    );
  }

  private get valueNotCloned(): T {
    const value = this.behaviorSubject.value;
    if (value instanceof UndefinedValue) {
      throw "This code is unreachable (if you see this exception, there's a bug in the code).";
    }
    return value;
  }

  private _behaviorSubject?: BehaviorSubject<T> = undefined;

  private get behaviorSubject(): BehaviorSubject<T> {
    if (this._behaviorSubject === undefined) {
      const initialValueFromPersistence =
        this.readPersistentValueConvertErrorsToUndefined();
      let initialValue;
      if (initialValueFromPersistence instanceof UndefinedValue) {
        initialValue = this.initialValueProvider();
      } else {
        initialValue = initialValueFromPersistence;
      }
      this._behaviorSubject = new BehaviorSubject<T>(initialValue);
    }
    return this._behaviorSubject;
  }

  private static cloneDeep<T>(value: T): T {
    return cloneDeep<T>(value);
  }

  private persistValue(value: T) {
    this.storage.setItem(this.key, JSON.stringify(value));
  }

  private readPersistentValueConvertErrorsToUndefined(): T | UndefinedValue {
    // If there's an invalid value in the storage, the app might be broken forever. So we rather ignore errors
    // in such cases and use the default value.
    try {
      return this.readPersistentValue();
    } catch (error) {
      console.warn(`Invalid value in storage for key '${this.key}'.`, error);
    }
    return new UndefinedValue();
  }

  private readPersistentValue(): T | UndefinedValue {
    const stringOrNull = this.storage.getItem(this.key);
    if (stringOrNull !== null) {
      return JSON.parse(stringOrNull) as T;
    } else {
      return new UndefinedValue();
    }
  }
}

/**
 * Custom class, cannot use "undefined", since "T" itself might be "undefined".
 */
class UndefinedValue {}
