import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BewFormComponent,
  FormComponentWithDisabled,
  FormComponentWithError,
  FormComponentWithLabel,
} from '../form-component';
import { ValidationErrors } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-bew-file-upload',
  templateUrl: './bew-file-upload.component.html',
  styleUrls: ['./bew-file-upload.component.scss'],
})
export class BewFileUploadComponent implements BewFileUploadComponentInterface {
  @Input()
  disabled: boolean = false;
  @Input()
  error: string = '';
  @Input()
  label: string = '';
  @Input()
  filename?: string;

  /**
   * There's currently no file and the user has selected 1-n file(s) for upload.
   */
  @Output()
  readonly filesSelected: EventEmitter<OnFileSelectedEvent> =
    new EventEmitter();
  /**
   * There's a file and the user clicked the remove button / icon (remove uploaded file).
   */
  @Output()
  readonly remove: EventEmitter<BewFileUploadComponentInterface> =
    new EventEmitter();

  @Output()
  readonly download: EventEmitter<BewFileUploadComponentInterface> =
    new EventEmitter();

  @Output()
  readonly preview: EventEmitter<BewFileUploadComponentInterface> =
    new EventEmitter();

  // @ts-ignore
  @ViewChild('pdfViewer', { static: false }) pdfViewer;

  showPdfPreview: boolean = false;
  showImgPreview: boolean = false;
  imgPreview: SafeUrl = '';
  private previewableFileTypes = ['.jpg', '.jpeg', '.png', '.pdf'];

  constructor() {}

  get invalid(): boolean {
    return this.error.length > 0;
  }

  get hasFile(): boolean {
    return this.filename !== undefined;
  }

  onFileSelected($event: Event) {
    const target: HTMLInputElement = $event.target as any;
    const fileList = target.files;
    let nonNullFileList;
    if (fileList === null) {
      nonNullFileList = new EmptyFileList();
    } else {
      nonNullFileList = fileList;
    }
    this.filesSelected.emit(new OnFileSelectedEvent(this, nonNullFileList));
  }

  onRemove() {
    if (!this.disabled) {
      this.remove.emit(this);
    }
  }

  onDownload() {
    this.download.emit(this);
  }

  onPreview() {
    this.preview.emit(this);
  }

  togglePdfPreview(pdf: Blob, filename: String) {
    this.showPdfPreview = !this.showPdfPreview;
    this.pdfViewer.pdfSrc = pdf;
    this.pdfViewer.downloadFileName = filename;
    this.pdfViewer.refresh();
  }

  toggleImgPreview(safeUrl: SafeUrl) {
    this.showImgPreview = !this.showImgPreview;
    this.imgPreview = safeUrl;
  }

  public isPreviewable() {
    return this.isInAllowedFileTypes(this.filename, this.previewableFileTypes);
  }

  private isInAllowedFileTypes(
    fileName: string | undefined,
    allowedFileTypes: Array<string>,
  ) {
    if (fileName == undefined) {
      return false;
    }
    const parts = fileName.split('.');
    if (parts != null && parts.length > 0) {
      const fileEnding = '.' + parts[parts.length - 1].toLocaleLowerCase();
      return allowedFileTypes.some(
        (allowedFileType) => allowedFileType === fileEnding,
      );
    }
    return false;
  }

  @Input()
  set errors(errors: ValidationErrors | null) {
    BewFormComponent.setError(errors, this);
  }
}

export interface BewFileUploadComponentInterface
  extends FormComponentWithLabel,
    FormComponentWithError,
    FormComponentWithDisabled {
  filename?: string;
}

export class OnFileSelectedEvent {
  constructor(
    public readonly component: BewFileUploadComponentInterface,
    public readonly files: FileList,
  ) {}
}

class EmptyFileList implements FileList {
  [index: number]: File;

  readonly length: number = 0;

  item(index: number): File | null {
    return null;
  }
}
