import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  private errorSubject = new Subject<string[]>();

  getError() {
    return this.errorSubject.asObservable();
  }

  throwError(messages: string[]) {
    this.errorSubject.next(messages);
  }
}
