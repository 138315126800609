import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinkAction } from '../link-action/link-action';

@Component({
  selector: 'app-bew-service-navigation',
  templateUrl: './bew-service-navigation.component.html',
  styleUrls: ['./bew-service-navigation.component.scss'],
})
export class BewServiceNavigationComponent implements OnInit {
  constructor() {}

  @Input()
  data?: ServiceNavigationData;
  @Output()
  readonly languageSelected: EventEmitter<LanguageSelectedEvent> =
    new EventEmitter<LanguageSelectedEvent>();

  ngOnInit(): void {}

  onLanguageSelected(languageItem: LanguageItem, index: number): boolean {
    const event: LanguageSelectedEvent = { index: index, item: languageItem };
    this.languageSelected.emit(event);
    return false;
  }
}

export interface ServiceNavigationData {
  menuItems: ServiceMenuItems;
  languageItems: LanguageItems;
}

export type ServiceMenuItems = Array<ServiceMenuItem>;

export interface ServiceMenuItem {
  label: string;
  action: LinkAction;
  loginStyle: boolean;
}

export type LanguageItems = Array<LanguageItem>;

export interface LanguageItem {
  label: string;
  active: boolean;
}

export interface LanguageSelectedEvent {
  item: LanguageItem;
  index: number;
}
