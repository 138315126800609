import { Component, Output } from '@angular/core';
import { ListMimeTypeFilter } from '../../data/list-mime-type-filter';
import { TranslateService } from '@ngx-translate/core';
import { AcceptedFiles } from '../../data/accepted-files';
import { Set } from 'immutable';

@Component({
  selector: 'app-default-file-filter',
  templateUrl: './default-file-filter.component.html',
  styleUrls: ['./default-file-filter.component.scss'],
})
export class DefaultFileFilterComponent {
  private currentFileFilter?: FileFilter;

  constructor(private readonly translateService: TranslateService) {}

  /**
   * This property can be assigned to a multi- or single file upload component.
   */
  @Output()
  get fileFilter(): FileFilter {
    if (this.currentFileFilter === undefined) {
      this.currentFileFilter = this.createFileFilter();
      return this.currentFileFilter;
    } else {
      return this.currentFileFilter;
    }
  }

  private createFileFilter(): FileFilter {
    const fileFilter = new FileFilter(this.translateService);

    fileFilter.filesNotAllowed.blackList = Array.from(
      // @ts-ignore
      AcceptedFiles.singleton.rejectedMimeTypes,
    );
    fileFilter.filesAllowedButNotDesired.whiteList = Array.from(
      // @ts-ignore
      AcceptedFiles.singleton.acceptedMimeTypes,
    );
    return fileFilter;
  }
}

class FileFilter extends ListMimeTypeFilter {
  constructor(private readonly translateService: TranslateService) {
    super();
  }

  textForAllowedButNotDesired(mimeType: string): string {
    return this.translateService.instant(
      'defaultFileFilterComponent.notDesiredFile',
    );
  }

  textForNotAllowed(mimeType: string): string {
    return this.translateService.instant(
      'defaultFileFilterComponent.noAllowedFile',
    );
  }
}
