<ng-container [ngSwitch]="type">
  <button
    [type]="button_type"
    *ngSwitchCase="'primary'"
    [disabled]="disabled"
    class="primary"
    (click)="onClick()">
    {{ label }}
  </button>
  <button
    [type]="button_type"
    *ngSwitchCase="'green'"
    [disabled]="disabled"
    class="green"
    (click)="onClick()">
    {{ label }}
  </button>

  <button
    [type]="button_type"
    *ngSwitchCase="'orange'"
    [disabled]="disabled"
    class="orange"
    (click)="onClick()">
    {{ label }}
  </button>
  <button
    [type]="button_type"
    *ngSwitchCase="'secondary'"
    [disabled]="disabled"
    class="secondary"
    (click)="onClick()">
    {{ label }}
  </button>
  <button
    [type]="button_type"
    *ngSwitchCase="'go_back'"
    [disabled]="disabled"
    class="go-back"
    (click)="onClick()">
    <span class="bew-button-icon-go-back-left bew-icon-left"></span>
    <span>{{ label }}</span>
    <span class="bew-button-icon-go-back-right bew-icon-right"></span>
  </button>
  <button
    [type]="button_type"
    *ngSwitchCase="'go_next'"
    [disabled]="disabled"
    class="go-next"
    (click)="onClick()">
    <span class="bew-button-icon-go-next-left bew-icon-left"></span>
    <span>{{ label }}</span>
    <span class="bew-button-icon-go-next-right bew-icon-right"></span>
  </button>

  <button
    [type]="button_type"
    *ngSwitchCase="'plus'"
    [disabled]="disabled"
    class="go-next"
    (click)="onClick()">
    <span class="bew-button-icon-plus-left bew-icon-left"></span>
    <span>{{ label }}</span>
    <span class="bew-button-icon-plus-right bew-icon-right"></span>
  </button>
  <div *ngSwitchDefault>Unknown button type: {{ type }}</div>
</ng-container>
