<label
  [class.checkbox-disabled]="disabled"
  [class.checkbox]="inline"
  [class.checkbox-block]="!inline">
  <input
    type="checkbox"
    (blur)="onTouched()"
    [(ngModel)]="value"
    (ngModelChange)="onValueChanged()"
    [disabled]="disabled" />
  <span class="checked"></span>
  <span class="checkbox"></span>
  <p [innerHTML]="label"></p>
</label>
