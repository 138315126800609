/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Arzneiform = '{\"code\": 1900, \"name\": \"AUGENTROPFEN\"}' | '{\"code\": 1901, \"name\": \"FEST\"}' | '{\"code\": 1902, \"name\": \"FLUESSIG\"}' | '{\"code\": 1903, \"name\": \"HALBFEST\"}' | '{\"code\": 1904, \"name\": \"HALBFEST_ZAEPFCHEN\"}';

export const Arzneiform = {
    _1900NameAUGENTROPFEN: '{\"code\": 1900, \"name\": \"AUGENTROPFEN\"}' as Arzneiform,
    _1901NameFEST: '{\"code\": 1901, \"name\": \"FEST\"}' as Arzneiform,
    _1902NameFLUESSIG: '{\"code\": 1902, \"name\": \"FLUESSIG\"}' as Arzneiform,
    _1903NameHALBFEST: '{\"code\": 1903, \"name\": \"HALBFEST\"}' as Arzneiform,
    _1904NameHALBFESTZAEPFCHEN: '{\"code\": 1904, \"name\": \"HALBFEST_ZAEPFCHEN\"}' as Arzneiform
};