<app-bew-box [label]="'antrag.betrieb.title' | translate">
  <app-efo-table
    #betriebTable
    [pageSize]="5"
    [totalElements]="totalElements"
    [totalPages]="totalPages"
    [numberOfElements]="numberOfElements"
    (searchContent)="findAll($event)">
    <tr table-header>
      <th>
        {{ 'betrieb.stammdaten.name' | translate }}
        <app-table-column-sort
          [label]="'name'"
          [sortBy]="betriebTable.sortConfiguration.sortBy"
          (sortByMe)="
            betriebTable.changeSorting($event)
          "></app-table-column-sort>
      </th>
      <th>
        {{ 'betrieb.stammdaten.strasse' | translate }}
        <app-table-column-sort
          [label]="'strasse'"
          [sortBy]="betriebTable.sortConfiguration.sortBy"
          (sortByMe)="
            betriebTable.changeSorting($event)
          "></app-table-column-sort>
      </th>
      <th>
        {{ 'betrieb.stammdaten.plz' | translate }}
        <app-table-column-sort
          [label]="'plz'"
          [sortBy]="betriebTable.sortConfiguration.sortBy"
          (sortByMe)="
            betriebTable.changeSorting($event)
          "></app-table-column-sort>
      </th>
      <th class="auto-width">
        {{ 'betrieb.stammdaten.gln' | translate }}
        <app-table-column-sort
          [label]="'gln'"
          [sortBy]="betriebTable.sortConfiguration.sortBy"
          (sortByMe)="
            betriebTable.changeSorting($event)
          "></app-table-column-sort>
      </th>
      <th></th>
    </tr>

    <tbody table-body>
      <tr *ngIf="betriebe?.length == 0">
        <td colspan="5">
          {{ 'betriebe.berechtigt.overview.none' | translate }}
        </td>
      </tr>
      <ng-container *ngFor="let betrieb of betriebe; index as i">
        <tr>
          <td>{{ betrieb.name }}</td>
          <td>{{ betrieb.strasse }}</td>
          <td>{{ betrieb.plz }}</td>
          <td class="auto-width">{{ betrieb.gln }}</td>

          <td>
            <app-betrieb-berechtigung-beantragen
              [betriebId]="betrieb.id"
              (antrag)="
                berechtigungBeantragen($event)
              "></app-betrieb-berechtigung-beantragen>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </app-efo-table>
</app-bew-box>
