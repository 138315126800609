/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileDTO } from './fileDTO';
import { LohnherstellerDraftDTO } from './lohnherstellerDraftDTO';

export interface AllgemeinDraftDTO { 
    praeparatName?: string;
    praeparatArt?: AllgemeinDraftDTO.PraeparatArtEnum;
    indikation?: string;
    dosierungsVorschrift?: string;
    darreichungsform?: AllgemeinDraftDTO.DarreichungsformEnum;
    lohnherstellung?: boolean;
    lohnhersteller?: LohnherstellerDraftDTO;
    files?: Array<FileDTO>;
    notes?: string;
}
export namespace AllgemeinDraftDTO {
    export type PraeparatArtEnum = 'ALLOPATHISCH' | 'ANTHROPOSOPHISCH' | 'AROMATHERAPIE' | 'FUNKTIONSMITTEL_NACH_DR_SCHUESSLER' | 'HOMOEOPATHISCH' | 'HOMOEOPATHISCH_SPAGYRISCH' | 'PHYTO' | 'SPAGYRISCH' | 'TRADITIONELL_AYURVEDISCH' | 'TRADITIONELL_CHINESISCH' | 'TRADITIONELL_TIBETISCH';
    export const PraeparatArtEnum = {
        ALLOPATHISCH: 'ALLOPATHISCH' as PraeparatArtEnum,
        ANTHROPOSOPHISCH: 'ANTHROPOSOPHISCH' as PraeparatArtEnum,
        AROMATHERAPIE: 'AROMATHERAPIE' as PraeparatArtEnum,
        FUNKTIONSMITTELNACHDRSCHUESSLER: 'FUNKTIONSMITTEL_NACH_DR_SCHUESSLER' as PraeparatArtEnum,
        HOMOEOPATHISCH: 'HOMOEOPATHISCH' as PraeparatArtEnum,
        HOMOEOPATHISCHSPAGYRISCH: 'HOMOEOPATHISCH_SPAGYRISCH' as PraeparatArtEnum,
        PHYTO: 'PHYTO' as PraeparatArtEnum,
        SPAGYRISCH: 'SPAGYRISCH' as PraeparatArtEnum,
        TRADITIONELLAYURVEDISCH: 'TRADITIONELL_AYURVEDISCH' as PraeparatArtEnum,
        TRADITIONELLCHINESISCH: 'TRADITIONELL_CHINESISCH' as PraeparatArtEnum,
        TRADITIONELLTIBETISCH: 'TRADITIONELL_TIBETISCH' as PraeparatArtEnum
    };
    export type DarreichungsformEnum = 'BAD' | 'CREME' | 'EMULSION_LOTION' | 'GLOBULI' | 'GRANULAT' | 'KAPSELN' | 'LOESUNG_SAFT' | 'LUTSCHTABLETTE' | 'PULVER' | 'SALBE' | 'SHAMPOO' | 'SPRAY_TOPISCH' | 'SPRAY_EINNAHME' | 'SUPPOSITORIEN_ZAEPFCHEN' | 'TABLETTEN' | 'TEE' | 'TROPFEN';
    export const DarreichungsformEnum = {
        BAD: 'BAD' as DarreichungsformEnum,
        CREME: 'CREME' as DarreichungsformEnum,
        EMULSIONLOTION: 'EMULSION_LOTION' as DarreichungsformEnum,
        GLOBULI: 'GLOBULI' as DarreichungsformEnum,
        GRANULAT: 'GRANULAT' as DarreichungsformEnum,
        KAPSELN: 'KAPSELN' as DarreichungsformEnum,
        LOESUNGSAFT: 'LOESUNG_SAFT' as DarreichungsformEnum,
        LUTSCHTABLETTE: 'LUTSCHTABLETTE' as DarreichungsformEnum,
        PULVER: 'PULVER' as DarreichungsformEnum,
        SALBE: 'SALBE' as DarreichungsformEnum,
        SHAMPOO: 'SHAMPOO' as DarreichungsformEnum,
        SPRAYTOPISCH: 'SPRAY_TOPISCH' as DarreichungsformEnum,
        SPRAYEINNAHME: 'SPRAY_EINNAHME' as DarreichungsformEnum,
        SUPPOSITORIENZAEPFCHEN: 'SUPPOSITORIEN_ZAEPFCHEN' as DarreichungsformEnum,
        TABLETTEN: 'TABLETTEN' as DarreichungsformEnum,
        TEE: 'TEE' as DarreichungsformEnum,
        TROPFEN: 'TROPFEN' as DarreichungsformEnum
    };
}