<div *ngIf="visible" class="bew-notification-error-container container">
  <div class="row">
    <div
      [class.bew-notification-left-pane-error]="isTypeError"
      [class.bew-notification-left-pane-info]="isTypeInfo"
      [class.bew-notification-left-pane-warning]="isTypeWarning"
      class="col-1 bew-notification-left-pane">
      &nbsp;
    </div>
    <div
      [class.bew-notification-right-pane-error]="isTypeError"
      [class.bew-notification-right-pane-info]="isTypeInfo"
      [class.bew-notification-right-pane-warning]="isTypeWarning"
      class="col-11 bew-notification-right-pane">
      <h1 class="bew-notification-header">{{ label }}</h1>
      <p class="bew-notification-notification-text">{{ text }}</p>
      <!-- technical error (only showed if user wants to and if there is a technical error) -->
      <ng-container *ngIf="hasTechnicalInfo">
        <app-bew-checkbox
          [(ngModel)]="showTechnicalInfo"
          [label]="showTechnicalInfoText">
        </app-bew-checkbox>
        <i
          *ngIf="showTechnicalInfo"
          class="bew-notification-notification-pre"
          >{{ technicalInfo }}</i
        >
      </ng-container>
      <!-- dismiss the error; just sets the visibility to false -->
      <app-bew-button
        (invoke)="onInvokeDismiss()"
        *ngIf="canBeDismissed"
        [label]="dismissButtonLabel"
        button_type="button"
        type="secondary">
      </app-bew-button>
    </div>
  </div>
</div>
