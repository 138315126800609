import { Component, Input } from '@angular/core';
import {
  FormComponentWithDisabled,
  FormComponentWithLabel,
} from '../form-component';
import { LinkAction } from '../link-action/link-action';

@Component({
  selector: 'app-bew-signet',
  templateUrl: './bew-signet.component.html',
  styleUrls: ['./bew-signet.component.scss'],
})
export class BewSignetComponent
  implements FormComponentWithDisabled, FormComponentWithLabel
{
  constructor() {}

  @Input()
  label: string = 'MISSING_LABEL';
  @Input()
  small: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  linkAction?: LinkAction;

  get isInvokable(): boolean {
    return this.linkAction !== undefined && !this.disabled;
  }
}
