import { Component, Input, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import {
  BewFormComponent,
  BewFormComponentWithValue,
  FormComponentWithDisabled,
  FormComponentWithError,
  FormComponentWithLabel,
} from '../form-component';

@Component({
  selector: 'app-bew-select',
  templateUrl: './bew-select.component.html',
  styleUrls: ['./bew-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BewSelectComponent,
    },
  ],
})
export class BewSelectComponent
  extends BewFormComponentWithValue<string>
  implements
    OnInit,
    FormComponentWithError,
    FormComponentWithDisabled,
    FormComponentWithLabel,
    ControlValueAccessor
{
  @Input()
  label: string = 'NO_LABEL';
  @Input()
  error: string = '';
  @Input()
  maxlength?: number;
  @Input()
  minlength?: number;
  @Input()
  pattern?: string;
  @Input()
  @Output()
  disabled: boolean = false;
  @Input()
  valueRequired: boolean = false;
  @Input()
  validateOnTouch: boolean = false;
  @Input()
  prefix: string = 'enum';
  @Input()
  items: any[] = [];

  @Input()
  @Output()
  override value: any = undefined;

  /**
   * The type of the text field, such as "number", "password" or "date".
   */
  @Input()
  type: string = 'text';

  ngOnInit(): void {}

  invalid: boolean = false;

  onInputTouched() {
    if (this.validateOnTouch) {
      this.validate();
    }
    this.onTouched();
  }

  onInputValueChanged() {
    this.validate();
    this.onValueChanged();
  }

  validate(): boolean {
    let invalid: boolean = false;
    if (this.valueRequired && (this.value == '' || this.value == undefined)) {
      invalid = true;
    }
    if (this.error !== undefined && this.error !== null && invalid) {
      this.invalid = true;
      return this.error.length > 0;
    } else {
      this.invalid = false;

      return false;
    }
  }

  protected initialValue(): string {
    return '';
  }

  @Input()
  set errors(errors: ValidationErrors | null) {
    BewFormComponent.setError(errors, this);
  }
}
