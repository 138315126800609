/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {BerechtigungMailConfigurationUpdateDTO} from '../model/berechtigungMailConfigurationUpdateDTO';
import {BerechtigungUpdateDTO} from '../model/berechtigungUpdateDTO';
import {PageBerechtigungDTO} from '../model/pageBerechtigungDTO';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class BerechtigungApiService {

  protected basePath = 'http://localhost:8094';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteBetriebBerechtigung(id: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public deleteBetriebBerechtigung(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public deleteBetriebBerechtigung(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public deleteBetriebBerechtigung(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteBetriebBerechtigung.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<string>('delete',`${this.basePath}/api/berechtigung/delete/${encodeURIComponent(String(id))}/betrieb`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePadBerechtigung(id: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public deletePadBerechtigung(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public deletePadBerechtigung(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public deletePadBerechtigung(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deletePadBerechtigung.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<string>('delete',`${this.basePath}/api/berechtigung/delete/${encodeURIComponent(String(id))}/pad`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param search
   * @param sortBy
   * @param page
   * @param size
   * @param direction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllBetriebsleiter(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'body', reportProgress?: boolean): Observable<PageBerechtigungDTO>;
  public findAllBetriebsleiter(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageBerechtigungDTO>>;
  public findAllBetriebsleiter(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageBerechtigungDTO>>;
  public findAllBetriebsleiter(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (direction !== undefined && direction !== null) {
      queryParameters = queryParameters.set('direction', <any>direction);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<PageBerechtigungDTO>('get',`${this.basePath}/api/berechtigung/all/betriebsleiter`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param betriebId
   * @param sortBy
   * @param page
   * @param size
   * @param direction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllByBetrieb(betriebId: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'body', reportProgress?: boolean): Observable<PageBerechtigungDTO>;
  public findAllByBetrieb(betriebId: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageBerechtigungDTO>>;
  public findAllByBetrieb(betriebId: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageBerechtigungDTO>>;
  public findAllByBetrieb(betriebId: string, sortBy?: string, page?: number, size?: number, direction?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (betriebId === null || betriebId === undefined) {
      throw new Error('Required parameter betriebId was null or undefined when calling findAllByBetrieb.');
    }





    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (betriebId !== undefined && betriebId !== null) {
      queryParameters = queryParameters.set('betriebId', <any>betriebId);
    }
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (direction !== undefined && direction !== null) {
      queryParameters = queryParameters.set('direction', <any>direction);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<PageBerechtigungDTO>('get',`${this.basePath}/api/berechtigung/all/betrieb/${encodeURIComponent(String(betriebId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param sortBy
   * @param page
   * @param size
   * @param direction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllByCurrentBenutzer(sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'body', reportProgress?: boolean): Observable<PageBerechtigungDTO>;
  public findAllByCurrentBenutzer(sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageBerechtigungDTO>>;
  public findAllByCurrentBenutzer(sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageBerechtigungDTO>>;
  public findAllByCurrentBenutzer(sortBy?: string, page?: number, size?: number, direction?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (direction !== undefined && direction !== null) {
      queryParameters = queryParameters.set('direction', <any>direction);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<PageBerechtigungDTO>('get',`${this.basePath}/api/berechtigung/all/self`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param sortBy
   * @param page
   * @param size
   * @param direction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllForPad(sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'body', reportProgress?: boolean): Observable<PageBerechtigungDTO>;
  public findAllForPad(sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageBerechtigungDTO>>;
  public findAllForPad(sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageBerechtigungDTO>>;
  public findAllForPad(sortBy?: string, page?: number, size?: number, direction?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (direction !== undefined && direction !== null) {
      queryParameters = queryParameters.set('direction', <any>direction);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<PageBerechtigungDTO>('get',`${this.basePath}/api/berechtigung/all/pad`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBetriebBerechtigung(body: BerechtigungUpdateDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public updateBetriebBerechtigung(body: BerechtigungUpdateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public updateBetriebBerechtigung(body: BerechtigungUpdateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public updateBetriebBerechtigung(body: BerechtigungUpdateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateBetriebBerechtigung.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/berechtigung/update/betrieb`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateMailConfigurationForBetrieb(body: BerechtigungMailConfigurationUpdateDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public updateMailConfigurationForBetrieb(body: BerechtigungMailConfigurationUpdateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public updateMailConfigurationForBetrieb(body: BerechtigungMailConfigurationUpdateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public updateMailConfigurationForBetrieb(body: BerechtigungMailConfigurationUpdateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateMailConfigurationForBetrieb.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/berechtigung/update/mailconfiguration`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePadBerechtigung(body: BerechtigungUpdateDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public updatePadBerechtigung(body: BerechtigungUpdateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public updatePadBerechtigung(body: BerechtigungUpdateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public updatePadBerechtigung(body: BerechtigungUpdateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updatePadBerechtigung.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/berechtigung/update/pad`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
