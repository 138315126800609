import { Injectable } from '@angular/core';
import {
  AllgemeinDraftDTO,
  BehaelterGroesseDraftDTO,
  FileDTO,
  FormelDraftDTO,
  FormelRisikoDraftDTO,
  KompenenteDraftDTO,
  LagerungDraftDTO,
  LagerungDTO,
  LohnherstellerDraftDTO,
  UpdateFormelDraftContentDTO,
  VerpackungDraftDTO,
  ZusammensetzungDraftDTO,
} from '../../backend';
import { List } from 'immutable';
import {
  Allgemein,
  BehaelterGroesse,
  Formel,
  FormelRisiko,
  Komponente,
  Lagerung,
  Lohnhersteller,
  PruefungsNotes,
  Verpackung,
  Zusammensetzung,
} from '../../components/formel/formel-detail/formel-detail.component';
import LagerungshinweiseEnum = LagerungDTO.LagerungshinweiseEnum;

@Injectable({
  providedIn: 'root',
})
export class FormelDraftMapperService {
  constructor() {}

  public mapFormelToUpdateFormelDraftContentDTO(
    formel: Formel,
  ): UpdateFormelDraftContentDTO {
    if (formel.formelRisiko) {
      return {
        formelDraftId: formel.id,
        allgemein: this.mapAllgemeinToDto(formel.allgemein),
        zusammensetzung: this.mapZusammensetzungtoDto(formel.zusammensetzung),
        lagerung: this.mapLagerungToDto(formel.lagerung),
        verpackung: this.mapVerpackungToDto(formel.verpackung),
        formelRisiko: this.mapFormelRisikoToDto(formel.formelRisiko),
      };
    } else {
      return {
        formelDraftId: formel.id,
        allgemein: this.mapAllgemeinToDto(formel.allgemein),
        zusammensetzung: this.mapZusammensetzungtoDto(formel.zusammensetzung),
        lagerung: this.mapLagerungToDto(formel.lagerung),
        verpackung: this.mapVerpackungToDto(formel.verpackung),
      };
    }
  }

  public mapFormelToDto(formel: Formel): FormelDraftDTO {
    return {
      id: formel.id,
      allgemein: this.mapAllgemeinToDto(formel.allgemein),
      zusammensetzung: this.mapZusammensetzungtoDto(formel.zusammensetzung),
      lagerung: this.mapLagerungToDto(formel.lagerung),
      verpackung: this.mapVerpackungToDto(formel.verpackung),
    };
  }

  private mapAllgemeinToDto(allgemein: Allgemein): AllgemeinDraftDTO {
    return {
      praeparatName: allgemein.praeparatName,
      praeparatArt: this.undefinedIfEmpty(allgemein.praeparatArt),
      indikation: allgemein.indikation,
      dosierungsVorschrift: allgemein.dosierungsVorschrift,
      darreichungsform: this.undefinedIfEmpty(allgemein.darreichungsform),
      lohnherstellung: allgemein.lohnherstellung,
      lohnhersteller: this.mapLohnherstellerToDto(allgemein.lohnhersteller),
      files: allgemein.files.toArray(),
      notes: allgemein.notes,
    };
  }

  private mapLohnherstellerToDto(
    lohnhersteller: Lohnhersteller,
  ): LohnherstellerDraftDTO {
    return {
      name: lohnhersteller.name,
      betriebsArt: this.undefinedIfEmpty(lohnhersteller.betriebsArt),
      strasse: lohnhersteller.strasse,
      plz: lohnhersteller.plz,
      ort: lohnhersteller.ort,
    };
  }

  private mapZusammensetzungtoDto(
    zusammensetzung: Zusammensetzung,
  ): ZusammensetzungDraftDTO {
    return {
      id: zusammensetzung.id,
      chargengroesse: this.toNumberOrUndefined(zusammensetzung.chargengroesse),
      chargengroesseEinheit: this.undefinedIfEmpty(
        zusammensetzung.chargengroesseEinheit,
      ),
      komponenten: this.mapKomponentenToDtos(
        zusammensetzung.komponenten.toArray(),
      ),
      files: zusammensetzung.files.toArray(),
      notes: zusammensetzung.notes,
    };
  }

  private mapKomponentenToDtos(
    komponenten: Komponente[],
  ): KompenenteDraftDTO[] {
    return List<KompenenteDraftDTO>(
      komponenten.map((komponente) => {
        return {
          komponentOrder: Number(komponente?.komponentOrder),
          bezeichnung: komponente?.bezeichnung,
          qualitaet: this.undefinedIfEmpty(komponente?.qualitaet),
          art: this.undefinedIfEmpty(komponente?.art),
          einheitsMenge: Number(komponente?.einheitsMenge),
          einheit: this.undefinedIfEmpty(komponente?.einheit),
          bemerkung: komponente?.bemerkung,
        };
      }),
    ).toArray();
  }

  private mapLagerungToDto(lagerung: Lagerung): LagerungDraftDTO {
    return {
      lagerungshinweise: List(lagerung.lagerungshinweise).toArray(),
      bemerkung: lagerung.bemerkung,
      haltbarkeitsdauer: lagerung.haltbarkeitsDauer,
      files: lagerung.files.toArray(),
      notes: lagerung.notes,
    };
  }

  private mapVerpackungToDto(verpackung: Verpackung): VerpackungDraftDTO {
    return {
      id: verpackung.id,
      behaelterGroessen: this.mapBehaelterGroessenToDtos(
        verpackung.behaelterGroessen.toArray(),
      ),
      files: verpackung.files.toArray(),
      notes: verpackung.notes,
      etiketten: verpackung.etiketten.toArray(),
    };
  }

  private mapBehaelterGroessenToDtos(
    behaelterGroessen: BehaelterGroesse[],
  ): BehaelterGroesseDraftDTO[] {
    return List<BehaelterGroesseDraftDTO>(
      behaelterGroessen.map((behaelterGroesse) => {
        return {
          packungsinhalt: behaelterGroesse?.packungsinhalt,
          einheit: behaelterGroesse?.einheit,
          artMaterialBehaelter: behaelterGroesse?.artMaterialBehaelter,
          bemerkung: behaelterGroesse?.bemerkung,
        };
      }),
    ).toArray();
  }

  //-------------------------
  public mapDtoToFormel(formelDraftDTO: FormelDraftDTO): Formel {
    if (formelDraftDTO.formelRisiko) {
      return {
        id: formelDraftDTO.id,
        state: undefined,
        allgemein: this.mapDtoToAllgemein(formelDraftDTO.allgemein),
        zusammensetzung: this.mapDtoToZusammensetzung(
          formelDraftDTO.zusammensetzung,
        ),
        lagerung: this.mapDtoToLagerung(formelDraftDTO.lagerung),
        verpackung: this.mapDtoToVerpackung(formelDraftDTO.verpackung),
        pruefungsNotes: this.mapDtoToPruefungsNotes(),
        betriebNotes: '',
        formelRisiko: this.mapDtoToFormelRisiko(formelDraftDTO.formelRisiko),
      };
    }
    return {
      id: formelDraftDTO.id,
      state: undefined,
      allgemein: this.mapDtoToAllgemein(formelDraftDTO.allgemein),
      zusammensetzung: this.mapDtoToZusammensetzung(
        formelDraftDTO.zusammensetzung,
      ),
      lagerung: this.mapDtoToLagerung(formelDraftDTO.lagerung),
      verpackung: this.mapDtoToVerpackung(formelDraftDTO.verpackung),
      pruefungsNotes: this.mapDtoToPruefungsNotes(),
      betriebNotes: '',
    };
  }

  private mapDtoToAllgemein(
    allgemeinDraftDTO: AllgemeinDraftDTO | undefined,
  ): Allgemein {
    if (allgemeinDraftDTO != undefined) {
      return {
        praeparatName: this.valueOrEmpty(allgemeinDraftDTO.praeparatName),
        praeparatArt: allgemeinDraftDTO.praeparatArt,
        indikation: this.valueOrEmpty(allgemeinDraftDTO.indikation),
        dosierungsVorschrift: this.valueOrEmpty(
          allgemeinDraftDTO.dosierungsVorschrift,
        ),
        darreichungsform: allgemeinDraftDTO.darreichungsform,
        lohnherstellung: this.valueOrFalse(allgemeinDraftDTO.lohnherstellung),
        lohnhersteller: this.mapDtoToLohnhersteller(
          allgemeinDraftDTO.lohnhersteller,
        ),
        files: this.toList(allgemeinDraftDTO.files),
        notes: this.valueOrEmpty(allgemeinDraftDTO.notes),
      };
    }
    return {
      praeparatName: '',
      praeparatArt: undefined,
      indikation: '',
      dosierungsVorschrift: '',
      darreichungsform: undefined,
      lohnherstellung: false,
      lohnhersteller: {
        name: '',
        betriebsArt: undefined,
        postfach: '',
        strasse: '',
        plz: '',
        ort: '',
      },
      files: List<FileDTO>(),
      notes: '',
    };
  }

  private mapDtoToLohnhersteller(
    lohnherstellerDraftDTO: LohnherstellerDraftDTO | undefined,
  ): Lohnhersteller {
    if (lohnherstellerDraftDTO != undefined) {
      return {
        name: this.valueOrEmpty(lohnherstellerDraftDTO.name),
        betriebsArt: lohnherstellerDraftDTO.betriebsArt,
        postfach: this.valueOrEmpty(lohnherstellerDraftDTO.postfach),
        strasse: this.valueOrEmpty(lohnherstellerDraftDTO.strasse),
        plz: this.valueOrEmpty(lohnherstellerDraftDTO.plz),
        ort: this.valueOrEmpty(lohnherstellerDraftDTO.ort),
      };
    }
    return {
      name: '',
      betriebsArt: undefined,
      postfach: '',
      strasse: '',
      plz: '',
      ort: '',
    };
  }

  private mapDtoToZusammensetzung(
    zusammensetzungDraftDTO: ZusammensetzungDraftDTO | undefined,
  ): Zusammensetzung {
    if (zusammensetzungDraftDTO != undefined) {
      return {
        id: zusammensetzungDraftDTO.id,
        chargengroesse: this.toStringOrEmpty(
          zusammensetzungDraftDTO.chargengroesse,
        ),
        chargengroesseEinheit: zusammensetzungDraftDTO.chargengroesseEinheit,
        komponent: {
          komponentOrder: '',
          bezeichnung: '',
          qualitaet: undefined,
          art: undefined,
          einheitsMenge: '',
          einheit: undefined,
          bemerkung: '',
        },
        komponenten: this.mapDtosTokomponenten(
          zusammensetzungDraftDTO.komponenten?.sort(
            // @ts-ignore
            (a, b) => a.komponentOrder - b.komponentOrder,
          ),
        ),
        files: this.toList(zusammensetzungDraftDTO.files),
        notes: this.valueOrEmpty(zusammensetzungDraftDTO.notes),
      };
    }
    return {
      id: undefined,
      chargengroesse: '',
      chargengroesseEinheit: undefined,
      komponent: {
        komponentOrder: '',
        bezeichnung: '',
        qualitaet: undefined,
        art: undefined,
        einheitsMenge: '',
        einheit: undefined,
        bemerkung: '',
      },
      komponenten: List.of(),
      files: List<FileDTO>(),
      notes: '',
    };
  }

  private mapDtoToLagerung(
    lagerungDraftDTO: LagerungDraftDTO | undefined,
  ): Lagerung {
    if (lagerungDraftDTO != undefined) {
      return {
        lagerungshinweise: this.mapDtosToLagerungshinweise(
          lagerungDraftDTO.lagerungshinweise,
        ),
        haltbarkeitsDauer: this.valueOrEmpty(
          lagerungDraftDTO.haltbarkeitsdauer,
        ),
        bemerkung: this.valueOrEmpty(lagerungDraftDTO.bemerkung),
        files: this.toList(lagerungDraftDTO.files),
        notes: this.valueOrEmpty(lagerungDraftDTO.notes),
      };
    }
    return {
      lagerungshinweise: List.of(),
      haltbarkeitsDauer: '',
      bemerkung: '',
      files: List<FileDTO>(),
      notes: '',
    };
  }

  private mapDtoToVerpackung(
    verpackungDraftDTO: VerpackungDraftDTO | undefined,
  ): Verpackung {
    if (verpackungDraftDTO != undefined) {
      return {
        id: verpackungDraftDTO.id,
        behealterGroesse: {
          packungsinhalt: '',
          einheit: undefined,
          artMaterialBehaelter: '',
          bemerkung: '',
        },
        behaelterGroessen: this.mapDtosToBehaelterGroessen(
          verpackungDraftDTO.behaelterGroessen,
        ),
        files: this.toList(verpackungDraftDTO.files),
        notes: this.valueOrEmpty(verpackungDraftDTO.notes),
        etiketten: this.toList(verpackungDraftDTO.etiketten),
      };
    }
    return {
      id: undefined,
      behealterGroesse: {
        packungsinhalt: '',
        einheit: undefined,
        artMaterialBehaelter: '',
        bemerkung: '',
      },
      behaelterGroessen: List.of(),
      files: List<FileDTO>(),
      notes: '',
      etiketten: List.of(),
    };
  }

  private mapDtoToPruefungsNotes(): PruefungsNotes {
    return {
      notesAllgemein: '',
      notesZusammensetzung: '',
      notesLagerung: '',
      notesVerpackung: '',
    };
  }

  private mapDtosToBehaelterGroessen(
    behaelterGroesseDraftDTOS: BehaelterGroesseDraftDTO[] | undefined,
  ): List<BehaelterGroesse> {
    if (behaelterGroesseDraftDTOS != undefined) {
      return List<BehaelterGroesse>(
        behaelterGroesseDraftDTOS.map((b) => {
          return {
            packungsinhalt: this.valueOrEmpty(b.packungsinhalt),
            einheit: b.einheit,
            artMaterialBehaelter: this.valueOrEmpty(b.artMaterialBehaelter),
            bemerkung: this.valueOrEmpty(b.bemerkung),
          };
        }),
      );
    }
    return List.of();
  }

  private toList(files: FileDTO[] | undefined): List<FileDTO> {
    if (files != undefined) {
      return List<FileDTO>(files);
    }
    return List.of();
  }

  private mapDtosTokomponenten(
    kompenenteDraftDTOs: KompenenteDraftDTO[] | undefined,
  ): List<Komponente> {
    if (kompenenteDraftDTOs != undefined) {
      return List<Komponente>(
        kompenenteDraftDTOs.map((k) => {
          return {
            komponentOrder: this.toStringOrEmpty(k.komponentOrder),
            bezeichnung: this.valueOrEmpty(k.bezeichnung),
            qualitaet: k.qualitaet,
            art: k.art,
            einheitsMenge: this.toStringOrEmpty(k.einheitsMenge),
            einheit: k.einheit,
            bemerkung: this.valueOrEmpty(k.bemerkung),
          };
        }),
      );
    }
    return List.of();
  }

  private mapDtosToLagerungshinweise(
    lagerungshinweise: LagerungshinweiseEnum[] | undefined,
  ): List<LagerungshinweiseEnum> {
    if (lagerungshinweise != undefined) {
      return List(
        lagerungshinweise.map((l) => {
          return l;
        }),
      );
    }
    return List.of();
  }

  private mapDtoToFormelRisiko(
    formelDraftRisikoDTO: FormelRisikoDraftDTO,
  ): FormelRisiko {
    return {
      formelRisikoParameter: formelDraftRisikoDTO.formelRisikoParameter,
      risikoFaktor: formelDraftRisikoDTO.risikoFaktor,
    };
  }

  private mapFormelRisikoToDto(
    formelRisiko: FormelRisiko,
  ): FormelRisikoDraftDTO {
    return {
      formelRisikoParameter: formelRisiko.formelRisikoParameter,
      risikoFaktor: formelRisiko.risikoFaktor,
    };
  }

  private toNumberOrUndefined(value: string) {
    if (value != undefined && value == '') {
      return undefined;
    }
    return Number(value);
  }

  private undefinedIfEmpty(value: any) {
    if (value != undefined && value == '') {
      return undefined;
    }
    return value;
  }

  public valueOrEmpty(value: string | undefined) {
    if (value == undefined) {
      return '';
    }
    return value;
  }

  private valueOrFalse(value: boolean | undefined) {
    if (value == undefined) {
      return false;
    }
    return value;
  }

  public toStringOrEmpty(value: Number | undefined) {
    if (value == undefined) {
      return '';
    }
    return value.toString();
  }
}
