/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BerechtigungUpdateDTO { 
    berechtigungId?: string;
    rolle?: BerechtigungUpdateDTO.RolleEnum;
}
export namespace BerechtigungUpdateDTO {
    export type RolleEnum = 'ADMINISTRATOR' | 'EFO_BETRIEBSLEITER' | 'EFO_BETRIEBSLEITER_STV' | 'EFO_BETRIEBSMITARBEITER' | 'PAD_SACHBEARBEITER' | 'PAD_SEKRETARIAT';
    export const RolleEnum = {
        ADMINISTRATOR: 'ADMINISTRATOR' as RolleEnum,
        EFOBETRIEBSLEITER: 'EFO_BETRIEBSLEITER' as RolleEnum,
        EFOBETRIEBSLEITERSTV: 'EFO_BETRIEBSLEITER_STV' as RolleEnum,
        EFOBETRIEBSMITARBEITER: 'EFO_BETRIEBSMITARBEITER' as RolleEnum,
        PADSACHBEARBEITER: 'PAD_SACHBEARBEITER' as RolleEnum,
        PADSEKRETARIAT: 'PAD_SEKRETARIAT' as RolleEnum
    };
}