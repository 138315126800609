<div *ngIf="data !== undefined" class="bew-main-menu">
  <a
    *ngFor="let item of data.items"
    [appBewLinkAction]="item.action"
    class="bew-main-menu-item"
    [class.bew-main-menu-item-small]="data.small"
    [class.bew-main-menu-item-big]="!data.small"
    [class.bew-main-menu-selected]="item.selected"
    >{{ item.label }}</a
  >
  <a
    *ngIf="data.search !== undefined"
    [appBewLinkAction]="data.search.action"
    class="bew-main-menu-search"></a>
</div>
