import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  BewFormComponent,
  FormComponentWithDisabled,
  FormComponentWithLabel,
} from '../../../bew/components/form-component';
import {
  BewFile as BewFile,
  BewMultiUploadInterface,
  DownloadFileAtIndexFunction,
  NumberOfFilesTextProviderFunction,
  PreviewFileAtIndexFunction,
  UploadFunction,
} from '../../../bew/components/bew-multi-upload/bew-multi-upload.component';
import { TranslateService } from '@ngx-translate/core';
import { FileService, UploadedFile } from '../../services/file.service';
import {
  FileFilter,
  FilerResultAccepted,
  FilerResultRejectedFile,
  FilerResultUndesiredFile,
  FilterResult,
} from '../../data/file-filter';
import { NGXLogger } from 'ngx-logger';
import { List } from 'immutable';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileDTO } from '../../backend';

@Component({
  selector: 'app-multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrls: ['./multi-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiFileUploadComponent),
      multi: true,
    },
  ],
})
export class MultiFileUploadComponent
  extends BewFormComponent<MultiFileUploadData | undefined | null>
  implements FormComponentWithLabel, FormComponentWithDisabled
{
  @Input()
  label: string = 'NO_LABEL';
  @Input()
  disabled: boolean = false;
  /**
   * A text that's shown when there's at least one file.
   */
  @Input()
  withFilesText: string = 'TEXT_WITH_FILES';
  /**
   * A text that's show when there's no file.
   */
  @Input()
  noFilesText: string = 'TEXT_NO_FILES';

  @Output()
  readonly updateFiles: EventEmitter<List<FileDTO>> = new EventEmitter<
    List<FileDTO>
  >();

  @Input()
  set bewFiles(files: List<FileDTO>) {
    this.currentFiles = List(
      files.map((inputFile) => {
        // @ts-ignore
        const bewFile: BewFile = { name: inputFile.name, payload: inputFile };
        return bewFile;
      }),
    );
  }

  changeFiles(bewFiles: List<BewFile>) {
    // @ts-ignore
    const files: List<FileDTO> = bewFiles.map((bewFile) => {
      return { id: bewFile?.payload.id, name: bewFile?.payload.name };
    });
    this.updateFiles.emit(files);
  }

  rejectedFileText: string = '';
  showRejectedFile: boolean = false;
  undesiredFileText: string = '';
  showUndesiredFile: boolean = false;

  showUploadError: boolean = false;
  uploadErrorTechnicalDetail?: any = undefined;
  uploadFunction: UploadFunction;
  downloadFunction: DownloadFileAtIndexFunction;
  previewFunction: PreviewFileAtIndexFunction;
  numberOfFilesTextProviderFunction: NumberOfFilesTextProviderFunction;

  constructor(
    private readonly translate: TranslateService,
    private readonly fileService: FileService,
    private readonly logger: NGXLogger,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.uploadFunction = this.upload.bind(this);
    this.downloadFunction = this.download.bind(this);
    this.previewFunction = this.preview.bind(this);
    this.numberOfFilesTextProviderFunction =
      this.numberOfFilesTextProvider.bind(this);
  }

  currentFiles: List<BewFile> = List.of();

  private currentFileFilter?: FileFilter;

  /**
   * You can set a file filter (accept / reject files). This is usually used to filter files according to their
   * size or mime type.
   */
  @Input()
  set fileFilter(value: FileFilter) {
    this.currentFileFilter = value;
  }

  get files(): List<BewFile> {
    return this.currentFiles;
  }

  set files(files: List<BewFile>) {
    this.currentFiles = files;
    this.changeFiles(this.currentFiles);
    this.onValueChanged();
  }

  private async upload(
    _self: BewMultiUploadInterface,
    files: FileList,
  ): Promise<void> {
    try {
      await this.uploadUncaught(files);
    } catch (error) {
      this.showUploadError = true;
      this.uploadErrorTechnicalDetail = error;
    }
  }

  private async download(_self: BewMultiUploadInterface, index: number) {
    await this.fileService.download(
      // @ts-ignore
      this.files.get(index).payload.id,
      // @ts-ignore
      this.files.get(index).payload.name,
    );
  }

  private async preview(
    _self: BewMultiUploadInterface,
    index: number,
  ): Promise<Blob> {
    return await this.fileService.preview(
      // @ts-ignore
      this.files.get(index).payload.id,
      // @ts-ignore
      this.files.get(index).payload.name,
    );
  }

  private static isValidUploadedFile(uploadedFile: UploadedFile) {
    return (
      MultiFileUploadComponent.isString(uploadedFile.id) &&
      MultiFileUploadComponent.isString(uploadedFile.name)
    );
  }

  private static isString(value?: any): boolean {
    return typeof value === 'string' || value instanceof String;
  }

  protected readValueFromControl(): MultiFileUploadData | null | undefined {
    // @ts-ignore
    return this.files
      .map((file) => {
        if (file != undefined) {
          const uploadedFile = file.payload as UploadedFile;
          if (MultiFileUploadComponent.isValidUploadedFile(uploadedFile)) {
            return uploadedFile;
          } else {
            this.logger.warn(
              `Invalid payload in file: ${uploadedFile} (should be UploadedFile). If you see this, this is most likely a bug.`,
            );
            return null;
          }
        } else {
          this.logger.warn(`File is undefined`);
          return null;
        }
      })
      .filter((maybeUploadedFile) => {
        return maybeUploadedFile !== null;
      })
      .map((file) => {
        // this does nothing, it's just here to satisfy the type checker.
        if (file === null) {
          throw "Unreachable code: Got null instead of a file (this is a bug, since we've just filtered all null-files)";
        } else {
          return file;
        }
      })
      .toArray();
  }

  protected writeValueToControl(
    value: MultiFileUploadData | null | undefined,
  ): void {
    let valueToUse: MultiFileUploadData;
    let nullOrUndefinedStateConvertedToEmptyArray = false;
    if (value !== null && value !== undefined) {
      valueToUse = value;
    } else {
      nullOrUndefinedStateConvertedToEmptyArray = true;
      valueToUse = [];
    }
    // note: we use this._files directly (instead of this.files), since there's no need to emit another event.
    this.currentFiles = List(
      valueToUse.map((inputFile) => {
        const bewFile: BewFile = { name: inputFile.name, payload: inputFile };
        return bewFile;
      }),
    );

    if (nullOrUndefinedStateConvertedToEmptyArray) {
      // This control automatically converts null/undefined values to an empty array ([]). Angular is not happy about this, so we need to
      // tell the change detector about that change. This prevents an ugly error in the browser console.
      this.changeDetectorRef.detectChanges();
    }
  }

  private async uploadUncaught(files: FileList): Promise<void> {
    this.resetErrorState();
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      if (file !== null) {
        await this.uploadSingleFile(file);
      }
    }
  }

  private resetErrorState(): void {
    this.showUploadError = false;
    this.showRejectedFile = false;
    this.showUndesiredFile = false;
  }

  private applyFileFilter(file: File): FilterResult {
    const filter = this.currentFileFilter;
    if (filter === undefined) {
      return new FilerResultAccepted();
    } else {
      return filter.apply(file);
    }
  }

  private numberOfFilesTextProvider(count: number): string {
    switch (count) {
      case 0:
        return this.translate.instant(
          'multiFileUploadComponent.numberOfFilesTextZero',
        );
      case 1:
        return this.translate.instant(
          'multiFileUploadComponent.numberOfFilesTextOne',
        );
      default:
        return this.translate.instant(
          'multiFileUploadComponent.numberOfFilesTextMultiple',
          { count: count },
        );
    }
  }

  /**
   * Uploads one single file. Also displays warnings / errors if the file is not accepted or if it's not a
   * desired file.
   */
  private async uploadSingleFile(file: File): Promise<void> {
    const filterResult = this.applyFileFilter(file);
    let accepted: boolean = false;
    if (filterResult instanceof FilerResultAccepted) {
      accepted = true;
    } else if (filterResult instanceof FilerResultUndesiredFile) {
      accepted = true;
      this.undesiredFileText = filterResult.text;
      this.showUndesiredFile = true;
    } else if (filterResult instanceof FilerResultRejectedFile) {
      this.rejectedFileText = filterResult.text;
      this.showRejectedFile = true;
    } else {
      throw `Unhandled filter result: ${filterResult}`;
    }

    if (accepted) {
      const uploadedFile = await this.fileService.upload(file);
      const bewFile: BewFile = {
        name: uploadedFile.name,
        payload: uploadedFile,
      };
      this.files = this.files.push(bewFile);
    }
  }
}

export type MultiFileUploadData = Array<UploadedFile>;
