import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadcrumbData } from '../bew-breadcrumb/bew-breadcrumb.component';
import { MainMenuData } from '../bew-main-menu/bew-main-menu.component';
import {
  LanguageSelectedEvent,
  ServiceNavigationData,
} from '../bew-service-navigation/bew-service-navigation.component';
import { LinkAction } from '../link-action/link-action';

@Component({
  selector: 'app-bew-simple-header',
  templateUrl: './bew-simple-header.component.html',
  styleUrls: ['./bew-simple-header.component.scss'],
})
export class BewSimpleHeaderComponent {
  @Input()
  signetLabel?: string;
  @Input()
  breadcrumbData?: BreadcrumbData;
  @Input()
  mainMenuData?: MainMenuData;
  @Input()
  serviceNavigationData?: ServiceNavigationData;
  @Input()
  signetLinkAction?: LinkAction;
  @Output()
  readonly languageSelected: EventEmitter<LanguageSelectedEvent> =
    new EventEmitter<LanguageSelectedEvent>();

  constructor() {}

  onLanguageSelected($event: LanguageSelectedEvent) {
    this.languageSelected.emit($event);
  }
}
