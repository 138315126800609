import { Component, EventEmitter, Output } from '@angular/core';
import {
  AllgemeinDraftDTO,
  BerechtigungAntragApiService,
  BerechtigungAntragCreateDTO,
  BerechtigungAntragDTO,
  BetriebApiService,
  BetriebDTO,
} from '../../../../backend';
import { SearchConfiguration } from '../../../efo-table/efo-table.component';
import { ErrorModalComponent } from '../../../error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-betrieb-all-overview',
  templateUrl: './betrieb-all-overview.component.html',
  styleUrl: './betrieb-all-overview.component.scss',
})
export class BetriebAllOverviewComponent {
  @Output()
  public reload = new EventEmitter<void>();

  totalElements: number = 0;
  totalPages: number = 0;
  numberOfElements: number = 0;

  berechtigungAntraege?: Array<BerechtigungAntragDTO> = [];

  betriebe?: Array<BetriebDTO> = [];

  id: string | undefined = undefined;

  constructor(
    private readonly betriebApiService: BetriebApiService,
    private readonly berechtigungAntragApiService: BerechtigungAntragApiService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.findAllBerechtigungAntraege();
  }

  findAll(searchConfiguration: SearchConfiguration) {
    this.betriebApiService
      .findAll(
        searchConfiguration.searchTerm,
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.betriebe = page.content;
        if (page.totalElements) {
          this.totalElements = page.totalElements;
        } else {
          this.totalElements = 0;
        }
        if (page.totalPages) {
          this.totalPages = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElements = page.numberOfElements;
        }
      });
  }

  berechtigungBeantragen(
    berechtigungAntragCreateDTO: BerechtigungAntragCreateDTO,
  ) {
    this.berechtigungAntragApiService
      .create(berechtigungAntragCreateDTO)
      .subscribe({
        next: (data) => {
          const modalRef = this.modalService.open(ErrorModalComponent);
          modalRef.componentInstance.message = ['modal.info.antrag.success'];
          modalRef.componentInstance.type = 'INFO';
          this.reload.emit();
        },
      });
  }

  public findAllBerechtigungAntraege() {
    this.berechtigungAntragApiService
      .findAllByCurrentBenutzer(undefined, undefined, 1000, undefined)
      .subscribe((page) => {
        this.berechtigungAntraege = page.content;
      });
  }

  hasBerechtigungsAntragFor(betrieb: BetriebDTO) {
    if (this.berechtigungAntraege != undefined) {
      return this.berechtigungAntraege.some(
        (a) => a.betrieb?.id === betrieb.id,
      );
    }
    return false;
  }

  protected readonly AllgemeinDraftDTO = AllgemeinDraftDTO;
  protected readonly Object = Object;
}
