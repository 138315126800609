import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bew-footer-container',
  templateUrl: './bew-footer-container.component.html',
  styleUrls: ['./bew-footer-container.component.scss'],
})
export class BewFooterContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
