/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BenutzerDTO } from './benutzerDTO';
import { BetriebDTO } from './betriebDTO';
import { MailConfigurationDTO } from './mailConfigurationDTO';

export interface BerechtigungDTO { 
    id?: string;
    betrieb?: BetriebDTO;
    benutzer?: BenutzerDTO;
    rolle?: BerechtigungDTO.RolleEnum;
    mailConfiguration?: MailConfigurationDTO;
}
export namespace BerechtigungDTO {
    export type RolleEnum = 'ADMINISTRATOR' | 'EFO_BETRIEBSLEITER' | 'EFO_BETRIEBSLEITER_STV' | 'EFO_BETRIEBSMITARBEITER' | 'PAD_SACHBEARBEITER' | 'PAD_SEKRETARIAT';
    export const RolleEnum = {
        ADMINISTRATOR: 'ADMINISTRATOR' as RolleEnum,
        EFOBETRIEBSLEITER: 'EFO_BETRIEBSLEITER' as RolleEnum,
        EFOBETRIEBSLEITERSTV: 'EFO_BETRIEBSLEITER_STV' as RolleEnum,
        EFOBETRIEBSMITARBEITER: 'EFO_BETRIEBSMITARBEITER' as RolleEnum,
        PADSACHBEARBEITER: 'PAD_SACHBEARBEITER' as RolleEnum,
        PADSEKRETARIAT: 'PAD_SEKRETARIAT' as RolleEnum
    };
}