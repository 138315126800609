import { Injectable } from '@angular/core';
import { PersistedValue } from '../data/persisted-value';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BenutzerDTO } from '../backend/model/benutzerDTO';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private static readonly storageKeyForUser = 'user';
  private readonly userPersistedValue: PersistedValue<BenutzerDTO | null>;

  constructor() {
    this.userPersistedValue = new PersistedValue<BenutzerDTO | null>(
      UserService.storageKeyForUser,
      () => {
        return null;
      },
      sessionStorage,
    );
  }

  get currentUser(): Observable<BenutzerDTO | null> {
    return this.userPersistedValue.observable;
  }

  get isLoggedIn(): boolean {
    return this.userPersistedValue.value !== null;
  }

  get isLoggedInObservable(): Observable<boolean> {
    return this.currentUser.pipe(
      map((maybeUser) => {
        return maybeUser !== null && maybeUser !== undefined;
      }),
    );
  }

  setUserLoggedIn(benutzerDTO: BenutzerDTO) {
    this.userPersistedValue.value = benutzerDTO;
  }

  removeUserAndClearSessionStorage() {
    this.userPersistedValue.value = null;
    sessionStorage.clear();
  }
}
