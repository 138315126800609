import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbConfiguration } from './services/breadcrumb.service';
import { EnhancedRouteFunctions } from './data/enhanced-route';
import { FormelDetailComponent } from './components/formel/formel-detail/formel-detail.component';
import { FormelOverviewComponent } from './components/formel/real/formel-overview/formel-overview.component';
import { FormelHistoryOverviewComponent } from './components/formel/history/formel-history-overview/formel-history-overview.component';
import { HomeComponent } from './components/home/home.component';
import { BerechtigungOverviewComponent } from './components/berechtigung/berechtigung-overview/berechtigung-overview.component';
import { AuthGuard } from './guards/auth.guard';
import { InfoComponent } from './components/general/info/info.component';
import { RechtlichesComponent } from './components/general/rechtliches/rechtliches.component';
import { ImpressumComponent } from './components/general/impressum/impressum.component';

type PathMatch = 'full' | 'prefix' | undefined;
export const routes = {
  dsf: {
    route: {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full' as PathMatch,
    },
  },
  home: {
    route: {
      path: 'home',
      component: HomeComponent,
    },
    auxData: [new BreadcrumbConfiguration('home.title')],
  },
  main: {
    route: {
      path: 'erfassen',
      component: FormelDetailComponent,
      canActivate: [AuthGuard],
    },
    auxData: [new BreadcrumbConfiguration('formel.title')],
  },
  betriebe: {
    route: {
      path: 'betriebe',
      component: BerechtigungOverviewComponent,
      canActivate: [AuthGuard],
    },
    auxData: [new BreadcrumbConfiguration('berechtigung.overview.title')],
  },
  formeln: {
    route: {
      path: 'formeln',
      component: FormelOverviewComponent,
      canActivate: [AuthGuard],
    },
    auxData: [new BreadcrumbConfiguration('formel.overview.title')],
  },
  history: {
    route: {
      path: 'history',
      component: FormelHistoryOverviewComponent,
      canActivate: [AuthGuard],
    },
    auxData: [new BreadcrumbConfiguration('history.overview.title')],
  },
  informationen: {
    route: {
      path: 'informationen',
      component: InfoComponent,
      canActivate: [AuthGuard],
    },
    auxData: [new BreadcrumbConfiguration('information.breadcrumb')],
  },
  rechtliches: {
    route: {
      path: 'rechtliches',
      component: RechtlichesComponent,
      canActivate: [AuthGuard],
    },
    auxData: [new BreadcrumbConfiguration('rechtliches.breadcrumb')],
  },
  impressum: {
    route: {
      path: 'impressum',
      component: ImpressumComponent,
      canActivate: [AuthGuard],
    },
    auxData: [new BreadcrumbConfiguration('impressum.breadcrumb')],
  },
};

@NgModule({
  imports: [RouterModule.forRoot(EnhancedRouteFunctions.build(routes))],
  exports: [RouterModule],
})
export class AppRoutingModule {}
