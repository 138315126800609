import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { LinkAction, OnClickFunction } from './link-action';
import { NavigationExtras, Router } from '@angular/router';

@Directive({
  selector: '[appBewLinkAction]',
  exportAs: 'appBewLinkAction',
})
export class BewLinkActionDirective {
  private _linkAction: LinkAction = {};

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly router: Router,
  ) {}

  @Input()
  set appBewLinkAction(appBewLinkAction: LinkAction | undefined) {
    if (appBewLinkAction === undefined) {
      this._linkAction = {};
    } else {
      this._linkAction = appBewLinkAction;
    }
    this.applyHrefLinkAction();
    this.applyOnClickActionFunction();
    this.applyOnClickActionForRouter();
  }

  private static handleClickEvent(
    self: any | undefined,
    fun: OnClickFunction,
    data?: any,
  ) {
    let boundFunction;
    if (self !== undefined) {
      boundFunction = fun.bind(self);
    } else {
      boundFunction = fun;
    }
    const result = boundFunction(data);
    BewLinkActionDirective.catchMaybePromise(result as any);
  }

  private static catchMaybePromise(maybePromise: any) {
    if (typeof maybePromise?.then === 'function') {
      const resultPromise = maybePromise as Promise<void>;
      resultPromise.then(
        (_ok) => {},
        (error) => {
          console.error(
            'Got unhandled error in promise (link action click event).',
            error,
          );
        },
      );
    }
  }

  private applyOnClickActionForRouter(): void {
    const routerCommands = this._linkAction.routerLinkCommands;
    const navigationExtras = this._linkAction.routerNavigationExtras;
    if (routerCommands !== undefined && routerCommands !== null) {
      this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
        event.preventDefault();
        this.executeRouterNavigation(routerCommands, navigationExtras);
      });
      this.renderer.setAttribute(this.elementRef.nativeElement, 'href', '#');
    }
  }

  private executeRouterNavigation(
    routerCommands: any[] | string,
    navigationExtras?: NavigationExtras,
  ) {
    let navigationResultPromise;
    if (typeof routerCommands === 'string') {
      navigationResultPromise = this.router.navigate(
        [routerCommands],
        navigationExtras,
      );
    } else {
      navigationResultPromise = this.router.navigate(
        routerCommands,
        navigationExtras,
      );
    }
    navigationResultPromise.then(
      (navigated) => {
        if (!navigated) {
          console.warn(
            'Navigation failed (link action click event).',
            routerCommands,
          );
        }
      },
      (error) => {
        console.error('Got error in promise (link action click event).', error);
      },
    );
  }

  private applyOnClickActionFunction(): void {
    const onClickFunction = this._linkAction.onClick;
    const onClickFunctionThis = this._linkAction.onClickThis;
    const onClickData = this._linkAction.onClickData;
    if (onClickFunction !== undefined) {
      this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
        event.preventDefault();
        BewLinkActionDirective.handleClickEvent(
          onClickFunctionThis,
          onClickFunction,
          onClickData,
        );
      });
      this.renderer.setAttribute(this.elementRef.nativeElement, 'href', '#');
    }
  }

  private applyHrefLinkAction(): void {
    const href = this._linkAction.href;
    if (href !== undefined) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'href', href);
      const openInNewWindow = true === this._linkAction.hrefOpenInNewWindow;
      if (openInNewWindow) {
        this.renderer.setAttribute(
          this.elementRef.nativeElement,
          'target',
          '_blank',
        );
      } else {
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'target');
      }
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'href');
    }
  }
}
