<div
  class="bew-signet"
  [class.bew-signet-invokable]="isInvokable"
  [appBewLinkAction]="linkAction">
  <div
    [class.bew-signet-logo]="!small"
    [class.bew-signet-logo-small]="small"
    class="bew-signet-logo-image"></div>
  <div
    [class.bew-signet-logo-caption]="!small"
    [class.bew-signet-logo-caption-small]="small">
    {{ label }}
  </div>
</div>
