{
  "table": {
    "search": "Recherche"
  },
  "navigation": {
    "login": "Connexion",
    "logout": "Déconnexion ({{firstName}} {{lastName}})",
    "registration": "Inscription",
    "contact": "Contact",
    "menu": {
      "berechtigungen": "Permissions",
      "informationen": "Informations",
      "formeln": "Formules",
      "entwuerfe": "Brouillons"
    }
  },
  "global": {
    "hint": "Indication",
    "warning": "Avertissement",
    "abort": "Annuler"
  },
  "home": {
    "title": "Accueil",
    "login": {
      "title": "Connexion",
      "description": "Connexion Interne"
    },
    "belogin": {
      "title": "Connexion BE-Login",
      "description": "BE-Login"
    }
  },
  "berechtigung": {
    "overview": {
      "title": "Permissions",
      "rolle": "Rôle"
    },
    "current": {
      "title": "Mes Permissions",
      "none": "Aucune permission. Vous pouvez faire une demande ci-dessous."
    }
  },
  "betrieb": {
    "admin": {
      "title": "Sélectionner l'entreprise",
      "none": "Aucune entreprise disponible"
    },
    "overview": {
      "title": "Entreprises"
    },
    "stammdaten": {
      "title": "Données de base",
      "name": "Nom",
      "name2": "Nom2",
      "beproid": "Bepro Id",
      "bur": "BUR",
      "uid": "UID",
      "gln": "GLN",
      "betriebsart": "Type d'entreprise",
      "telefon": "Téléphone",
      "mail": "Courriel",
      "postfach": "Boîte postale",
      "strasse": "Rue",
      "plz": "Code postal",
      "ort": "Lieu",
      "korrespondenzsprache": "Langue de correspondance"
    },
    "rechnungsaddresse": {
      "title": "Adresse de facturation",
      "name": "Nom",
      "name2": "Nom2",
      "zusatz": "Complément 1",
      "zusatz2": "Complément 2",
      "postfach": "Boîte postale",
      "strasse": "Rue",
      "plz": "Code postal",
      "ort": "Lieu",
      "country": "Pays"
    },
    "korrespondenz": {
      "title": "Courriel de correspondance"
    },
    "benutzerverwaltung": {
      "title": "Gestion des utilisateurs",
      "none": "Aucun utilisateur dans l'entreprise",
      "username": "Nom d'utilisateur",
      "firstname": "Prénom",
      "lastname": "Nom de famille",
      "mail": "Courriel",
      "rolle": "Rôle"
    },
    "mailconfiguration": {
      "title": "Configuration du courrier",
      "pruefung": "Soumission de courrier",
      "accept": "Confirmation de réception du courrier",
      "reject": "Courrier rejeté",
      "korrespondenzmail": "Courriel de correspondance",
      "submitter": "Utilisateur soumettant"
    },
    "antraege": {
      "title": "Demandes de collaborateurs",
      "none": "Aucune demande pour l'entreprise",
      "remark": "Remarque"
    }
  },
  "pad": {
    "detail": {
      "title": "Service Pharmaceutique",
      "benutzerverwaltung": {
        "title": "Gestion des utilisateurs",
        "none": "Aucun utilisateur dans le service pharmaceutique",
        "username": "Nom d'utilisateur",
        "firstname": "Prénom",
        "lastname": "Nom de famille",
        "mail": "Courriel",
        "betrieb": "Entreprise",
        "ort": "Lieu",
        "strasse": "Rue",
        "rolle": "Rôle",
        "betriebsleiter": {
          "delete": {
            "title": "Supprimer le chef d'établissement"
          }
        }
      },
      "antraege": {
        "pad": {
          "title": "Demandes de service pharmaceutique",
          "none": "Aucune demande pour le service pharmaceutique",
          "remark": "Remarque"
        },
        "betriebsleiter": {
          "title": "Demandes de gestionnaire d'entreprise",
          "none": "Aucune demande pour le gestionnaire d'entreprise",
          "remark": "Remarque",
          "change": {
            "title": "Demandes de changement de gestionnaire d'entreprise",
            "none": "Aucune demande pour le changement de gestionnaire d'entreprise",
            "remark": "Remarque",
            "old": {
              "username": "Ancien gestionnaire",
              "rolle": "Rôle"
            },
            "new": {
              "username": "Nouveau gestionnaire",
              "rolle": "Rôle"
            },
            "betrieb": "Entreprise"
          }
        }
      }
    }
  },
  "antrag": {
    "overview": {
      "title": "Demandes de permission"
    },
    "current": {
      "title": "Mes demandes de permission",
      "none": "Aucune demande. Vous pouvez faire une demande ci-dessous."
    },
    "betrieb": {
      "title": "Demander une permission pour l'entreprise"
    },
    "pad": {
      "title": "Demander une permission pour le service pharmaceutique",
      "rolle": "Rôle pour le service pharmaceutique"
    }
  },
  "formel": {
    "overview": {
      "title": "Formules",
      "draft": "Brouillons",
      "preaparat": {
        "name": "Nom du produit",
        "art": "Type de produit"
      },
      "update": {
        "username": "Modifié par",
        "date": "Modifié le"
      },
      "state": "Statut",
      "pruefer": "SPHC",
      "edit": "Modifier",
      "create": {
        "username": "Créé par",
        "date": "Créé le"
      },
      "betrieb": {
        "name": "Entreprise"
      },
      "none": "Aucune formule disponible",
      "pad": {
        "all": {
          "title": "Toutes les formules (PAD)"
        },
        "pruefung": {
          "title": "Formules en cours de traitement (PAD)",
          "self": {
            "title": "Mes formules en cours de traitement (PAD)"
          }
        }
      }
    },
    "title": "Créer une formule",
    "detail": {
      "title": {
        "create": "Créer une formule",
        "edit": "Traiter la formule",
        "view": "Traiter de la formule SPHC",
        "pruefung": "Vérifier la formule"
      },
      "betrieb": {
        "notes": {
          "title": "Remarque pour le SPHC"
        }
      },
      "allgemein": {
        "title": "Informations générales",
        "preaparat": {
          "name": {
            "title": "Nom du préparation",
            "tooltip": "Le nom (et l'indication) de la préparation est lié au droit de remise de l'établissement, respectivement de la personne. C'est pourquoi le nom d'un médicament ne peut être associé à une indication se situant hors du droit de remise. Il est donc interdit de choisir une désignation suggérant une indication qui nécessite un diagnostic médical préalable et une prescription. <ul><li> Exemple d'indication non autorisée : gouttes pour diabétiques</li><li>Indication autorisée : pour soutenir/compléter le traitement du diabète</li></ul>Le nom du produit ne doit ni être tapageur ni utiliser des termes non médicaux. Exemples de noms à éviter : gouttes du jodleur, spray pour la gueule de bois, thé detox<p>Références : art. 9, al. 2, let. c LPTh <br>Pharmacopée Helvétique, chapitre 17.2.4<br>Recommandations 0010 de l'association suisse des pharmaciens cantonaux concernant la fabrication de médicaments selon formule propre (Anerkannte Fachliteratur zur Herstellung von Arzneimittel nach Eigener Formel, en allemand uniquement)"
          },
          "art": {
            "title": "Type de préparation",
            "tooltip": "Le type de préparation doit figurer sur l'étiquette – médicaments allopathiques hormis.<br>Il convient de suivre les définitions de l'article 4 de l'ordonnance sur les médicaments complémentaires et les phytomédicaments (OAMédcophy, RS 812.212.24)<br>La désignation et l'étiquetage doivent correspondre aux exigences de la dernière édition de la Pharmacopée Helvétique (chapitre 17.1.5) et, dans le domaine de la médecine complémentaire, aux dispositions de l'OAMédcophy. <br> En principe il n’est pas permis de combiner différentes formes de thérapie. Si l'on s'en écarte, il faut justifier et documenter la manière dont les principes des orientations thérapeutiques correspondantes sont respectés."
          }
        },
        "indikation": {
          "title": "Indication",
          "tooltip": "L'indication de la préparation est liée au droit de remise de l'établissement, respectivement de la personne. C'est pourquoi le nom d'un médicament ne peut être associé à une indication se situant hors du droit de remise.  l'indication donnée ne doit donc dépasser le droit de remise. Il est interdit de choisir une désignation suggérant une indication qui nécessite un diagnostic médical préalable et une prescription (voir texte sous « Nom de la préparation »)<ul><li> Exemple d'indication non autorisée : en cas de ou contre le diabète </li><li> Indication autorisée : pour soutenir/compléter le traitement du diabète </li></ul>L'indication doit tenir compte de la littérature spécialisée et des indications concernant les médicaments au profil comparable qui ont été autorisés par Swissmedic (voir recommandations 0010, chapitre 3.1.2)."
        },
        "dosierungsvorschriften": {
          "title": "Recommandations d'utilisation",
          "tooltip": "Les recommandations sur l'administration et la posologie doivent tenir compte des directives de Swissmedic et de la Ph. Helv. actuelle."
        },
        "darreichungsform": {
          "title": "Forme galénique",
          "tooltip": "L'étiquetage doit également mentionner la forme pharmaceutique. Il est possible de renoncer à indiquer cette dernière si la désignation du médicament est en elle-même suffisamment parlante (p. ex. sirop contre la toux). Pour d'autres informations concernant la forme pharmaceutique, se référer aux monographies générales de la Pharmacopée Helvétique et de la Pharmacopée européenne."
        },
        "lohnherstellung": {
          "title": "Produit est fabriqué",
          "tooltip": "Si le produit est fabriqué par un établissement bénéficiant d'une autorisation de fabrication (pharmacie, droguerie, société bénéficiant d'une autorisation de fabrication délivrée par Swissmedic), la réponse doit ici être positive. <br>Merci de prendre note que la fabrication doit être réglée par un contrat. Vous trouverez des informations et des documents à ce sujets dans les recommandations H 006.002 de l'association des pharmaciens cantonaux sur la fabrication des médicaments non soumis à autorisation."
        },
        "lohnhersteller": {
          "betriebsname": "Nom de l'entreprise",
          "betriebsart": "Type d'entreprise",
          "strasse": "Rue",
          "plz": "Code postal",
          "ort": "Lieu"
        },
        "file": {
          "info": "Vous pouvez télécharger ici des documents tels que le rapport de développement. Veuillez ne pas télécharger de documents confidentiels."
        },
        "notes": "Notes"
      },
      "zusammensetzung": {
        "title": "Composition",
        "chargengroesse": {
          "title": "Taille de lot",
          "tooltip": "<p>AperçuLa taille du lot est la quantité totale d'une fabrication produite pendant un processus de fabrication uniforme. (Exemple : Au cours d'un processus de fabrication, 500 ml de salicylvaseline sont fabriqués et conditionnés dans 5 tubes de 100 ml chacun. Les 5 tubes portent tous le même numéro de lot, la taille du lot correspond à 500ml). <br>Référence : Ph. Helv. 20.1.B Définitions</p>"
        },
        "masseinheit": "Unité de mesure",
        "sortierreihenfolge": {
          "title": "Position",
          "tooltip": "FR-<p>Es ist sinnvoll zuerst die Wirkstoffe zu erfassen und anschliessend die Hilfsstoffe.</p><p>Die Zahl muss eine ganze Zahl darstellen, die nur einmal pro Formel vorkommt. Wenn ein Wert gewählt wird, der bereits bei einer Komponente gesetzt wurde, werden bei allen Komponenten, die Werte >= des neuen Wertes haben, eine Nummer raufgezählt.</p>"
        },
        "bezeichnung": {
          "title": "Désignation",
          "tooltip": "<p>Désignation des substances actives et des excipients<p>Les différents composants doivent être désignés par leur nom officiel soit en français, soit en latin conformément à la Pharmacopée Helvétique (Ph. Helv.) et à la Pharmacopée Européenne (Ph. Eur.) Si les substances utilisées ne sont pas pures mais s'il s'agit de mélanges (base de pommade, solutions de conservation, mélanges d'excipients), il est obligatoire d'en indiquer les composants individuels. Ces derniers doivent strictement respecter la qualité prévue par la Pharmacopée ; les excipients soumis à déclaration doivent en outre figurer sur l'étiquette. <br>La composition de ces mélanges peut être soumise /scannée séparément avec l'indication quantitative et qualitative des substances individuelles  (où ? Ajouter sous fichier(s)).</p><p>De plus, des informations additionelles peuvent également être fournies ici, par exemple concernant la composition des capsules utilisées.</p>"
        },
        "qualitaet": {
          "title": "Qualité",
          "tooltip": "Si un composant n'est pas monographié dans une pharmacopée reconnue, il convient de confirmer que les exigences relatives à une matière première pharmaceutique sont remplies et qu'un certificat d'analyse est disponible. Veuillez l'indiquer pour le composant concerné sous Remarques. (Attention : seuls des principes actifs conformes à l'art. 37 OAM peuvent être utilisés.)"
        },
        "art": {
          "title": "Type",
          "tooltip": "<strong>Excipients soumis à déclaration - « Excipients pharmaceutiques revêtant un intérêt particulier »</strong><p> Les excipients soumis à déclaration sont désignés en tant qu'excipients pharmaceutiques revêtant un intérêt particulier. L'article 14b de l'ordonnance sur les exigences relatives aux médicaments OEMéd prévoit l'utilisation de mises en garde qui sont mentionnées à l'annexe 3A de l'OEMéd.<br>Comme les emballages de la plupart des médicaments fabriqués selon une formule propre ne contiennent pas de notice, il est important que les excipients pharmaceutiques revêtant un intérêt particulier soient déclarés qualitativement sur l'emballage et que la clientèle soit rendue attentive, lors de l'achat, aux risques entraînés par la consommation de ces substances. Pour l'éthanol, l'obligation de déclaration actuelle reste valable.</p>"
        },
        "einheitsmenge": {
          "title": "Quantité de la composante",
          "tooltip": "Indiquer la quantité de la composante en question. Veuillez noter que la somme de toutes les quantités unitaires doit correspondre à la taille du lot."
        },
        "einheit": "Unité",
        "bemerkung": "Remarque",
        "notes": "Notes",
        "komponente": {
          "new": {
            "title": "Ajouter un composant",
            "submit": "Ajouter"
          },
          "edit": {
            "title": "Modifier un composant",
            "submit": "Modifier",
            "cancel": "Annuler"
          }
        },
        "komponenten": {
          "title": "Composants",
          "none": "Aucun composant disponible",
          "info": "Veuillez noter que la somme de toutes les quantités unitaires doit correspondre à la taille du lot.",
          "wirkstoff_andere_info": "Pour la fabrication de médicaments pour lesquels, conformément à l'article 9, paragraphes 2, lettres a–c<sup>bis</sup>, 2<sup>bis</sup> et 2<sup>ter</sup> HMG, aucune autorisation n'est requise, seuls les principes actifs conformément à l'article 37 de l'Ordonnance sur les médicaments (OMéd) peuvent être utilisés. Veuillez télécharger sous Documents les monographies correspondantes en format PDF pour les composants issus de Ph. F. (section «Préparations homéopathiques») et B.Hom.P, ou confirmer pour tous les autres composants conformément à l'article 37 OMéd dans la remarque que les exigences pour une substance de départ pharmaceutique sont remplies et qu'un certificat d'analyse est disponible.",
          "hilfsstoff_andere_info": "Si un composant n'est pas monographié dans une pharmacopée reconnue, il convient de confirmer que les exigences relatives à une matière première pharmaceutique sont remplies et qu'un certificat d'analyse est disponible. Veuillez le mentionner pour le composant concerné sous Remarques. Vérifiez toujours l'admissibilité des composants selon l'art. 37 de l'OMéd."
        },
        "file": {
          "info": "Vous pouvez télécharger ici vos certificats d'analyse ou des documents relatifs aux composants. Veuillez ne pas télécharger de documents confidentiels."
        }
      },
      "lagerung": {
        "title": "Stockage",
        "lagerungshinweise": {
          "title": "Instructions de stockage",
          "tooltip": "En principe les instructions de stockage suivantes sont possibles (selon Swissmedic): <ul><li>Ne pas conserver au-dessus de 25°C.</li><li>Ne pas conserver à plus de 30°C.</li><li>Conserver à température ambiante (15-25°C).</li><li>Conserver entre 15-30°C.</li><li>Garder le récipient fermé.</li><li>Tenir hors de portée des enfants.</li><li>Conserver au réfrigérateur (2-8°C).</li><li>Conserver au congélateur (en dessous de -15°C).</li><li>Ne pas conserver au réfrigérateur.</li><li>Ne pas congeler.</li><li>Conserver dans l’emballage d’origine.</li> <li>Garder le récipient bien fermé.</li><li>Conserver le récipient dans son carton pour le protéger de la lumière (et/ou de l’humidité).</li><li>Mise en garde à propos des enfants (p. ex. « Conserver hors de portée des enfants »)</li></ul>"
        },
        "bemerkung": "Remarque",
        "haltbarkeitsdauer": {
          "title": "Durée de stabilité (format p. ex. 6 mois)",
          "info": "Durée de conservation (format p. ex. 6 mois) et, le cas échéant, délai d'utilisation (au moins pour les formes médicamenteuses semi-solides et liquides dans des récipients multidoses).",
          "tooltip": " <p><strong>Durée de stabilité / Date de péremption </strong><br>Durée de stabilité, dans les conditions de stockage données, définie par des données de stabilité ou la littérature. Cela s'applique également aux durées de conservation inférieures à un an. Si la durée de conservation est supérieure à un an ou à la durée recommandée dans la littérature, des données de stabilité sont requises (voir OMédV, art. 36).  La date de péremption est indiquée sur l'étiquette/l'étiquetage par une date précise au format JJ.MM.AAAA, au-delà de laquelle le médicament ne doit plus être utilisé.</p><p><strong>Délai d'utilisation</strong><br>Un délai de consommation au format JJ.MM.AAAA doit être indiqué pour les médicaments ne devant plus être utilisés au-delà d'un certain temps après ouverture.</p>"
        },
        "file": {
          "info": "Vous pouvez télécharger ici des documents relatifs au stockage. Veuillez ne pas télécharger de documents confidentiels."
        },
        "notes": "Notes"
      },
      "verpackung": {
        "title": "Emballage",
        "behaeltergroesse": {
          "title": "Tailles de conditionnement",
          "tooltip": "Ici on peut entrer les différentes tailles de conditionnement (emballage pour la vente).",
          "packungsinhalt": {
            "title": "Contenu de l'emballage",
            "tooltip": "Indication du nombre de comprimés, capsules, pilules ou de la quantité de pommade, d'un sirop contenus dans l'emballage (les unités correspondantes doivent être saisies dans les champs ci-après). Par exemple : <ul><li>30 (comprimés)</li><li>50 (millilitres)</li></ul>"
          },
          "einheit": "Unité",
          "bemerkung": "Remarque",
          "art": {
            "title": "Type et matériau du récipient",
            "tooltip": "<p>Les exigences relatives aux récipients à usage pharmaceutique sont définies au chapitre 3.2 de la Ph.Eur. Les chapitres suivants doivent être pris en compte: 3.2.1 \"Récipients en verre\" et 3.2.2 \"Récipients et bouchons en plastique à usage pharmaceutique\".</p><p>Concernant la description du récipient (et du bouchon et, le cas échéant, du doseur), il suffit d'indiquer le type, la forme et le matériau : exemples<ul><li>par ex. flacon en verre avec compte-gouttes (polyéthylène) et bouchon à vis (polypropylène)</li><li>flacon en polyéthylène avec bouchon à vis (polyéthylène) et gobelet doseur (polypropylène)</li></ul></p>"
          },
          "new": {
            "title": "Ajouter une taille de récipient",
            "submit": "Ajouter"
          },
          "edit": {
            "title": "Modifier la taille du récipient",
            "submit": "Modifier",
            "cancel": "Annuler"
          }
        },
        "behaltergroessen": {
          "title": "Tailles des récipients",
          "none": "Aucune taille de récipient disponible"
        },
        "etiketten": {
          "title": "Étiquettes",
          "tooltip": "Vous pouvez télécharger vos étiquettes dans les formats de fichier suivants : '.doc', '.docx', '.gif', '.jpg', '.jpeg', '.pdf', '.png', '.pptx', '.xls', '.xlsx'. Seules les images et les PDF sont disponibles pour l'aperçu.",
          "upload": {
            "title": "Télécharger une étiquette",
            "noEtikettenYet": "Aucune étiquette téléchargée pour le moment.",
            "withnoEtikettenText": "Vous pouvez télécharger d'autres étiquettes si nécessaire."
          }
        },
        "notes": "Notes",
        "file": {
          "info": "Vous pouvez télécharger ici des documents relatifs aux récipients. Veuillez ne pas télécharger de documents confidentiels."
        }
      },
      "risikorechner": {
        "title": "Calcul du risque",
        "info": {
          "title": "Évaluation du risque selon l'ordonnance sur les autorisations dans le domaine des médicaments (OAMéd).",
          "text": "L'évaluation du risque doit toujours être effectuée un médicament spécifique. Si elle donne une valeur inférieure à 100, une autorisation cantonale de fabrication est nécessaire en lieu et place d’une autorisation de Swissmedic."
        },
        "text_unter_100": "L'examen des données renvoie le résultat suivant : <strong>{{resultat}}</strong>. L'autorisation de fabrication de l'Office du pharmacien cantonal est suffisante.",
        "text_ueber_100": "L'examen des données renvoie le résultat suivant : <strong>{{resultat}}</strong>. Si la multiplication des facteurs donne une valeur supérieure à 100, une autorisation de l'institut est nécessaire au lieu d'une autorisation de fabrication cantonale.",
        "form": {
          "anwendungart": "Type d'utilisation",
          "mengenverhaeltnis": "Rapport quantitatif",
          "wirkstoffrisiko": "Risque du principe actif",
          "herstellungsprozess": "Processus de fabrication",
          "arzneiform": "Forme posologique",
          "produktionsmenge": {
            "label": "Production annuelle ___",
            "augentropfen": "Production annuelle en litres",
            "fest": "Production annuelle en nombre de pièces",
            "fluessig": "Production annuelle en litres",
            "halbfest": "Production annuelle en grammes",
            "halbfest_zaepfchen": "Production annuelle en nombre de pièces"
          }
        },
        "button": "Calcul du risque"
      },
      "einreichen": {
        "title": "Soumettre la formule pour notification"
      },
      "abschliessen": {
        "title": "Terminer"
      }
    },
    "draft": {
      "overview": {
        "title": "Brouillons",
        "create": "Créer une nouvelle formule"
      }
    },
    "copy": {
      "status_zur_nachbearbeitung_zurueckgewiesen": "Veuillez retravailler directement la formule rejetée",
      "status_meldebestaetigung_neue_version_vorhanden": "Il s'agit d'une nouvelle soumission d'une formule déjà confirmée. Par conséquent, la formule ne peut être copiée à nouveau qu'après la fin de l'examen de la nouvelle formule.",
      "success": "La formule a été copiée"
    },
    "parentLabel": "Copie de",
    "archive": {
      "label": "Renoncer",
      "warning": "Voulez-vous vraiment renoncer à la formule ? La formule ne pourra plus être modifiée et la production selon la formule ne sera plus autorisée.",
      "status_hint": "Une formule ne peut être archivée que si elle a reçu une confirmation de notification. Vous pouvez supprimer la formule si elle n'est plus nécessaire."
    },
    "history": {
      "overview": {
        "title": "Historique",
        "create": {
          "username": "Créé par",
          "date": "Créé le"
        },
        "state": "Statut",
        "back": "Retour",
        "none": "Aucune entrée historique disponible."
      }
    }
  },
  "rechtliches": {
    "breadcrumb": "Mentions légales",
    "title": "Conditions d'utilisation d'EFo",
    "stand": "(état au 04.06.2019) ",
    "paragraph": {
      "title.1": "1. Objet",
      "text.1": "EFo est une plateforme en ligne sur laquelle il est possible d'annoncer de nouvelles formules.",
      "title.2": "2. Droit d'utilisation",
      "text.2": "Sont autorisés à utiliser EFo les bénéficiaires de prestation, leurs représentants ou les fournisseurs de prestation",
      "title.3": "3. Frais",
      "text.3": "L'utilisation d'EFo est gratuite.",
      "title.4": "4. Mise à disposition, exploitation et gestion",
      "text.4.1": "La plateforme est mise à disposition, exploitée et gérée par l'Office du pharmacien cantonal du canton de Berne (OPHC)",
      "text.4.2": "Il revient à l'OPHC d'assurer l'exploitation d'EFo (sécurité, réseau, performances, sécurité des données, etc.).",
      "text.4.3": "L'OPHC veille à remédier aussi vite que possible aux éventuelles perturbations et pannes d'EFo. Ces dernières ne doivent pas se faire au détriment des bénéficiaires de prestations.",
      "title.5": "5. Traitement et protection des données, confidentialité",
      "text.5.1": "Les fournisseurs de prestation sont considérés comme autorités au sens de la loi sur la protection des données (art. 2, al. 6, lit. b LCPD) et sont tenus d’en respecter les dispositions (art. 2, al. 6 LCPD).",
      "text.5.2": "Les utilisateurs et utilisatrices d'EFo acceptent que l’OPHC ait accès en tout temps aux données d'EFo à des fins d’analyse et de rapport.",
      "text.5.3": "Les utilisateurs et utilisatrices d'EFo traitent de façon confidentielle les faits et les informations qui ne sont ni notoires, ni accessibles de façon générale à tout un chacun et pour lesquels il existe un intérêt de maintenir le secret selon les règles de la bonne foi en raison de leur nature. En cas de doute, les faits et informations sont à traiter de manière confidentielle. L’obligation de garder le secret subsiste même une fois que l’application n’est plus utilisée.",
      "text.5.4": "Les bénéficiaires de prestations, leur représentants et les fournisseurs de prestation protègent efficacement les données traitées contre des tiers non autorisés. Les informations de connexion à la plateforme ne doivent pas être divulguées et sont à protéger contre tout abus.",
      "text.5.5": "Tout service qui transfère les droits d’utilisation et d’administration de la plateforme s’assure que la législation sur la protection des données est respectée aussi pour les données traitées sur mandat (art. 8 et 16 LCPD).",
      "title.6": "6. Responsabilité",
      "text.6": "Le canton de Berne ne répond pas des dommages dus à l’utilisation d'EFo. ",
      "title.7": "7. Adaptation des conditions d’utilisation",
      "text.7": "L'OHPC se réserve le droit de modifier les conditions d'utilisation. Le cas échéant, les nouvelles conditions seront envoyées par courriel aux bénéficiaires de prestation, à leurs représentants et aux fournisseurs de prestation. Sauf avis contraire donné par écrit dans les dix jours, elles sont réputées acceptées.",
      "title.8": "8. For juridique et droit applicable",
      "text.8": "Tout litige lié à l’utilisation de la plateforme opposant les utilisateurs à l’OPHC relève du tribunal du siège de l’administration cantonale à Berne.",
      "title.9": "9. Renseignements",
      "text.9": "En cas de question spécifiques ou techniques sur Efo :"
    },
    "helpline": "Permanence téléphonique",
    "telefon": "Numéro de téléphone:",
    "telefon.nr": "+410313003370",
    "telefon.nr.text": "031 300 33 70",
    "email": "Courriel:",
    "email.url": "info@bernermodell.ch",
    "info": "Renseignements complémentaires:",
    "info.url": "https://www.be.ch/portal/de/tools/rechtliches.html"
  },
  "impressum": {
    "breadcrumb": "Impressum",
    "title.1": "Responsabilité générale",
    "gef": "Direction de la santé, des affaires sociales et de l'intégration du canton de Berne",
    "amt": "Office cantonal des pharmaciens",
    "gef.strasse": "Rathausgasse 1",
    "gef.postfach": "Postfach",
    "gef.plz": "3000 Berne 8",
    "title.2": "FR-Technische Umsetzung",
    "bedag": "Bedag Informatik AG",
    "bedag.strasse": "Engehaldestrasse 12",
    "bedag.plz": "3012 Berne"
  },
  "information": {
    "breadcrumb": "Informations",
    "title": "Formule propre",
    "block1": "Les officines publiques, les pharmacies d’hôpitaux et les drogueries peuvent, sur la base de la loi sur les produits thérapeutiques, fabriquer des médicaments non soumis à prescription médicale, d’après une «formule propre», et qui sont destinés à être remis aux clients de l’établissement.",
    "voraussetzung_title": "Les exigences suivantes doivent être remplies par les établissements du canton de Berne qui fabriquent et remettent des médicaments d’après une «formule propre»  :",
    "voraussetzung_1": "Autorisation d’exploitation et de fabrication pour la confection de médicaments en petites quantités d’après une «formule propre»",
    "voraussetzung_2": "Les médicaments fabriqués d’après une «formule propre» doivent être annoncés à l'OPHC avec l'indication de leur désignation, de leur composition et de leur étiquetage  (Ordonnance sur la santé publique, Art. 58, 59, 61).",
    "produkte_title": "Les produits suivants ne doivent pas être annoncés :",
    "produkt_1": "Les produits chimiques (s'ils sont étiquetés comme tels et remis sans aucune revendication de vertu curative) ",
    "produkt_2": "Les produits cosmétiques",
    "produkt_3": "Les denrées alimentaires, les compléments alimentaires etc.",
    "produkt_4": "Les dispositifs médicaux",
    "produkt_5": "Les médicaments d’après une «formule magistrale», qui sont p. ex. aussi fabriqués par lots",
    "produkt_footer": "En cas de doute vous pouvez vous adresser à nous",
    "block2": "Le droit de fabrication et de remise aux propres clients d’un médicament d’après une «formule propre»  s'applique uniquement à  l’établissement titulaire (formule propre à l’établissement) !",
    "beschriftung_title": "Etiketage/désignation",
    "beschriftung_text_1": "La désignation du médicament ne doit pas être contraire à l'ordre public ou aux bonnes mœurs, induire en erreur ou prêter à confusion. Elle doit répondre aux exigences de la Ph.Helv.  actuelle (chapitres 17.1.5 et 17.2.5),  respectivement dans le domaine de la médecine complémentaire remplir les exigences correspondantes du OAMédcophy.",
    "beschriftung_text_2": "Le mélange de différentes formes de thérapie - à l'exception des médicaments allopathiques avec des phytomédicaments - n'est pas autorisé. Les médicaments complémentaires doivent être désignés comme tels.",
    "beschriftung_text_3": "Interdit: publicité et références à d'autres produits de l'établissement",
    "kontakt": {
      "title": "Contact",
      "kapa": "Service pharmaceutique",
      "strasse": "Rathausgasse 1",
      "plz": "3000 Berne 8",
      "telefon": "Téléphone: 031 633 79 26",
      "link": {
        "amt": "Service pharmaceutique",
        "amt-link": "https://www.gsi.be.ch/fr/start/ueber-uns/gesundheitsamt/pharmazeutischer-dienst-pad.html",
        "formular": "Formulaire de contact",
        "kontakt-link": "https://www.gsi.be.ch/fr/tools/kontaktformular/kontaktformularPAD.html",
        "email": "Courriel"
      }
    }
  },
  "footer": {
    "signetlabel": "Direction de la santé, des affaires sociales et de l'intégration (GSI)",
    "about": "Formule propre",
    "rechtliches": "Mentions légales",
    "impressum": "Impressum",
    "kontakt": "Contact",
    "copyright": "© {{year}} - Canton de Berne",
    "follow": "Suivez-nous"
  },
  "multiFileUploadComponent": {
    "dropHereText": "Déposer le-s fichier-s ici",
    "uploadFileButtonText": "Ajouter un/des fichier-s",
    "uploadingText": "Téléversement en cours…",
    "uploadErrorText": "Erreur lors du téléversement d’un ou de plusieurs fichiers. Veuillez réessayer. Assurez-vous que vous disposez d’une connexion à Internet et que la taille du/des fichier-s n’excède pas le maximum autorisé (25 Mo). Si le problème persiste, contactez notre service d’assistance.",
    "uploadErrorTitle": "Erreur lors du téléversement",
    "rejectedFileTitle": "Fichier refusé",
    "undesiredFileTitle": "Informations",
    "numberOfFilesTextZero": "",
    "numberOfFilesTextOne": "1 fichier téléversé",
    "numberOfFilesTextMultiple": "{{count}} fichiers téléversés",
    "uploadErrorDismissButtonLabel": "Ok",
    "uploadErrorShowTechnicalErrorText": "Afficher l’erreur technique",
    "rejectedFileDismissButton": "J’ai compris",
    "undesiredFileDismissButton": "J’ai compris",
    "title": "Télécharger des documents",
    "noDocumentYet": "Aucun document téléversé pour le moment",
    "withDocumentsText": "Vous pouvez (si nécessaire) téléverser d’autres documents."
  },
  "modal": {
    "info": {
      "title": "Information",
      "antrag": {
        "success": "Votre demande a été créée avec succès."
      },
      "mail": {
        "korrespondenz": {
          "success": "Le courrier de correspondance a été enregistré avec succès."
        },
        "configuration": {
          "success": "La configuration du courrier a été enregistrée avec succès."
        }
      },
      "formel": {
        "pruefer": {
          "set": "La formule a été prise en traitement SPHC."
        }
      }
    },
    "confirm": {
      "delete": {
        "title": "Confirmer la suppression",
        "behaeltergroesse": "Voulez-vous vraiment supprimer la taille du conteneur?",
        "komponente": "Voulez-vous vraiment supprimer le composant?",
        "betrieb_berechtigung": "Voulez-vous vraiment retirer l'utilisateur de l'exploitation?",
        "pad_berechtigung": "Voulez-vous vraiment retirer l'utilisateur du service pharmaceutique?",
        "berechtigung_antrag": "Voulez-vous vraiment retirer la demande d'autorisation?",
        "betriebsleiter_change_antrag": "Voulez-vous vraiment retirer la demande de changement de chef d'exploitation?",
        "betriebsleiter_berechtigung": "Voulez-vous vraiment supprimer le chef d'exploitation ?",
        "formel": "Voulez-vous vraiment supprimer la formule?",
        "file": "Voulez-vous vraiment supprimer le fichier?",
        "accept": "Supprimer",
        "cancel": "Annuler"
      },
      "remove": {
        "title": "Supprimer",
        "pruefer": "Voulez-vous vraiment supprimer l'opérateur actuel?",
        "accept": "Supprimer"
      },
      "reject": {
        "antrag": "Voulez-vous vraiment rejeter la demande?"
      }
    },
    "close": "Fermer"
  },
  "info": {
    "modal": {
      "title": "Informations"
    }
  },
  "error": {
    "no.berechtigung.for.betrieb": "Autorisations manquantes pour effectuer cette action pour cet établissement.",
    "no.berechtigung": "Autorisations manquantes pour effectuer cette action.",
    "berechtigung.forbidden": "Autorisations manquantes pour effectuer cette action",
    "not.current.benutzer": "Aucun utilisateur actuel n'existe.",
    "benutzer.not.exist": "L'utilisateur n'existe pas.",
    "berechtigung.search.betrieb.null": "L'entreprise n'existe pas.",
    "berechtigung.udpate.berechtigung.id.null": "Aucune autorisation Id disponible.",
    "berechtigung.udpate.berechtigung.betrieb.null": "Aucune exploitation disponible.",
    "berechtigung.antrag.search.no.betrieb": "Aucune exploitation disponible.",
    "berechtigung.antrag.not.exist": "La demande d'autorisation n'existe pas.",
    "modal": {
      "title": "Message d'erreur",
      "close": "Fermer"
    },
    "common": {
      "no.berechtigung.for.site": "Vous n'avez pas l'autorisation pour cette page.",
      "no.betrieb.selected": "Aucune entreprise sélectionnée"
    },
    "betrieb": {
      "korrespondenzmail": {
        "size": "La longueur du mail de correspondance ne doit pas dépasser 255 caractères."
      }
    },
    "berechtigungs": {
      "antrag": {
        "duplicate": "Vous avez déjà soumis une demande de permission identique.",
        "identical": {
          "berechtigung": "Vous disposez déjà d'une autorisation identique."
        },
        "already": {
          "betriebsleiter": "Vous êtes déjà autorisé en tant que chef d'exploitation sur une exploitation. Un utilisateur ne peut être chef d'exploitation que sur une seule exploitation.",
          "betrieb": {
            "berechtigung": "Vous avez déjà une autorisation sur cet établissement. Un utilisateur ne peut être autorisé qu'une seule fois par établissement.",
            "antrag": "Vous avez déjà une demande d'autorisation sur cet établissement. Un utilisateur ne peut être autorisé qu'une seule fois par établissement."
          }
        },
        "benutzer": {
          "null": "La demande d'autorisation ne contient pas d'utilisateur."
        },
        "rolle": {
          "null": "La demande d'autorisation ne contient pas de rôle.",
          "not.allowed": "Aucune demande d'autorisation ne peut être créée pour le rôle sélectionné."
        },
        "betrieb": {
          "null": "La demande d'autorisation ne contient pas de site.",
          "not.allowed": "Aucun site ne peut être sélectionné pour le rôle choisi."
        }
      }
    },
    "betriebsleiter": {
      "change": {
        "antrag": {
          "duplicate": "Vous avez déjà créé une demande de changement de chef d'établissement identique.",
          "null": "La demande de changement de chef d'exploitation est vide.",
          "betriebsleiter.new.null": "La demande de changement de chef d'exploitation ne contient pas de nouveau chef d'exploitation.",
          "betriebsleiter.old.null": "La demande de changement de chef d'exploitation ne contient pas d'ancien chef d'exploitation.",
          "betriebsleiter.new.rolle.null": "La demande de changement de chef d'exploitation ne contient aucun rôle pour le nouveau chef d'exploitation.",
          "betriebsleiter.new.betrieb.null": "La demande de changement de chef d'exploitation ne contient pas d'exploitation pour le nouveau chef d'exploitation.",
          "betriebsleiter.new.benutzer.null": "La demande de changement de chef d'exploitation ne contient pas d'utilisateur pour le nouveau chef d'exploitation.",
          "betriebsleiter.new.invalid.rolle": "Le nouveau chef d'exploitation doit avoir le rôle d'agent d'exploitation ou de chef d'exploitation adjoint.",
          "betriebsleiter.new.betrieb.different": "Le nouveau chef d'entreprise doit avoir le même établissement que l'ancien.",
          "not.exist": "La demande de changement de chef d'établissement n'existe pas."
        }
      }
    },
    "formel": {
      "allgemein": {
        "praeparat": {
          "name": {
            "blank": "Le nom du produit ne peut pas être vide.",
            "size": "Le nom du produit ne peut pas dépasser 250 caractères."
          },
          "art": {
            "null": "Le type de produit ne peut pas être vide."
          }
        },
        "indikation": {
          "blank": "L'indication ne peut pas être vide.",
          "size": "L'indication ne peut pas dépasser 150 caractères."
        },
        "dosierungsvorschrift": {
          "blank": "Les instructions de dosage ne peuvent pas être vides.",
          "size": "Les instructions de dosage ne peuvent pas dépasser 150 caractères."
        },
        "darreichungsform": {
          "null": "La forme galénique ne peut pas être vide."
        },
        "lohnhersteller": {
          "name": {
            "blank": "Le nom du fabricant à façon ne peut pas être vide.",
            "size": "Le nom du fabricant à façon ne peut pas dépasser 150 caractères."
          },
          "betriebsart": {
            "null": "Le type d'entreprise ne peut pas être vide."
          },
          "postfach": {
            "size": "La boîte postale ne peut pas dépasser 30 caractères."
          },
          "strasse": {
            "blank": "La rue ne peut pas être vide.",
            "size": "La rue ne peut pas dépasser 50 caractères."
          },
          "plz": {
            "blank": "Le code postal ne peut pas être vide.",
            "size": "Le code postal ne peut pas dépasser 10 caractères."
          },
          "ort": {
            "blank": "La localité ne peut pas être vide.",
            "size": "La localité ne peut pas dépasser 150 caractères."
          }
        }
      },
      "zusammensetzung": {
        "chargengroesse": {
          "null": "La taille de lot ne peut pas être vide.",
          "size": "La taille de lot doit être comprise entre 1 et 999999999."
        },
        "chargengroesseeinheit": {
          "null": "L'unité de taille de lot ne peut pas être vide."
        },
        "komponenten": {
          "empty": "Il doit y avoir au moins un composant."
        },
        "komponente": {
          "komponentorder": {
            "null": "La position d'un composant ne peut pas être vide.",
            "size": "La position d'un composant doit être comprise entre 1 et 99."
          },
          "bezeichnung": {
            "blank": "La désignation d'un composant ne peut pas être vide.",
            "size": "La désignation d'un composant ne peut pas dépasser 250 caractères."
          },
          "qualitaet": {
            "null": "La qualité d'un composant ne peut pas être vide.",
            "andere.bemerkung.blank": "Un composant de qualité 'Autre' doit contenir une remarque."
          },
          "art": {
            "null": "Le type d'un composant ne peut pas être vide."
          },
          "einheitsmenge": {
            "blank": "La quantité unitaire d'un composant ne peut pas être vide.",
            "size": "La quantité unitaire d'un composant doit être comprise entre 0 et 999999999."
          },
          "einheit": {
            "blank": "L'unité d'un composant ne peut pas être vide."
          },
          "bemerkung": {
            "size": "La remarque d'un composant ne peut pas dépasser 250 caractères.",
            "blank": "Si un composant n'est pas monographié dans une pharmacopée reconnue, il doit être confirmé que les exigences pour une substance de départ pharmaceutique sont remplies et qu'un certificat d'analyse est disponible. Veuillez noter cela dans les remarques du composant concerné."
          }
        }
      },
      "lagerung": {
        "hinweise": {
          "null": "Il doit y avoir au moins une instruction de stockage."
        },
        "haltbarkeitsdauer": {
          "blank": "La durée de conservation ne peut pas être vide.",
          "size": "La durée de conservation ne peut pas dépasser 150 caractères."
        },
        "bemerkung": {
          "size": "La remarque pour le stockage ne peut pas dépasser 250 caractères."
        }
      },
      "verpackung": {
        "packungsinhalt": {
          "blank": "Le contenu de l'emballage ne peut pas être vide.",
          "size": "Le contenu de l'emballage ne peut pas dépasser 250 caractères."
        },
        "einheit": {
          "null": "L'unité de l'emballage ne peut pas être vide."
        },
        "artmaterialbehaelter": {
          "blank": "Le type de matériau du récipient ne peut pas être vide.",
          "size": "Le type de matériau du récipient ne peut pas dépasser 150 caractères."
        },
        "bemerkung": {
          "size": "La remarque pour les composants de l'emballage ne peut pas dépasser 250 caractères."
        },
        "etiketten": {
          "empty": "Il doit y avoir au moins une étiquette."
        }
      },
      "not.exist": "La formule n'existe pas.",
      "draft": {
        "not.exist": "Le projet n'existe pas."
      },
      "formelrisiko": {
        "blank": "Le risque de la formule ne peut pas être vide."
      },
      "archive": {
        "status": "Une formule ne peut être archivée que si elle a reçu une confirmation de notification. Vous pouvez supprimer la formule si elle n'est plus nécessaire."
      },
      "clone": {
        "status": "Une formule ne peut être dupliquée que si elle a reçu une confirmation de notification."
      },
      "delete": {
        "wrong": {
          "state": "Une formule ne peut être supprimée que si elle a le statut En cours ou Refusée pour traitement ultérieur."
        },
        "is.parent": "La formule a une copie. Supprimez d'abord la copie avant de supprimer cette formule."
      },
      "update": {
        "wrong": {
          "state": "La formule se trouve dans le mauvais statut pour cette action."
        }
      }
    },
    "pruefung": {
      "notes": {
        "allgemein": {
          "size": "La note d'examen pour les généralités ne doit pas dépasser 4000 caractères."
        },
        "zusammensetzung": {
          "size": "La note d'examen de composition ne doit pas dépasser 4000 caractères."
        },
        "lagerung": {
          "size": "La note d'examen pour le stockage ne doit pas dépasser 4000 caractères."
        },
        "verpackung": {
          "size": "La note d'examen pour l'emballage ne doit pas dépasser 4000 caractères."
        }
      }
    }
  },
  "enum": {
    "praeperatart": {
      "ALLOPATHISCH": "Allopathique",
      "ANTHROPOSOPHISCH": "Anthroposophique",
      "AROMATHERAPIE": "Aromathérapie",
      "FUNKTIONSMITTEL_NACH_DR_SCHUESSLER": "Sels minéraux de Schüssler",
      "HOMOEOPATHISCH": "Homéopathique",
      "HOMOEOPATHISCH_SPAGYRISCH": "Homéopathique spagyrique",
      "PHYTO": "Phytothérapie",
      "SPAGYRISCH": "Spagyrique",
      "TRADITIONELL_AYURVEDISCH": "Médecine traditionnelle ayurvédique ",
      "TRADITIONELL_CHINESISCH": "Médecine traditionnelle chinoise ",
      "TRADITIONELL_TIBETISCH": "Médecine traditionnelle tibétaine "
    },
    "darreichungsform": {
      "BAD": "Bain",
      "CREME": "Crème",
      "EMULSION_LOTION": "Émulsion/lotion",
      "GLOBULI": "Pilules",
      "GRANULAT": "Granulés",
      "KAPSELN": "Capsules",
      "LOESUNG_SAFT": "Solution/sirop",
      "LUTSCHTABLETTE": "Pastilles",
      "PULVER": "Poudre",
      "SALBE": "Pommade",
      "SHAMPOO": "Shampooing",
      "SPRAY_TOPISCH": "Spray pour application topique",
      "SPRAY_EINNAHME": "Spray buccal",
      "SUPPOSITORIEN_ZAEPFCHEN": "Suppositoires",
      "TABLETTEN": "Comprimés",
      "TEE": "Thé",
      "TROPFEN": "Gouttes"
    },
    "betriebsart": {
      "ANDERER": "Autre",
      "BETRIEB_MIT_SWISSMEDIC_BEWILLIGUNG": "Établissement avec autorisation Swissmedic",
      "DROGERIE": "Droguerie",
      "OEFFENTLICHE_APOTHEKE": "Pharmacie publique",
      "SPITALAPOTHEKE": "Pharmacie d'hôpital",
      "PRAXISADRESSE": "Praxisadresse",
      "PA_ARZTPRAXIS": "Privatapotheke Arztpraxis"
    },
    "chargengroesseeinheit": {
      "MIKROGRAMM": "Microgramme",
      "MILLIGRAMM": "Milligramme",
      "GRAMM": "Gramme",
      "KILOGRAMM": "Kilogramme",
      "MIKROLITER": "Microlitre",
      "MILLILITER": "Millilitre",
      "LITER": "Litre",
      "KAPSEL": "Capsule",
      "TABLETTE": "Comprimé",
      "ZAEPFCHEN": "Suppositoire",
      "GLOBULI": "Pilule"
    },
    "qualitaet": {
      "ANDERE": "Autres",
      "PH_EUR": "PH Eur.",
      "PH_HELV": "PH Helv.",
      "HAB": "HAB"
    },
    "komponenteart": {
      "HILFSSTOFF_DEKLARATIONSPFLICHTIG": "Excipient soumis à déclaration",
      "HILFSSTOFF_WEITERER": "Autre excipient",
      "WIRKSTOFF_DEKLARATIONSPFLICHTIG": "Principe actif soumis à déclaration"
    },
    "komponenteeinheit": {
      "MIKROGRAMM": "Microgramme",
      "MILLIGRAMM": "Milligramme",
      "GRAMM": "Gramme",
      "KILOGRAMM": "Kilogramme",
      "MIKROLITER": "Microlitre",
      "MILLILITER": "Millilitre",
      "LITER": "Litre",
      "KAPSEL": "Capsule",
      "TABLETTE": "Comprimé",
      "ZAEPFCHEN": "Suppositoire",
      "GLOBULI": "Pilule"
    },
    "lagerungshinweis": {
      "NICHT_UEBER_25_GRAD": "Ne pas conserver au-dessus de 25°C",
      "BEI_RAUMTEMPARATUR": "Conserver à température ambiante (15-25°C)",
      "BEI_15_BIS_30_GRAD": "FR-Bei 15-30°C lagern",
      "IM_KUEHLSCHRANK": "Conserver au réfrigérateur (2-8°C)",
      "IN_DER_ORIGINALVERPACKUNG": "Conserver dans l'emballage d'origine",
      "VOR_LICHT_SCHUETZEN": "Protéger le contenu de la lumière et de l'humidité",
      "TIEFGEKUEHLT": "Conserver au congélateur (au-dessous de -15°C)",
      "NICHT_IM_KUEHLSCHRANK": "Ne pas conserver au réfrigérateur",
      "NICHT_EINFRIEREN": "Ne pas congeler",
      "BEHAELTER_FEST_VERSCHLOSSEN": "FR-Den Behälter fest verschlossen halten",
      "KINDERWARNUNG": "FR-Ausser Reichweite von Kinder aufbewahren"
    },
    "behaeltergroesseeinheit": {
      "MIKROGRAMM": "Microgramme",
      "MILLIGRAMM": "Milligramme",
      "GRAMM": "Gramme",
      "KILOGRAMM": "Kilogramme",
      "MIKROLITER": "Microlitre",
      "MILLILITER": "Millilitre",
      "LITER": "Litre",
      "KAPSEL": "Capsule",
      "TABLETTE": "Comprimé",
      "ZAEPFCHEN": "Suppositoire",
      "GLOBULI": "Pilule"
    },
    "anwendungsart": {
      "ENTERAL": "Voie entérale",
      "ENTERAL_ODER_TOPISCH": "Voie entérale ou utilisation topique avec stérilisation obligatoire",
      "INHALATIV": "Inhalation",
      "OPHTHALMOLOGISCH": "Utilisation ophtalmologique (sur l'oeil sans lésion)",
      "OPHTHALMOLOGISCH_CHIRURGISCH": "Utilisation ophtalmologique en chirurgie ou dans le cas de lésions traumatiques",
      "PARENTERALE": "Voie parentérale",
      "TOPISCH": "Utilisation topique"
    },
    "wirkstoffrisiko": {
      "HOHES": "Risque élevé",
      "MITTLERES": "Risque modéré",
      "GERINGES": "Risque faible"
    },
    "herstellungsprozess": {
      "ABFUELLEN": "Condtionnement des formes de médicaments non stériles",
      "ASEPTISCH": "Fabrication aseptique",
      "ASEPTISCH_ENDSTERILISATION": "Fabrication aseptique avec stérilisation finale",
      "LOESEN_MISCHEN": "Dissolution et mélange",
      "VERDUENNEN": "Dilution"
    },
    "mengenverhaeltnis": {
      "AUSGEGLICHEN": "Répartition équilibrée (rapport d'1:1)",
      "AUSSCHL_EIGENE_KUNDSCHAFT": "Fabrication exclusivement destinée à la clientèle de l'établissement",
      "AUSSCHL_LOHNAUFTRAG": "Fabrication exclusivement pour une commande à façon",
      "HAUPTS_EIGENE_KUNDSCHAFT": "Fabrication principalement destinée à la clientèle de l'établissement (rapport d'environ 1:2)",
      "HAUPTS_LOHNAUFTRAG": "Fabrication principalement destinée pour une commande à façon (rapport d'environ 2:1)"
    },
    "arzneiform": {
      "AUGENTROPFEN": "Gouttes ophtalmologiques",
      "FEST": "Forme médicamenteuse solide",
      "FLUESSIG": "Forme médicamenteuse liquide dans des emballages ",
      "HALBFEST": "Forme médicamenteuse semi-solide (pommades, crèmes, etc.)",
      "HALBFEST_ZAEPFCHEN": "Forme médicamenteuse semi-solide (suppositoires)"
    },
    "produktionsmenge": {
      "FLUESSIG_1": "Plus de 2000",
      "FLUESSIG_2": "1000 à 2000",
      "FLUESSIG_3": "500 à 999",
      "FLUESSIG_4": "100 à 499",
      "FLUESSIG_5": "Moins de 100",
      "FEST_1": "Plus de 120'000",
      "FEST_2": "60'000 à 120'000",
      "FEST_3": "30'000 à 59'999",
      "FEST_4": "6000 à 29'999",
      "FEST_5": "Moins de 6000",
      "HALBFEST_ZAEPF_1": "Plus de 40'000",
      "HALBFEST_ZAEPF_2": "20'000 à 40'000",
      "HALBFEST_ZAEPF_3": "10'000 à 19'999",
      "HALBFEST_ZAEPF_4": "2000 à 9999",
      "HALBFEST_ZAEPF_5": "Moins de 2000",
      "HALBFEST_1": "Plus de 200'000",
      "HALBFEST_2": "100'000 à 200'000",
      "HALBFEST_3": "50'000 à 99'999",
      "HALBFEST_4": "10'000 à 49'999",
      "HALBFEST_5": "Moins de 10'000",
      "AUGENTR_1": "Plus de 200",
      "AUGENTR_2": "100 à 200",
      "AUGENTR_3": "50 à 99",
      "AUGENTR_4": "10 à 49",
      "AUGENTR_5": "Moins de 10"
    },
    "state": {
      "IN_ARBEIT": "En cours",
      "IN_PRUEFUNG": "En traitement SPHC",
      "MELDEBESTAETIGUNG_MIT_KOSTENFOLGE": "Confirmation de notification avec frais",
      "MELDEBESTAETIGUNG_OHNE_KOSTENFOLGE": "Confirmation de notification sans frais",
      "MELDEBESTAETIGUNG_NEUE_VERSION_VORHANDEN": "Confirmation de notification nouvelle version disponible",
      "ZUR_NACHBEARBEITUNG_ZURUECKGEWIESEN": "Rejeté pour retravail",
      "VERZICHTET": "Renoncé"
    },
    "rolle": {
      "ADMINISTRATOR": "ADMINISTRATEUR",
      "EFO_BETRIEBSLEITER": "Responsable d'entreprise",
      "EFO_BETRIEBSLEITER_STV": "Adjoint du responsable d'entreprise",
      "EFO_BETRIEBSMITARBEITER": "Employé d'entreprise",
      "PAD_SACHBEARBEITER": "Collaborateur PAD",
      "PAD_SEKRETARIAT": "Secrétariat PAD"
    }
  },
  "file": {
    "preview": "Aperçu"
  },
  "tooltip": {
    "betrieb_select": "Sélectionner un établissement. <brEn tant qu'administrateur, vous pouvez effectuer toutes les actions pour cet établissement.",
    "korrespondenz_mail_edit": "Traiter un e-mail de correspondance",
    "save": "Enregistrer",
    "delete": "Supprimer",
    "edit": "Modifier",
    "update_rolle": "Rôle de changement. <br>Sélectionnez dans le menu déroulant le rôle souhaité pour l'utilisateur et confirmez avec ce bouton.",
    "accept": "Accepter",
    "reject": "Refuser, un motif de refus doit être saisi.",
    "betriebsleiter_change": "Demande de changement de chef d'exploitation",
    "berechtigung_beantragen": "Demander l'autorisation",
    "history": "Historique",
    "view": "Vue",
    "formel_pruefen": "Vérifier la formule",
    "remove_pruefer": "Supprimer le vérificateur",
    "set_pruefer": "Vérifier la formule",
    "formel_save": "Enregistrer la formule. <br> la formule est enregistrée. <br> Orange : la formule n'est pas enregistrée.",
    "formel_copy": "Formel kopieren",
    "formel_archive": "Copier une formule",
    "file_preview": "Datei Vorschau",
    "file_download": "Aperçu du fichier",
    "file_remove": "Supprimer un fichier",
    "toggle_pruefungsnotes": "Afficher / masquer les notes d'examen"
  }
}
