import { Route, Routes } from '@angular/router';

export type AuxDataKey = string;

export interface AuxDataProvider {
  readonly key: AuxDataKey;
  readonly value: any;
}

export interface EnhancedRoute {
  route: Route;
  children?: { [key: string]: EnhancedRoute };
  auxData?: Array<AuxDataProvider>;
}

interface InternalEnhancedRoute extends EnhancedRoute {
  _enhancedData?: EnhancedData;
}

export interface EnhancedRoutes {
  [key: string]: EnhancedRoute;
}

export class EnhancedRouteFunctions {
  public static build(routes: EnhancedRoutes): Routes {
    const angularRoutes = [];
    for (const route of Object.values(routes)) {
      const enhanced = EnhancedRouteFunctions.enhance(route);
      angularRoutes.push(enhanced);
    }
    return angularRoutes;
  }

  public static setAuxData(route: EnhancedRoute, key: AuxDataKey, value: any) {
    EnhancedRouteFunctions.requireEnhancedData(route).setAuxData(key, value);
  }

  public static getAuxData(route: EnhancedRoute, key: AuxDataKey): any {
    return EnhancedRouteFunctions.requireEnhancedData(route).getAuxData(key);
  }

  public static getParent(route: EnhancedRoute): EnhancedRoute | undefined {
    return EnhancedRouteFunctions.requireEnhancedData(route).parent;
  }

  public static children(route: EnhancedRoute): Array<EnhancedRoute> {
    const children = route.children;
    if (children === undefined) {
      return [];
    } else {
      return Object.values(children);
    }
  }

  private static enhance(
    route: EnhancedRoute,
    parent?: InternalEnhancedRoute,
  ): Route {
    const internalEnhancedRoute: InternalEnhancedRoute = route;
    // setup enhanced data
    if (internalEnhancedRoute._enhancedData !== undefined) {
      throw 'Already enhanced. Cannot enhance more than once.';
    }
    internalEnhancedRoute._enhancedData = new EnhancedData(parent);
    // process children
    const angularData = internalEnhancedRoute.route;
    const angularChildren = angularData.children;
    if (angularChildren !== undefined && angularChildren.length > 0) {
      throw 'Do not add children to angular route. Children are automatically added.';
    }
    const maybeChildren = internalEnhancedRoute.children;
    if (maybeChildren !== undefined) {
      // add all children
      for (const child of Object.values(maybeChildren)) {
        const childAngularData = EnhancedRouteFunctions.enhance(
          child,
          internalEnhancedRoute,
        );
        if (internalEnhancedRoute.route.children === undefined) {
          internalEnhancedRoute.route.children = [];
        }
        internalEnhancedRoute.route.children.push(childAngularData);
      }
    }
    const auxDataProviders = route.auxData;
    if (auxDataProviders !== undefined) {
      for (const auxDataProvider of auxDataProviders) {
        EnhancedRouteFunctions.setAuxData(
          internalEnhancedRoute,
          auxDataProvider.key,
          auxDataProvider.value,
        );
      }
    }
    return internalEnhancedRoute.route;
  }

  private static requireEnhancedData(route: EnhancedRoute): EnhancedData {
    const internalEnhancedRoute: InternalEnhancedRoute = route;
    const enhancedData = internalEnhancedRoute._enhancedData;
    if (enhancedData === undefined) {
      throw `Route has not yet been enhanced. ${JSON.stringify(route)}`;
    }
    return enhancedData;
  }
}

export class PathAsString {
  private static readonly key: AuxDataKey = 'path_as_string';

  public static get(route: EnhancedRoute): string {
    const existingData = EnhancedRouteFunctions.getAuxData(
      route,
      PathAsString.key,
    );
    if (existingData !== undefined) {
      return existingData as string;
    }
    const pathAsArray = PathAsArray.get(route);
    const newPath = '/' + pathAsArray.join('/');
    EnhancedRouteFunctions.setAuxData(route, PathAsString.key, newPath);
    return newPath;
  }

  public static findByPath(
    route: EnhancedRoute,
    path: string,
  ): EnhancedRoute | undefined {
    const pathAsString = PathAsString.get(route);
    if (path === pathAsString) {
      return route;
    }
    // ask children
    for (const child of EnhancedRouteFunctions.children(route)) {
      const maybeResult = PathAsString.findByPath(child, path);
      if (maybeResult !== undefined) {
        return maybeResult;
      }
    }
    return undefined;
  }

  public static findByPathInRoot(
    root: EnhancedRoutes,
    path: string,
  ): EnhancedRoute | undefined {
    for (const child of Object.values(root)) {
      const maybeResult = PathAsString.findByPath(child, path);
      if (maybeResult !== undefined) {
        return maybeResult;
      }
    }
    return undefined;
  }
}

export class PathAsArray {
  private static readonly key: AuxDataKey = 'path_as_array';

  public static get(route: EnhancedRoute): Array<string> {
    const existingData = EnhancedRouteFunctions.getAuxData(
      route,
      PathAsArray.key,
    );
    if (existingData !== undefined) {
      return existingData as Array<string>;
    }
    const newPath = [];
    const maybeParent = EnhancedRouteFunctions.getParent(route);
    if (maybeParent !== undefined) {
      newPath.push(...PathAsArray.get(maybeParent));
    }
    const maybeThisPath = route.route.path;
    if (maybeThisPath !== undefined && maybeThisPath !== '') {
      newPath.push(maybeThisPath);
    }
    // save in cache
    EnhancedRouteFunctions.setAuxData(route, PathAsArray.key, newPath);
    return newPath;
  }
}

class EnhancedData {
  parent?: InternalEnhancedRoute;
  private data: { [key: AuxDataKey]: any } = {};

  constructor(parent?: InternalEnhancedRoute) {
    this.parent = parent;
  }

  setAuxData(key: AuxDataKey, value: any) {
    this.data[key] = value;
  }

  getAuxData(key: AuxDataKey): any {
    return this.data[key];
  }
}
