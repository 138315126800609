<div class="bew-table-container">
  <app-bew-input
    *ngIf="searchable"
    [label]="'table.search' | translate"
    [(value)]="searchTerm"
    (valueChange)="searchFromStart()">
  </app-bew-input>
  <table>
    <thead>
      <ng-content select="[table-header]"></ng-content>
    </thead>
    <ng-content select="[table-body]"></ng-content>
    <tfoot *ngIf="searchable">
      <tr>
        <td>
          <button
            class="efo-button blue"
            (click)="previousPage()"
            [disabled]="previousPageDisabled()">
            <i class="fa fa-arrow-alt-circle-left"></i>
          </button>
          <button
            class="efo-button blue"
            (click)="nextPage()"
            [disabled]="nextPageDisabled()">
            <i class="fa fa-arrow-alt-circle-right"></i>
          </button>
        </td>
        <td>
          {{ pageItemsFrom() }} - {{ pageItemsTo() }} / {{ totalElements }}
        </td>
      </tr>
    </tfoot>
  </table>
</div>
