<div class="container mt-2">
  <div class="row">
    <div class="col-12 title title-margins" translate="impressum.title.1"></div>
  </div>
  <div class="row">
    <div class="col-12 font-bold" translate="impressum.gef"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="impressum.amt"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="impressum.gef.strasse"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="impressum.gef.postfach"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="impressum.gef.plz"></div>
  </div>
  <div class="row">
    <div
      class="col-12 title title-margins"
      translate="impressum.title.2"
      style="margin-top: 20px"></div>
  </div>
  <div class="row">
    <div class="col-12 font-bold" translate="impressum.bedag"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="impressum.bedag.strasse"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="impressum.bedag.plz"></div>
  </div>
</div>
