<app-berechtigung-current-overview
  (reload)="reload()"></app-berechtigung-current-overview>

<app-betrieb-select></app-betrieb-select>

<app-betrieb-current-detail (reload)="reload()"></app-betrieb-current-detail>

<app-pad-detail (reload)="reload()"></app-pad-detail>

<app-bew-box [label]="'antrag.overview.title' | translate">
  <app-betrieb-berechtigung-beantragt-overview
    (reload)="reload()"></app-betrieb-berechtigung-beantragt-overview>
  <app-antrag-betriebsleiter-change-current
    (reload)="reload()"></app-antrag-betriebsleiter-change-current>

  <app-betrieb-all-overview (reload)="reload()"></app-betrieb-all-overview>

  <app-berechtigung-beantragen-pad
    (reload)="reload()"></app-berechtigung-beantragen-pad>
</app-bew-box>
