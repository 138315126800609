import { Component, Input, OnInit } from '@angular/core';
import { LinkAction } from '../link-action/link-action';

@Component({
  selector: 'app-bew-breadcrumb',
  templateUrl: './bew-breadcrumb.component.html',
  styleUrls: ['./bew-breadcrumb.component.scss'],
})
export class BewBreadcrumbComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input()
  data?: BreadcrumbData;
}

export interface BreadcrumbData {
  home: LinkAction;
  items: Array<BreadcrumbItem>;
}

export interface BreadcrumbItem {
  i18nKeyForLabel: string;
  action: LinkAction;
  active: boolean;
}
