import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FooterNavigationItems } from '../bew/components/bew-footer-navigation/bew-footer-navigation.component';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  LanguageItems,
  LanguageSelectedEvent,
  ServiceMenuItem,
  ServiceNavigationData,
} from '../bew/components/bew-service-navigation/bew-service-navigation.component';
import { BreadcrumbData } from '../bew/components/bew-breadcrumb/bew-breadcrumb.component';
import { NavigationExtras, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LinkAction } from '../bew/components/link-action/link-action';
import { LocaleService } from './services/locale.service';
import { BreadcrumbService } from './services/breadcrumb.service';
import { routes } from './app-routing.module';
import {
  MainMenuData,
  MenuItem,
} from '../bew/components/bew-main-menu/bew-main-menu.component';
import { PathAsArray, PathAsString } from './data/enhanced-route';
import { LoginService } from './services/login.service';
import { UserService } from './services/user.service';
import { BenutzerDTO } from './backend/model/benutzerDTO';
import { BerechtigungService } from './services/berechtigung.service';
import { BerechtigungDTO } from './backend/model/berechtigungDTO';
import { ErrorService } from './services/error.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { InformationApiService } from './backend';
import { InformationDTO } from './backend/model/informationDTO';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // @ts-ignore
  @ViewChild('errorModal') errorModal;
  errorMessage: string = '';

  readonly breadcrumbData: Observable<BreadcrumbData>;
  readonly signetLinkAction: LinkAction;
  readonly footerNavigationItems: Observable<FooterNavigationItems>;
  readonly serviceNavigationData: Observable<ServiceNavigationData>;
  readonly mainMenuData: Observable<MainMenuData>;

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly localeService: LocaleService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly location: Location,
    private readonly loginService: LoginService,
    private readonly userService: UserService,
    private readonly berechtigungService: BerechtigungService,
    private readonly errorService: ErrorService,
    private modalService: NgbModal,
    private readonly informationApiService: InformationApiService,
  ) {
    this.footerNavigationItems =
      AppComponent.createFooterNavigationItemsObservable(this.translateService);
    this.serviceNavigationData = this.createServiceNavigationData(
      localeService,
      translateService,
      userService,
      berechtigungService,
    );
    this.breadcrumbData = this.createBreadcrumbDataObservable();
    this.signetLinkAction = AppComponent.createSignetLinkAction();
    this.mainMenuData = this.createMainMenuData(
      translateService,
      berechtigungService,
    );
  }

  ngOnInit(): void {
    this.errorService.getError().subscribe((message: string[]) => {
      this.showErrorModal(message);
    });

    this.informationApiService
      .getStartInformation()
      .subscribe((info: InformationDTO) => {
        if (
          this.loginService.isLoggedIn() &&
          info != null &&
          info.content != null
        ) {
          this.showErrorModal([info.content]);
        }
      });

    this.translateService.setDefaultLang('de');

    // we bundle the translations to prevent a flash of untranslated content
    // (this does not significantly increase bundle size unless you have dozens of translations)
    for (const lang of (window as any).environment.availableLanguages) {
      this.translateService.setTranslation(
        lang,
        require(`../assets/i18n/${lang}.json`),
      );
    }

    if (this.loginService.isLoggedIn()) {
      this.berechtigungService.selectFirstBerechtigung();
    }
  }

  showErrorModal(message: string[]) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.type = 'INFO';
  }

  private static createSignetLinkAction(): LinkAction {
    const commands = ['test'];
    const extras: NavigationExtras = {
      // null means: relative to "root"
      relativeTo: null,
    };
    return {
      routerLinkCommands: commands,
      routerNavigationExtras: extras,
    };
  }

  private static createFooterNavigationItemsObservable(
    translateService: TranslateService,
  ): Observable<FooterNavigationItems> {
    // IDE shows this as deprecated (according to me, the IDE is wrong - only the overloads with schedulers are deprecated).
    return combineLatest(
      translateService.stream('footer.rechtliches'),
      translateService.stream('footer.impressum'),
    ).pipe(
      map(([rechtliches, impressum]) => {
        const data: FooterNavigationItems = [];
        data.push({
          label: rechtliches,
          action: {
            routerLinkCommands: PathAsArray.get(routes.rechtliches),
          },
        });
        data.push({
          label: impressum,
          action: {
            routerLinkCommands: PathAsArray.get(routes.impressum),
          },
        });
        return data;
      }),
    );
  }

  private createServiceNavigationData(
    localeService: LocaleService,
    translateService: TranslateService,
    userService: UserService,
    berechtigungService: BerechtigungService,
  ): Observable<ServiceNavigationData> {
    const user = userService.currentUser;
    const berechtigung = berechtigungService.currentBerechtigung;
    const berechtigungItems = this.createBerechtigungItems(
      translateService,
      berechtigung,
    );
    const loginLogoutMenuItem = this.createLoginLogoutMenuItem(
      translateService,
      user,
    );
    const languageItems = AppComponent.createLanguageItems(localeService);
    return combineLatest(
      languageItems,
      loginLogoutMenuItem,
      berechtigungItems,
    ).pipe(
      map(([languageItems, loginLogoutMenuItem, berechtigungItems]) => {
        return {
          languageItems: languageItems,
          menuItems: [berechtigungItems, loginLogoutMenuItem],
        };
      }),
    );
  }

  private createLoginLogoutMenuItem(
    translateService: TranslateService,
    user: Observable<BenutzerDTO | null>,
  ): Observable<ServiceMenuItem> {
    return user.pipe(
      switchMap((maybeUser) => {
        if (maybeUser !== null) {
          // we have a user (so we provide the logout menu item)
          const logoutText = translateService.stream('navigation.logout', {
            firstName: maybeUser.firstName,
            lastName: maybeUser.lastName,
          });
          return logoutText.pipe(
            map((logoutText) => {
              return <ServiceMenuItem>{
                label: logoutText,
                loginStyle: false,
                action: {
                  onClickThis: this,
                  onClick: this.logout,
                },
              };
            }),
          );
        } else {
          // no user (so we provide the login menu item)
          const loginText = translateService.stream('navigation.login');
          return loginText.pipe(
            map((loginText) => {
              return <ServiceMenuItem>{
                label: loginText,
                loginStyle: true,
                action: {
                  onClickThis: this,
                  onClick: this.login,
                },
              };
            }),
          );
        }
      }),
    );
  }

  private static createServiceNavigationRegistrationMenuItems(
    translateService: TranslateService,
  ): Observable<Array<ServiceMenuItem>> {
    return combineLatest(
      translateService.stream('navigation.registration'),
      translateService.stream('navigation.registrationUrl'),
    ).pipe(
      map(([footerNavContact, footerNavContactUrl]) => {
        return [
          {
            label: footerNavContact,
            action: {
              href: footerNavContactUrl,
              hrefOpenInNewWindow: true,
            },
            loginStyle: false,
          },
        ];
      }),
    );
  }

  private static createServiceNavigationContactMenuItem(
    translateService: TranslateService,
  ): Observable<ServiceMenuItem> {
    return combineLatest(
      translateService.stream('navigation.contact'),
      translateService.stream('navigation.contactUrl'),
    ).pipe(
      map(([footerNavContact, footerNavContactUrl]) => {
        return {
          label: footerNavContact,
          action: {
            // yes, the URL changes depending on the language (thus it's in the translation)
            href: footerNavContactUrl,
            hrefOpenInNewWindow: true,
          },
          loginStyle: false,
        };
      }),
    );
  }

  private static createLanguageItems(
    localeService: LocaleService,
  ): Observable<LanguageItems> {
    const currentLocale = localeService.locale;
    return currentLocale.pipe(
      map((currentLocale) => {
        // we're already happy if only the language matches (since we don't have multiple locales for
        // one language anyway).
        const currentLanguage = LocaleService.languageFromLocale(currentLocale);
        return AppComponent.getLanguages().map((language) => {
          return {
            label: language.label,
            active: language.language === currentLanguage,
          };
        });
      }),
    );
  }

  private createBerechtigungItems(
    translateService: TranslateService,
    berechtigung: Observable<BerechtigungDTO | null>,
  ): Observable<ServiceMenuItem> {
    return berechtigung.pipe(
      switchMap((maybeBerechtigung) => {
        if (maybeBerechtigung !== null) {
          // we have a user (so we provide the logout menu item)
          const rolleText = translateService.stream(
            'enum.rolle.' + maybeBerechtigung.rolle,
          );
          return rolleText.pipe(
            map((rolleText) => {
              let betriebDisplayName: string = '';
              if (maybeBerechtigung.betrieb?.name) {
                betriebDisplayName = maybeBerechtigung.betrieb?.name + ' | ';
              }
              return <ServiceMenuItem>{
                label: betriebDisplayName + rolleText,
                loginStyle: false,
              };
            }),
          );
        } else {
          // no user (so we provide the login menu item)
          const loginText = translateService.stream('navigation.login');
          return loginText.pipe(
            map((loginText) => {
              return <ServiceMenuItem>{
                label: '',
                loginStyle: false,
              };
            }),
          );
        }
      }),
    );
  }

  onLanguageSelected(event: LanguageSelectedEvent) {
    const index = event.index;
    const language = AppComponent.getLanguages()[index];
    if (language !== undefined) {
      this.localeService.setLocale(language.locale);
    } else {
      console.log(`No language at index ${index} found.`);
    }
  }

  private static getLanguages(): Array<Language> {
    return LocaleService.supportedLocales.map((locale) => {
      const language = LocaleService.languageFromLocale(locale);
      return {
        label: language.toUpperCase(),
        language: language,
        locale: locale,
      };
    });
  }

  convertNullToUndefined(value: any | null): any {
    if (value === null) {
      return undefined;
    } else {
      return value;
    }
  }

  login() {
    this.loginService.login();
  }

  loginBeLogin() {
    this.loginService.loginBeLogin();
  }

  logout() {
    this.userService.removeUserAndClearSessionStorage();
    this.loginService.logout();
  }

  private createBreadcrumbDataObservable(): Observable<BreadcrumbData> {
    const routerEvents = this.router.events;
    return routerEvents.pipe(
      map((_ignored) => {
        return this.generateBreadcrumbData();
      }),
    );
  }

  private generateBreadcrumbData(): BreadcrumbData {
    return this.breadcrumbService.createBreadcrumbData(routes);
    const home = {
      routerLinkCommands: '',
    };
    return { home: home, items: [] };
  }

  private createMainMenuData(
    translateService: TranslateService,
    berechtigungService: BerechtigungService,
  ): Observable<MainMenuData> {
    return combineLatest(
      translateService.stream('navigation.menu.berechtigungen'),
      translateService.stream('navigation.menu.informationen'),
      translateService.stream('navigation.menu.formeln'),
      berechtigungService.currentBerechtigung,
    ).pipe(
      map(([berechtigungen, informationen, formeln, berechtigung]) => {
        let hasRolle = false;
        if (berechtigung && berechtigung.rolle) {
          hasRolle = true;
        }
        let isAdmin = false;
        if (berechtigung && berechtigung.rolle == 'ADMINISTRATOR') {
          isAdmin = true;
        }
        let menuItems: Array<MenuItem> = [];
        menuItems.push({
          label: informationen,
          selected: false,
          action: {
            routerLinkCommands: [PathAsString.get(routes.informationen)],
          },
        });
        menuItems.push({
          label: berechtigungen,
          selected: false,
          action: {
            routerLinkCommands: [PathAsString.get(routes.betriebe)],
          },
        });
        if (hasRolle) {
          menuItems.push({
            label: formeln,
            selected: false,
            action: {
              routerLinkCommands: [PathAsString.get(routes.formeln)],
            },
          });
        }
        return {
          small: false,
          items: menuItems,
        };
      }),
    );
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }
}

interface Language {
  label: string;
  language: string;
  locale: string;
}
