/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Anwendungsart = '{\"code\": 1500, \"name\": \"ENTERAL\", \"riskFactor\": 3.0}' | '{\"code\": 1501, \"name\": \"ENTERAL_ODER_TOPISCH\", \"riskFactor\": 4.0}' | '{\"code\": 1502, \"name\": \"INHALATIV\", \"riskFactor\": 4.0}' | '{\"code\": 1503, \"name\": \"OPHTHALMOLOGISCH\", \"riskFactor\": 1.0}' | '{\"code\": 1504, \"name\": \"OPHTHALMOLOGISCH_CHIRURGISCH\", \"riskFactor\": 4.0}' | '{\"code\": 1505, \"name\": \"PARENTERALE\", \"riskFactor\": 5.0}' | '{\"code\": 1506, \"name\": \"TOPISCH\", \"riskFactor\": 1.0}';

export const Anwendungsart = {
    _1500NameENTERALRiskFactor30: '{\"code\": 1500, \"name\": \"ENTERAL\", \"riskFactor\": 3.0}' as Anwendungsart,
    _1501NameENTERALODERTOPISCHRiskFactor40: '{\"code\": 1501, \"name\": \"ENTERAL_ODER_TOPISCH\", \"riskFactor\": 4.0}' as Anwendungsart,
    _1502NameINHALATIVRiskFactor40: '{\"code\": 1502, \"name\": \"INHALATIV\", \"riskFactor\": 4.0}' as Anwendungsart,
    _1503NameOPHTHALMOLOGISCHRiskFactor10: '{\"code\": 1503, \"name\": \"OPHTHALMOLOGISCH\", \"riskFactor\": 1.0}' as Anwendungsart,
    _1504NameOPHTHALMOLOGISCHCHIRURGISCHRiskFactor40: '{\"code\": 1504, \"name\": \"OPHTHALMOLOGISCH_CHIRURGISCH\", \"riskFactor\": 4.0}' as Anwendungsart,
    _1505NamePARENTERALERiskFactor50: '{\"code\": 1505, \"name\": \"PARENTERALE\", \"riskFactor\": 5.0}' as Anwendungsart,
    _1506NameTOPISCHRiskFactor10: '{\"code\": 1506, \"name\": \"TOPISCH\", \"riskFactor\": 1.0}' as Anwendungsart
};