import { Component, QueryList, ViewChildren } from '@angular/core';
import {
  FormelApiService,
  FormelDraftApiService,
  FormelDraftDTO,
  FormelDTO,
  FormelHistoryEntryDTO,
} from '../../../../backend';
import { SortConfiguration } from '../../../table-column-sort/table-column-sort.component';
import { NavigationExtras, Router } from '@angular/router';
import { PathAsString } from '../../../../data/enhanced-route';
import { routes } from '../../../../app-routing.module';
import {
  EfoTableComponent,
  SearchConfiguration,
} from '../../../efo-table/efo-table.component';
import { BerechtigungService } from '../../../../services/berechtigung.service';
import { first } from 'rxjs';
import { ErrorModalComponent } from '../../../error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import StateEnum = FormelHistoryEntryDTO.StateEnum;

@Component({
  selector: 'app-formel-overview',
  templateUrl: './formel-overview.component.html',
  styleUrl: './formel-overview.component.scss',
})
export class FormelOverviewComponent {
  @ViewChildren(EfoTableComponent) // @ts-ignore
  efoBenutzerTableComponent!: QueryList<EfoTableComponent>;

  formelDraftsForBetrieb?: Array<FormelDraftDTO> = [];
  totalElementsFormelDraftsForBetrieb: number = 0;
  numberOfElementsFormelDraftsForBetrieb: number = 0;
  totalPagesFormelDraftsForBetrieb: number = 0;

  formelnForBetrieb?: Array<FormelDTO> = [];
  totalElementsFormelnForBetrieb: number = 0;
  numberOfElementsFormelnForBetrieb: number = 0;
  totalPagesFormelnForBetrieb: number = 0;

  formelnForPadInPruefungWherePruefer?: Array<FormelDTO> = [];
  totalElementsFormelnForPadInPruefungWherePruefer: number = 0;
  numberOfElementsFormelnForPadInPruefungWherePruefer: number = 0;
  totalPagesFormelnForPadInPruefungWherePruefer: number = 0;

  formelnForPadInPruefung?: Array<FormelDTO> = [];
  totalElementsFormelnForPadInPruefung: number = 0;
  numberOfElementsFormelnForPadInPruefung: number = 0;
  totalPagesFormelnForPadInPruefung: number = 0;

  formelnForPadAll?: Array<FormelDTO> = [];
  totalElementsFormelnForPadAll: number = 0;
  numberOfElementsFormelnForPadAll: number = 0;
  totalPagesFormelnForPadAll: number = 0;

  sortConfiguration: SortConfiguration = {
    sortBy: 'allgemein.praeparatName',
    direction: undefined,
  };

  isAdmin = false;
  isPadSachbearbeiter = false;
  isPadSekretariat = false;
  hasBetrieb = false;

  constructor(
    private readonly router: Router,
    private readonly formelDraftApiService: FormelDraftApiService,
    private readonly formelApiService: FormelApiService,
    public readonly berechtigungService: BerechtigungService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.isAdmin = this.berechtigungService.isAdmin();
    this.isPadSachbearbeiter = this.berechtigungService.isPadSachbearbeiter();
    this.isPadSekretariat = this.berechtigungService.isPadSekretariat();
    this.hasBetrieb = this.berechtigungService.hasBetrieb();
  }

  searchAll() {
    this.efoBenutzerTableComponent.forEach((table) => table.search());
  }

  searchDrafts(searchConfiguration: SearchConfiguration) {
    this.berechtigungService.currentBetrieb
      .pipe(first())
      .subscribe((betrieb) => {
        if (betrieb != undefined && betrieb.id != undefined) {
          this.formelDraftApiService
            .search(
              betrieb.id,
              searchConfiguration.searchTerm,
              searchConfiguration.sortConfiguration.sortBy,
              searchConfiguration.pageConfiguration.pageNumber,
              searchConfiguration.pageConfiguration.pageSize,
              searchConfiguration.sortConfiguration.direction,
            )
            .subscribe((page) => {
              this.formelDraftsForBetrieb = page.content;
              if (page.totalElements) {
                this.totalElementsFormelDraftsForBetrieb = page.totalElements;
              } else {
                this.totalElementsFormelDraftsForBetrieb = 0;
              }
              if (page.totalPages) {
                this.totalPagesFormelDraftsForBetrieb = page.totalPages;
              }
              if (page.numberOfElements) {
                this.numberOfElementsFormelDraftsForBetrieb =
                  page.numberOfElements;
              } else {
                this.numberOfElementsFormelDraftsForBetrieb = 0;
              }
            });
        }
      });
  }

  searchForBetrieb(searchConfiguration: SearchConfiguration) {
    const betrieb = this.berechtigungService.currentBetriebOnce();
    if (betrieb && betrieb.id) {
      this.formelApiService
        .searchForBetrieb(
          betrieb.id,
          searchConfiguration.searchTerm,
          searchConfiguration.sortConfiguration.sortBy,
          searchConfiguration.pageConfiguration.pageNumber,
          searchConfiguration.pageConfiguration.pageSize,
          searchConfiguration.sortConfiguration.direction,
        )
        .subscribe((page) => {
          this.formelnForBetrieb = page.content;
          if (page.totalElements) {
            this.totalElementsFormelnForBetrieb = page.totalElements;
          } else {
            this.totalElementsFormelnForBetrieb = 0;
          }
          if (page.totalPages) {
            this.totalPagesFormelnForBetrieb = page.totalPages;
          }
          if (page.numberOfElements) {
            this.numberOfElementsFormelnForBetrieb = page.numberOfElements;
          } else {
            this.numberOfElementsFormelnForBetrieb = 0;
          }
        });
    }
  }

  searchForPadInPruefungWherePruefer(searchConfiguration: SearchConfiguration) {
    this.formelApiService
      .searchForPadInPruefungWherePruefer(
        searchConfiguration.searchTerm,
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.formelnForPadInPruefungWherePruefer = page.content;
        if (page.totalElements) {
          this.totalElementsFormelnForPadInPruefungWherePruefer =
            page.totalElements;
        } else {
          this.totalElementsFormelnForPadInPruefungWherePruefer = 0;
        }
        if (page.totalPages) {
          this.totalPagesFormelnForPadInPruefungWherePruefer = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsFormelnForPadInPruefungWherePruefer =
            page.numberOfElements;
        } else {
          this.numberOfElementsFormelnForPadInPruefungWherePruefer = 0;
        }
      });
  }

  searchForPadInPruefung(searchConfiguration: SearchConfiguration) {
    this.formelApiService
      .searchForPadInPruefung(
        searchConfiguration.searchTerm,
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.formelnForPadInPruefung = page.content;
        if (page.totalElements) {
          this.totalElementsFormelnForPadInPruefung = page.totalElements;
        } else {
          this.totalElementsFormelnForPadInPruefung = 0;
        }
        if (page.totalPages) {
          this.totalPagesFormelnForPadInPruefung = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsFormelnForPadInPruefung = page.numberOfElements;
        } else {
          this.numberOfElementsFormelnForPadInPruefung = 0;
        }
      });
  }

  searchForPadAll(searchConfiguration: SearchConfiguration) {
    this.formelApiService
      .searchForPadAll(
        searchConfiguration.searchTerm,
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.formelnForPadAll = page.content;
        if (page.totalElements) {
          this.totalElementsFormelnForPadAll = page.totalElements;
        } else {
          this.totalElementsFormelnForPadAll = 0;
        }
        if (page.totalPages) {
          this.totalPagesFormelnForPadAll = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsFormelnForPadAll = page.numberOfElements;
        } else {
          this.numberOfElementsFormelnForPadAll = 0;
        }
      });
  }

  public deleteDraft(id: string | undefined) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = ['modal.confirm.delete.formel'];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';
    modalRef.result.then((result) => {
      if (result) {
        if (id) {
          this.formelDraftApiService._delete(id).subscribe(() => {
            this.searchAll();
          });
        }
      }
    });
  }

  public delete(id: string | undefined) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = ['modal.confirm.delete.formel'];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';
    modalRef.result.then((result) => {
      if (result) {
        if (id) {
          this.formelApiService.delete(id).subscribe(() => {
            this.searchAll();
          });
        }
      }
    });
    /*
    if (id) {
      this.formelApiService.delete(id).subscribe(() => {
        this.searchAll();
      });
    }*/
  }

  public createDraft() {
    this.berechtigungService.currentBetrieb
      .pipe(first())
      .subscribe((betrieb) => {
        if (betrieb != undefined && betrieb.id != undefined) {
          this.formelDraftApiService.create(betrieb.id).subscribe((id) => {
            this.navigateToFormelDraft(id, true);
          });
        }
      });
  }

  public navigateToFormelDraft(id: string | undefined, edit: boolean) {
    const navigationExtras: NavigationExtras = {
      queryParams: { id: id, isDraft: true, edit: edit },
      // Other options if needed
    };
    this.router.navigate([PathAsString.get(routes.main)], navigationExtras);
  }

  public editDraft(id: string | undefined) {
    this.navigateToFormelDraft(id, true);
  }

  public edit(id: string | undefined, state: StateEnum | undefined) {
    if (id != undefined) {
      if (state == StateEnum.ZURNACHBEARBEITUNGZURUECKGEWIESEN) {
        this.formelApiService
          .updateState({ formelId: id, desiredState: StateEnum.INARBEIT })
          .subscribe((id) => {
            this.navigateToFormel(id, true);
          });
      } else {
        this.navigateToFormel(id, true);
      }
    }
  }

  public viewFormel(id: string | undefined) {
    if (id != undefined) {
      this.navigateToFormel(id, false);
    }
  }

  public pruefen(id: string | undefined) {
    if (id != undefined) {
      this.navigateToFormel(id, true);
    }
  }

  public setPruefer(id: string | undefined) {
    if (id) {
      this.formelApiService.updatePruefer(id).subscribe(() => {
        const modalRef = this.modalService.open(ErrorModalComponent);
        modalRef.componentInstance.message = ['modal.info.formel.pruefer.set'];
        modalRef.componentInstance.type = 'INFO';
        this.searchAll();
      });
    }
  }

  public removePruefer(id: string | undefined) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = ['modal.confirm.remove.pruefer'];
    modalRef.componentInstance.type = 'CONFIRM_REMOVE';

    modalRef.result.then((result) => {
      if (result) {
        if (id) {
          this.formelApiService
            .removePruefer(id)
            .subscribe(() => this.searchAll());
        }
      }
    });
  }

  public showHistory(id: string | undefined) {
    if (id != undefined) {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: id, isDraft: false },
        // Other options if needed
      };
      this.router.navigate(
        [PathAsString.get(routes.history)],
        navigationExtras,
      );
    }
  }

  public isDeletable(state: StateEnum | undefined) {
    return (
      state == StateEnum.INARBEIT ||
      state == StateEnum.MELDEBESTAETIGUNGMITKOSTENFOLGE ||
      state == StateEnum.MELDEBESTAETIGUNGOHNEKOSTENFOLGE ||
      state == StateEnum.ZURNACHBEARBEITUNGZURUECKGEWIESEN
    );
  }

  public navigateToFormel(id: string | undefined, edit: boolean) {
    const navigationExtras: NavigationExtras = {
      queryParams: { id: id, isDraft: false, edit: edit },
      // Other options if needed
    };
    this.router.navigate([PathAsString.get(routes.main)], navigationExtras);
  }

  protected readonly event = event;
  protected readonly history = history;
}
