<div class="container mt-2">
  <div class="row">
    <div class="col-12 m- title mb-4 mt-4" translate="information.title"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="information.block1"></div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <div translate="information.voraussetzung_title"></div>
      <ul>
        <li translate="information.voraussetzung_1"></li>
        <li translate="information.voraussetzung_2"></li>
      </ul>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div [innerHTML]="'information.produkte_title' | translate"></div>
      <ul>
        <li translate="information.produkt_1"></li>
        <li translate="information.produkt_2"></li>
        <li translate="information.produkt_3"></li>
        <li translate="information.produkt_4"></li>
        <li translate="information.produkt_5"></li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12" translate="information.produkt_footer"></div>
  </div>
  <div class="row mt-4">
    <div class="col-12" translate="information.block2"></div>
  </div>
  <div class="row mt-5">
    <div
      class="col-12 font-weight-bold"
      translate="information.beschriftung_title"></div>
  </div>
  <div class="row mt-3">
    <div class="col-12" translate="information.beschriftung_text_1"></div>
  </div>
  <div class="row mt-3">
    <div class="col-12" translate="information.beschriftung_text_2"></div>
  </div>
  <div class="row mt-3">
    <div class="col-12" translate="information.beschriftung_text_3"></div>
  </div>

  <div class="row mt-5">
    <div
      class="col-12 font-weight-bold"
      translate="information.kontakt.title"></div>
  </div>
  <div class="row mt-3">
    <div
      class="col-12 font-weight-bold"
      translate="information.kontakt.kapa"></div>
  </div>
  <div class="row mt-2">
    <div class="col-12" translate="information.kontakt.strasse"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="information.kontakt.plz"></div>
  </div>
  <div class="row">
    <div class="col-12" translate="information.kontakt.telefon"></div>
  </div>
  <div class="row">
    <div class="col-12">
      <a
        class="external-link"
        href="{{ 'information.kontakt.link.amt-link' | translate }}"
        >{{ 'information.kontakt.link.amt' | translate }}</a
      >
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <a
        class="external-link"
        href="{{ 'information.kontakt.link.kontakt-link' | translate }}"
        >{{ 'information.kontakt.link.formular' | translate }}</a
      >
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <a href="mailto: info.pad@be.ch">{{
        'information.kontakt.link.email' | translate
      }}</a>
    </div>
  </div>
</div>
