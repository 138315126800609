/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface KompenenteDraftDTO { 
    komponentOrder?: number;
    bezeichnung?: string;
    qualitaet?: KompenenteDraftDTO.QualitaetEnum;
    art?: KompenenteDraftDTO.ArtEnum;
    einheitsMenge?: number;
    einheit?: KompenenteDraftDTO.EinheitEnum;
    bemerkung?: string;
}
export namespace KompenenteDraftDTO {
    export type QualitaetEnum = 'ANDERE' | 'PH_EUR' | 'PH_HELV' | 'HAB';
    export const QualitaetEnum = {
        ANDERE: 'ANDERE' as QualitaetEnum,
        PHEUR: 'PH_EUR' as QualitaetEnum,
        PHHELV: 'PH_HELV' as QualitaetEnum,
        HAB: 'HAB' as QualitaetEnum
    };
    export type ArtEnum = 'HILFSSTOFF_DEKLARATIONSPFLICHTIG' | 'HILFSSTOFF_WEITERER' | 'WIRKSTOFF_DEKLARATIONSPFLICHTIG';
    export const ArtEnum = {
        HILFSSTOFFDEKLARATIONSPFLICHTIG: 'HILFSSTOFF_DEKLARATIONSPFLICHTIG' as ArtEnum,
        HILFSSTOFFWEITERER: 'HILFSSTOFF_WEITERER' as ArtEnum,
        WIRKSTOFFDEKLARATIONSPFLICHTIG: 'WIRKSTOFF_DEKLARATIONSPFLICHTIG' as ArtEnum
    };
    export type EinheitEnum = 'MIKROGRAMM' | 'MILLIGRAMM' | 'GRAMM' | 'KILOGRAMM' | 'MIKROLITER' | 'MILLILITER' | 'LITER' | 'KAPSEL' | 'TABLETTE' | 'ZAEPFCHEN' | 'GLOBULI';
    export const EinheitEnum = {
        MIKROGRAMM: 'MIKROGRAMM' as EinheitEnum,
        MILLIGRAMM: 'MILLIGRAMM' as EinheitEnum,
        GRAMM: 'GRAMM' as EinheitEnum,
        KILOGRAMM: 'KILOGRAMM' as EinheitEnum,
        MIKROLITER: 'MIKROLITER' as EinheitEnum,
        MILLILITER: 'MILLILITER' as EinheitEnum,
        LITER: 'LITER' as EinheitEnum,
        KAPSEL: 'KAPSEL' as EinheitEnum,
        TABLETTE: 'TABLETTE' as EinheitEnum,
        ZAEPFCHEN: 'ZAEPFCHEN' as EinheitEnum,
        GLOBULI: 'GLOBULI' as EinheitEnum
    };
}