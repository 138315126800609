import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  BenutzerApiService,
  BerechtigungAntragApiService,
  BerechtigungAntragDTO,
  BerechtigungApiService,
  BetriebApiService,
  BetriebsleiterChangeAntragApiService,
  UpdateKorrespondenzMailConfigurationDTO,
  UpdateKorrespondenzMailDTO,
  UpdateSubmitterMailConfigurationDTO,
} from '../../../backend';
import { BerechtigungService } from '../../../services/berechtigung.service';
import { BerechtigungDTO } from '../../../backend/model/berechtigungDTO';
import {
  EfoTableComponent,
  SearchConfiguration,
} from '../../efo-table/efo-table.component';
import { BerechtigungUpdateDTO } from '../../../backend/model/berechtigungUpdateDTO';
import { BerechtigungMailConfigurationUpdateDTO } from '../../../backend/model/berechtigungMailConfigurationUpdateDTO';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-betrieb-current-detail',
  templateUrl: './betrieb-current-detail.component.html',
  styleUrl: './betrieb-current-detail.component.scss',
})
export class BetriebCurrentDetailComponent {
  @ViewChildren(EfoTableComponent) // @ts-ignore
  efoBenutzerTableComponent!: QueryList<EfoTableComponent>;

  @Output()
  public reload = new EventEmitter<void>();

  currentBerechtigung?: BerechtigungDTO;
  berechtigungen?: Array<BerechtigungDTO> = [];

  berechtigungenForBetrieb?: Array<BerechtigungDTO> = [];
  berechtigungAntraegeForBetrieb?: Array<BerechtigungAntragDTO> = [];

  totalElementsBenutzer: number = 0;
  totalPagesBenutzer: number = 0;
  numberOfElementsBenutzer: number = 0;

  totalElementsAntraege: number = 0;
  totalPagesAntraege: number = 0;
  numberOfElementsAntraege: number = 0;

  korrespondenzMailMutable = false;
  korrespondenzMail: string = '';

  korrespondenzMail_mailFormelInPruefung = false;
  korrespondenzMail_mailFormelAccepted = false;
  korrespondenzMail_mailFormelRejected = false;

  constructor(
    public betriebApiService: BetriebApiService,
    private readonly berechtigungApiService: BerechtigungApiService,
    private readonly betriebsleiterChangeAntragApiService: BetriebsleiterChangeAntragApiService,
    public readonly berechtigungService: BerechtigungService,
    private readonly benutzerApiService: BenutzerApiService,
    private readonly berechtigungAntragApiService: BerechtigungAntragApiService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.berechtigungService.currentBerechtigung.subscribe((b) => {
      if (b != null) {
        this.currentBerechtigung = b;
        if (b.betrieb?.korrespondenzMail) {
          this.korrespondenzMail = b.betrieb?.korrespondenzMail;
        }
      } else {
        this.currentBerechtigung = undefined;
      }
    });
  }

  ngAfterViewInit() {
    this.berechtigungService.currentBerechtigung.subscribe((b) => {
      if (b != null) {
        if (b && b.betrieb && b.betrieb.id) {
          this.searchAll();
        }
      }
    });
  }

  searchAll() {
    this.efoBenutzerTableComponent.forEach((table) => table.search());
  }

  findAllBerechtigungenOfCurrentBetrieb(
    searchConfiguration: SearchConfiguration,
  ) {
    let betriebId = this.currentBerechtigung?.betrieb?.id;
    if (betriebId) {
      this.berechtigungApiService
        .findAllByBetrieb(
          betriebId,
          searchConfiguration.sortConfiguration.sortBy,
          searchConfiguration.pageConfiguration.pageNumber,
          searchConfiguration.pageConfiguration.pageSize,
          searchConfiguration.sortConfiguration.direction,
        )
        .subscribe((page) => {
          this.berechtigungenForBetrieb = page.content;
          if (page.totalElements) {
            this.totalElementsBenutzer = page.totalElements;
          } else {
            this.totalElementsBenutzer = 0;
          }
          if (page.totalPages) {
            this.totalPagesBenutzer = page.totalPages;
          }
          if (page.numberOfElements) {
            this.numberOfElementsBenutzer = page.numberOfElements;
          }
          this.cdr.detectChanges();
        });
    }
  }

  findAllAntraegeOfCurrentBetrieb(searchConfiguration: SearchConfiguration) {
    let betriebId = this.currentBerechtigung?.betrieb?.id;
    if (betriebId) {
      this.berechtigungAntragApiService
        .findAllByBetriebId(betriebId)
        .subscribe((page) => {
          this.berechtigungAntraegeForBetrieb = page.content;
          if (page.totalElements) {
            this.totalElementsAntraege = page.totalElements;
          } else {
            this.totalElementsAntraege = 0;
          }
          if (page.totalPages) {
            this.totalPagesAntraege = page.totalPages;
          }
          if (page.numberOfElements) {
            this.numberOfElementsAntraege = page.numberOfElements;
          }
          this.cdr.detectChanges();
        });
    }
  }

  getCurrentBetriebName(): string {
    if (
      this.currentBerechtigung &&
      this.currentBerechtigung.betrieb &&
      this.currentBerechtigung.betrieb.name
    ) {
      return this.currentBerechtigung.betrieb.name;
    }
    return '';
  }

  getCurrentKorrespondenzMail(): string {
    if (
      this.currentBerechtigung &&
      this.currentBerechtigung.betrieb &&
      this.currentBerechtigung.betrieb.korrespondenzMail
    ) {
      return this.currentBerechtigung.betrieb.korrespondenzMail;
    }
    return '';
  }

  hasberechtigteInBetrieb() {
    return (
      this.berechtigungenForBetrieb != undefined &&
      this.berechtigungenForBetrieb.length > 0
    );
  }

  deleteFromBetrieb(b: BerechtigungDTO) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = [
      'modal.confirm.delete.betrieb_berechtigung',
    ];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';

    modalRef.result.then((result) => {
      if (result) {
        if (b && b.id) {
          this.berechtigungApiService
            .deleteBetriebBerechtigung(b.id)
            .subscribe(() => this.reload.emit());
        }
      }
    });
  }

  updateRolle(udpate: BerechtigungUpdateDTO) {
    this.berechtigungApiService
      .updateBetriebBerechtigung(udpate)
      .subscribe(() => this.reload.emit());
  }

  changeBetriebsleier(b: BerechtigungDTO) {
    if (b && b.id) {
      this.betriebsleiterChangeAntragApiService
        .create({
          betriebsleiterOld: this.currentBerechtigung?.id,
          betriebsleiterNew: b.id,
        })
        .subscribe(() => {
          const modalRef = this.modalService.open(ErrorModalComponent);
          modalRef.componentInstance.message = ['modal.info.antrag.success'];
          modalRef.componentInstance.type = 'INFO';
          this.reload.emit();
        });
    }
  }

  accept(berechtigungAntrag: BerechtigungAntragDTO) {
    if (berechtigungAntrag && berechtigungAntrag.id) {
      this.berechtigungAntragApiService
        .accept(berechtigungAntrag.id)
        .subscribe(() => this.reload.emit());
    }
  }

  reject(berechtigungAntrag: BerechtigungAntragDTO, remark: string) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = ['modal.confirm.reject.antrag'];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';

    modalRef.result.then((result) => {
      if (result) {
        if (berechtigungAntrag && berechtigungAntrag.id) {
          this.berechtigungAntragApiService
            .reject(berechtigungAntrag.id, remark)
            .subscribe(() => this.reload.emit());
        }
      }
    });
  }

  isBerechtigt() {
    return (
      this.currentBerechtigung != undefined &&
      this.currentBerechtigung.betrieb != undefined
    );
  }

  betriebBenutzerDeletable(berechtigungDTO: BerechtigungDTO) {
    const rolleOfBerechtigung = berechtigungDTO.rolle;
    let isSelf = false;
    if (this.currentBerechtigung) {
      isSelf = berechtigungDTO.id == this.currentBerechtigung.id;
    }
    let currentRolle = this.currentBerechtigung?.rolle;
    let hasRolleToDelete = false;
    if (currentRolle && rolleOfBerechtigung) {
      if (currentRolle == 'ADMINISTRATOR') {
        hasRolleToDelete = true;
      }
      if (
        currentRolle == 'EFO_BETRIEBSLEITER' &&
        (rolleOfBerechtigung == 'EFO_BETRIEBSLEITER_STV' ||
          rolleOfBerechtigung == 'EFO_BETRIEBSMITARBEITER')
      ) {
        hasRolleToDelete = true;
      }
      if (
        currentRolle == 'EFO_BETRIEBSLEITER_STV' &&
        rolleOfBerechtigung == 'EFO_BETRIEBSMITARBEITER'
      ) {
        hasRolleToDelete = true;
      }
    }
    return !isSelf && hasRolleToDelete;
  }

  betriebBenutzerMutable(berechtigungDTO: BerechtigungDTO) {
    const rolleOfBerechtigung = berechtigungDTO.rolle;
    let isSelf = false;
    if (this.currentBerechtigung) {
      isSelf = berechtigungDTO.id == this.currentBerechtigung.id;
    }
    let currentRolle = this.currentBerechtigung?.rolle;
    let hasRolleToUpdate = false;
    if (currentRolle && rolleOfBerechtigung) {
      if (
        (currentRolle == 'EFO_BETRIEBSLEITER' &&
          (rolleOfBerechtigung == 'EFO_BETRIEBSLEITER_STV' ||
            rolleOfBerechtigung == 'EFO_BETRIEBSMITARBEITER')) ||
        currentRolle == 'ADMINISTRATOR'
      ) {
        hasRolleToUpdate = true;
      }
    }
    return !isSelf && hasRolleToUpdate;
  }

  betriebBenutzerAntragAcceptableRejectable(berechtigungDTO: BerechtigungDTO) {
    const rolleOfBerechtigung = berechtigungDTO.rolle;
    let currentRolle = this.currentBerechtigung?.rolle;
    let hasRolleToAcceptReject = false;
    if (currentRolle && rolleOfBerechtigung) {
      if (
        (currentRolle == 'EFO_BETRIEBSLEITER' ||
          currentRolle == 'ADMINISTRATOR') &&
        (rolleOfBerechtigung == 'EFO_BETRIEBSLEITER_STV' ||
          rolleOfBerechtigung == 'EFO_BETRIEBSMITARBEITER')
      ) {
        hasRolleToAcceptReject = true;
      }
      if (
        currentRolle == 'EFO_BETRIEBSLEITER_STV' &&
        rolleOfBerechtigung == 'EFO_BETRIEBSMITARBEITER'
      ) {
        hasRolleToAcceptReject = true;
      }
    }
    return hasRolleToAcceptReject;
  }

  updateKorresponenzMail() {
    const mail = this.korrespondenzMail;
    let upateDTO: UpdateKorrespondenzMailDTO = {
      betriebId: this.currentBerechtigung?.betrieb?.id,
      korrespondenzMail: mail,
    };
    this.betriebApiService.updateMailKorrespondenz(upateDTO).subscribe(() => {
      this.berechtigungService.setKorrespondenzMail(mail);
      this.korrespondenzMailMutable = false;
      const modalRef = this.modalService.open(ErrorModalComponent);
      modalRef.componentInstance.message = [
        'modal.info.mail.korrespondenz.success',
      ];
      modalRef.componentInstance.type = 'INFO';
    });
  }

  updateKorresponenzMailConfiguration(
    mailFormelInPruefung: boolean,
    mailFormelAccepted: boolean,
    mailFormelRejected: boolean,
  ) {
    const mail = this.korrespondenzMail;
    let upateDTO: UpdateKorrespondenzMailConfigurationDTO = {
      betriebId: this.currentBerechtigung?.betrieb?.id,
      mailFormelInPruefung: mailFormelInPruefung,
      mailFormelAccepted: mailFormelAccepted,
      mailFormelRejected: mailFormelRejected,
    };
    this.betriebApiService
      .updateMailKorrespondenzConfiguration(upateDTO)
      .subscribe(() => {
        if (this.currentBerechtigung?.betrieb?.korrespondenzMailConfiguration) {
          this.currentBerechtigung.betrieb.korrespondenzMailConfiguration.mailFormelInPruefung =
            mailFormelInPruefung;
          this.currentBerechtigung.betrieb.korrespondenzMailConfiguration.mailFormelAccepted =
            mailFormelAccepted;
          this.currentBerechtigung.betrieb.korrespondenzMailConfiguration.mailFormelRejected =
            mailFormelRejected;
          this.berechtigungService.setBetrieb(this.currentBerechtigung.betrieb);
          this.searchAll();
          const modalRef = this.modalService.open(ErrorModalComponent);
          modalRef.componentInstance.message = [
            'modal.info.mail.configuration.success',
          ];
          modalRef.componentInstance.type = 'INFO';
        }
      });
  }
  updateMailSubmitterConfiguration(
    mailFormelInPruefung: boolean,
    mailFormelAccepted: boolean,
    mailFormelRejected: boolean,
  ) {
    const mail = this.korrespondenzMail;
    let upateDTO: UpdateSubmitterMailConfigurationDTO = {
      betriebId: this.currentBerechtigung?.betrieb?.id,
      mailFormelInPruefung: mailFormelInPruefung,
      mailFormelAccepted: mailFormelAccepted,
      mailFormelRejected: mailFormelRejected,
    };
    this.betriebApiService
      .updateMailSubmitterConfiguration(upateDTO)
      .subscribe(() => {
        if (this.currentBerechtigung?.betrieb?.submitterMailConfiguration) {
          this.currentBerechtigung.betrieb.submitterMailConfiguration.mailFormelInPruefung =
            mailFormelInPruefung;
          this.currentBerechtigung.betrieb.submitterMailConfiguration.mailFormelAccepted =
            mailFormelAccepted;
          this.currentBerechtigung.betrieb.submitterMailConfiguration.mailFormelRejected =
            mailFormelRejected;
          this.berechtigungService.setBetrieb(this.currentBerechtigung.betrieb);
          this.searchAll();
          const modalRef = this.modalService.open(ErrorModalComponent);
          modalRef.componentInstance.message = [
            'modal.info.mail.configuration.success',
          ];
          modalRef.componentInstance.type = 'INFO';
        }
      });
  }

  updateMailConfigurationForBetrieb(
    berechtiungId: string | undefined,
    mailFormelInPruefung: boolean,
    mailFormelAccepted: boolean,
    mailFormelRejected: boolean,
  ) {
    let upateDTO: BerechtigungMailConfigurationUpdateDTO = {
      berechtigungId: berechtiungId,
      mailFormelInPruefung: mailFormelInPruefung,
      mailFormelAccepted: mailFormelAccepted,
      mailFormelRejected: mailFormelRejected,
    };
    this.berechtigungApiService
      .updateMailConfigurationForBetrieb(upateDTO)
      .subscribe(() => {
        this.searchAll();
        const modalRef = this.modalService.open(ErrorModalComponent);
        modalRef.componentInstance.message = [
          'modal.info.mail.configuration.success',
        ];
        modalRef.componentInstance.type = 'INFO';
      });
  }

  protected readonly BerechtigungUpdateDTO = BerechtigungUpdateDTO;
}
