import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import {
  BewFormComponent,
  BewFormComponentWithValue,
  FormComponentWithDisabled,
  FormComponentWithError,
  FormComponentWithLabel,
} from '../form-component';

@Component({
  selector: 'app-bew-textarea',
  templateUrl: './bew-textarea.component.html',
  styleUrls: ['./bew-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BewTextareaComponent,
    },
  ],
})
export class BewTextareaComponent
  extends BewFormComponentWithValue<string>
  implements
    OnInit,
    FormComponentWithLabel,
    FormComponentWithError,
    FormComponentWithDisabled
{
  @ViewChild('textArea')
  private textArea?: ElementRef;

  @Input()
  label: string = 'NO_LABEL';
  @Input()
  error: string = '';
  @Input()
  maxlength?: number;
  @Input()
  minlength?: number;
  @Input()
  @Output()
  disabled: boolean = false;

  ngOnInit(): void {}

  get invalid(): boolean {
    return this.error.length > 0;
  }

  private readValueDirectlyFromComponent(): string {
    const textAreaRef = this.textArea;
    if (textAreaRef !== undefined) {
      const textArea = textAreaRef.nativeElement;
      if (textArea instanceof HTMLTextAreaElement) {
        return textArea.value;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  // TODO checkts-ignore
  // @ts-ignore
  protected readValueFromControl(): string | undefined {
    return this.readValueDirectlyFromComponent();
  }

  protected initialValue(): string {
    return '';
  }

  set errors(errors: ValidationErrors | null) {
    BewFormComponent.setError(errors, this);
  }
}
