<app-bew-box
  *ngIf="
    berechtigungService.isAdminOrHasAnyRolle([
      'PAD_SACHBEARBEITER',
      'PAD_SEKRETARIAT'
    ]) | async
  "
  label="{{ 'pad.detail.title' | translate }}">
  <app-bew-box label="{{ 'pad.detail.benutzerverwaltung.title' | translate }}">
    <div class="bew-table-container">
      <app-efo-table
        #benutzerTable
        [searchable]="false"
        [pageSize]="1000"
        [totalElements]="totalElementsBenutzer"
        [totalPages]="totalPagesBenutzer"
        [numberOfElements]="numberOfElementsBenutzer"
        (searchContent)="findAllBerechtigungenForPad($event)">
        <tr table-header>
          <th>
            {{ 'pad.detail.benutzerverwaltung.username' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.firstname' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.lastname' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.mail' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.rolle' | translate }}
          </th>
          <th></th>
          <th></th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungenForPad?.length == 0">
            <td colspan="7">
              {{ 'pad.detail.benutzerverwaltung.none' | translate }}
            </td>
          </tr>
          <ng-container *ngFor="let b of berechtigungenForPad; index as i">
            <tr>
              <td>{{ b.benutzer?.username }}</td>
              <td>{{ b.benutzer?.firstName }}</td>
              <td>{{ b.benutzer?.lastName }}</td>
              <td>{{ b.benutzer?.mail }}</td>
              <td>{{ 'enum.rolle.' + b.rolle | translate }}</td>

              <td>
                <button
                  *ngIf="
                    berechtigungService.isAdminOrHasAnyRolle([
                      'PAD_SEKRETARIAT'
                    ]) | async
                  "
                  class="efo-button red"
                  (click)="delete(b)"
                  [ngbTooltip]="tooltipDelete"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td>
                <app-update-rolle
                  *ngIf="
                    berechtigungService.isAdminOrHasAnyRolle([
                      'PAD_SEKRETARIAT'
                    ]) | async
                  "
                  [isPad]="true"
                  [berechtigungId]="b.id"
                  (update)="updateRolle($event)">
                </app-update-rolle>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>

  <app-bew-box
    *ngIf="
      berechtigungService.isAdminOrHasAnyRolle(['PAD_SEKRETARIAT']) | async
    "
    label="{{ 'pad.detail.antraege.pad.title' | translate }}">
    <div class="bew-table-container">
      <app-efo-table
        #antraegeTable
        [searchable]="false"
        [pageSize]="1000"
        [totalElements]="totalElementsAntraegePAD"
        [totalPages]="totalPagesAntraegePAD"
        [numberOfElements]="numberOfElementsAntraegePAD"
        (searchContent)="findAllAntraegeForPad($event)">
        <tr table-header>
          <th>
            {{ 'pad.detail.benutzerverwaltung.username' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.firstname' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.lastname' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.mail' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.rolle' | translate }}
          </th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungAntraegeForPad?.length == 0">
            <td colspan="7">
              {{ 'pad.detail.antraege.pad.none' | translate }}
            </td>
          </tr>
          <ng-container
            *ngFor="let b of berechtigungAntraegeForPad; index as i">
            <tr>
              <td>{{ b.benutzer?.username }}</td>
              <td>{{ b.benutzer?.firstName }}</td>
              <td>{{ b.benutzer?.lastName }}</td>
              <td>{{ b.benutzer?.mail }}</td>
              <td>{{ 'enum.rolle.' + b.rolle | translate }}</td>
            </tr>
            <tr>
              <td colspan="4">
                <app-bew-input
                  #remark
                  [label]="'pad.detail.antraege.pad.remark' | translate">
                </app-bew-input>
              </td>
              <td>
                <button
                  *ngIf="
                    berechtigungService.isAdminOrHasAnyRolle([
                      'PAD_SEKRETARIAT'
                    ]) | async
                  "
                  class="efo-button blue input-row"
                  (click)="accept(b)"
                  [ngbTooltip]="tooltipAccept"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-check"></i>
                </button>
                <button
                  *ngIf="
                    berechtigungService.isAdminOrHasAnyRolle([
                      'PAD_SEKRETARIAT'
                    ]) | async
                  "
                  class="efo-button red input-row"
                  [disabled]="remark.value == ''"
                  (click)="reject(b, remark.value)"
                  [ngbTooltip]="tooltipReject"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-x"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>

  <app-bew-box
    *ngIf="
      berechtigungService.isAdminOrHasAnyRolle(['PAD_SEKRETARIAT']) | async
    "
    label="{{ 'pad.detail.antraege.betriebsleiter.title' | translate }}">
    <div class="bew-table-container">
      <app-efo-table
        #antraegeTable
        [searchable]="false"
        [pageSize]="1000"
        [totalElements]="totalElementsAntraegeBetriebsleiter"
        [totalPages]="totalPagesAntraegeBetriebsleiter"
        [numberOfElements]="numberOfElementsAntraegeBetriebsleiter"
        (searchContent)="findAllAntraegeForBetriebsleiter($event)">
        <tr table-header>
          <th>
            {{ 'pad.detail.benutzerverwaltung.username' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.firstname' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.lastname' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.mail' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.betrieb' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.rolle' | translate }}
          </th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungAntraegeForBetriebsleiter?.length == 0">
            <td colspan="7">
              {{ 'pad.detail.antraege.betriebsleiter.none' | translate }}
            </td>
          </tr>
          <ng-container
            *ngFor="let b of berechtigungAntraegeForBetriebsleiter; index as i">
            <tr>
              <td>{{ b.benutzer?.username }}</td>
              <td>{{ b.benutzer?.firstName }}</td>
              <td>{{ b.benutzer?.lastName }}</td>
              <td>{{ b.benutzer?.mail }}</td>
              <td>{{ b.betrieb?.name }}</td>
              <td>{{ 'enum.rolle.' + b.rolle | translate }}</td>
            </tr>
            <tr>
              <td colspan="5">
                <app-bew-input
                  #remark
                  [label]="
                    'pad.detail.antraege.betriebsleiter.remark' | translate
                  ">
                </app-bew-input>
              </td>
              <td>
                <button
                  class="efo-button blue input-row"
                  (click)="accept(b)"
                  [ngbTooltip]="tooltipAccept"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-check"></i>
                </button>
                <button
                  class="efo-button red input-row"
                  (click)="reject(b, remark.value)"
                  [disabled]="remark.value == ''"
                  [ngbTooltip]="tooltipReject"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-x"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>

  <app-bew-box
    *ngIf="
      berechtigungService.isAdminOrHasAnyRolle(['PAD_SEKRETARIAT']) | async
    "
    label="{{ 'pad.detail.antraege.betriebsleiter.change.title' | translate }}">
    <div class="bew-table-container">
      <app-efo-table
        #betriebsleiterChangeTable
        [searchable]="false"
        [pageSize]="1000"
        [totalElements]="totalElementsAntraegeBetriebsleiterChange"
        [totalPages]="totalPagesAntraegeBetriebsleiterChange"
        [numberOfElements]="numberOfElementsAntraegeBetriebsleiterChange"
        (searchContent)="findAllAntraegeForBetriebsleiterChange($event)">
        <tr table-header>
          <th>
            {{
              'pad.detail.antraege.betriebsleiter.change.old.username'
                | translate
            }}
          </th>
          <th>
            {{
              'pad.detail.antraege.betriebsleiter.change.old.rolle' | translate
            }}
          </th>

          <th>
            {{
              'pad.detail.antraege.betriebsleiter.change.old.username'
                | translate
            }}
          </th>
          <th>
            {{
              'pad.detail.antraege.betriebsleiter.change.old.rolle' | translate
            }}
          </th>
          <th>
            {{
              'pad.detail.antraege.betriebsleiter.change.betrieb' | translate
            }}
          </th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungAntraegeForBetriebsleiterChange?.length == 0">
            <td colspan="7">
              {{ 'pad.detail.antraege.betriebsleiter.change.none' | translate }}
            </td>
          </tr>
          <ng-container
            *ngFor="
              let b of berechtigungAntraegeForBetriebsleiterChange;
              index as i
            ">
            <tr>
              <td>
                {{ b.betriebsleiterOld?.benutzer?.firstName }}
                {{ b.betriebsleiterOld?.benutzer?.lastName }}
              </td>
              <td>
                {{ 'enum.rolle.' + b.betriebsleiterOld?.rolle | translate }}
              </td>
              <td>
                {{ b.betriebsleiterNew?.benutzer?.firstName }}
                {{ b.betriebsleiterNew?.benutzer?.lastName }}
              </td>
              <td>
                {{ 'enum.rolle.' + b.betriebsleiterNew?.rolle | translate }}
              </td>
              <td>{{ b.betriebsleiterOld?.betrieb?.name }}</td>
            </tr>
            <tr>
              <td colspan="4">
                <app-bew-input
                  #remark
                  [label]="
                    'pad.detail.antraege.betriebsleiter.change.remark'
                      | translate
                  ">
                </app-bew-input>
              </td>
              <td>
                <button
                  class="efo-button blue input-row"
                  (click)="acceptBetriebsleiterChange(b)"
                  [ngbTooltip]="tooltipAccept"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-check"></i>
                </button>
                <button
                  class="efo-button red input-row"
                  (click)="rejectBetriebsleiterChange(b, remark.value)"
                  [disabled]="remark.value == ''"
                  [ngbTooltip]="tooltipReject"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-x"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>

  <app-bew-box
    *ngIf="
      berechtigungService.isAdminOrHasAnyRolle(['PAD_SACHBEARBEITER']) | async
    "
    label="{{
      'pad.detail.benutzerverwaltung.betriebsleiter.delete.title' | translate
    }}">
    <div class="bew-table-container">
      <app-efo-table
        #betriebsleiterDeleteTable
        [pageSize]="5"
        [totalElements]="totalElementsBerechtigungBetriebsleiter"
        [totalPages]="totalPagesBerechtigungBetriebsleiter"
        [numberOfElements]="numberOfElementsBerechtigungBetriebsleiter"
        (searchContent)="findAllBetriebsleiter($event)">
        <tr table-header>
          <th>
            {{ 'pad.detail.benutzerverwaltung.username' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.firstname' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.lastname' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.betrieb' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.strasse' | translate }}
          </th>
          <th>
            {{ 'pad.detail.benutzerverwaltung.ort' | translate }}
          </th>
          <th></th>
        </tr>
        <tbody table-body>
          <tr *ngIf="berechtigungenBetriebsleiter?.length == 0">
            <td colspan="7">
              {{ 'pad.detail.antraege.betriebsleiter.change.none' | translate }}
            </td>
          </tr>
          <ng-container
            *ngFor="let b of berechtigungenBetriebsleiter; index as i">
            <tr>
              <td>{{ b.benutzer?.username }}</td>
              <td>{{ b.benutzer?.firstName }}</td>
              <td>{{ b.benutzer?.lastName }}</td>
              <td>{{ b.betrieb?.name }}</td>
              <td>{{ b.betrieb?.strasse }}</td>
              <td>{{ b.betrieb?.ort }}</td>
              <td>
                <button
                  class="efo-button red"
                  (click)="deleteBetriebsleiter(b)"
                  [ngbTooltip]="tooltipDelete"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>
</app-bew-box>

<ng-template #tooltipDelete>
  <div [innerHTML]="'tooltip.delete' | translate"></div>
</ng-template>

<ng-template #tooltipAccept>
  <div [innerHTML]="'tooltip.accept' | translate"></div>
</ng-template>

<ng-template #tooltipReject>
  <div [innerHTML]="'tooltip.reject' | translate"></div>
</ng-template>
