import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BerechtigungAntragDTO } from '../../backend';
import { BerechtigungUpdateDTO } from '../../backend/model/berechtigungUpdateDTO';
import RolleEnum = BerechtigungAntragDTO.RolleEnum;

@Component({
  selector: 'app-update-rolle',
  templateUrl: './update-rolle.component.html',
  styleUrl: './update-rolle.component.scss',
})
export class UpdateRolleComponent {
  rollen: Array<RolleEnum> = [
    RolleEnum.EFOBETRIEBSLEITERSTV,
    RolleEnum.EFOBETRIEBSMITARBEITER,
  ];
  rolle: RolleEnum = RolleEnum.EFOBETRIEBSMITARBEITER;

  @Output()
  public update = new EventEmitter<BerechtigungUpdateDTO>();

  @Input()
  berechtigungId: string | undefined;
  @Input()
  isPad: boolean = false;
  @Input()
  isAdmin: boolean = false;

  ngOnInit() {
    if (this.isPad) {
      this.rolle = RolleEnum.PADSACHBEARBEITER;
      this.rollen = [RolleEnum.PADSACHBEARBEITER, RolleEnum.PADSEKRETARIAT];
    } else if (this.isAdmin) {
      this.rolle = RolleEnum.EFOBETRIEBSMITARBEITER;
      this.rollen = [
        RolleEnum.EFOBETRIEBSLEITER,
        RolleEnum.EFOBETRIEBSLEITERSTV,
        RolleEnum.EFOBETRIEBSMITARBEITER,
      ];
    }
  }

  berechtigungBeantragen() {
    this.update.emit({
      berechtigungId: this.berechtigungId,
      rolle: this.rolle,
    });
  }
}
