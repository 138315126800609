import { Component, EventEmitter, Output } from '@angular/core';
import {
  BerechtigungAntragApiService,
  BerechtigungAntragCreateDTO,
  BerechtigungAntragDTO,
} from '../../../../backend';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../../../error-modal/error-modal.component';
import RolleEnum = BerechtigungAntragDTO.RolleEnum;

@Component({
  selector: 'app-berechtigung-beantragen-pad',
  templateUrl: './berechtigung-beantragen-pad.component.html',
  styleUrl: './berechtigung-beantragen-pad.component.scss',
})
export class BerechtigungBeantragenPadComponent {
  @Output()
  public reload = new EventEmitter<void>();

  padRollen: RolleEnum[] = [
    RolleEnum.PADSACHBEARBEITER,
    RolleEnum.PADSEKRETARIAT,
  ];
  selectedRolle: RolleEnum = RolleEnum.PADSACHBEARBEITER;

  constructor(
    private readonly berechtigungAntragApiService: BerechtigungAntragApiService,
    private modalService: NgbModal,
  ) {}

  berechtigungBeantragen() {
    let createDTO: BerechtigungAntragCreateDTO = {
      betriebId: undefined,
      rolle: this.selectedRolle,
    };
    this.berechtigungAntragApiService.create(createDTO).subscribe((id) => {
      const modalRef = this.modalService.open(ErrorModalComponent);
      modalRef.componentInstance.message = ['modal.info.antrag.success'];
      modalRef.componentInstance.type = 'INFO';
      this.reload.emit();
    });
  }
}
