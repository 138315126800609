/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileDTO } from './fileDTO';
import { KompenenteDraftDTO } from './kompenenteDraftDTO';

export interface ZusammensetzungDraftDTO { 
    id?: string;
    chargengroesse?: number;
    chargengroesseEinheit?: ZusammensetzungDraftDTO.ChargengroesseEinheitEnum;
    komponenten?: Array<KompenenteDraftDTO>;
    files?: Array<FileDTO>;
    notes?: string;
}
export namespace ZusammensetzungDraftDTO {
    export type ChargengroesseEinheitEnum = 'MIKROGRAMM' | 'MILLIGRAMM' | 'GRAMM' | 'KILOGRAMM' | 'MIKROLITER' | 'MILLILITER' | 'LITER';
    export const ChargengroesseEinheitEnum = {
        MIKROGRAMM: 'MIKROGRAMM' as ChargengroesseEinheitEnum,
        MILLIGRAMM: 'MILLIGRAMM' as ChargengroesseEinheitEnum,
        GRAMM: 'GRAMM' as ChargengroesseEinheitEnum,
        KILOGRAMM: 'KILOGRAMM' as ChargengroesseEinheitEnum,
        MIKROLITER: 'MIKROLITER' as ChargengroesseEinheitEnum,
        MILLILITER: 'MILLILITER' as ChargengroesseEinheitEnum,
        LITER: 'LITER' as ChargengroesseEinheitEnum
    };
}