/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BehaelterGroesseDraftDTO { 
    packungsinhalt?: string;
    einheit?: BehaelterGroesseDraftDTO.EinheitEnum;
    artMaterialBehaelter?: string;
    bemerkung?: string;
}
export namespace BehaelterGroesseDraftDTO {
    export type EinheitEnum = 'MILLIGRAMM' | 'GRAMM' | 'KILOGRAMM' | 'MILLILITER' | 'LITER' | 'KAPSEL' | 'TABLETTE' | 'ZAEPFCHEN' | 'GLOBULI';
    export const EinheitEnum = {
        MILLIGRAMM: 'MILLIGRAMM' as EinheitEnum,
        GRAMM: 'GRAMM' as EinheitEnum,
        KILOGRAMM: 'KILOGRAMM' as EinheitEnum,
        MILLILITER: 'MILLILITER' as EinheitEnum,
        LITER: 'LITER' as EinheitEnum,
        KAPSEL: 'KAPSEL' as EinheitEnum,
        TABLETTE: 'TABLETTE' as EinheitEnum,
        ZAEPFCHEN: 'ZAEPFCHEN' as EinheitEnum,
        GLOBULI: 'GLOBULI' as EinheitEnum
    };
}