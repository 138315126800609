import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  BewFormComponent,
  BewFormComponentWithValue,
  FormComponentWithDisabled,
  FormComponentWithError,
  FormComponentWithLabel,
} from '../form-component';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'app-bew-input',
  templateUrl: './bew-input.component.html',
  styleUrls: ['./bew-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BewInputComponent,
    },
  ],
})
export class BewInputComponent
  extends BewFormComponentWithValue<string>
  implements
    OnInit,
    FormComponentWithError,
    FormComponentWithDisabled,
    FormComponentWithLabel,
    ControlValueAccessor
{
  @Input()
  label: string = 'NO_LABEL';
  @Input()
  error: string = '';
  @Input()
  maxlength?: number;
  @Input()
  minlength?: number;
  @Input()
  pattern?: string;
  @Input()
  @Output()
  disabled: boolean = false;
  @Input()
  valueRequired: boolean = false;
  @Input()
  validateOnTouch: boolean = false;
  @Input()
  results: any[] = [];

  @Output() selectResult = new EventEmitter<any>();
  @Output() inputBlurred: EventEmitter<FocusEvent> =
    new EventEmitter<FocusEvent>();

  /**
   * The type of the text field, such as "number", "password" or "date".
   */
  @Input()
  type: string = 'text';
  @Input()
  decimal: boolean = false;
  @Input()
  integer: boolean = false;

  // @ts-ignore
  @ViewChild('bewInput') bewInput: ElementRef;

  regex: RegExp = new RegExp(/^.*?$/);
  decimalRegex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/);
  integerRegex: RegExp = new RegExp(/^\d*\.?$/);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.decimal || this.integer) {
      this.bewInput.nativeElement.addEventListener(
        'keydown',
        this.onKeyDown.bind(this),
      );
      if (this.decimal) {
        this.regex = this.decimalRegex;
      }
      if (this.integer) {
        this.regex = this.integerRegex;
      }
    }
  }

  invalid: boolean = false;

  showResults: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      if (this.value != '') {
        this.validate();
      }
    }
  }

  doShowResults() {
    if (this.results.length > 0) {
      this.showResults = true;
    }
  }

  selectInputResult(result: any) {
    this.selectResult.next(result);
    this.showResults = false;
  }
  onInputTouched() {
    if (this.validateOnTouch) {
      this.validate();
    }
    this.onTouched();
  }

  onInputValueChanged() {
    this.validate();
    this.onValueChanged();
    if (this.value != '') {
      this.doShowResults();
    } else {
      this.showResults = false;
    }
  }

  validate(): boolean {
    let invalid: boolean = false;
    if (this.valueRequired && this.value == '') {
      invalid = true;
    }
    if (this.error !== undefined && this.error !== null && invalid) {
      this.invalid = true;
      return this.error.length > 0;
    } else {
      this.invalid = false;

      return false;
    }
  }

  protected initialValue(): string {
    return '';
  }

  @Input()
  set errors(errors: ValidationErrors | null) {
    BewFormComponent.setError(errors, this);
  }

  onKeyDown(event: KeyboardEvent) {
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      (event.ctrlKey && (event.key === 'v' || event.key === 'V')) ||
      (event.ctrlKey && (event.key === 'c' || event.key === 'C'))
    ) {
      return;
    }
    const current: string = this.bewInput.nativeElement.value;
    const selectionStart = this.bewInput.nativeElement.selectionStart;
    const selectionEnd = this.bewInput.nativeElement.selectionEnd;
    const next: string =
      current.substring(0, selectionStart) +
      event.key +
      current.substring(selectionEnd);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    this.showResults = false;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (this.decimal || this.integer) {
      const clipboardData = event.clipboardData;
      const pastedInput = clipboardData?.getData('text/plain') ?? '';
      if (pastedInput.match(this.regex)) {
        this.value = '';
      } else {
        event.preventDefault();
      }
    }
  }
}
