<div class="row">
  <div
    class="col image-teaser-anmeldung-be-login col-md-12"
    (click)="loginBeLogin()">
    <span class="teaser-label" translate="home.belogin.title"></span>
    <p class="teaser-small" translate="home.belogin.description"></p>
  </div>
  <div class="col image-teaser-anmeldung col-md-12" (click)="login()">
    <span class="teaser-label" translate="home.login.title"></span>
    <p class="teaser-small" translate="home.login.description"></p>
  </div>
</div>
