import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { PersistedValue } from '../data/persisted-value';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';
import { BenutzerApiService } from '../backend';
import { BenutzerDTO } from '../backend/model/benutzerDTO';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { PathAsString } from '../data/enhanced-route';
import { routes } from '../app-routing.module';
import { BerechtigungService } from './berechtigung.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private discoveryDocument;
  private parsedToken;

  userInfo: any;

  private userApiSelfSubscription?: Subscription;
  private readonly isAuthenticatedSubject$ = new BehaviorSubject<boolean>(
    false,
  );
  // We have to keep that state in the local storage, since after logout the app will be reloaded and thus
  // everything in memory will be lost. So a logout is initiated; this value is set to 'true'; then the oauth service
  // performs the logout and redirects to ("/") - this will reload the app; then the app tries to get the discovery document
  // (and when this happens, it will navigate to the post logout url) (similar mechanism is found in HPV).
  private readonly navigateToPostLogoutUrlOnce = new PersistedValue<boolean>(
    'login_service.navigate_to_post_logout_url',
    () => false,
  );

  constructor(
    private readonly oauthService: OAuthService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly logger: NGXLogger,
    private readonly benutzerApi: BenutzerApiService,
    private readonly userService: UserService,
    private readonly berechtigungService: BerechtigungService,
  ) {
    this.oauthService.events.subscribe((event) => {
      this.onOAuthServiceEvent(event);
    });

    oauthService.issuer = environment.openIdConnect.issuer;
    oauthService.clientId = environment.openIdConnect.clientId;
    oauthService.scope = 'openid profile email';
    oauthService.redirectUri =
      window.location.origin + location.prepareExternalUrl('/');
    oauthService.strictDiscoveryDocumentValidation = false; // google does not agree that IAM endpoints must be on the same domain
    oauthService.tokenValidationHandler = new JwksValidationHandler();
    oauthService.setupAutomaticSilentRefresh();
    oauthService.loadDiscoveryDocumentAndTryLogin();

    this.discoveryDocument = oauthService.loadDiscoveryDocument();
    // try parsing the token(s) when the auth-server redirects back to us
    this.parsedToken = this.discoveryDocument
      .then(() =>
        this.oauthService.tryLogin({
          onTokenReceived: (info) => {
            router.navigateByUrl(info.state!, { replaceUrl: true });
          },
        }),
      )
      .then(() => {
        this.userInfo = oauthService.getIdentityClaims();
      });

    if (oauthService.hasValidAccessToken()) {
      this.loadUserInfo();
    }
  }

  /**
   * This is called by the auth guard.
   */
  public canActivateForAuthGuard(): Observable<boolean> {
    if (this.oauthService.hasValidIdToken()) {
      return of(true);
    }

    this.oauthService.initLoginFlow();
    return of(false);
  }

  public isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  private static returnUriIfItLooksValid(
    uriCandidate: string | any,
  ): string | undefined {
    if (typeof uriCandidate === 'string') {
      if (uriCandidate.length > 0) {
        return uriCandidate;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
  ngOnInit() {
    if (this.isLoggedIn()) {
      this.login();
    }
  }

  public login(): Promise<true> {
    this.oauthService.initImplicitFlow();
    return new Promise<true>((resolve, reject) => {}); // forever unresolved (because we're navigating away)
  }
  loginBeLogin() {
    this.navigateToPostLogoutUrlOnce.value = false;
    this.oauthService.initImplicitFlow(undefined, { kc_idp_hint: 'be-login' });
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  private loadUserInfo() {
    // unregister (if not, we'd get more and more subscriptions).
    if (this.userApiSelfSubscription !== undefined) {
      this.userApiSelfSubscription.unsubscribe();
      this.userApiSelfSubscription = undefined;
    }
    this.userApiSelfSubscription = this.benutzerApi
      .self()
      .subscribe((benutzerDto: BenutzerDTO) => {
        const isLoggedInBeforeSettingUser = this.userService.isLoggedIn;
        if (!isLoggedInBeforeSettingUser) {
          this.router.navigate([PathAsString.get(routes.informationen)]);
        }
        this.userService.setUserLoggedIn(benutzerDto);
        this.berechtigungService.selectFirstBerechtigung();
      });
  }

  private onOAuthServiceEvent(event: OAuthEvent) {
    this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    const type = event.type;
    switch (type) {
      case 'token_received':
        this.loadUserInfo();
        break;
      case 'discovery_document_loaded':
        this.maybeNavigateToPostLogoutUrl();
        break;
      default:
        break;
    }
  }

  private maybeNavigateToPostLogoutUrl() {
    const navigateToPostLogoutUrl = this.navigateToPostLogoutUrlOnce.value;
    if (navigateToPostLogoutUrl) {
      // only navigate *once*.
      this.navigateToPostLogoutUrlOnce.value = false;
      const beLoginLogoutUrl = LoginService.returnUriIfItLooksValid(
        environment.beLoginLogoutUrl,
      );
      if (beLoginLogoutUrl !== undefined) {
        this.navigateToPostLogoutUrl(beLoginLogoutUrl);
      } else {
        this.logger.debug(
          'Not navigating to a post logout url (since none is set in the environment).',
        );
      }
    }
  }

  private navigateToPostLogoutUrl(url: string) {
    this.logger.debug(`Navigating to post logout url ${url}.`);
    window.location.href = url;
  }
}
