import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export function authModuleConfig(): OAuthModuleConfig {
  return {
    resourceServer: {
      allowedUrls: [environment.apiUrl],
      sendAccessToken: true,
    },
  };
}

export function authModuleStorage() {
  // use the same storage as the gef-portal to sync tokens
  return window.localStorage;
}
