<app-bew-box [label]="'berechtigung.current.title' | translate">
  <div class="bew-table-container">
    <app-efo-table
      #berechtigungCurrentTable
      [searchable]="false"
      [totalElements]="totalElements"
      [totalPages]="totalPages"
      [numberOfElements]="numberOfElements"
      (searchContent)="findAllBerechtigungen($event)">
      <tr table-header>
        <th>
          {{ 'betrieb.stammdaten.name' | translate }}
        </th>
        <th>
          {{ 'betrieb.stammdaten.ort' | translate }}
        </th>
        <th>
          {{ 'betrieb.stammdaten.beproid' | translate }}
        </th>
        <th>
          {{ 'betrieb.stammdaten.gln' | translate }}
        </th>
        <th>
          {{ 'berechtigung.overview.rolle' | translate }}
        </th>
      </tr>
      <tbody table-body>
        <tr *ngIf="berechtigungen?.length == 0">
          <td colspan="5">
            {{ 'berechtigung.current.none' | translate }}
          </td>
        </tr>
        <ng-container
          *ngFor="let berechtigung of berechtigungen; let i = index">
          <tr
            (click)="selectRow(berechtigung, i)"
            class="berechtigung_item"
            [class.selected]="selectedIndex === i">
            <td>{{ berechtigung.betrieb?.name }}</td>
            <td>{{ berechtigung.betrieb?.ort }}</td>
            <td>{{ berechtigung.betrieb?.beproId }}</td>
            <td>{{ berechtigung.betrieb?.gln }}</td>
            <td>{{ 'enum.rolle.' + berechtigung.rolle | translate }}</td>
          </tr>
        </ng-container>
      </tbody>
    </app-efo-table>
  </div>
</app-bew-box>
