<app-bew-multi-upload
  [disabled]="disabled"
  [(files)]="files"
  [label]="label"
  [noFilesText]="noFilesText"
  [numberOfFilesTextProviderFunction]="numberOfFilesTextProviderFunction"
  [uploadFunction]="uploadFunction"
  [downloadFileAtIndexFunction]="downloadFunction"
  [previewFileAtIndexFunction]="previewFunction"
  [withFilesText]="withFilesText"
  dropHereText="{{ 'multiFileUploadComponent.dropHereText' | translate }}"
  uploadFileButtonText="{{
    'multiFileUploadComponent.uploadFileButtonText' | translate
  }}"
  uploadingText="{{ 'multiFileUploadComponent.uploadingText' | translate }}">
  <!-- Upload error -->
  <app-bew-notification
    [(visible)]="showUploadError"
    [exception]="uploadErrorTechnicalDetail"
    dismissButtonLabel="{{
      'multiFileUploadComponent.uploadErrorDismissButtonLabel' | translate
    }}"
    label="{{ 'multiFileUploadComponent.uploadErrorTitle' | translate }}"
    showTechnicalInfoText="{{
      'multiFileUploadComponent.uploadErrorShowTechnicalErrorText' | translate
    }}"
    text="{{ 'multiFileUploadComponent.uploadErrorText' | translate }}"
    type="error">
  </app-bew-notification>
  <!-- Rejected file (too big; wrong mime type; ...) -->
  <app-bew-notification
    [(visible)]="showRejectedFile"
    [text]="rejectedFileText"
    dismissButtonLabel="{{
      'multiFileUploadComponent.rejectedFileDismissButton' | translate
    }}"
    label="{{ 'multiFileUploadComponent.rejectedFileTitle' | translate }}"
    type="warning">
  </app-bew-notification>
  <!-- Undesired file (... but still accepted) -->
  <app-bew-notification
    [(visible)]="showUndesiredFile"
    [canBeDismissed]="true"
    [text]="undesiredFileText"
    dismissButtonLabel="{{
      'multiFileUploadComponent.undesiredFileDismissButton' | translate
    }}"
    label="{{ 'multiFileUploadComponent.undesiredFileTitle' | translate }}"
    type="info">
  </app-bew-notification>
</app-bew-multi-upload>
