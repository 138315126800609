{
  "table": {
    "search": "Suche"
  },
  "navigation": {
    "login": "Login",
    "logout": "Abmelden ({{firstName}} {{lastName}})",
    "registration": "Registration",
    "contact": "Contact",
    "menu": {
      "berechtigungen": "Berechtigungen",
      "informationen": "Informationen",
      "formeln": "Formeln",
      "entwuerfe": "Entwürfe"
    }
  },
  "global": {
    "hint": "Hinweis",
    "warning": "Warnung",
    "abort": "Abbrechen"
  },
  "home": {
    "title": "home",
    "login": {
      "title": "Anmeldung",
      "description": "Login Intern"
    },
    "belogin": {
      "title": "Anmeldung BE-Login",
      "description": "BE-Login"
    }
  },
  "berechtigung": {
    "overview": {
      "title": "Berechtigungen",
      "rolle": "Rolle"
    },
    "current": {
      "title": "Meine Berechtigungen",
      "none": "Keine Berechtigungen. Sie können weiter unten einen Antrag stellen."
    }
  },
  "betrieb": {
    "admin": {
      "title": "Betrieb auswählen",
      "none": "Keine Betriebe vorhanden"
    },
    "overview": {
      "title": "Betriebe"
    },
    "stammdaten": {
      "title": "Stammdaten",
      "name": "Name",
      "name2": "Name2",
      "beproid": "Bepro Id",
      "bur": "BUR",
      "uid": "UID",
      "gln": "GLN",
      "betriebsart": "Betriebsart",
      "telefon": "Telefon",
      "mail": "Mail",
      "postfach": "Postfach",
      "strasse": "Strasse",
      "plz": "Plz",
      "ort": "Ort",
      "korrespondenzsprache": "Korrespondenzsprache"
    },
    "rechnungsaddresse": {
      "title": "Rechnungsaddresse",
      "name": "Name",
      "name2": "Name2",
      "zusatz": "Zusatz 1",
      "zusatz2": "Zusatz 2",
      "postfach": "Postfach",
      "strasse": "Strasse",
      "plz": "Plz",
      "ort": "Ort",
      "country": "Land"
    },
    "korrespondenz": {
      "title": "Korrespondenzmail"
    },
    "benutzerverwaltung": {
      "title": "Benutzerverwaltung",
      "none": "Keine Benutzer im Betrieb",
      "username": "Benutzername",
      "firstname": "Vorname",
      "lastname": "Nachname",
      "mail": "Mail",
      "rolle": "Rolle"
    },
    "mailconfiguration": {
      "title": "Mail Konfiguration",
      "pruefung": "Mail Einreichung",
      "accept": "Mail Meldebestätigung",
      "reject": "Mail Zurückgewiesen",
      "korrespondenzmail": "Korrespondenzmail",
      "submitter": "Einreichender Benutzer"
    },
    "antraege": {
      "title": "Betriebsmitarbeiteranträge",
      "none": "Keine Anträge für den Betrieb vorhanden",
      "remark": "Bemerkung"
    }
  },
  "pad": {
    "detail": {
      "title": "Pharmazeutischer Dienst",
      "benutzerverwaltung": {
        "title": "Benutzerverwaltung",
        "none": "Keine Benutzer im Pharmazeutischen Dienst",
        "username": "Benutzername",
        "firstname": "Vorname",
        "lastname": "Nachname",
        "mail": "Mail",
        "betrieb": "Betrieb",
        "ort": "Ort",
        "strasse": "Strasse",
        "rolle": "Rolle",
        "betriebsleiter": {
          "delete": {
            "title": "Betriebsleiter löschen"
          }
        }
      },
      "antraege": {
        "pad": {
          "title": "Anträge Pharmazeutischer Dienst",
          "none": "Keine Anträge für den Pharmazeutischen Dienst vorhanden",
          "remark": "Bemerkung"
        },
        "betriebsleiter": {
          "title": "Anträge Betiebsleiter",
          "none": "Keine Anträge für Betriebsleiter vorhanden",
          "remark": "Bemerkung",
          "change": {
            "title": "Anträge Betiebsleiter Wechsel",
            "none": "Keine Anträge für Betriebsleiter Wechsel vorhanden",
            "remark": "Bemerkung",
            "old": {
              "username": "Betriebsleiter Alt",
              "rolle": "Rolle"
            },
            "new": {
              "username": "Betriebsleiter Neu",
              "rolle": "Rolle"
            },
            "betrieb": "Betrieb"
          }
        }
      }
    }
  },
  "antrag": {
    "overview": {
      "title": "Berechtigungsanträge"
    },
    "current": {
      "title": "Meine Berechtigungsanträge",
      "none": "Keine Anträge vorhanden. Sie können weiter unten einen Antrag stellen."
    },
    "betrieb": {
      "title": "Berechtigung für Betrieb beantragen"
    },
    "pad": {
      "title": "Berechtigung für Pharmazeutischen Dienst beantragen",
      "rolle": "Rolle für Pharmazeutischer Dienst"
    }
  },
  "formel": {
    "overview": {
      "title": "Formeln",
      "draft": "Entwürfe",
      "preaparat": {
        "name": "Präparat Name",
        "art": "Präparat Art"
      },
      "update": {
        "username": "Geändert von",
        "date": "Geändert am"
      },
      "state": "Status",
      "pruefer": "PAD",
      "edit": "Bearbeiten",
      "create": {
        "username": "Erstellt von",
        "date": "Erstellt am"
      },
      "betrieb": {
        "name": "Betrieb"
      },
      "none": "Keine Formeln vorhanden",
      "pad": {
        "all": {
          "title": "Alle Formeln (PAD)"
        },
        "pruefung": {
          "title": "Formeln in Bearbeitung (PAD)",
          "self": {
            "title": "Meine Formeln in Bearbeitung (PAD)"
          }
        }
      }
    },
    "detail": {
      "title": {
        "create": "Formel erstellen",
        "edit": "Formel bearbeiten",
        "view": "Formel Ansicht",
        "pruefung": "Formel bearbeiten PAD"
      },
      "betrieb": {
        "notes": {
          "title": "Bemerkung für PAD"
        }
      },
      "allgemein": {
        "title": "Allgemeine Angaben",
        "preaparat": {
          "name": {
            "title": "Präparat Name",
            "tooltip": "Der Name (und die Indikation) des Präparates ist an die Abgabekompetenz des Betriebes bzw. der Person geknüpft, deshalb darf der Name nicht auf eine Indikation hinweisen, die ausserhalb der Abgabekompetenz liegt. So darf z.B. keine Bezeichnung gewählt werden, die auf eine Indikation hinweist, die eine vorgängige ärztliche Diagnose und Verschreibung erfordert.<ul><li>Nicht erlaubt z.B.: Diabetestropfen</li><li>Erlaubt: Zur Unterstützung/Ergänzung bei der Behandlung von Diabetes.</li></ul>Die Namensgebung sollte auch nicht „marktschreierisch“ sein und keine „nicht-medizinische“ etc. Angaben verwenden. Negative Beispiele: Jodler Tropfen, Silvester-Kater-Spray, Entschlackungs-Tee<br>Referenzen: Art. 9 Abs. 2 Bst. c Heilmittelgesetz (HMG) <br>Ph.Helv. 17.2.4<br>Positionspapier 0010 Anerkannte Fachliteratur zur Herstellung von Arzneimitteln nach „Eigener Formel“ (Kantonsapothekervereinigung Schweiz)"
          },
          "art": {
            "title": "Präparat Art",
            "tooltip": "Die Art des Präparates muss – mit Ausnahme von allopathischen Arzneimitteln – auf der Etikette deklariert werden.<br>Begrifflich gelten die entsprechenden Definitionen der Komplementär – und Phytoarzneimittelverordnung (KPAV, SR 812.212.24, Art. 4 Begriffe)<br>Die Bezeichnung und Beschriftung muss den Anforderungen der aktuellen Ph.Helv. entsprechen (Kapitel 17.1.4) bzw. im Bereich Komplementärmedizin den entsprechenden Anforderungen der KPAV. <br>Grundsätzlich ist das Vermischen verschiedener Therapieformen nicht erlaubt. Sollte davon abgewichen werden, muss begründet und dokumentiert werden, wie die Prinzipien der entsprechenden Therapierichtungen beachtet werden."
          }
        },
        "indikation": {
          "title": "Indikation",
          "tooltip": "Die Indikation des Präparates ist an die Abgabekompetenz des Betriebes bzw. der Person geknüpft, deshalb darf auf keine Indikation hingewiesen werden, die ausserhalb der Abgabekompetenz liegt. So darf z.B. keine Bezeichnung gewählt werden, die auf eine Indikation hinweist, die eine vorgängige ärztliche Diagnose und Verschreibung erfordert (siehe auch Text unter Name des Präparates). <ul><li>Nicht erlaubt z.B.: Bei oder gegen Diabetes</li> <li>Erlaubt: Zur Unterstützung/Ergänzung bei der Behandlung von Diabetes.</li></ul>Bei der Angabe der Indikationen müssen die entsprechende Fachliteratur sowie die Indikationen bei ähnlichen von Swissmedic zugelassenen Arzneimitteln berücksichtigt werden (vgl. auch Positionspapier 0010 Kapitel 3.1.2)."
        },
        "dosierungsvorschriften": {
          "title": "Dosierungsvorschriften",
          "tooltip": "Bei den Angaben über die Anwendung und Dosierung sind die Vorgaben der Swissmedic und der aktuellen Ph. Helv. zu berücksichtigen.."
        },
        "darreichungsform": {
          "title": "Darreichungsform",
          "tooltip": "Bei der Beschriftung muss auch die Darreichungsform erwähnt werden. Ist die Bezeichnung des Arzneimittels bereits eindeutig (z.B. Hustensirup) kann auf eine zusätzliche Bezeichnung der Darreichungsform verzichtet werden. Weitere Informationen für die einzelnen Darreichungsformen sind den entsprechenden «Monographien zu Darreichungsformen» der Ph.Helv. bzw. Ph.Eur. zu entnehmen."
        },
        "lohnherstellung": {
          "title": "Lohnherstellung",
          "tooltip": "Falls das Produkt von einem anderen Betrieb mit entsprechender Herstellungsbewilligungen (Apotheke, Drogerie, Betrieb mit einer Herstellungsbewilligung von Swissmedic) hergestellt wird, muss dieser Punkt mit ja beantwortet werden.<br>Bitte beachten Sie, dass die Lohnherstellung vertraglich geregelt werden muss. Entsprechende Informationen und Vorlagen finden Sie im Positionspapier H 006.002 «Lohnherstellung nicht zulassungspflichtiger Arzneimittel…» der Kantonsapothekervereinigung Nordwestschweiz»"
        },
        "lohnhersteller": {
          "betriebsname": "Betriebsname",
          "betriebsart": "Betriebsart",
          "strasse": "Strasse",
          "plz": "PLZ",
          "ort": "Ort"
        },
        "file": {
          "info": "Hier können Sie z.B. Dokumente zum Entwicklungsrapport hochladen. Bitte laden Sie keine vertraulichen Dokumente hoch."
        },
        "notes": "Notizen"
      },
      "zusammensetzung": {
        "title": "Zusammensetzung",
        "chargengroesse": {
          "title": "Chargengrösse",
          "tooltip": "<p>Die Chargengrösse ist die während einem einheitlichen Herstellungsvorgang erzeugten Gesamtmenge einer Herstellung. (Beispiel: In einem Herstellungsvorgang werden 500ml Salicylvaseline hergestellt und in 5 Tuben à je 100ml abgefüllt. Die 5 Tuben tragen alle die selbe Chargennummer, die Chargengrösse entspricht 500ml) <br>Referenz: Ph. Helv. 20.1.B Begriffsbestimmungen</p>"
        },
        "masseinheit": "Masseinheit",
        "sortierreihenfolge": {
          "title": "Position",
          "tooltip": "<p>Es ist sinnvoll zuerst die Wirkstoffe zu erfassen und anschliessend die Hilfsstoffe.</p><p>Die Zahl muss eine ganze Zahl darstellen, die nur einmal pro Formel vorkommt. Wenn ein Wert gewählt wird, der bereits bei einer Komponente gesetzt wurde, werden bei allen Komponenten, die Werte >= des neuen Wertes haben, eine Nummer raufgezählt.</p>"
        },
        "bezeichnung": {
          "title": "Bezeichnung",
          "tooltip": "<p>Angabe der Bezeichnung der einzelnen Wirk- und Hilfsstoffe.</p><p>Die Bezeichnung der einzelnen Komponenten erfolgt gemäss Ph.Helv./Ph.Eur. entweder mit offiziellen deutschen oder lateinischen Bezeichnung. Falls nicht Einzelstoffe verwendet werden, sondern Gemische (Salbengrundlagen, Konservierungslösungen, andere Hilfsstoffgemische) müssen die entsprechenden Einzelkomponenten dieser Gemische aufgeführt werden. Die Komponenten dieser Gemische müssen grundsätzlich auch Pharmakopöe-Qualität aufweisen zudem sind ggfs. deklarationspflichtige Hilfsstoffe auf der Etikette zu deklarieren.<br>Die Zusammensetzung solcher Gemische kann mit der quantitativen und qualitativen Angabe der Einhzelsubstanzen eingereicht/eingescannt (wo? Unter Datei(en) hinzufügen) werden.</p><p>Auch können hier weitere Angaben z.B. über die verwendeten Kapselhüllen angegeben werden.</p>"
        },
        "qualitaet": {
          "title": "Qualtität",
          "tooltip": "Falls für die verwendeten Komponenten entsprechende Monographien in der Ph.Helv. bzw. Ph.Eur. vorhanden sind, <strong>muss</strong> die entsprechende Qualität verwendet werden, Art. 8 Grundsatz des Inverkehrbringens (HMG). Werden Arzneimittel oder pharmazeutische Hilfsstoffe in Verkehr gebracht, so müssen sie den Anforderungen der Pharmakopöe oder anderer vom Institut anerkannter Arzneibücher entsprechen, sofern entsprechende Vorschriften aufgeführt sind."
        },
        "art": {
          "title": "Art",
          "tooltip": "<strong>Deklarationspflichtige Hilfsstoffe - «Pharmazeutische Hilfsstoffe von besonderem Interesse»</strong><p>Deklarationspflichtige Hilfsstoffe werden als pharmazeutische Hilfsstoffe von besonderem Interesse bezeichnet. In der Arzneimittel-Zulassungsverordnung (AMZV, Art. 14b) ist das Anbringen von Warnhinweisen vorgesehen. Eine entsprechende Liste ist in Anhang 3a der AMZV aufgeführt.<br>Da in den meisten Arzneimitteln nach «Eigener Formel» keine Packungsbeilagen mit entsprechenden Informationen vorhanden sind, ist es wichtig, dass die entsprechenden «Hilfsstoffe von besonderem Interesse» auf der Etikette qualitativ deklariert werden und v.a. die Kundschaft bei der Abgabe auf entsprechende Risiken aufmerksam gemacht wird. Für Ethanol gelten dieselben Deklarationspflichten wie bisher.</p>"
        },
        "einheitsmenge": {
          "title": "Einheitsmenge",
          "tooltip": "Hier wird die Menge der entsprechenden Komponente aufgeführt. Bitte beachten Sie, dass die Summe aller Einheitsmengen der Chargengrösse entsprechen muss."
        },
        "einheit": "Einheit",
        "bemerkung": "Bemerkung",
        "notes": "Notizen",
        "komponente": {
          "new": {
            "title": "Komponente Erfassen",
            "submit": "Erfassen"
          },
          "edit": {
            "title": "Komponente Bearbeiten",
            "submit": "Ändern",
            "cancel": "Abbrechen"
          }
        },
        "komponenten": {
          "title": "Komponenten",
          "none": "Keine Komponenten vorhanden",
          "info": "Bitte beachten Sie, dass die Summe aller Einheitsmengen der Chargengrösse entsprechen muss.",
          "wirkstoff_andere_info": "Für die Herstellung von Arzneimitteln, für die nach Artikel 9 Absätze 2 Buchstaben a–c<sup>bis</sup>, 2<sup>bis</sup> und 2<sup>ter</sup> HMG keine Zulassung erforderlich ist, dürfen ausschliesslich Wirkstoffe gemäss Art. 37 der Arzneimittelverordnung (VAM) verwendet werden. Bitte laden Sie unter Dokumente für Komponenten aus Ph. F. (Teil «Préparations homeopathiques») und B.Hom.P die entsprechende Monographie als PDF hoch oder bestätigen Sie für alle anderen Komponenten gemäss Art. 37 VAM in der Bemerkung, dass die Anforderungen an einen pharmazeutischen Ausgangsstoff erfüllt sind und ein Analysenzertifikat vorliegt.",
          "hilfsstoff_andere_info": "Wenn eine Komponente nicht in einem anerkannten Arzneibuch monographiert ist, ist zu bestätigen, dass die Anforderungen an einen pharmazeutischen Ausgangsstoff erfüllt sind und ein Analysenzertifikat vorliegt. Vermerken Sie dies bitte bei der entsprechenden Komponente unter Bemerkungen. Prüfen Sie die Komponenten immer auf Zulässigkeit gemäss Art. 37 der VAM!"
        },
        "file": {
          "info": "Hier können Sie z.B. Ihre Analysezertifikate oder Dokumente zu den Komponenten hochladen. Bitte laden Sie keine vertraulichen Dokumente hoch."
        }
      },
      "lagerung": {
        "title": "Lagerung",
        "lagerungshinweise": {
          "title": "Lagerungshinweise",
          "tooltip": "Folgende Lagerhinweise sind grundsätzlich möglich (nach Swissmedic):<ul><li>Nicht über 25°C lagern.</li><li>Nicht über 30°C lagern.</li><li>Bei Raumtemperatur (15-25°C) lagern.</li><li>Bei 15-30°C lagern.</li><li>Im Kühlschrank (2-8°C) lagern.</li><li>Tiefgekühlt (unterhalb von -15°C) lagern.</li><li>Nicht im Kühlschrank lagern.</li><li>Nicht einfrieren.</li><li>In der Originalverpackung aufbewahren.</li><li>Den Behälter fest verschlossen halten.</li><li>Den Behälter im Umkarton aufbewahren, um den Inhalt vor Licht und Feuchtigkeit zu schützen.</li><li>Kinderwarnung (z. B. „ausser Reichweite von Kindern aufbewahren“)</li></ul> "
        },
        "bemerkung": "Bemerkung",
        "haltbarkeitsdauer": {
          "title": "Haltbarkeitsdauer (Format z.B. 6 Monate)",
          "info": "Haltbarkeitsdauer (Format z.B. 6 Monate) und ggf. Aufbrauchfrist (mindestens für halbfeste und flüssige Arzneiformen in Mehrdosenbehältnissen).",
          "tooltip": "<p><strong>Haltbarkeitsdauer / Verfallsdatum </strong><br>Die anhand von Stabilitätsdaten oder durch Literaturdaten ermittelt/festgelegte Haltbarkeitsdauer bei entsprechender Lagerbedingungen.  Das trifft auch für Haltbarkeiten von weniger als einem Jahr zu. Bei einer Haltbarkeitsdauer, die über die Dauer von einem Jahr bzw. der in der Literatur empfohlenen Dauer hinausgeht, werden Stabilitätsdaten verlangt (vgl. VAM Art. 36). Das entsprechende Verfallsdatum wird auf der Etikette/Beschriftung mit einer taggenauen Angabe des Datums, nach dem das Arzneimittel nicht mehr verwendet werden darf. Form: TT.MM.JJJJ</p><p><strong>Aufbrauchsfrist</strong><br>Eine Aufbrauchsfrist muss bei Arzneimitteln angegeben werden, die nach dem Öffnen nur beschränkt haltbar sind. Form TT.MM.JJJJ</p>"
        },
        "file": {
          "info": "Hier können Sie z.B. Dokumente zur Lagerung hochladen. Bitte laden Sie keine vertraulichen Dokumente hoch."
        },
        "notes": "Notizen"
      },
      "verpackung": {
        "title": "Verpackung",
        "behaeltergroesse": {
          "title": "Behältergrösse",
          "tooltip": "Hier können Sie die verschiedenen Packungsgrössen (Verkaufspackungen) eingeben.",
          "packungsinhalt": {
            "title": "Packungsinhalt",
            "tooltip": "Angabe wieviel Tabettlen, Kapseln, Globuli oder welche Menge einer Salbe, eines Sirups in einer Packung vorhanden ist (die entsprechenden Einheiten werden in der nächsten Eingabemaske eingegeben). z.B.<ul><li>30 (Tabletten)</li><li>50 (Milliliter)</li></ul>"
          },
          "einheit": "Einheit",
          "bemerkung": "Bemerkung",
          "art": {
            "title": "Art und Material des Behälters",
            "tooltip": "Die Anforderungen an Behältnisse für pharmazeutische Zwecke sind im Kapitel 3.2 der Ph.Eur. festgelegt. Dabei sind v.a. folgende Kapitel zu beachten: die Kapitel 3.2.1 Glasbehältnisse und 3.2.2. Kunststoffbehältnisse und –verschlüsse zur pharmazeutischen Verwendung .<br>Bei der Beschreibung des Behälters (und des Verschlusses und ggfs. der Dosiervorrichtung) genügt die Angabe von Art, Form und Material: Beispiele <ul><li>z.B. Glasflasche mit Tropfenzähler (Polyethylen) und Schraubdeckel (Polypropylen)</li><li>Polyethylenflasche mit Schraubdeckel (Polyethylen) und Dosierbecher (Polypropylen)</li></ul>"
          },
          "new": {
            "title": "Behältergrösse Erfassen",
            "submit": "Erfassen"
          },
          "edit": {
            "title": "Behältergrösse Bearbeiten",
            "submit": "Ändern",
            "cancel": "Abbrechen"
          }
        },
        "behaltergroessen": {
          "title": "Behältergrössen",
          "none": "Keine Behältergrössen vorhanden"
        },
        "etiketten": {
          "title": "Etiketten",
          "tooltip": "Hier können Sie Ihre Etiketten in den folgenden Dateiformaten hochladen: '.doc', '.docx', '.gif', '.jpg', '.jpeg', '.pdf', '.png', '.pptx', '.xls', '.xlsx'. Für die Vorschau sind nur Bilder und PDF verfügbar.",
          "upload": {
            "title": "Etikette hochladen",
            "noEtikettenYet": "Noch keine Etikette(n) hochgeladen.",
            "withnoEtikettenText": "Sie können (bei Bedarf) weitere Etiketten hochladen."
          }
        },
        "file": {
          "info": "Hier können Sie z.B. Dokumente zu den Behältnissen hochladen. Bitte laden Sie keine vertraulichen Dokumente hoch."
        },
        "notes": "Notizen"
      },
      "risikorechner": {
        "title": "Risikoberechnung",
        "info": {
          "title": "Risikoprüfung nach Arzneimittel-Bewilligungsverordnung (AMBV).",
          "text": "Die Risikoprüfung ist jeweils für ein bestimmtes Arzneimittel durchzuführen. Ergibt die Multiplikation der Faktoren einen Wert unter 100, so bedarf es anstelle einer Bewilligung des Instituts einer kantonalen Herstellungsbewilligung."
        },
        "text_unter_100": "Die Prüfung der angegebenen Daten ergab ein Ergebnis von <strong>{{resultat}}</strong>. Die Herstellungsbewilligung des Kantonsapothekeramtes ist ausreichend.",
        "text_ueber_100": "Die Prüfung der angegebenen Daten ergab ein Ergebnis von <strong>{{resultat}}</strong>. Ergibt die Multiplikation der Faktoren einen Wert über 100, so bedarf es anstelle einer kantonalen Herstellungsbewilligung einer Bewilligung des Instituts.",
        "form": {
          "anwendungart": "Anwendungsart",
          "mengenverhaeltnis": "Mengenverhältnis",
          "wirkstoffrisiko": "Wirkstoffrisiko",
          "herstellungsprozess": "Herstellungsprozess",
          "arzneiform": "Arzneiform",
          "produktionsmenge": {
            "label": "Jährliche Produktionsmenge ___",
            "augentropfen": "Jährliche Produktionsmenge in Liter",
            "fest": "Jährliche Produktionsmenge als Stückzahl",
            "fluessig": "Jährliche Produktionsmenge in Liter",
            "halbfest": "Jährliche Produktionsmenge in Gramm",
            "halbfest_zaepfchen": "Jährliche Produktionsmenge als Stückzahl"
          }
        },
        "button": "Risiko berechnen"
      },
      "einreichen": {
        "title": "Formel zur Meldung einreichen"
      },
      "abschliessen": {
        "title": "Abschliessen"
      }
    },
    "draft": {
      "overview": {
        "title": "Entwürfe",
        "create": "Neue Formel erfassen"
      }
    },
    "copy": {
      "status_zur_nachbearbeitung_zurueckgewiesen": "Bitte direkt die zurückgewiesene Formel weiterbearbeiten",
      "status_meldebestaetigung_neue_version_vorhanden": "Es handelt sich um eine Neueinreichung einer bereits bestätigten Formel. Daher kann die Formel erst wieder kopiert werden, wenn die Prüfung der neuen Formel beendet ist.",
      "success": "Die Formel wurde erfolgreich kopiert."
    },
    "parentLabel": "Kopie von",
    "archive": {
      "label": "Verzichten",
      "warning": "Wollen Sie wirklich auf die Formel verzichten?  Die Formel kann nicht mehr bearbeitet werden und die Herstellung gemäss Formel ist nicht mehr zugelassen",
      "status_hint": "Eine Formel kann nur archiviert werden, wenn sie eine Meldebestätigung erhalten hat. Sie können die Formel löschen, falls sie nicht mehr benötigt wird"
    },
    "history": {
      "overview": {
        "title": "Historie",
        "create": {
          "username": "Erstellt von",
          "date": "Erstellt am"
        },
        "state": "Status",
        "back": "Zurück",
        "none": "Keine Historieeinträge vorhanden."
      }
    }
  },
  "rechtliches": {
    "breadcrumb": "Rechtliches",
    "title": "Nutzungsbedingungen EFo",
    "stand": "(Stand 04.06.2019) ",
    "paragraph": {
      "title.1": "1. Gegenstand",
      "text.1": "EFo ist das Web-basierte Online-Tool, über welches neue Formeln gemeldet werden können.",
      "title.2": "2. Nutzungsberechtigung",
      "text.2": "Zur Nutzung von EFo berechtigt sind die leistungsempfangenden Personen bzw. ihre Vertretungen sowie die Leistungserbringenden.",
      "title.3": "3. Kosten",
      "text.3": "Die Nutzung von EFo ist kostenlos.",
      "title.4": "4. Bereitstellung, Betrieb und Betreuung von EFo",
      "text.4.1": "EFo wird vom Kantonsapothekeramt des Kantons Bern (KAPA) bereitgestellt, betrieben und betreut.",
      "text.4.2": "Alle Aspekte des Betriebs von EFo (Sicherheit, Netzwerk, Leistung, Datensicherung, etc.) werden durch das KAPA sichergestellt.",
      "text.4.3": "Das KAPA ist besorgt, allfällige Betriebsstörungen und –unterbrüche von EFo so schnell als möglich zu beheben. Den leistungsempfangenden Personen dürfen durch allfällige Betriebsstörungen und –unterbrüche keine Nachteile entstehen.",
      "title.5": "5. Datenschutz, Datenerhebung und Geheimhaltung",
      "text.5.1": "Leistungserbringende sind Behörden im Sinne des kantonalen Datenschutzgesetzes (Art. 2 Abs. 6 Bst. b KDSG ). Sie sind verpflichtet die geltende Datenschutzgesetzgebung einzuhalten.",
      "text.5.2": "Die NutzerInnen von EFo erklären sich damit einverstanden, dass das KAPA zur Beurteilung der geltend gemachten Leistungsansprüche sowie zu Analyse- und Reportingzwecken jederzeit auf die in EFo enthaltenen Daten zugreifen darf.",
      "text.5.3": "Die NutzerInnen von EFo haben alle Tatsachen und Informationen vertraulich zu behandeln, die weder offenkundig noch allgemein zugänglich sind und an denen aufgrund ihrer Natur nach Treu und Glauben ein Geheimhaltungsinteresse besteht. Im Zweifelsfall sind Tatsachen und Informationen vertraulich zu behandeln. Die Geheimhaltungspflicht besteht auch nach Beendigung der Nutzung von EFo fort.",
      "text.5.4": "Leistungsempfangende Personen bzw. ihre Vertretungen und Leistungserbringenden haben die Daten, die sie bearbeiten, gegen unbefugte Kenntnisnahme Dritter wirksam zu schützen. Die Login-Informationen für EFo sind geheim zu halten und vor missbräuchlicher Verwendung zu schützen.",
      "text.5.5": "Bei einer Übertragung der Nutzungs- und Administratorenrechte von EFo hat die übertragende Stelle sicherzustellen, dass die Datenschutzgesetzgebung auch bei der Datenbearbeitung im Auftrag eingehalten wird. (Art. 8 und 16 KDSG).",
      "title.6": "6. Haftung",
      "text.6": "Der Kanton übernimmt keine Haftung für Schäden, welche durch die Nutzung von EFo entstanden sind. Die Nutzung erfolgt auf eigene Gefahr.",
      "title.7": "7. Anpassung der Nutzungsbedingungen",
      "text.7": "Das KAPA behält sich vor, die Nutzungsbedingungen zu ändern. Die neuen Nutzungsbedingungen werden den leistungsempfangenden Personen bzw. ihren Vertretungen und Leistungserbringenden in diesem Fall per Email zugestellt. Ohne schriftlichen Gegenbericht innert 10 Tagen seit Zustellung gelten die neuen Nutzungsbedingungen als akzeptiert.",
      "title.8": "8. Gerichtsstand und anwendbares Recht",
      "text.8": "Für sämtliche Streitigkeiten, die sich aufgrund der Nutzung von EFo zwischen den Nutzern und dem KAPA ergeben, sind ausschliesslich die Gerichte am Sitz der kantonalen Verwaltung in Bern zuständig. Anwendbar ist ausschliesslich Schweizer Recht.",
      "title.9": "9. Kontaktangaben",
      "text.9": "Bei fachlichen und technischen Fragen zu EFo:"
    },
    "helpline": "Helpline",
    "telefon": "Telefonnummer:",
    "telefon.nr": "+410313003370",
    "telefon.nr.text": "031 300 33 70",
    "email": "E-Mail:",
    "email.url": "info@bernermodell.ch",
    "info": "Weiterführende Informationen:",
    "info.url": "https://www.be.ch/portal/de/tools/rechtliches.html"
  },
  "impressum": {
    "breadcrumb": "Impressum",
    "title.1": "Gesamtverantwortung",
    "gef": "Gesundheits-, Sozial- und Integrationsdirektion des Kantons Bern",
    "amt": "Kantonsapothekeramt",
    "gef.strasse": "Rathausgasse 1",
    "gef.postfach": "Postfach",
    "gef.plz": "3000 Bern 8",
    "title.2": "Technische Umsetzung",
    "bedag": "Bedag Informatik AG",
    "bedag.strasse": "Engehaldestrasse 12",
    "bedag.plz": "CH-3012 Bern"
  },
  "information": {
    "breadcrumb": "Informationen",
    "title": "Eigene Formel",
    "block1": "Öffentliche Apotheken, Spitalapotheken und Drogerien dürfen aufgrund des Heilmittelgesetzes nicht verschreibungspflichte Arzneimittel - «Eigene Formel» - für die Abgabe an die eigene Kundschaft herstellen.",
    "voraussetzung_title": "Damit solche Arzneimittel «nach eigener Formel» hergestellt und abgegeben werden dürfen, sind im Kanton Bern folgende Voraussetzungen zu erfüllen:",
    "voraussetzung_1": "Betriebsbewilligung und Herstellungsbewilligung zur Herstellung von Arzneimitteln nach eigener Formel",
    "voraussetzung_2": "Meldung dieser Arzneimittel an den Pharmazeutischen Dienst unter Angabe von Bezeichnung, Zusammensetzung und Beschriftung (Gesundheitsverordnung, Art. 58, 59, 61).",
    "produkte_title": "Welche Art von Produkten müssen <strong>nicht gemeldet</strong> werden",
    "produkt_1": "Chemikalien (falls diese als solche und entsprechende gekennzeichnet, sowie ohne Heilanpreisungen, abgegeben werden)",
    "produkt_2": "Kosmetika",
    "produkt_3": "Lebensmittel, Nahrungsergänzungsmittel etc.",
    "produkt_4": "Medizinprodukte",
    "produkt_5": "Arzneimittel nach «Formula Magistralis», die z.B. auch defekturmässig hergestellt werden",
    "produkt_footer": "Im Zweifelsfall können Sie sich an uns wenden",
    "block2": "Die sog. eigene Formel gilt nur für den (einen) eigenen Betriebsstandort und darf nur dort an die eigene Kundschaft abgegeben werden!",
    "beschriftung_title": "Beschriftung/Bezeichnung",
    "beschriftung_text_1": "Die Bezeichnung des Arzneimittels darf nicht der öffentlichen Ordnung oder den guten Sitten widersprechen, irreführend sein oder zu Verwechslungen führen können. Sie muss den Anforderungen der aktuellen Ph.Helv. entsprechen (Kapitel 17.1.5 und 17.2.5) bzw. im Bereich Komplementärmedizin den entsprechenden Anforderungen der KPAV.",
    "beschriftung_text_2": "Grundsätzlich ist das Vermischen verschiedener Therapieformen nicht erlaubt. Sollte davon abgewichen werden, muss begründet und dokumentiert werden, wie die Prinzipien der entsprechenden Therapierichtungen beachtet werden. Komplementärarzneimittel sollen als solche bezeichnet werden.",
    "beschriftung_text_3": "Nicht erlaubt: Werbung und Verweise auf andere Produkte des Betriebs",
    "kontakt": {
      "title": "Kontakt",
      "kapa": "Pharmazeutischer Dienst",
      "strasse": "Rathausgasse 1",
      "plz": "3000 Bern 8",
      "telefon": "Telefon: 031 633 79 26",
      "link": {
        "amt": "Pharmazeutischer Dienst",
        "amt-link": "https://www.gsi.be.ch/de/start/ueber-uns/gesundheitsamt/pharmazeutischer-dienst-pad.html",
        "formular": "Kontaktformular",
        "kontakt-link": "https://www.gsi.be.ch/de/tools/kontaktformular/kontaktformularPAD.html",
        "email": "E-Mail"
      }
    }
  },
  "footer": {
    "signetlabel": "Gesundheits-, Sozial- und Integrationsdirektion (GSI)",
    "about": "Eigene Formel",
    "rechtliches": "Rechtliches",
    "impressum": "Impressum",
    "kontakt": "Kontakt",
    "copyright": "© {{year}} - Kanton Bern",
    "follow": "Folgen Sie uns"
  },
  "multiFileUploadComponent": {
    "dropHereText": "Dateien hier droppen",
    "uploadFileButtonText": "Datei(en) hinzufügen",
    "uploadingText": "Lade hoch...",
    "uploadErrorText": "Fehler beim Hochladen einer oder mehrerer Dateien. Sie können den Vorgang erneut versuchen. Stellen Sie sicher, dass eine Internetverbindung besteht und dass die Grösse der Datei(en) (25M) das erlaubte Maximum nicht überschreitet. Sollte das Problem bestehen bleiben, kontaktieren Sie den Support.",
    "uploadErrorTitle": "Fehler beim Hochladen",
    "rejectedFileTitle": "Abgelehnte Datei",
    "undesiredFileTitle": "Information",
    "numberOfFilesTextZero": "",
    "numberOfFilesTextOne": "Eine (1) Datei hochgeladen",
    "numberOfFilesTextMultiple": "{{count}} Dateien hochgeladen",
    "uploadErrorDismissButtonLabel": "Ok",
    "uploadErrorShowTechnicalErrorText": "Technischen Fehler anzeigen",
    "rejectedFileDismissButton": "Verstanden",
    "undesiredFileDismissButton": "Verstanden",
    "title": "Dokumente hochladen",
    "noDocumentYet": "Noch kein(e) Dokument(e) hochgeladen.",
    "withDocumentsText": "Sie können (bei Bedarf) weitere Dokumente hochladen."
  },
  "modal": {
    "info": {
      "title": "Information",
      "antrag": {
        "success": "Ihr Antrag wurde erfolgreich erstellt."
      },
      "mail": {
        "korrespondenz": {
          "success": "Korrespondenzmail wurde erfolgreich gespeichert."
        },
        "configuration": {
          "success": "Mailkonfiguration wurde erfolgreich gespeichert."
        }
      },
      "formel": {
        "pruefer": {
          "set": "Formel wurde vom PAD in Bearbeitung genommen."
        }
      }
    },
    "confirm": {
      "delete": {
        "title": "Löschen bestätigen",
        "behaeltergroesse": "Wollen Sie die Behältergrösse wirklich löschen?",
        "komponente": "Wollen Sie die Komponente wirklich löschen?",
        "betrieb_berechtigung": "Wollen Sie den Benutzer wirklich aus dem Betrieb entfernen?",
        "pad_berechtigung": "Wollen Sie den Benutzer wirklich aus dem Pharmazeutischen Dienst entfernen?",
        "berechtigung_antrag": "Wollen Sie den Berechtigungsantrag wirklich zurückziehen?",
        "betriebsleiter_change_antrag": "Wollen Sie den Betriebsleiterwechsel Antrag wirklich zurückziehen?",
        "betriebsleiter_berechtigung": "Wollen Sie den Betriebsleiter wirklich löschen?",
        "formel": "Wollen Sie die Formel wirklich löschen?",
        "file": "Wollen Sie die Datei wirklich löschen?",
        "accept": "Löschen",
        "cancel": "Abbrechen"
      },
      "remove": {
        "title": "Entfernen",
        "pruefer": "Wollen Sie den aktuellen Bearbeiter wirklich Entfernen?",
        "accept": "Entfernen"
      },
      "reject": {
        "antrag": "Wollen Sie den Antrag wirklich ablehnen?"
      }
    },
    "close": "Schliessen"
  },
  "info": {
    "modal": {
      "title": "Information"
    }
  },
  "error": {
    "no.berechtigung.for.betrieb": "Fehlende Berechtigungen um diese Aktion für diesen Betrieb auszuführen.",
    "no.berechtigung": "Fehlende Berechtigungen um diese Aktion auszuführen.",
    "berechtigung.forbidden": "Fehlende Berechtigungen um diese Aktion auszuführen.",
    "not.current.benutzer": "Kein aktueller Benutzer vorhanden.",
    "benutzer.not.exist": "Benutzer existiert nicht.",
    "berechtigung.search.betrieb.null": "Betrieb existiert nicht.",
    "berechtigung.udpate.berechtigung.id.null": "Keine Berechtigung Id vorhanden.",
    "berechtigung.udpate.berechtigung.betrieb.null": "Kein Betrieb vorhanden.",
    "berechtigung.antrag.search.no.betrieb": "Kein Betrieb vorhanden.",
    "berechtigung.antrag.not.exist": "Der BErechtigungsantrag existiert nicht.",
    "modal": {
      "title": "Fehlermeldung",
      "close": "Schliessen"
    },
    "common": {
      "no.berechtigung.for.site": "Sie haben für diese Seite keine Berechtigung.",
      "no.betrieb.selected": "Kein Betrieb ausgwählt."
    },
    "betrieb": {
      "korrespondenzmail": {
        "size": "Das Korrespondenzmail darf die Länge von 255 zeichen nicht überschreiten."
      }
    },
    "berechtigungs": {
      "antrag": {
        "duplicate": "Sie haben bereits einen identischen Berechtigungsantrag gestellt.",
        "identical": {
          "berechtigung": "Sie haben bereits eine identische Berechtigung."
        },
        "already": {
          "betriebsleiter": "Sie sind bereits als Betriebsleiter auf einem Betrieb Berechtigt. Ein Benutzer darf nur auf einem Betrieb Betriebsleiter sein.",
          "betrieb": {
            "berechtigung": "Sie haben bereits eine Berechtigung auf diesem Betrieb. Ein Benutzer darf pro Betrieb nur einmal berechtig sein.",
            "antrag": "Sie haben bereits einen Berechtigungsantrag auf diesem Betrieb. Ein Benutzer pro Betrieb nur einmal Berechtig sein."
          }
        },
        "benutzer": {
          "null": "Der Berechtigungsantrag enthält keinen Benutzer."
        },
        "rolle": {
          "null": "Der Berechtigungsantrag enthält keine Rolle.",
          "not.allowed": "Für die ausgwählte Rolle darf kein Berechtigungsantrag erstellt werden."
        },
        "betrieb": {
          "null": "Der Berechtigungsantrag enthält keinen Betrieb.",
          "not.allowed": "Für die ausgwählte Rolle darf kein Betrieb ausgewählt werden."
        }
      }
    },
    "betriebsleiter": {
      "change": {
        "antrag": {
          "duplicate": "Sie haben bereits einen Identischen Betriebleiterwechsel Antrag erstellt.",
          "null": "Der Betriebsleiterwechsel Antrag ist leer.",
          "betriebsleiter.new.null": "Der Betriebsleiterwechsel Antrag enthält keinen neuen Betriebsleiter.",
          "betriebsleiter.old.null": "Der Betriebsleiterwechsel Antrag enthält keinen alten Betriebsleiter.",
          "betriebsleiter.new.rolle.null": "Der Betriebsleiterwechsel Antrag enthält keine Rolle für den neuen Betriebsleiter.",
          "betriebsleiter.new.betrieb.null": "Der Betriebsleiterwechsel Antrag enthält keinen Betrieb für den neuen Betriebsleiter.",
          "betriebsleiter.new.benutzer.null": "Der Betriebsleiterwechsel Antrag enthält keinen Benutzer für den neuen Betriebsleiter.",
          "betriebsleiter.new.invalid.rolle": "Der neue Betriebsleiter muss die Rolle Betriebsmitarbeiter oder Betriebsleiter Stv. besitzen.",
          "betriebsleiter.new.betrieb.different": "Der neue Betriebsleiter muss muss den gleichen Betrieb haben wie der alte.",
          "not.exist": "Der Betriebsleiterwechsel Antrag existiert nicht."
        }
      }
    },
    "formel": {
      "allgemein": {
        "praeparat": {
          "name": {
            "blank": "Der Präparatname darf nicht leer sein.",
            "size": "Der Präparatname darf 250 Zeichen nicht überschreiten."
          },
          "art": {
            "null": "Die Präparatart darf nicht leer sein."
          }
        },
        "indikation": {
          "blank": "Die Indikation darf nicht leer sein.",
          "size": "Die Indikation darf 150 Zeichen nicht überschreiten."
        },
        "dosierungsvorschrift": {
          "blank": "Die Dosierungsvorschrift darf nicht leer sein.",
          "size": "Die Dosierungsvorschrift darf 150 Zeichen nicht überschreiten."
        },
        "darreichungsform": {
          "null": "Die Darreichungsform darf nicht leer sein."
        },
        "lohnhersteller": {
          "name": {
            "blank": "Der Lohnhersteller darf nicht leer sein.",
            "size": "Der Lohnhersteller darf 150 Zeichen nicht überschreiten."
          },
          "betriebsart": {
            "null": "Die Betriebsart darf nicht leer sein."
          },
          "postfach": {
            "size": "Das Postfach darf 30 Zeichen nicht überschreiten."
          },
          "strasse": {
            "blank": "Die Strasse darf nicht leer sein.",
            "size": "Die Strasse darf 50 Zeichen nicht überschreiten."
          },
          "plz": {
            "blank": "Die Postleitzahl darf nicht leer sein.",
            "size": "Die Postleitzahl darf 10 Zeichen nicht überschreiten."
          },
          "ort": {
            "blank": "Der Ort darf nicht leer sein.",
            "size": "Der Ort darf 150 Zeichen nicht überschreiten."
          }
        }
      },
      "zusammensetzung": {
        "chargengroesse": {
          "null": "Die Chargengrösse darf nicht leer sein.",
          "size": "Die Chargengrösse muss zwischen 1 und 999999999 liegen."
        },
        "chargengroesseeinheit": {
          "null": "Die Chargengrössen Einheit darf nicht leer sein."
        },
        "komponenten": {
          "empty": "Es muss mindestens eine Komponente vorhanden sein."
        },
        "komponente": {
          "komponentorder": {
            "null": "Die Position einer Komponente darf nicht leer sein.",
            "size": "Die Position einer Komponente muss zwischen 1 bis 99 liegen."
          },
          "bezeichnung": {
            "blank": "Die Bezeichnung einer Komponente darf nicht leer sein.",
            "size": "Die Bezeichnung einer Komponente darf 250 Zeichen nicht überschreiten."
          },
          "qualitaet": {
            "null": "Die Qualität einer Komponente darf nicht leer sein.",
            "andere.bemerkung.blank": "Eine Komponente der Qualität 'Andere' muss eine Bemerkung enthalten."
          },
          "art": {
            "null": "Die Art einer Komponente darf nicht leer sein."
          },
          "einheitsmenge": {
            "blank": "Die Einheitsmenge einer Komponente darf nicht leer sein.",
            "size": "Die Einheitsmenge einer Komponente muss zwischen 0 bis 999999999 liegen."
          },
          "einheit": {
            "blank": "Die Einheit einer Komponente darf nicht leer sein."
          },
          "bemerkung": {
            "size": "Die Bemerkung einer Komponente darf 250 Zeichen nicht überschreiten.",
            "blank": "Wenn eine Komponente nicht in einem anerkannten Arzneibuch monographiert ist, ist zu bestätigen, dass die Anforderungen an einen pharmazeutischen Ausgangsstoff erfüllt sind und ein Analysenzertifikat vorliegt. Vermerken Sie dies bitte bei der entsprechenden Komponente unter Bemerkungen."
          }
        }
      },
      "lagerung": {
        "hinweise": {
          "null": "Es muss mindestens ein Lagerungshinweis vorhanden sein."
        },
        "haltbarkeitsdauer": {
          "blank": "Die Haltbarkeitsdauer darf nicht leer sein.",
          "size": "Die Haltbarkeitsdauer darf 150 Zeichen nicht überschreiten."
        },
        "bemerkung": {
          "size": "Die Bemerkung für Lagerung darf 250 Zeichen nicht überschreiten."
        }
      },
      "verpackung": {
        "packungsinhalt": {
          "blank": "Der Packungsinhalt darf nicht leer sein.",
          "size": "Die Packungsinhalt darf 250 Zeichen nicht überschreiten."
        },
        "einheit": {
          "null": "Die Einheit der Verpackung darf nicht leer sein."
        },
        "artmaterialbehaelter": {
          "blank": "Die Materialart darf nicht leer sein.",
          "size": "Die Materialart darf 150 Zeichen nicht überschreiten."
        },
        "bemerkung": {
          "size": "Die Bemerkung für Verpackung Komponenten darf 250 Zeichen nicht überschreiten."
        },
        "etiketten": {
          "empty": "Es muss mindestens eine Etikette vorhanden sein."
        }
      },
      "not.exist": "Die Formel existiert nicht.",
      "draft": {
        "not.exist": "Der Entwurf existiert nicht."
      },
      "formelrisiko": {
        "blank": "Das Formelrisiko darf nicht leer sein."
      },

      "archive": {
        "status": "Eine Formel kann nur archiviert werden, wenn sie eine Meldebestätigung erhalten hat. Sie können die Formel löschen, falls sie nicht mehr benötigt wird"
      },
      "clone": {
        "status": "Eine Formel kann nur dupliziert werden, wenn sie eine Meldebestätigung erhalten hat."
      },
      "delete": {
        "wrong": {
          "state": "Eine Formel darf nur im Status In Arbeit oder Zur Nachbearbeitung zurückgewiesen gelöscht werden."
        },
        "is.parent": "Die Formel hat eine Kopie. Löschen Sie zuerst die Kopie bevor sie diese Formel löschen."
      },
      "update": {
        "wrong": {
          "state": "Die Formel befindet sich im falschen status für diese Aktion."
        }
      }
    },
    "pruefung": {
      "notes": {
        "allgemein": {
          "size": "Die Prüfungsnoziz für Allgemein darf 4000 Zeichen nicht überschreiten."
        },
        "zusammensetzung": {
          "size": "Die Prüfungsnoziz für Zusammensetzung darf 4000 Zeichen nicht überschreiten."
        },
        "lagerung": {
          "size": "Die Prüfungsnoziz für Lagerung darf 4000 Zeichen nicht überschreiten."
        },
        "verpackung": {
          "size": "Die Prüfungsnoziz für Verpackung darf 4000 Zeichen nicht überschreiten."
        }
      }
    }
  },
  "enum": {
    "praeperatart": {
      "ALLOPATHISCH": "Allopathisch",
      "ANTHROPOSOPHISCH": "Anthroposophisch",
      "AROMATHERAPIE": "Aromatherapie",
      "FUNKTIONSMITTEL_NACH_DR_SCHUESSLER": "Funktionsmittel nach Dr. Schüssler",
      "HOMOEOPATHISCH": "Homöopathisch",
      "HOMOEOPATHISCH_SPAGYRISCH": "Homöopathisch Spagyrisch",
      "PHYTO": "Phyto",
      "SPAGYRISCH": "Spagyrisch",
      "TRADITIONELL_AYURVEDISCH": "Traditionell Ayurvedisch",
      "TRADITIONELL_CHINESISCH": "Traditionell Chinesisch",
      "TRADITIONELL_TIBETISCH": "Traditionell Tibetisch"
    },
    "darreichungsform": {
      "BAD": "Bad",
      "CREME": "Crème",
      "EMULSION_LOTION": "Emulsion/Lotion",
      "GLOBULI": "Globuli",
      "GRANULAT": "Granulat",
      "KAPSELN": "Kapseln",
      "LOESUNG_SAFT": "Lösung/Sirup",
      "LUTSCHTABLETTE": "Lutschtablette",
      "PULVER": "Pulver",
      "SALBE": "Salbe",
      "SHAMPOO": "Shampoo",
      "SPRAY_TOPISCH": "Spray Topisch",
      "SPRAY_EINNAHME": "Spray Einnahme",
      "SUPPOSITORIEN_ZAEPFCHEN": "Suppositorien/Zäpfchen",
      "TABLETTEN": "Tabletten",
      "TEE": "Tee",
      "TROPFEN": "Tropfen"
    },
    "betriebsart": {
      "ANDERER": "Anderer",
      "BETRIEB_MIT_SWISSMEDIC_BEWILLIGUNG": "Betrieb mit Swissmedic-Bewilligung",
      "DROGERIE": "Drogerie",
      "OEFFENTLICHE_APOTHEKE": "Öffentliche Apotheke",
      "SPITALAPOTHEKE": "Spitalapotheke",
      "PRAXISADRESSE": "Praxisadresse",
      "PA_ARZTPRAXIS": "Privatapotheke Arztpraxis"
    },
    "chargengroesseeinheit": {
      "MIKROGRAMM": "Mikrogramm",
      "MILLIGRAMM": "Milligramm",
      "GRAMM": "Gramm",
      "KILOGRAMM": "Kilogramm",
      "MIKROLITER": "Mikroliter",
      "MILLILITER": "Milliliter",
      "LITER": "Liter",
      "KAPSEL": "Kapseln",
      "TABLETTE": "Tabletten",
      "ZAEPFCHEN": "Zäpfchen",
      "GLOBULI": "Globuli"
    },
    "qualitaet": {
      "ANDERE": "Andere",
      "PH_EUR": "Ph Eur.",
      "PH_HELV": "Ph Helv.",
      "HAB": "HAB"
    },
    "komponenteart": {
      "HILFSSTOFF_DEKLARATIONSPFLICHTIG": "deklarationspflichtiger Hilfsstoff",
      "HILFSSTOFF_WEITERER": "weiterer Hilfsstoff",
      "WIRKSTOFF_DEKLARATIONSPFLICHTIG": "deklarationspflichtiger Wirkstoff"
    },
    "komponenteeinheit": {
      "MIKROGRAMM": "Mikrogramm",
      "MILLIGRAMM": "Milligramm",
      "GRAMM": "Gramm",
      "KILOGRAMM": "Kilogramm",
      "MIKROLITER": "Mikroliter",
      "MILLILITER": "Milliliter",
      "LITER": "Liter",
      "KAPSEL": "Kapseln",
      "TABLETTE": "Tabletten",
      "ZAEPFCHEN": "Zäpfchen",
      "GLOBULI": "Globuli"
    },
    "lagerungshinweis": {
      "NICHT_UEBER_25_GRAD": "Nicht über 25°C lagern",
      "BEI_RAUMTEMPARATUR": "Bei Raumtemperatur (15-25°C) lagern",
      "BEI_15_BIS_30_GRAD": "Bei 15-30°C lagern",
      "IM_KUEHLSCHRANK": "Im Kühlschrank (2-8°C) lagern",
      "IN_DER_ORIGINALVERPACKUNG": "In der Originalverpackung aufbewahren",
      "VOR_LICHT_SCHUETZEN": "Inhalt vor Licht und Feuchtigkeit schützen",
      "TIEFGEKUEHLT": "Tiefgekühlt (unterhalb von -15°C) lagern",
      "NICHT_IM_KUEHLSCHRANK": "Nicht im Kühlschrank lagern",
      "NICHT_EINFRIEREN": "Nicht Einfrieren",
      "BEHAELTER_FEST_VERSCHLOSSEN": "Den Behälter fest verschlossen halten",
      "KINDERWARNUNG": "Ausser Reichweite von Kinder aufbewahren"
    },
    "behaeltergroesseeinheit": {
      "MIKROGRAMM": "Mikrogramm",
      "MILLIGRAMM": "Milligramm",
      "GRAMM": "Gramm",
      "KILOGRAMM": "Kilogramm",
      "MIKROLITER": "Mikroliter",
      "MILLILITER": "Milliliter",
      "LITER": "Liter",
      "KAPSEL": "Kapseln",
      "TABLETTE": "Tabletten",
      "ZAEPFCHEN": "Zäpfchen",
      "GLOBULI": "Globuli"
    },
    "anwendungsart": {
      "ENTERAL": "enterale Anwendung",
      "ENTERAL_ODER_TOPISCH": "enterale oder topische Anwendung mit Anforderungen an Sterilität",
      "INHALATIV": "inhalative Anwendung",
      "OPHTHALMOLOGISCH": "ophthalmologische Anwendung am unverletzten Auge",
      "OPHTHALMOLOGISCH_CHIRURGISCH": "ophthalmologische Anwendung in der Chirurgie oder bei traumatischen Verletzungen",
      "PARENTERALE": "parenterale Anwendung",
      "TOPISCH": "topische Anwendung"
    },
    "wirkstoffrisiko": {
      "HOHES": "hohes Risiko",
      "MITTLERES": "mittleres Risiko",
      "GERINGES": "geringes Risiko"
    },
    "herstellungsprozess": {
      "ABFUELLEN": "abfüllen nicht steriler Arzneiformen",
      "ASEPTISCH": "aseptische Herstellung",
      "ASEPTISCH_ENDSTERILISATION": "aseptische Herstellung mit Endsterilisation",
      "LOESEN_MISCHEN": "lösen und mischen",
      "VERDUENNEN": "verdünnen"
    },
    "mengenverhaeltnis": {
      "AUSGEGLICHEN": "ausgeglichen (Verhältnis: rund 1:1)",
      "AUSSCHL_EIGENE_KUNDSCHAFT": "ausschliesslich für die eigene Kundschaft",
      "AUSSCHL_LOHNAUFTRAG": "ausschliesslich im Lohnauftrag",
      "HAUPTS_EIGENE_KUNDSCHAFT": "hauptsächlich für die eigene Kundschaft (Verhältnis: rund 1:2)",
      "HAUPTS_LOHNAUFTRAG": "hauptsächlich im Lohnauftrag (Verhältnis: rund 2:1)"
    },
    "arzneiform": {
      "AUGENTROPFEN": "Augentropfen",
      "FEST": "feste Arzneiformen",
      "FLUESSIG": "flüssige Arzneiformen in üblichen Packungseinheiten oder Applikationseinheiten",
      "HALBFEST": "halbfeste Arzneiformen (Salben, Cremes etc.)",
      "HALBFEST_ZAEPFCHEN": "halbfeste Arzneiformen (Zäpfchen)"
    },
    "produktionsmenge": {
      "FLUESSIG_1": "mehr als 2000",
      "FLUESSIG_2": "1000-2000",
      "FLUESSIG_3": "500-999",
      "FLUESSIG_4": "100-499",
      "FLUESSIG_5": "weniger als 100",
      "FEST_1": "mehr als 120'000",
      "FEST_2": "60'000-120'000",
      "FEST_3": "30'000-59'999",
      "FEST_4": "6000-29'999",
      "FEST_5": "weniger als 6000",
      "HALBFEST_ZAEPF_1": "mehr als 40'000",
      "HALBFEST_ZAEPF_2": "20'000-40'000",
      "HALBFEST_ZAEPF_3": "10'000-19'999",
      "HALBFEST_ZAEPF_4": "2000-9999",
      "HALBFEST_ZAEPF_5": "weniger als 2000",
      "HALBFEST_1": "mehr als 200'000",
      "HALBFEST_2": "100'000-200'000",
      "HALBFEST_3": "50'000-99'999",
      "HALBFEST_4": "10'000-49'999",
      "HALBFEST_5": "weniger als 10'000",
      "AUGENTR_1": "mehr als 200",
      "AUGENTR_2": "100-200",
      "AUGENTR_3": "50-99",
      "AUGENTR_4": "10-49",
      "AUGENTR_5": "weniger als 10"
    },
    "state": {
      "IN_ARBEIT": "In Arbeit",
      "IN_PRUEFUNG": "in Bearbeitung PAD",
      "MELDEBESTAETIGUNG_MIT_KOSTENFOLGE": "Meldebestätigung mit Kostenfolge",
      "MELDEBESTAETIGUNG_OHNE_KOSTENFOLGE": "Meldebestätigung ohne Kostenfolge",
      "MELDEBESTAETIGUNG_NEUE_VERSION_VORHANDEN": "Meldebestätigung neue Version vorhanden",
      "ZUR_NACHBEARBEITUNG_ZURUECKGEWIESEN": "Zur Nachbearbeitung zurückgewiesen",
      "VERZICHTET": "Verzichtet"
    },
    "rolle": {
      "ADMINISTRATOR": "ADMINISTRATOR",
      "EFO_BETRIEBSLEITER": "Betriebsleiter",
      "EFO_BETRIEBSLEITER_STV": "Betriebsleiter Stv.",
      "EFO_BETRIEBSMITARBEITER": "Betriebsmitarbeiter",
      "PAD_SACHBEARBEITER": "PAD Sachbearbeiter",
      "PAD_SEKRETARIAT": "PAD Sekretariat"
    }
  },
  "file": {
    "preview": "Vorschau"
  },
  "tooltip": {
    "betrieb_select": "Betrieb auswählen.<br> Sie können als Administrator alle Aktionen für diesen Betrieb ausführen.",
    "korrespondenz_mail_edit": "Korrespondenzmail bearbeiten",
    "save": "Speichern",
    "delete": "Löschen",
    "edit": "Bearbeiten",
    "update_rolle": "Rolle ändern. <br>Wählen sie im Drop-down die gewünschte Rolle für den Benutzer aus und Bestätigen sie mit diesem Knopf",
    "accept": "Annhemen",
    "reject": "Ablehnen, es muss ein Ablehnungsgrund erfasst werden.",
    "betriebsleiter_change": "Betriebsleiter Wechsel beantragen",
    "berechtigung_beantragen": "Berechtigung beantragen",
    "history": "Historie",
    "view": "Ansicht",
    "formel_pruefen": "Formel prüfen",
    "remove_pruefer": "Prüfer entfernen",
    "set_pruefer": "Formel in prüfung nehmen",
    "formel_save": "Formel speichern. <br> Grün: Formel ist gespeichert. <br> Orange: Formel ist nicht gespeichert.",
    "formel_copy": "Formel kopieren",
    "formel_archive": "Formel archivieren",
    "file_preview": "Datei Vorschau",
    "file_download": "Datei herunterladen",
    "file_remove": "Datei löschen",
    "toggle_pruefungsnotes": "Prüfungsnotizen einblenden / ausblenden"
  }
}
