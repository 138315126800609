/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LohnherstellerDraftDTO { 
    name?: string;
    betriebsArt?: LohnherstellerDraftDTO.BetriebsArtEnum;
    postfach?: string;
    strasse?: string;
    plz?: string;
    ort?: string;
}
export namespace LohnherstellerDraftDTO {
    export type BetriebsArtEnum = 'ANDERER' | 'BETRIEB_MIT_SWISSMEDIC_BEWILLIGUNG' | 'DROGERIE' | 'OEFFENTLICHE_APOTHEKE' | 'SPITALAPOTHEKE';
    export const BetriebsArtEnum = {
        ANDERER: 'ANDERER' as BetriebsArtEnum,
        BETRIEBMITSWISSMEDICBEWILLIGUNG: 'BETRIEB_MIT_SWISSMEDIC_BEWILLIGUNG' as BetriebsArtEnum,
        DROGERIE: 'DROGERIE' as BetriebsArtEnum,
        OEFFENTLICHEAPOTHEKE: 'OEFFENTLICHE_APOTHEKE' as BetriebsArtEnum,
        SPITALAPOTHEKE: 'SPITALAPOTHEKE' as BetriebsArtEnum
    };
}