<div class="bew-footer">
  <div class="bew-footer-layout">
    <div *ngIf="signetLabel !== undefined" class="bew-footer-signet">
      <app-bew-signet [small]="true" [label]="signetLabel"></app-bew-signet>
    </div>

    <div *ngIf="copyrightText !== undefined" class="bew-footer-copyright">
      {{ copyrightText }}
    </div>

    <div *ngIf="aboutText !== undefined" class="bew-footer-about">
      {{ aboutText }}
    </div>

    <div
      *ngIf="footerNavigationItems !== undefined"
      class="bew-footer-navigation">
      <app-bew-footer-navigation
        [data]="footerNavigationItems"></app-bew-footer-navigation>
    </div>

    <div class="bew-footer-empty"></div>
  </div>
</div>
