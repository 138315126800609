<div class="bew-table-container">
  <button class="efo-button blue" (click)="back()">
    <i class="fa fa-backward"></i>
    {{ 'formel.history.overview.back' | translate }}
  </button>

  <app-bew-box
    [label]="
      ('formel.history.overview.title' | translate) +
      ' ( ' +
      name +
      ' / ' +
      betrieb +
      ' )'
    ">
    <app-efo-table
      #historyTable
      [searchable]="false"
      [pageSize]="1000"
      [totalElements]="totalElementsHistoryEntries"
      [totalPages]="totalPagesHistoryEntries"
      [numberOfElements]="numberOfElementsHistoryEntries"
      (searchContent)="searchHistory($event)">
      <tr table-header>
        <th>
          {{ 'formel.history.overview.create.username' | translate }}
        </th>
        <th>
          {{ 'formel.history.overview.create.date' | translate }}
        </th>
        <th>
          {{ 'formel.history.overview.state' | translate }}
        </th>
        <th></th>
      </tr>

      <tbody
        *ngIf="historyEntries?.length == 0"
        table-body
        class="efo-history-row">
        <tr *ngIf="historyEntries?.length == 0">
          <td colspan="5">{{ 'formel.history.overview.none' | translate }}</td>
        </tr>
      </tbody>

      <tbody
        *ngFor="let entry of historyEntries; index as i"
        table-body
        [class.efo-history-row]="showInfo[i]">
        <tr *ngIf="historyEntries?.length == 0">
          <td colspan="5">{{ 'formel.history.overview.none' | translate }}</td>
        </tr>
        <tr>
          <td>{{ getName(entry) }}</td>
          <td>{{ entry.createDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
          <td>{{ 'enum.state.' + entry.state | translate }}</td>

          <td>
            <button
              class="efo-button blue"
              *ngIf="
                entry.state == 'ZUR_NACHBEARBEITUNG_ZURUECKGEWIESEN' ||
                entry.state == 'MELDEBESTAETIGUNG_MIT_KOSTENFOLGE' ||
                entry.state == 'MELDEBESTAETIGUNG_OHNE_KOSTENFOLGE'
              "
              (click)="toggleNotes(i)"
              [ngbTooltip]="tooltipTogglePruefungsnotes"
              triggers="hover"
              [openDelay]="500"
              [closeDelay]="300">
              <i class="fa fa-note-sticky"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="showInfo[i]">
          <th>{{ 'formel.detail.allgemein.title' | translate }}</th>
          <th>{{ 'formel.detail.zusammensetzung.title' | translate }}</th>
          <th>{{ 'formel.detail.lagerung.title' | translate }}</th>
          <th>{{ 'formel.detail.verpackung.title' | translate }}</th>
        </tr>
        <tr *ngIf="showInfo[i]">
          <td>{{ entry.pruefungsNotes?.notesAllgemein }}</td>
          <td>{{ entry.pruefungsNotes?.notesZusammensetzung }}</td>
          <td>{{ entry.pruefungsNotes?.notesLagerung }}</td>
          <td>{{ entry.pruefungsNotes?.notesVerpackung }}</td>
        </tr>
      </tbody>
    </app-efo-table>
  </app-bew-box>
</div>

<ng-template #tooltipTogglePruefungsnotes>
  <div [innerHTML]="'tooltip.toggle_pruefungsnotes' | translate"></div>
</ng-template>
