import { Configuration } from './backend';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiConfiguration extends Configuration {
  constructor() {
    super();
  }

  override basePath = environment.apiUrl;
}
