/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Mengenverhaeltnis = '{\"code\": 1800, \"name\": \"AUSGEGLICHEN\", \"riskFactor\": 3.0}' | '{\"code\": 1801, \"name\": \"AUSSCHL_EIGENE_KUNDSCHAFT\", \"riskFactor\": 0.2}' | '{\"code\": 1802, \"name\": \"AUSSCHL_LOHNAUFTRAG\", \"riskFactor\": 5.0}' | '{\"code\": 1803, \"name\": \"HAUPTS_EIGENE_KUNDSCHAFT\", \"riskFactor\": 2.0}' | '{\"code\": 1804, \"name\": \"HAUPTS_LOHNAUFTRAG\", \"riskFactor\": 4.0}';

export const Mengenverhaeltnis = {
    _1800NameAUSGEGLICHENRiskFactor30: '{\"code\": 1800, \"name\": \"AUSGEGLICHEN\", \"riskFactor\": 3.0}' as Mengenverhaeltnis,
    _1801NameAUSSCHLEIGENEKUNDSCHAFTRiskFactor02: '{\"code\": 1801, \"name\": \"AUSSCHL_EIGENE_KUNDSCHAFT\", \"riskFactor\": 0.2}' as Mengenverhaeltnis,
    _1802NameAUSSCHLLOHNAUFTRAGRiskFactor50: '{\"code\": 1802, \"name\": \"AUSSCHL_LOHNAUFTRAG\", \"riskFactor\": 5.0}' as Mengenverhaeltnis,
    _1803NameHAUPTSEIGENEKUNDSCHAFTRiskFactor20: '{\"code\": 1803, \"name\": \"HAUPTS_EIGENE_KUNDSCHAFT\", \"riskFactor\": 2.0}' as Mengenverhaeltnis,
    _1804NameHAUPTSLOHNAUFTRAGRiskFactor40: '{\"code\": 1804, \"name\": \"HAUPTS_LOHNAUFTRAG\", \"riskFactor\": 4.0}' as Mengenverhaeltnis
};