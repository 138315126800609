/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Herstellungsprozess = '{\"code\": 1700, \"name\": \"ABFUELLEN\", \"riskFactor\": 1.0}' | '{\"code\": 1701, \"name\": \"ASEPTISCH\", \"riskFactor\": 5.0}' | '{\"code\": 1702, \"name\": \"ASEPTISCH_ENDSTERILISATION\", \"riskFactor\": 4.0}' | '{\"code\": 1703, \"name\": \"LOESEN_MISCHEN\", \"riskFactor\": 3.0}' | '{\"code\": 1704, \"name\": \"VERDUENNEN\", \"riskFactor\": 2.0}';

export const Herstellungsprozess = {
    _1700NameABFUELLENRiskFactor10: '{\"code\": 1700, \"name\": \"ABFUELLEN\", \"riskFactor\": 1.0}' as Herstellungsprozess,
    _1701NameASEPTISCHRiskFactor50: '{\"code\": 1701, \"name\": \"ASEPTISCH\", \"riskFactor\": 5.0}' as Herstellungsprozess,
    _1702NameASEPTISCHENDSTERILISATIONRiskFactor40: '{\"code\": 1702, \"name\": \"ASEPTISCH_ENDSTERILISATION\", \"riskFactor\": 4.0}' as Herstellungsprozess,
    _1703NameLOESENMISCHENRiskFactor30: '{\"code\": 1703, \"name\": \"LOESEN_MISCHEN\", \"riskFactor\": 3.0}' as Herstellungsprozess,
    _1704NameVERDUENNENRiskFactor20: '{\"code\": 1704, \"name\": \"VERDUENNEN\", \"riskFactor\": 2.0}' as Herstellungsprozess
};