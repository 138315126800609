import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  AuxDataKey,
  AuxDataProvider,
  EnhancedRoute,
  EnhancedRouteFunctions,
  EnhancedRoutes,
  PathAsString,
} from '../data/enhanced-route';
import {
  BreadcrumbData,
  BreadcrumbItem,
} from '../../bew/components/bew-breadcrumb/bew-breadcrumb.component';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  constructor(private readonly location: Location) {}

  createBreadcrumbData(root: EnhancedRoutes): BreadcrumbData {
    const breadcrumbItems: Array<BreadcrumbItem> = [];

    const location = this.location.path(false);
    const route = PathAsString.findByPathInRoot(root, location);
    if (route !== undefined) {
      this.appendIncludingParents(breadcrumbItems, route, false);
    }
    // reverse them
    breadcrumbItems.reverse();

    const home = {
      routerLinkCommands: '',
    };

    return { home: home, items: breadcrumbItems };
  }

  private appendIncludingParents(
    arrayToAdd: Array<BreadcrumbItem>,
    route: EnhancedRoute,
    active: boolean,
  ) {
    const maybeBreadcrumbConfig = BreadcrumbConfiguration.get(route);
    if (maybeBreadcrumbConfig !== undefined) {
      const path = PathAsString.get(route);
      const breadcrumbItem: BreadcrumbItem = {
        i18nKeyForLabel: maybeBreadcrumbConfig.getTextKey.toString(),
        action: {
          routerLinkCommands: [path],
        },
        active: active,
      };
      arrayToAdd.push(breadcrumbItem);
    }
    // ask parent
    const maybeParent = EnhancedRouteFunctions.getParent(route);
    if (maybeParent !== undefined) {
      this.appendIncludingParents(arrayToAdd, maybeParent, true);
    }
  }
}

export class BreadcrumbConfiguration implements AuxDataProvider {
  private static readonly key: AuxDataKey = 'breadcrumb_data';
  readonly getTextKey: String;

  constructor(getTextKey: String) {
    this.getTextKey = getTextKey;
  }

  get key(): AuxDataKey {
    return BreadcrumbConfiguration.key;
  }

  get value(): any {
    return this;
  }

  static get(route: EnhancedRoute): BreadcrumbConfiguration | undefined {
    const maybeData = EnhancedRouteFunctions.getAuxData(
      route,
      BreadcrumbConfiguration.key,
    );
    if (maybeData == undefined) {
      return undefined;
    }
    if (!(maybeData instanceof BreadcrumbConfiguration)) {
      throw `Invalid data, ${maybeData} should be of type breadcrumb configuration.`;
    }
    return maybeData;
  }
}
