<div class="bew-service-navigation" *ngIf="data !== undefined">
  <!-- menu items -->
  <ng-container *ngFor="let menuItem of data.menuItems">
    <a
      [appBewLinkAction]="menuItem.action"
      [class.bew-service-navigation-login]="menuItem.loginStyle"
      class="bew-service-navigation-menu">
      <span *ngIf="menuItem.loginStyle" class="bew-service-navigation-login"
        >&nbsp;</span
      >{{ menuItem.label }}</a
    >
  </ng-container>
  <!-- language selection / display -->
  <div class="bew-service-navigation-language-selector">
    <ng-container
      *ngFor="
        let languageItem of data.languageItems;
        last as last;
        index as index
      ">
      <a
        href="#"
        (click)="onLanguageSelected(languageItem, index)"
        class="bew-service-navigation-language"
        [class.bew-service-navigation-active]="languageItem.active"
        >{{ languageItem.label }}</a
      >
      <span *ngIf="!last">|</span>
    </ng-container>
  </div>
</div>
