import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormComponentWithDisabled,
  FormComponentWithLabel,
  InvokedEventComponent,
} from '../form-component';

@Component({
  selector: 'app-bew-button',
  templateUrl: './bew-button.component.html',
  styleUrls: ['./bew-button.component.scss'],
})
export class BewButtonComponent
  implements
    OnInit,
    FormComponentWithDisabled,
    FormComponentWithLabel,
    InvokedEventComponent
{
  @Input()
  label: string = 'NO_LABEL';
  @Input()
  type: string = 'primary';
  @Input()
  disabled: boolean = false;
  @Output()
  readonly invoke: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Default value is submit.
   */
  @Input()
  button_type: string = 'submit';

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    if (!this.disabled) {
      this.invoke.emit();
    }
  }
}
