import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormelApiService, FormelHistoryApiService } from '../../../backend';
import { BerechtigungService } from '../../../services/berechtigung.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-no-berechtigung-for-site',
  templateUrl: './error-no-berechtigung-for-site.component.html',
  styleUrl: './error-no-berechtigung-for-site.component.scss',
})
export class ErrorNoBerechtigungForSiteComponent {
  constructor(private readonly translateService: TranslateService) {}
}
