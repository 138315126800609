<div class="row">
  <div class="col-9">
    <select name="role" class="form-control" [(ngModel)]="betriebRolle">
      <option *ngFor="let rolle of betriebRollen" value="{{ rolle }}">
        {{ 'enum.rolle.' + rolle | translate }}
      </option>
    </select>
  </div>
  <div class="col-2">
    <button
      class="efo-button blue"
      (click)="berechtigungBeantragen()"
      [ngbTooltip]="tooltipBerechtigungBeantragen"
      triggers="hover"
      [openDelay]="500"
      [closeDelay]="300">
      <i class="fa fa-add"></i>
    </button>
  </div>
</div>

<ng-template #tooltipBerechtigungBeantragen>
  <div [innerHTML]="'tooltip.berechtigung_beantragen' | translate"></div>
</ng-template>
