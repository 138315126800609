<div
  class="bew-table-container"
  *ngIf="
    berechtigungService.isAdminOrHasAnyRolle([
      'EFO_BETRIEBSMITARBEITER',
      'EFO_BETRIEBSLEITER_STV',
      'EFO_BETRIEBSLEITER'
    ]) | async
  ">
  <div>
    <button
      class="efo-button blue"
      (click)="createDraft()"
      type="plus"
      [disabled]="!hasBetrieb">
      {{ 'formel.draft.overview.create' | translate }}
      <i class="fa fa-plus"></i>
    </button>
    <span *ngIf="!hasBetrieb" class="no-betrieb-label"
      >({{ 'error.common.no.betrieb.selected' | translate }})</span
    >
  </div>

  <app-bew-box
    [label]="
      ('formel.overview.draft' | translate) +
      ' ( ' +
      (berechtigungService.currentBetriebName() | translate) +
      ' )'
    ">
    <div class="bew-table-container">
      <app-efo-table
        #formelDraftTable
        [totalElements]="totalElementsFormelDraftsForBetrieb"
        [totalPages]="totalPagesFormelDraftsForBetrieb"
        [numberOfElements]="numberOfElementsFormelDraftsForBetrieb"
        [pageSize]="10"
        (searchContent)="searchDrafts($event)">
        <tr table-header>
          <th>
            {{ 'formel.overview.preaparat.name' | translate }}
            <app-table-column-sort
              [label]="'allgemein.praeparatName'"
              [sortBy]="formelDraftTable.sortConfiguration.sortBy"
              (sortByMe)="
                formelDraftTable.changeSorting($event)
              "></app-table-column-sort>
          </th>
          <th>
            {{ 'formel.overview.create.username' | translate }}
            <app-table-column-sort
              [label]="'createdUsername'"
              [sortBy]="formelDraftTable.sortConfiguration.sortBy"
              (sortByMe)="
                formelDraftTable.changeSorting($event)
              "></app-table-column-sort>
          </th>
          <th>
            {{ 'formel.overview.create.date' | translate }}
            <app-table-column-sort
              [label]="'createDate'"
              [sortBy]="formelDraftTable.sortConfiguration.sortBy"
              (sortByMe)="
                formelDraftTable.changeSorting($event)
              "></app-table-column-sort>
          </th>
          <th>
            {{ 'formel.overview.update.username' | translate }}
            <app-table-column-sort
              [label]="'lastMutationUsername'"
              [sortBy]="formelDraftTable.sortConfiguration.sortBy"
              (sortByMe)="
                formelDraftTable.changeSorting($event)
              "></app-table-column-sort>
          </th>
          <th>
            {{ 'formel.overview.update.date' | translate }}
            <app-table-column-sort
              [label]="'updateDate'"
              [sortBy]="formelDraftTable.sortConfiguration.sortBy"
              (sortByMe)="
                formelDraftTable.changeSorting($event)
              "></app-table-column-sort>
          </th>
          <th></th>
          <th></th>
        </tr>
        <tbody table-body>
          <tr *ngIf="formelDraftsForBetrieb?.length == 0">
            <td colspan="10">
              {{ 'formel.overview.none' | translate }}
            </td>
          </tr>
          <ng-container
            *ngFor="let formel of formelDraftsForBetrieb; index as i">
            <tr>
              <td>{{ formel.allgemein?.praeparatName }}</td>
              <td>{{ formel.createdUsername }}</td>
              <td>{{ formel.createDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
              <td>{{ formel.lastMutationUsername }}</td>
              <td>{{ formel.updateDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
              <td>
                <button
                  class="efo-button blue"
                  (click)="editDraft(formel.id)"
                  [ngbTooltip]="tooltipEdit"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-edit"></i>
                </button>
              </td>
              <td>
                <button
                  class="efo-button red"
                  (click)="deleteDraft(formel.id)"
                  [ngbTooltip]="tooltipDelete"
                  triggers="hover"
                  [openDelay]="500"
                  [closeDelay]="300">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </app-efo-table>
    </div>
  </app-bew-box>
</div>

<app-bew-box
  [label]="
    ('formel.overview.title' | translate) +
    ' ( ' +
    (berechtigungService.currentBetriebName() | translate) +
    ' )'
  "
  *ngIf="isAdmin || hasBetrieb">
  <div class="bew-table-container">
    <app-efo-table
      #formelnForBetriebTable
      [totalElements]="totalElementsFormelnForBetrieb"
      [totalPages]="totalPagesFormelnForBetrieb"
      [numberOfElements]="numberOfElementsFormelnForBetrieb"
      [pageSize]="10"
      (searchContent)="searchForBetrieb($event)">
      <tr table-header>
        <th>
          {{ 'formel.overview.preaparat.name' | translate }}
          <app-table-column-sort
            [label]="'allgemein.praeparatName'"
            [sortBy]="formelnForBetriebTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForBetriebTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.create.username' | translate }}
          <app-table-column-sort
            [label]="'createdUsername'"
            [sortBy]="formelnForBetriebTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForBetriebTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.create.date' | translate }}
          <app-table-column-sort
            [label]="'createDate'"
            [sortBy]="formelnForBetriebTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForBetriebTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.update.username' | translate }}
          <app-table-column-sort
            [label]="'lastMutationUsername'"
            [sortBy]="formelnForBetriebTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForBetriebTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.update.date' | translate }}
          <app-table-column-sort
            [label]="'updateDate'"
            [sortBy]="formelnForBetriebTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForBetriebTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.state' | translate }}
          <app-table-column-sort
            [label]="'state'"
            [sortBy]="formelnForBetriebTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForBetriebTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tbody table-body>
        <tr *ngIf="formelnForBetrieb?.length == 0">
          <td colspan="10">
            {{ 'formel.overview.none' | translate }}
          </td>
        </tr>
        <ng-container *ngFor="let formel of formelnForBetrieb; index as i">
          <tr>
            <td>{{ formel.allgemein?.praeparatName }}</td>
            <td>{{ formel.createdUsername }}</td>
            <td>{{ formel.createDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            <td>{{ formel.lastMutationUsername }}</td>
            <td>{{ formel.updateDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            <td>{{ 'enum.state.' + formel.state | translate }}</td>
            <td>
              <button
                class="efo-button blue"
                (click)="viewFormel(formel.id)"
                [ngbTooltip]="tooltipView"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-eye"></i>
              </button>
            </td>
            <td>
              <button
                *ngIf="
                  formel.state == 'IN_ARBEIT' ||
                  formel.state == 'ZUR_NACHBEARBEITUNG_ZURUECKGEWIESEN'
                "
                class="efo-button blue"
                (click)="edit(formel.id, formel.state)"
                [ngbTooltip]="tooltipEdit"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-edit"></i>
              </button>
            </td>
            <td>
              <button
                class="efo-button blue"
                (click)="showHistory(formel.id)"
                [ngbTooltip]="tooltipHistory"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-history"></i>
              </button>
            </td>
            <td>
              <button
                class="efo-button red"
                (click)="delete(formel.id)"
                [ngbTooltip]="tooltipDelete"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </app-efo-table>
  </div>
</app-bew-box>

<app-bew-box
  [label]="'formel.overview.pad.pruefung.self.title' | translate"
  *ngIf="isAdmin || isPadSachbearbeiter">
  <div class="bew-table-container">
    <app-efo-table
      #formelnForPadInPruefungWherePrueferTable
      [totalElements]="totalElementsFormelnForPadInPruefungWherePruefer"
      [totalPages]="totalPagesFormelnForPadInPruefungWherePruefer"
      [numberOfElements]="numberOfElementsFormelnForPadInPruefungWherePruefer"
      [pageSize]="10"
      (searchContent)="searchForPadInPruefungWherePruefer($event)">
      <tr table-header>
        <th>
          {{ 'formel.overview.preaparat.name' | translate }}
          <app-table-column-sort
            [label]="'allgemein.praeparatName'"
            [sortBy]="
              formelnForPadInPruefungWherePrueferTable.sortConfiguration.sortBy
            "
            (sortByMe)="
              formelnForPadInPruefungWherePrueferTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.create.username' | translate }}
          <app-table-column-sort
            [label]="'createdUsername'"
            [sortBy]="
              formelnForPadInPruefungWherePrueferTable.sortConfiguration.sortBy
            "
            (sortByMe)="
              formelnForPadInPruefungWherePrueferTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.create.date' | translate }}
          <app-table-column-sort
            [label]="'createDate'"
            [sortBy]="
              formelnForPadInPruefungWherePrueferTable.sortConfiguration.sortBy
            "
            (sortByMe)="
              formelnForPadInPruefungWherePrueferTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.update.username' | translate }}
          <app-table-column-sort
            [label]="'lastMutationUsername'"
            [sortBy]="
              formelnForPadInPruefungWherePrueferTable.sortConfiguration.sortBy
            "
            (sortByMe)="
              formelnForPadInPruefungWherePrueferTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.update.date' | translate }}
          <app-table-column-sort
            [label]="'updateDate'"
            [sortBy]="
              formelnForPadInPruefungWherePrueferTable.sortConfiguration.sortBy
            "
            (sortByMe)="
              formelnForPadInPruefungWherePrueferTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.state' | translate }}
          <app-table-column-sort
            [label]="'state'"
            [sortBy]="
              formelnForPadInPruefungWherePrueferTable.sortConfiguration.sortBy
            "
            (sortByMe)="
              formelnForPadInPruefungWherePrueferTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.betrieb.name' | translate }}
          <app-table-column-sort
            [label]="'betrieb'"
            [sortBy]="
              formelnForPadInPruefungWherePrueferTable.sortConfiguration.sortBy
            "
            (sortByMe)="
              formelnForPadInPruefungWherePrueferTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th></th>
        <th></th>
      </tr>
      <tbody table-body>
        <tr *ngIf="formelnForPadInPruefungWherePruefer?.length == 0">
          <td colspan="10">
            {{ 'formel.overview.none' | translate }}
          </td>
        </tr>
        <ng-container
          *ngFor="
            let formel of formelnForPadInPruefungWherePruefer;
            index as i
          ">
          <tr>
            <td>{{ formel.allgemein?.praeparatName }}</td>
            <td>{{ formel.createdUsername }}</td>
            <td>{{ formel.createDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            <td>{{ formel.lastMutationUsername }}</td>
            <td>{{ formel.updateDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            <td>{{ 'enum.state.' + formel.state | translate }}</td>
            <td>{{ formel.betrieb?.name }}</td>
            <td>
              <button
                class="efo-button blue"
                (click)="pruefen(formel.id)"
                [ngbTooltip]="tooltipFormelPruefen"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-person-circle-check"></i>
              </button>
            </td>
            <td>
              <button
                class="efo-button red"
                (click)="removePruefer(formel.id)"
                [ngbTooltip]="tooltipRemovePruefer"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-x"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </app-efo-table>
  </div>
</app-bew-box>

<app-bew-box
  [label]="'formel.overview.pad.pruefung.title' | translate"
  *ngIf="isAdmin || isPadSachbearbeiter">
  <div class="bew-table-container">
    <app-efo-table
      #formelnForPadInPruefungTable
      [totalElements]="totalElementsFormelnForPadInPruefung"
      [totalPages]="totalPagesFormelnForPadInPruefung"
      [numberOfElements]="numberOfElementsFormelnForPadInPruefung"
      [pageSize]="10"
      (searchContent)="searchForPadInPruefung($event)">
      <tr table-header>
        <th>
          {{ 'formel.overview.preaparat.name' | translate }}
          <app-table-column-sort
            [label]="'allgemein.praeparatName'"
            [sortBy]="formelnForPadInPruefungTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadInPruefungTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.create.username' | translate }}
          <app-table-column-sort
            [label]="'createdUsername'"
            [sortBy]="formelnForPadInPruefungTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadInPruefungTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.create.date' | translate }}
          <app-table-column-sort
            [label]="'createDate'"
            [sortBy]="formelnForPadInPruefungTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadInPruefungTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.update.username' | translate }}
          <app-table-column-sort
            [label]="'lastMutationUsername'"
            [sortBy]="formelnForPadInPruefungTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadInPruefungTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.update.date' | translate }}
          <app-table-column-sort
            [label]="'updateDate'"
            [sortBy]="formelnForPadInPruefungTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadInPruefungTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.state' | translate }}
          <app-table-column-sort
            [label]="'state'"
            [sortBy]="formelnForPadInPruefungTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadInPruefungTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.betrieb.name' | translate }}
          <app-table-column-sort
            [label]="'betrieb'"
            [sortBy]="formelnForPadInPruefungTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadInPruefungTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.pruefer' | translate }}
          <app-table-column-sort
            [label]="'pruefer'"
            [sortBy]="formelnForPadInPruefungTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadInPruefungTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th></th>
      </tr>
      <tbody table-body>
        <tr *ngIf="formelnForPadInPruefung?.length == 0">
          <td colspan="10">
            {{ 'formel.overview.none' | translate }}
          </td>
        </tr>
        <ng-container
          *ngFor="let formel of formelnForPadInPruefung; index as i">
          <tr>
            <td>{{ formel.allgemein?.praeparatName }}</td>
            <td>{{ formel.createdUsername }}</td>
            <td>{{ formel.createDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            <td>{{ formel.lastMutationUsername }}</td>
            <td>{{ formel.updateDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            <td>{{ 'enum.state.' + formel.state | translate }}</td>
            <td>{{ formel.betrieb?.name }}</td>
            <td>
              <div *ngIf="formel.pruefer">
                {{ formel.pruefer.firstName + ' ' + formel.pruefer.lastName }}
              </div>
            </td>
            <td>
              <button
                *ngIf="!formel.pruefer"
                class="efo-button blue"
                (click)="setPruefer(formel.id)"
                [ngbTooltip]="tooltipSetPruefer"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-hand"></i>
              </button>
              <button
                *ngIf="isAdmin && formel.pruefer"
                class="efo-button red"
                (click)="removePruefer(formel.id)"
                [ngbTooltip]="tooltipRemovePruefer"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-x"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </app-efo-table>
  </div>
</app-bew-box>

<app-bew-box
  [label]="'formel.overview.pad.all.title' | translate"
  *ngIf="isAdmin || isPadSachbearbeiter || isPadSekretariat">
  <div class="bew-table-container">
    <app-efo-table
      #formelnForPadAllTable
      [totalElements]="totalElementsFormelnForPadAll"
      [totalPages]="totalPagesFormelnForPadAll"
      [numberOfElements]="numberOfElementsFormelnForPadAll"
      [pageSize]="10"
      (searchContent)="searchForPadAll($event)">
      <tr table-header>
        <th>
          {{ 'formel.overview.preaparat.name' | translate }}
          <app-table-column-sort
            [label]="'allgemein.praeparatName'"
            [sortBy]="formelnForPadAllTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadAllTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.create.username' | translate }}
          <app-table-column-sort
            [label]="'createdUsername'"
            [sortBy]="formelnForPadAllTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadAllTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.create.date' | translate }}
          <app-table-column-sort
            [label]="'createDate'"
            [sortBy]="formelnForPadAllTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadAllTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.update.username' | translate }}
          <app-table-column-sort
            [label]="'lastMutationUsername'"
            [sortBy]="formelnForPadAllTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadAllTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.update.date' | translate }}
          <app-table-column-sort
            [label]="'updateDate'"
            [sortBy]="formelnForPadAllTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadAllTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.state' | translate }}
          <app-table-column-sort
            [label]="'state'"
            [sortBy]="formelnForPadAllTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadAllTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th>
          {{ 'formel.overview.betrieb.name' | translate }}
          <app-table-column-sort
            [label]="'betrieb'"
            [sortBy]="formelnForPadAllTable.sortConfiguration.sortBy"
            (sortByMe)="
              formelnForPadAllTable.changeSorting($event)
            "></app-table-column-sort>
        </th>
        <th></th>
        <th></th>
      </tr>
      <tbody table-body>
        <tr *ngIf="formelnForPadAll?.length == 0">
          <td colspan="5">
            {{ 'formel.overview.none' | translate }}
          </td>
        </tr>
        <ng-container *ngFor="let formel of formelnForPadAll; index as i">
          <tr>
            <td>{{ formel.allgemein?.praeparatName }}</td>
            <td>{{ formel.createdUsername }}</td>
            <td>{{ formel.createDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            <td>{{ formel.lastMutationUsername }}</td>
            <td>{{ formel.updateDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            <td>{{ 'enum.state.' + formel.state | translate }}</td>
            <td>{{ formel.betrieb?.name }}</td>
            <td>
              <button
                class="efo-button blue"
                (click)="viewFormel(formel.id)"
                [ngbTooltip]="tooltipView"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-eye"></i>
              </button>
            </td>
            <td>
              <button
                class="efo-button blue"
                (click)="showHistory(formel.id)"
                [ngbTooltip]="tooltipHistory"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-history"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </app-efo-table>
  </div>
</app-bew-box>

<ng-template #tooltipEdit>
  <div [innerHTML]="'tooltip.edit' | translate"></div>
</ng-template>
<ng-template #tooltipDelete>
  <div [innerHTML]="'tooltip.delete' | translate"></div>
</ng-template>
<ng-template #tooltipView>
  <div [innerHTML]="'tooltip.view' | translate"></div>
</ng-template>
<ng-template #tooltipHistory>
  <div [innerHTML]="'tooltip.history' | translate"></div>
</ng-template>
<ng-template #tooltipFormelPruefen>
  <div [innerHTML]="'tooltip.formel_pruefen' | translate"></div>
</ng-template>
<ng-template #tooltipRemovePruefer>
  <div [innerHTML]="'tooltip.remove_pruefer' | translate"></div>
</ng-template>
<ng-template #tooltipSetPruefer>
  <div [innerHTML]="'tooltip.set_pruefer' | translate"></div>
</ng-template>
