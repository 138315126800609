<app-bew-box
  label="{{ 'pad.detail.antraege.betriebsleiter.change.title' | translate }}">
  <div class="bew-table-container">
    <app-efo-table
      #antraegeTable
      [searchable]="false"
      [pageSize]="1000"
      [totalElements]="totalElementsAntraege"
      [totalPages]="totalPagesAntraege"
      [numberOfElements]="numberOfElementsAntraege"
      (searchContent)="searchBerechtigungAntraege($event)">
      <tr table-header>
        <th>
          {{
            'pad.detail.antraege.betriebsleiter.change.old.username' | translate
          }}
        </th>
        <th>
          {{
            'pad.detail.antraege.betriebsleiter.change.old.rolle' | translate
          }}
        </th>

        <th>
          {{
            'pad.detail.antraege.betriebsleiter.change.new.username' | translate
          }}
        </th>
        <th>
          {{
            'pad.detail.antraege.betriebsleiter.change.new.rolle' | translate
          }}
        </th>
        <th>
          {{ 'pad.detail.antraege.betriebsleiter.change.betrieb' | translate }}
        </th>
        <th></th>
      </tr>
      <tbody table-body>
        <tr *ngIf="antraege?.length == 0">
          <td colspan="6">
            {{ 'pad.detail.antraege.betriebsleiter.change.none' | translate }}
          </td>
        </tr>
        <ng-container *ngFor="let b of antraege; index as i">
          <tr>
            <td>{{ b.betriebsleiterOld?.benutzer?.username }}</td>
            <td>
              {{ 'enum.rolle.' + b.betriebsleiterOld?.rolle | translate }}
            </td>
            <td>{{ b.betriebsleiterNew?.benutzer?.username }}</td>
            <td>
              {{ 'enum.rolle.' + b.betriebsleiterNew?.rolle | translate }}
            </td>
            <td>{{ b.betriebsleiterOld?.betrieb?.name }}</td>

            <td>
              <button
                class="efo-button red"
                (click)="withdraw(b)"
                [ngbTooltip]="tooltipDelete"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </app-efo-table>
  </div>
</app-bew-box>

<ng-template #tooltipDelete>
  <div [innerHTML]="'tooltip.delete' | translate"></div>
</ng-template>
