<app-bew-box
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)"
  [erroneous]="erroneous"
  [label]="label"
  [showErrorIcon]="showErrorIcon">
  <!-- some intro text & icon -->
  <div
    *ngIf="showIcon"
    class="bew-margin-bottom-block bew-multi-upload-icon-body">
    <span
      class="bew-multi-upload-icon bew-multi-file-upload-container-icon"></span>
    <span class="bew-multi-upload-icon bew-multi-file-upload-arrow-icon"></span>
  </div>

  <div class="bew-margin-bottom-block">
    <p *ngIf="showWithFilesText">{{ withFilesText }}</p>
    <p *ngIf="showNoFilesText">{{ noFilesText }}</p>
  </div>

  <!-- A list of files already uploaded -->
  <ng-container
    *ngFor="let filename of filenamesOfUploadedFiles; let i = index">
    <app-bew-file-upload
      #fileItem
      (remove)="onRemoveFileAtIndex(i)"
      (download)="onDownloadFileAtIndex(i)"
      (preview)="onPreviewFileAtIndex(i, fileItem)"
      [disabled]="disabledInternal"
      [filename]="filename"
      label="">
    </app-bew-file-upload>
  </ng-container>

  <hr *ngIf="hasFiles" />

  <div class="bew-multi-upload-number-text">
    {{ numberOfFilesUploadedText }}
  </div>

  <ng-container *ngIf="hasNoticeText">
    <hr />
    <div class="bew-form-notice">
      {{ noticeText }}
    </div>
  </ng-container>

  <app-bew-button
    (invoke)="uploader.click()"
    [disabled]="disabledInternal"
    [label]="uploadFileButtonText"
    button_type="button"
    type="secondary">
  </app-bew-button>

  <input
    #uploader
    (change)="onFileSelected($event)"
    [disabled]="disabledInternal"
    class="bew-multi-upload-file-input"
    multiple="multiple"
    type="file" />

  <!-- additional children (if any); this can be used to display errors or additional information. -->
  <ng-content></ng-content>

  <!-- overlay (shows texts like "uploading files..." or "drop files here") -->
  <div
    *ngIf="overlayActive"
    class="bew-multi-upload-overlay-container-container">
    <div class="bew-multi-upload-overlay-container">
      <h3 class="bew-multi-upload-overlay-text">{{ resultingOverlayText }}</h3>
    </div>
  </div>
</app-bew-box>
