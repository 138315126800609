<div
  [class.inputfield]="!invalid"
  [class.invalid]="invalid"
  [class.floating-label]="invalid">
  <input
    #bewInput
    [(ngModel)]="value"
    [class.notice]="!invalid"
    [class.invalid]="invalid"
    [placeholder]="label"
    [disabled]="disabled"
    (blur)="onInputTouched()"
    (focus)="doShowResults()"
    (ngModelChange)="onInputValueChanged()"
    [maxLength]="maxlength || 2147483647"
    [minLength]="minlength || 0"
    [type]="type" />
  <label class="floating-label">{{ label }}</label>
  <span *ngIf="invalid" class="error">{{ error | translate }}</span>
  <span *ngIf="invalid" class="bew-input-invalid-icon"></span>

  <div *ngIf="showResults">
    <div class="results-container">
      <ul>
        <li *ngFor="let result of results" (click)="selectInputResult(result)">
          {{ result.name + '(' + result.source + ')' }}
        </li>
      </ul>
    </div>
  </div>
</div>
