import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  EfoTableComponent,
  SearchConfiguration,
} from '../../efo-table/efo-table.component';
import { BerechtigungDTO } from '../../../backend/model/berechtigungDTO';
import {
  BenutzerApiService,
  BerechtigungAntragApiService,
  BerechtigungAntragCreateDTO,
  BerechtigungAntragDTO,
  BerechtigungApiService,
  BetriebsleiterChangeAntragApiService,
  BetriebsleiterChangeAntragDTO,
} from '../../../backend';
import { BerechtigungService } from '../../../services/berechtigung.service';
import { BerechtigungUpdateDTO } from '../../../backend/model/berechtigungUpdateDTO';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import RolleEnum = BerechtigungDTO.RolleEnum;

@Component({
  selector: 'app-pad-detail',
  templateUrl: './pad-detail.component.html',
  styleUrl: './pad-detail.component.scss',
})
export class PadDetailComponent {
  @ViewChildren(EfoTableComponent) // @ts-ignore
  efoBenutzerTableComponent!: QueryList<EfoTableComponent>;

  @Output()
  public reload = new EventEmitter<void>();

  currentBerechtigung?: BerechtigungDTO;
  berechtigungen?: Array<BerechtigungDTO> = [];

  berechtigungenForPad?: Array<BerechtigungDTO> = [];
  berechtigungAntraegeForPad?: Array<BerechtigungAntragDTO> = [];
  berechtigungAntraegeForBetriebsleiter?: Array<BerechtigungAntragDTO> = [];
  berechtigungAntraegeForBetriebsleiterChange?: Array<BetriebsleiterChangeAntragDTO> =
    [];
  berechtigungenBetriebsleiter?: Array<BerechtigungDTO> = [];

  totalElementsBenutzer: number = 0;
  totalPagesBenutzer: number = 0;
  numberOfElementsBenutzer: number = 0;

  totalElementsAntraegePAD: number = 0;
  totalPagesAntraegePAD: number = 0;
  numberOfElementsAntraegePAD: number = 0;

  totalElementsAntraegeBetriebsleiter: number = 0;
  totalPagesAntraegeBetriebsleiter: number = 0;
  numberOfElementsAntraegeBetriebsleiter: number = 0;

  totalElementsAntraegeBetriebsleiterChange: number = 0;
  totalPagesAntraegeBetriebsleiterChange: number = 0;
  numberOfElementsAntraegeBetriebsleiterChange: number = 0;

  totalElementsBerechtigungBetriebsleiter: number = 0;
  totalPagesBerechtigungBetriebsleiter: number = 0;
  numberOfElementsBerechtigungBetriebsleiter: number = 0;

  constructor(
    private readonly berechtigungApiService: BerechtigungApiService,
    private readonly betriebsleiterChangeAntragApiService: BetriebsleiterChangeAntragApiService,
    public readonly berechtigungService: BerechtigungService,
    private readonly benutzerApiService: BenutzerApiService,
    private readonly berechtigungAntragApiService: BerechtigungAntragApiService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.berechtigungService.currentBerechtigung.subscribe((b) => {
      if (b != null) {
        this.currentBerechtigung = b;
      } else {
        this.currentBerechtigung = undefined;
      }
    });
  }

  ngAfterViewInit() {
    this.berechtigungService.currentBerechtigung.subscribe((b) => {
      if (b != null) {
        if (
          b &&
          b.betrieb == null &&
          (b.rolle == RolleEnum.PADSACHBEARBEITER ||
            b.rolle == RolleEnum.PADSEKRETARIAT ||
            b.rolle == RolleEnum.ADMINISTRATOR)
        ) {
          this.searchAll();
        }
      }
    });
  }

  isBerechtigt() {
    return (
      this.currentBerechtigung &&
      (this.currentBerechtigung?.rolle == RolleEnum.PADSACHBEARBEITER ||
        this.currentBerechtigung?.rolle == RolleEnum.PADSEKRETARIAT ||
        this.currentBerechtigung?.rolle == RolleEnum.ADMINISTRATOR)
    );
  }

  searchAll() {
    this.efoBenutzerTableComponent.forEach((table) => table.search());
  }

  findAllBerechtigungenForPad(searchConfiguration: SearchConfiguration) {
    this.berechtigungApiService
      .findAllForPad(
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.berechtigungenForPad = page.content;
        if (page.totalElements) {
          this.totalElementsBenutzer = page.totalElements;
        } else {
          this.totalElementsBenutzer = 0;
        }
        if (page.totalPages) {
          this.totalPagesBenutzer = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsBenutzer = page.numberOfElements;
        }
        this.cdr.detectChanges();
      });
  }

  findAllAntraegeForPad(searchConfiguration: SearchConfiguration) {
    this.berechtigungAntragApiService.findAllForPAD().subscribe((page) => {
      this.berechtigungAntraegeForPad = page.content;
      if (page.totalElements) {
        this.totalElementsAntraegePAD = page.totalElements;
      } else {
        this.totalElementsAntraegePAD = 0;
      }
      if (page.totalPages) {
        this.totalPagesAntraegePAD = page.totalPages;
      }
      if (page.numberOfElements) {
        this.numberOfElementsAntraegePAD = page.numberOfElements;
      }
      this.hasAntrageForPad();
      this.cdr.detectChanges();
    });
  }
  findAllAntraegeForBetriebsleiter(searchConfiguration: SearchConfiguration) {
    this.berechtigungAntragApiService
      .findAllForBetriebsleiter()
      .subscribe((page) => {
        this.berechtigungAntraegeForBetriebsleiter = page.content;
        if (page.totalElements) {
          this.totalElementsAntraegeBetriebsleiter = page.totalElements;
        } else {
          this.totalElementsAntraegeBetriebsleiter = 0;
        }
        if (page.totalPages) {
          this.totalPagesAntraegeBetriebsleiter = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsAntraegeBetriebsleiter = page.numberOfElements;
        }
        this.cdr.detectChanges();
      });
  }
  findAllAntraegeForBetriebsleiterChange(
    searchConfiguration: SearchConfiguration,
  ) {
    this.betriebsleiterChangeAntragApiService
      .findAllForBetriebsleiter()
      .subscribe((page) => {
        this.berechtigungAntraegeForBetriebsleiterChange = page.content;
        if (page.totalElements) {
          this.totalElementsAntraegeBetriebsleiterChange = page.totalElements;
        } else {
          this.totalElementsAntraegeBetriebsleiterChange = 0;
        }
        if (page.totalPages) {
          this.totalPagesAntraegeBetriebsleiterChange = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsAntraegeBetriebsleiterChange =
            page.numberOfElements;
        }
        this.cdr.detectChanges();
      });
  }

  findAllBetriebsleiter(searchConfiguration: SearchConfiguration) {
    console.log(searchConfiguration);
    this.berechtigungApiService
      .findAllBetriebsleiter(
        searchConfiguration.searchTerm,
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.berechtigungenBetriebsleiter = page.content;
        if (page.totalElements) {
          this.totalElementsBerechtigungBetriebsleiter = page.totalElements;
        } else {
          this.totalElementsBerechtigungBetriebsleiter = 0;
        }
        if (page.totalPages) {
          this.totalPagesBerechtigungBetriebsleiter = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsBerechtigungBetriebsleiter =
            page.numberOfElements;
        }
        this.cdr.detectChanges();
      });
  }

  hasberechtigteForPad() {
    return (
      this.berechtigungenForPad != undefined &&
      this.berechtigungenForPad.length > 0
    );
  }

  delete(b: BerechtigungDTO) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = [
      'modal.confirm.delete.pad_berechtigung',
    ];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';

    modalRef.result.then((result) => {
      if (result) {
        if (b && b.id) {
          this.berechtigungApiService
            .deletePadBerechtigung(b.id)
            .subscribe(() => this.reload.emit());
        }
      }
    });
  }

  deleteBetriebsleiter(b: BerechtigungDTO) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = [
      'modal.confirm.delete.betriebsleiter_berechtigung',
    ];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';

    modalRef.result.then((result) => {
      if (result) {
        if (b && b.id) {
          this.berechtigungApiService
            .deleteBetriebBerechtigung(b.id)
            .subscribe(() => this.reload.emit());
        }
      }
    });
  }

  updateRolle(udpate: BerechtigungUpdateDTO) {
    this.berechtigungApiService
      .updatePadBerechtigung(udpate)
      .subscribe(() => this.reload.emit());
  }

  accept(berechtigungAntrag: BerechtigungAntragDTO) {
    if (berechtigungAntrag && berechtigungAntrag.id) {
      this.berechtigungAntragApiService
        .accept(berechtigungAntrag.id)
        .subscribe(() => this.reload.emit());
    }
  }

  reject(berechtigungAntrag: BerechtigungAntragDTO, remark: string) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = ['modal.confirm.reject.antrag'];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';

    modalRef.result.then((result) => {
      if (result) {
        if (berechtigungAntrag && berechtigungAntrag.id) {
          this.berechtigungAntragApiService
            .reject(berechtigungAntrag.id, remark)
            .subscribe(() => this.reload.emit());
        }
      }
    });
  }

  acceptBetriebsleiterChange(antrag: BetriebsleiterChangeAntragDTO) {
    if (antrag && antrag.id) {
      this.betriebsleiterChangeAntragApiService
        .accept(antrag.id)
        .subscribe(() => this.reload.emit());
    }
  }

  rejectBetriebsleiterChange(
    antrag: BetriebsleiterChangeAntragDTO,
    remark: string,
  ) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = ['modal.confirm.reject.antrag'];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';
    modalRef.result.then((result) => {
      if (result) {
        if (antrag && antrag.id) {
          this.betriebsleiterChangeAntragApiService
            .reject(antrag.id, remark)
            .subscribe(() => this.reload.emit());
        }
      }
    });
  }

  hasAntrageForPad() {
    return (
      this.berechtigungAntraegeForPad &&
      this.berechtigungAntraegeForPad.length > 0
    );
  }

  protected readonly BerechtigungDTO = BerechtigungDTO;
  protected readonly BerechtigungAntragCreateDTO = BerechtigungAntragCreateDTO;
  protected readonly RolleEnum = RolleEnum;
}
