/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {CloneFormelDTO} from '../model/cloneFormelDTO';
import {CopyFormelDTO} from '../model/copyFormelDTO';
import {CreateFormelDTO} from '../model/createFormelDTO';
import {FormelDTO} from '../model/formelDTO';
import {FormelRisikoDTO} from '../model/formelRisikoDTO';
import {FormelRisikoParameterDTO} from '../model/formelRisikoParameterDTO';
import {PageFormelDTO} from '../model/pageFormelDTO';
import {UpdateBetriebNotesDTO} from '../model/updateBetriebNotesDTO';
import {UpdateFormelContentDTO} from '../model/updateFormelContentDTO';
import {UpdateFormelStateDTO} from '../model/updateFormelStateDTO';
import {UpdatePruefungsNotesDTO} from '../model/updatePruefungsNotesDTO';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class FormelApiService {

  protected basePath = 'http://localhost:8094';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public achiveFormel(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public achiveFormel(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public achiveFormel(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public achiveFormel(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling achiveFormel.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('put',`${this.basePath}/api/formel/archive/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public calculateFormelRisiko(body: FormelRisikoParameterDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
  public calculateFormelRisiko(body: FormelRisikoParameterDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
  public calculateFormelRisiko(body: FormelRisikoParameterDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
  public calculateFormelRisiko(body: FormelRisikoParameterDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling calculateFormelRisiko.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<number>('post',`${this.basePath}/api/formel/calculateRisiko`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cloneFormel(body: CloneFormelDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public cloneFormel(body: CloneFormelDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public cloneFormel(body: CloneFormelDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public cloneFormel(body: CloneFormelDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling cloneFormel.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/formel/clone`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public copyFormel(body: CopyFormelDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public copyFormel(body: CopyFormelDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public copyFormel(body: CopyFormelDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public copyFormel(body: CopyFormelDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling copyFormel.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/formel/copy`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public create(body: CreateFormelDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public create(body: CreateFormelDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public create(body: CreateFormelDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public create(body: CreateFormelDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling create.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/formel/create`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public delete(id: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public delete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public delete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public delete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling delete.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<string>('delete',`${this.basePath}/api/formel/delete/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findByIdForBetrieb(id: string, observe?: 'body', reportProgress?: boolean): Observable<FormelDTO>;
  public findByIdForBetrieb(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormelDTO>>;
  public findByIdForBetrieb(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormelDTO>>;
  public findByIdForBetrieb(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling findByIdForBetrieb.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<FormelDTO>('get',`${this.basePath}/api/formel/${encodeURIComponent(String(id))}/betrieb`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findByIdForPad(id: string, observe?: 'body', reportProgress?: boolean): Observable<FormelDTO>;
  public findByIdForPad(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormelDTO>>;
  public findByIdForPad(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormelDTO>>;
  public findByIdForPad(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling findByIdForPad.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<FormelDTO>('get',`${this.basePath}/api/formel/${encodeURIComponent(String(id))}/pad`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFormelRisiko(id: string, observe?: 'body', reportProgress?: boolean): Observable<FormelRisikoDTO>;
  public getFormelRisiko(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormelRisikoDTO>>;
  public getFormelRisiko(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormelRisikoDTO>>;
  public getFormelRisiko(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getFormelRisiko.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<FormelRisikoDTO>('get',`${this.basePath}/api/formel/${encodeURIComponent(String(id))}/risiko`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removePruefer(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public removePruefer(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public removePruefer(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public removePruefer(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling removePruefer.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('put',`${this.basePath}/api/formel/preuefer/remove/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param betriebId
   * @param search
   * @param sortBy
   * @param page
   * @param size
   * @param direction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchForBetrieb(betriebId: string, search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'body', reportProgress?: boolean): Observable<PageFormelDTO>;
  public searchForBetrieb(betriebId: string, search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageFormelDTO>>;
  public searchForBetrieb(betriebId: string, search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageFormelDTO>>;
  public searchForBetrieb(betriebId: string, search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (betriebId === null || betriebId === undefined) {
      throw new Error('Required parameter betriebId was null or undefined when calling searchForBetrieb.');
    }






    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (betriebId !== undefined && betriebId !== null) {
      queryParameters = queryParameters.set('betriebId', <any>betriebId);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (direction !== undefined && direction !== null) {
      queryParameters = queryParameters.set('direction', <any>direction);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<PageFormelDTO>('get',`${this.basePath}/api/formel/searchForBetrieb`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param search
   * @param sortBy
   * @param page
   * @param size
   * @param direction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchForPadAll(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'body', reportProgress?: boolean): Observable<PageFormelDTO>;
  public searchForPadAll(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageFormelDTO>>;
  public searchForPadAll(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageFormelDTO>>;
  public searchForPadAll(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (direction !== undefined && direction !== null) {
      queryParameters = queryParameters.set('direction', <any>direction);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<PageFormelDTO>('get',`${this.basePath}/api/formel/searchForPadAll`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param search
   * @param sortBy
   * @param page
   * @param size
   * @param direction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchForPadInPruefung(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'body', reportProgress?: boolean): Observable<PageFormelDTO>;
  public searchForPadInPruefung(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageFormelDTO>>;
  public searchForPadInPruefung(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageFormelDTO>>;
  public searchForPadInPruefung(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (direction !== undefined && direction !== null) {
      queryParameters = queryParameters.set('direction', <any>direction);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<PageFormelDTO>('get',`${this.basePath}/api/formel/searchForPadInPruefung`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param search
   * @param sortBy
   * @param page
   * @param size
   * @param direction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchForPadInPruefungWherePruefer(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'body', reportProgress?: boolean): Observable<PageFormelDTO>;
  public searchForPadInPruefungWherePruefer(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageFormelDTO>>;
  public searchForPadInPruefungWherePruefer(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageFormelDTO>>;
  public searchForPadInPruefungWherePruefer(search?: string, sortBy?: string, page?: number, size?: number, direction?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (direction !== undefined && direction !== null) {
      queryParameters = queryParameters.set('direction', <any>direction);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<PageFormelDTO>('get',`${this.basePath}/api/formel/searchForPadInPruefungWherePruefer`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBetriebNotes(body: UpdateBetriebNotesDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public updateBetriebNotes(body: UpdateBetriebNotesDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public updateBetriebNotes(body: UpdateBetriebNotesDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public updateBetriebNotes(body: UpdateBetriebNotesDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateBetriebNotes.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/formel/update/betrieb/notes`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateContent(body: UpdateFormelContentDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public updateContent(body: UpdateFormelContentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public updateContent(body: UpdateFormelContentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public updateContent(body: UpdateFormelContentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateContent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/formel/update/content`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePruefer(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updatePruefer(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updatePruefer(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updatePruefer(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updatePruefer.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('put',`${this.basePath}/api/formel/preuefer/update/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePruefungsNotes(body: UpdatePruefungsNotesDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public updatePruefungsNotes(body: UpdatePruefungsNotesDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public updatePruefungsNotes(body: UpdatePruefungsNotesDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public updatePruefungsNotes(body: UpdatePruefungsNotesDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updatePruefungsNotes.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/formel/update/pruefung/notes`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateState(body: UpdateFormelStateDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public updateState(body: UpdateFormelStateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public updateState(body: UpdateFormelStateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public updateState(body: UpdateFormelStateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateState.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>('post',`${this.basePath}/api/formel/update/state`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
