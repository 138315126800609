import { Component, Input, OnInit } from '@angular/core';
import { LinkAction } from '../link-action/link-action';

@Component({
  selector: 'app-bew-footer-navigation',
  templateUrl: './bew-footer-navigation.component.html',
  styleUrls: ['./bew-footer-navigation.component.scss'],
})
export class BewFooterNavigationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input()
  data?: FooterNavigationItems;
}

export type FooterNavigationItems = Array<FooterNavigationItem>;

export interface FooterNavigationItem {
  label: string;
  action: LinkAction;
}
