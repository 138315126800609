import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-column-sort',
  templateUrl: './table-column-sort.component.html',
  styleUrl: './table-column-sort.component.scss',
})
export class TableColumnSortComponent {
  @Output()
  sortByMe = new EventEmitter<SortConfiguration>();

  @Input()
  label: string = '';

  @Input()
  sortBy: string = '';

  direction: string | 'ASC' | 'DESC' | undefined = undefined;

  updateSorting() {
    if (this.label == this.sortBy) {
      switch (this.direction) {
        case undefined: {
          this.direction = 'DESC';
          break;
        }
        case 'DESC': {
          this.direction = 'ASC';
          break;
        }
        case 'ASC': {
          this.direction = undefined;
          break;
        }
      }
    } else {
      this.direction = undefined;
    }
  }

  click() {
    this.updateSorting();
    this.sortByMe.emit({
      sortBy: this.label,
      direction: this.direction,
    });
  }

  ngOnChanges(): void {
    if (this.sortBy != this.label) {
      this.direction = undefined;
    } else {
      this.updateSorting();
    }
  }
}

export interface SortConfiguration {
  sortBy: string;
  direction: string | 'ASC' | 'DESC' | undefined;
}
