import { Component } from '@angular/core';
import {
  FormelApiService,
  FormelDTO,
  FormelHistoryApiService,
  FormelHistoryEntryDTO,
} from '../../../../backend';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PathAsString } from '../../../../data/enhanced-route';
import { routes } from '../../../../app-routing.module';
import { BerechtigungService } from '../../../../services/berechtigung.service';
import { SearchConfiguration } from '../../../efo-table/efo-table.component';

@Component({
  selector: 'app-formel-history-overview',
  templateUrl: './formel-history-overview.component.html',
  styleUrl: './formel-history-overview.component.scss',
})
export class FormelHistoryOverviewComponent {
  historyEntries?: Array<FormelHistoryEntryDTO> = [];
  totalElementsHistoryEntries: number = 0;
  numberOfElementsHistoryEntries: number = 0;
  totalPagesHistoryEntries: number = 0;

  id: string = '';
  name: string = '';
  betrieb: string = '';

  showInfo: boolean[] = [];

  searchSubscription: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly formelHistoryApiService: FormelHistoryApiService,
    private route: ActivatedRoute,
    public berechtigungService: BerechtigungService,
    private readonly formelApiService: FormelApiService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
      this.setFormelData(this.id);
    });
  }

  setFormelData(id: string) {
    const isPad = this.berechtigungService.isPad();
    if (isPad) {
      this.formelApiService.findByIdForPad(id).subscribe((formelDTO) => {
        this.setName(formelDTO);
        this.setBetrieb(formelDTO);
      });
    } else {
      this.formelApiService.findByIdForBetrieb(id).subscribe((formelDTO) => {
        this.setName(formelDTO);
        this.setBetrieb(formelDTO);
      });
    }
  }

  setName(formelDTO: FormelDTO) {
    const name = formelDTO.allgemein?.praeparatName;
    if (name) {
      this.name = name;
    }
  }

  setBetrieb(formelDTO: FormelDTO) {
    const betrieb = formelDTO.betrieb?.name;
    if (betrieb) {
      this.betrieb = betrieb;
    }
  }

  searchHistory(searchConfiguration: SearchConfiguration) {
    this.formelHistoryApiService
      .get(
        this.id,
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.historyEntries = page.content;
        if (this.historyEntries) {
          this.showInfo = this.historyEntries.map(() => false);
        }
        if (page.totalElements) {
          this.totalElementsHistoryEntries = page.totalElements;
        } else {
          this.totalElementsHistoryEntries = 0;
        }
        if (page.totalPages) {
          this.totalPagesHistoryEntries = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElementsHistoryEntries = page.numberOfElements;
        } else {
          this.numberOfElementsHistoryEntries = 0;
        }
      });
  }

  public back() {
    this.router.navigate([PathAsString.get(routes.formeln)]);
  }

  public toggleNotes(index: number): void {
    this.showInfo[index] = !this.showInfo[index];
  }

  public getName(entry: FormelHistoryEntryDTO) {
    if (entry.firstName != undefined && entry.lastName != undefined) {
      return entry.firstName + ' ' + entry.lastName;
    }
    if (entry.firstName != undefined) {
      return entry.firstName;
    }
    if (entry.lastName != undefined) {
      return entry.lastName;
    }
    return '';
  }

  protected readonly event = event;
}
