<div class="container mt-2">
  <div class="row">
    <div
      class="col-12 title"
      translate="rechtliches.title"
      style="margin-top: 10px"></div>
  </div>
  <div class="row">
    <div class="col-12 subtitle" translate="rechtliches.stand"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.1"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.1"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.2"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.2"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.3"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.3"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.4"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.4.1"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">2.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.4.2"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">3.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.4.3"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.5"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.5.1"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">2.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.5.2"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">3.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.5.3"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">4.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.5.4"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">5.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.5.5"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.6"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.6"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.7"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.7"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.8"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.8"></div>
  </div>
  <div class="row">
    <div
      class="col-12 paragraph-title"
      translate="rechtliches.paragraph.title.9"></div>
  </div>
  <div class="row">
    <div class="tab"></div>
    <div class="numbering">1.</div>
    <div class="col-11" translate="rechtliches.paragraph.text.9"></div>
  </div>
  <div class="row">
    <div style="width: 4%"></div>
    <div class="col-11" translate="rechtliches.helpline"></div>
  </div>
  <div class="row col-12">
    <div style="width: 4%"></div>
    <div translate="rechtliches.telefon"></div>
    <div class="col-7">
      <a href="tel:+{{ 'rechtliches.telefon.nr' | translate }}">{{
        'rechtliches.telefon.nr.text' | translate
      }}</a>
    </div>
  </div>
  <div class="row col-12">
    <div style="width: 4%"></div>
    <div translate="rechtliches.email"></div>
    <div class="col-7">
      <a href="mailto:+{{ 'rechtliches.email.url' | translate }}">{{
        'rechtliches.email.url' | translate
      }}</a>
    </div>
  </div>
  <div class="row col-12">
    <div style="width: 4%"></div>
    <div
      translate="rechtliches.info"
      style="
        font-weight: bold;
        -ms-flex: 0 0 22%;
        flex: 0 0 22%;
        max-width: 22%;
      "></div>
    <div class="col-7">
      <a href="{{ 'rechtliches.info.url' | translate }}">{{
        'rechtliches.info.url' | translate
      }}</a>
    </div>
  </div>
</div>
