<app-bew-box [label]="'antrag.pad.title' | translate">
  <div class="row">
    <div class="col-9">
      <select
        id="pad-antrag"
        name="pad-antrag"
        class="form-control"
        [(ngModel)]="selectedRolle">
        <option></option>
        <option *ngFor="let rolle of padRollen" value="{{ rolle }}">
          {{ 'enum.rolle.' + rolle | translate }}
        </option>
      </select>
    </div>
    <div class="col-3">
      <button
        class="efo-button blue"
        (click)="berechtigungBeantragen()"
        [ngbTooltip]="tooltipBerechtigungBeantragen"
        triggers="hover"
        [openDelay]="500"
        [closeDelay]="300">
        <i class="fa fa-add"></i>
      </button>
    </div>
  </div>
</app-bew-box>

<ng-template #tooltipBerechtigungBeantragen>
  <div [innerHTML]="'tooltip.berechtigung_beantragen' | translate"></div>
</ng-template>
