<div
  [class.inputfield]="!invalid"
  [class.invalid]="invalid"
  [class.floating-label]="invalid">
  <select
    [(ngModel)]="value"
    [class.notice]="!invalid"
    [class.invalid]="invalid"
    [class.none-selected]="this.value == '' || this.value == undefined"
    [disabled]="disabled"
    (blur)="onInputTouched()"
    (ngModelChange)="onInputValueChanged()">
    <option></option>
    <option *ngFor="let item of items" value="{{ item }}">
      {{ prefix + item | translate }}
    </option>
  </select>
  <label class="floating-label">{{ label }}</label>
  <span *ngIf="invalid" class="error">{{ error | translate }}</span>
  <span *ngIf="invalid" class="bew-select-invalid-icon"></span>
</div>
