export interface FileFilter {
  apply(file: File): FilterResult;
}

export abstract class FilterResult {}

export class FilerResultAccepted extends FilterResult {}

/**
 * The file is accepted but it's not a desired file (the user is presented a warning, something like: "This
 * file type is accepted but we prefer PDFs" ... or something like this).
 */
export class FilerResultUndesiredFile extends FilterResult {
  constructor(public readonly text: string) {
    super();
  }
}

/**
 * The file is not accepted (won't upload).
 */
export class FilerResultRejectedFile extends FilterResult {
  constructor(public readonly text: string) {
    super();
  }
}
