import { Injectable } from '@angular/core';
import { FileApiService } from '../backend/api/fileApi.service';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private fileApiService: FileApiService,
    private sanitizer: DomSanitizer,
  ) {}

  async upload(file: File): Promise<UploadedFile> {
    let presentFilename = '';
    if (file.name !== undefined && file.name !== null) {
      presentFilename = file.name;
    }
    const uploadResult = this.fileApiService.uploadForm(file);
    const fileId = await uploadResult.toPromise();
    if (fileId != undefined) {
      return { id: fileId, name: presentFilename };
    } else {
      return { id: 'ERROR_UNDEFINED', name: presentFilename };
    }
  }

  async download(id: string, name: string) {
    this.fileApiService.download(id).subscribe((content) => {
      saveAs(content, name);
    });
  }

  async preview(id: string, name: string): Promise<Blob> {
    return await firstValueFrom(this.fileApiService.download(id));
  }
}

/**
 * An uploaded file. Contains the name and an ID.
 */
export interface UploadedFile {
  id: string;
  name: string;
}
