/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Produktionsmenge = '{\"code\": 2000, \"name\": \"FLUESSIG_1\", \"riskFactor\": 5.0}' | '{\"code\": 2001, \"name\": \"FLUESSIG_2\", \"riskFactor\": 4.0}' | '{\"code\": 2002, \"name\": \"FLUESSIG_3\", \"riskFactor\": 3.0}' | '{\"code\": 2003, \"name\": \"FLUESSIG_4\", \"riskFactor\": 2.0}' | '{\"code\": 2004, \"name\": \"FLUESSIG_5\", \"riskFactor\": 1.0}' | '{\"code\": 2100, \"name\": \"FEST_1\", \"riskFactor\": 5.0}' | '{\"code\": 2101, \"name\": \"FEST_2\", \"riskFactor\": 4.0}' | '{\"code\": 2102, \"name\": \"FEST_3\", \"riskFactor\": 3.0}' | '{\"code\": 2103, \"name\": \"FEST_4\", \"riskFactor\": 2.0}' | '{\"code\": 2104, \"name\": \"FEST_5\", \"riskFactor\": 1.0}' | '{\"code\": 2200, \"name\": \"HALBFEST_ZAEPF_1\", \"riskFactor\": 5.0}' | '{\"code\": 2201, \"name\": \"HALBFEST_ZAEPF_2\", \"riskFactor\": 4.0}' | '{\"code\": 2202, \"name\": \"HALBFEST_ZAEPF_3\", \"riskFactor\": 3.0}' | '{\"code\": 2203, \"name\": \"HALBFEST_ZAEPF_4\", \"riskFactor\": 2.0}' | '{\"code\": 2204, \"name\": \"HALBFEST_ZAEPF_5\", \"riskFactor\": 1.0}' | '{\"code\": 2300, \"name\": \"HALBFEST_1\", \"riskFactor\": 5.0}' | '{\"code\": 2301, \"name\": \"HALBFEST_2\", \"riskFactor\": 4.0}' | '{\"code\": 2302, \"name\": \"HALBFEST_3\", \"riskFactor\": 3.0}' | '{\"code\": 2303, \"name\": \"HALBFEST_4\", \"riskFactor\": 2.0}' | '{\"code\": 2304, \"name\": \"HALBFEST_5\", \"riskFactor\": 1.0}' | '{\"code\": 2400, \"name\": \"AUGENTR_1\", \"riskFactor\": 5.0}' | '{\"code\": 2401, \"name\": \"AUGENTR_2\", \"riskFactor\": 4.0}' | '{\"code\": 2402, \"name\": \"AUGENTR_3\", \"riskFactor\": 3.0}' | '{\"code\": 2403, \"name\": \"AUGENTR_4\", \"riskFactor\": 2.0}' | '{\"code\": 2404, \"name\": \"AUGENTR_5\", \"riskFactor\": 1.0}';

export const Produktionsmenge = {
    _2000NameFLUESSIG1RiskFactor50: '{\"code\": 2000, \"name\": \"FLUESSIG_1\", \"riskFactor\": 5.0}' as Produktionsmenge,
    _2001NameFLUESSIG2RiskFactor40: '{\"code\": 2001, \"name\": \"FLUESSIG_2\", \"riskFactor\": 4.0}' as Produktionsmenge,
    _2002NameFLUESSIG3RiskFactor30: '{\"code\": 2002, \"name\": \"FLUESSIG_3\", \"riskFactor\": 3.0}' as Produktionsmenge,
    _2003NameFLUESSIG4RiskFactor20: '{\"code\": 2003, \"name\": \"FLUESSIG_4\", \"riskFactor\": 2.0}' as Produktionsmenge,
    _2004NameFLUESSIG5RiskFactor10: '{\"code\": 2004, \"name\": \"FLUESSIG_5\", \"riskFactor\": 1.0}' as Produktionsmenge,
    _2100NameFEST1RiskFactor50: '{\"code\": 2100, \"name\": \"FEST_1\", \"riskFactor\": 5.0}' as Produktionsmenge,
    _2101NameFEST2RiskFactor40: '{\"code\": 2101, \"name\": \"FEST_2\", \"riskFactor\": 4.0}' as Produktionsmenge,
    _2102NameFEST3RiskFactor30: '{\"code\": 2102, \"name\": \"FEST_3\", \"riskFactor\": 3.0}' as Produktionsmenge,
    _2103NameFEST4RiskFactor20: '{\"code\": 2103, \"name\": \"FEST_4\", \"riskFactor\": 2.0}' as Produktionsmenge,
    _2104NameFEST5RiskFactor10: '{\"code\": 2104, \"name\": \"FEST_5\", \"riskFactor\": 1.0}' as Produktionsmenge,
    _2200NameHALBFESTZAEPF1RiskFactor50: '{\"code\": 2200, \"name\": \"HALBFEST_ZAEPF_1\", \"riskFactor\": 5.0}' as Produktionsmenge,
    _2201NameHALBFESTZAEPF2RiskFactor40: '{\"code\": 2201, \"name\": \"HALBFEST_ZAEPF_2\", \"riskFactor\": 4.0}' as Produktionsmenge,
    _2202NameHALBFESTZAEPF3RiskFactor30: '{\"code\": 2202, \"name\": \"HALBFEST_ZAEPF_3\", \"riskFactor\": 3.0}' as Produktionsmenge,
    _2203NameHALBFESTZAEPF4RiskFactor20: '{\"code\": 2203, \"name\": \"HALBFEST_ZAEPF_4\", \"riskFactor\": 2.0}' as Produktionsmenge,
    _2204NameHALBFESTZAEPF5RiskFactor10: '{\"code\": 2204, \"name\": \"HALBFEST_ZAEPF_5\", \"riskFactor\": 1.0}' as Produktionsmenge,
    _2300NameHALBFEST1RiskFactor50: '{\"code\": 2300, \"name\": \"HALBFEST_1\", \"riskFactor\": 5.0}' as Produktionsmenge,
    _2301NameHALBFEST2RiskFactor40: '{\"code\": 2301, \"name\": \"HALBFEST_2\", \"riskFactor\": 4.0}' as Produktionsmenge,
    _2302NameHALBFEST3RiskFactor30: '{\"code\": 2302, \"name\": \"HALBFEST_3\", \"riskFactor\": 3.0}' as Produktionsmenge,
    _2303NameHALBFEST4RiskFactor20: '{\"code\": 2303, \"name\": \"HALBFEST_4\", \"riskFactor\": 2.0}' as Produktionsmenge,
    _2304NameHALBFEST5RiskFactor10: '{\"code\": 2304, \"name\": \"HALBFEST_5\", \"riskFactor\": 1.0}' as Produktionsmenge,
    _2400NameAUGENTR1RiskFactor50: '{\"code\": 2400, \"name\": \"AUGENTR_1\", \"riskFactor\": 5.0}' as Produktionsmenge,
    _2401NameAUGENTR2RiskFactor40: '{\"code\": 2401, \"name\": \"AUGENTR_2\", \"riskFactor\": 4.0}' as Produktionsmenge,
    _2402NameAUGENTR3RiskFactor30: '{\"code\": 2402, \"name\": \"AUGENTR_3\", \"riskFactor\": 3.0}' as Produktionsmenge,
    _2403NameAUGENTR4RiskFactor20: '{\"code\": 2403, \"name\": \"AUGENTR_4\", \"riskFactor\": 2.0}' as Produktionsmenge,
    _2404NameAUGENTR5RiskFactor10: '{\"code\": 2404, \"name\": \"AUGENTR_5\", \"riskFactor\": 1.0}' as Produktionsmenge
};